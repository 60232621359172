import { Form } from "react-bootstrap";
import { MatterRecordableItemForInvoiceModel } from "models/view/MatterRecordableItemForInvoiceModel";
import Loader from "components/Loader";
import moment from "moment";
import { DateFormat } from "utils/constants";
import Field from "components/Fields/Field";
import { formatCurrency } from "utils/misc";

type Props = {
  isLoading: boolean,
  items: MatterRecordableItemForInvoiceModel[],
}

export default function ViewMatterInvoiceRecordableItems(props: Props) {
  return (
    <Form.Group className="d-table">
      <div className="lp-box-list">
        {props.isLoading && <Loader inlineLoader />}

        {props.items.length > 0 &&
          props.items.map((x: MatterRecordableItemForInvoiceModel) => (
            <div key={x.id} className="lp-box-list-item read-only">
              <span className="type-icon date">
                {moment(x?.date).format(DateFormat.Moment) + (x.user ? ' - ' + x.user?.name : '')}
              </span>

              <div className="lp-box-list-full-row">
                <Field label={'Description'} value={x.description} />
              </div>

              <Field
                label={'Amount Charged'}
                value={
                  <>
                    <span>{formatCurrency(x.amountCharged)}</span>
                    {x.adjustmentAmountCharged > 0 &&
                      <span className="positive-value">{'+' + formatCurrency(x.adjustmentAmountCharged)}</span>
                    }
                    {x.adjustmentAmountCharged < 0 &&
                      <span className="negative-value">{formatCurrency(x.adjustmentAmountCharged)}</span>
                    }
                  </>
                }
              />

              <Field
                label={'VAT Value'}
                value={formatCurrency(x.calculatedVatValue)}
                className="highlighted"
              />
            </div>
          ))
        }
        {props.items.length === 0 && (
          <div className="lp-list-empty">No unbilled recordable items found</div>
        )}
      </div>
    </Form.Group>
  );
}
