import { useEffect, useState } from "react";
import { deleteMatterRecordableItem, getMatterRecordableItem } from "actions/matter";
import { Col, Row } from "react-bootstrap";
import Field from "components/Fields/Field";
import Loader from "components/Loader/index";
import { DateFormat } from "utils/constants";
import moment from "moment";
import { MatterRecordableItemModel } from "models/view/MatterRecordableItemModel";
import { formatCurrency } from "utils/misc";
import useSlidingPanelActions from "actions/slidingPanel";
import { useAppSelector } from "hooks/appSelector";
import EditMatterRecordableEvent from "../EditMatterRecordableEvent/EditMatterRecordableEvent";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { ModalState } from "state/modalSlice";
import useModalActions from "actions/modal";
import useGridActions from "actions/grid";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

type Props = {
  matterId: string,
  recordableEventId: string,
  recoradbleItemId: string,
  isMatterClosed?: boolean,
  isBilled?: boolean,
  onEditSubmitCallback?: Function,
  showMoreButtons?: boolean,
}

export default function ViewMatterRecordableItem(props: Props) {
  const [matterRecordableItem, setMatterRecordableItem] = useState<MatterRecordableItemModel | undefined>(undefined);
  const [loading, setIsLoading] = useState<boolean>(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const modalActions = useModalActions();
  const gridActions = useGridActions();
  const currentGridState = useAppSelector((state) => state.grid);
  const loggedInUser = useAppSelector((state) => state.user);

  useEffect(() => {
    setIsLoading(true);
    getMatterRecordableItem(props.matterId, props.recordableEventId, props.recoradbleItemId).then((response) => {
      setMatterRecordableItem(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });

    if(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterRecordableEvents) &&
      !props.isMatterClosed && !props.isBilled) {
      slidingPanelActions.setOnEdit(() => {
        slidingPanelActions.clearSlidingPanel();

        slidingPanelActions.setSlidingPanel(
        {
          isShown: true,
          width: "55rem",
          title: "Edit Recordable Event",
          children: <EditMatterRecordableEvent
            matterId={props.matterId}
            recordableEventId={props.recordableEventId}
            onSubmitCallback={() => {
              props.onEditSubmitCallback && props.onEditSubmitCallback();
              onChildPanelClose();
            }}
          />,
          onCancel: onChildPanelClose
        });
      });
    }

    if (loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterRecordableEvents) && 
      !props.isMatterClosed && props.showMoreButtons) {
      slidingPanelActions.setMoreButtons([{
        type: ActionButtonTypes.Delete,
        callback: () => onClickDelete(),
      }]);
    }
  }, [props.recoradbleItemId]);

  const onChildPanelClose = () => {
    slidingPanelActions.clearSlidingPanel();
    slidingPanelActions.setSlidingPanel({
      ...currentSlidingPanelState,
    });
  }

  const onClickDelete = () => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this Matter Recordable Item?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallback(props.matterId, props.recordableEventId, props.recoradbleItemId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  };

  const deleteCallback = (matterId: string, recordableEventId: string, recordableItemId: string) => {
    const gridState = currentGridState;
    modalActions.toggleModalLoading();
    deleteMatterRecordableItem(matterId, recordableEventId, recordableItemId).then(() => {
      gridActions.setGridRowData(gridState.rowData.filter((x: MatterRecordableItemModel) => x.id !== recordableItemId));
      modalActions.toggleModalShownStatus();
      slidingPanelActions.clearSlidingPanel();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
  };

  return (
    <>
      {loading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col>
          <Field
            label={"Date"}
            value={matterRecordableItem?.date ? moment(matterRecordableItem?.date).format(DateFormat.Moment) : "—"}
          />
        </Col>
        <Col>
          <Field
            label={"Recordable Event"}
            value={matterRecordableItem?.recordableEventDisplayName}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Fee Earner"}
            value={matterRecordableItem?.user?.name}
          />
        </Col>
        <Col>
          <Field
            label={"Fee Earner Level"}
            value={matterRecordableItem?.feeEarnerLevel?.name}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Activity"}
            value={matterRecordableItem?.activity?.name}
          />
        </Col>
        <Col>
          <Field
            label={"Activity Code"}
            value={matterRecordableItem?.activity?.code}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Description"}
            value={matterRecordableItem?.description}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Start Time"}
            value={matterRecordableItem?.startTime ? moment(matterRecordableItem?.startTime, "HH:mm:ss").format(DateFormat.MomentTime) : "—"}
          />
        </Col>
        <Col>
          <Field
            label={"End Time"}
            value={matterRecordableItem?.endTime ? moment(matterRecordableItem?.endTime, "HH:mm:ss").format(DateFormat.MomentTime) : "—"}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Number of Minutes"}
            value={matterRecordableItem?.numberOfMinutes}
          />
        </Col>
        <Col>
          <Field
            label={"Number of Units"}
            value={matterRecordableItem?.numberOfUnits}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Charging Scheme"}
            value={matterRecordableItem?.chargingScheme?.name}
          />
        </Col>
        <Col>
          <Field
            label={"Charge Rate"}
            value={formatCurrency(matterRecordableItem?.chargeRate)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Amount Charged"}
            value={formatCurrency(matterRecordableItem?.amountCharged)}
          />
        </Col>
        <Col>
          <Field
            label={"Invoice Ref Number"}
            value={matterRecordableItem?.matterInvoice?.invoiceRefNumber}
          />
        </Col>
      </Row>
      {matterRecordableItem?.matterInvoiceId &&
        <Row>
          <Col>
            <Field
              label={"Amount Invoiced"}
              value={formatCurrency(matterRecordableItem?.adjustedAmountChargedOnInvoice)}
            />
          </Col>
          <Col>
            <Field
              label={"Calculated VAT Value"}
              value={formatCurrency(matterRecordableItem?.calculatedVATValueOnInvoice)}
            />
          </Col>
        </Row>
      }
      <Row>
        <Col>
          <Field
            label={"Client"}
            value={matterRecordableItem?.matterParticipatingEntity?.name}
          />
        </Col>
      </Row>
    </>
  );
}
