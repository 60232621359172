import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { SlidingPanelModel } from "models/SlidingPanelModel";
import { useDispatch } from "react-redux";
import {
  setSlidingPanelData,
  setOnEditFunction,
  toggleSlidingPanel,
  clearSlidingPanelData,
  setMoreButtonsFunction,
} from "state/slidingPanelSlice";

const useSlidingPanelActions = () => {
  const dispatch = useDispatch();

  const setSlidingPanel = (data: SlidingPanelModel) => {
    dispatch(setSlidingPanelData(data));
  };

  const setOnEdit = (onEdit: Function) => {
    dispatch(setOnEditFunction(onEdit));
  };

  const setMoreButtons = (moreButtons: { type: ActionButtonTypes, callback: Function }[]) => {
    dispatch(setMoreButtonsFunction(moreButtons));
  };

  const toggleSlidingPanelShownStatus = () => {
    dispatch(toggleSlidingPanel());
  };

  const clearSlidingPanel = () => {
    dispatch(clearSlidingPanelData());
  };

  return {
    setSlidingPanel,
    setOnEdit,
    setMoreButtons,
    toggleSlidingPanelShownStatus,
    clearSlidingPanel
  };
}

export default useSlidingPanelActions;
