import {
  MdDashboard,
  MdBusinessCenter,
  MdAccountBalanceWallet,
  MdOutlinePayment,
  MdMoreTime
} from 'react-icons/md';
import { FaAddressBook, FaFileInvoice } from 'react-icons/fa';
import { IoCalendar, IoSettingsOutline } from 'react-icons/io5';
import { HiIdentification } from 'react-icons/hi';
import { BsFillFileEarmarkDiffFill } from 'react-icons/bs';
import { UserPermissionsNames } from 'enums/UserPermissionsNames';

export type NavbarMenuItem = {
  id: string,
  title: string,
  path: string,
  icon?: JSX.Element,
  permission?: UserPermissionsNames,
  atLeastOnePermision?: UserPermissionsNames[],
  children?: NavbarMenuItem[]
}

export const getNavBarMenuItems = (isDiaryManager: boolean, isDelegated: boolean) => {
  const NavBarMenu: NavbarMenuItem[] = [
    {
      id: 'dashboard',
      title: 'Dashboard',
      path: '/',
      icon: <MdDashboard />
    },
    {
      id: 'matter',
      title: 'Matters',
      path: '/matter',
      icon: <MdBusinessCenter />,
      atLeastOnePermision: [UserPermissionsNames.ViewMatters, UserPermissionsNames.ManageMatters]
    },
    {
      id: 'lte',
      title: 'Trading Entities',
      path: '/lte',
      icon: <HiIdentification />,
      atLeastOnePermision: [UserPermissionsNames.ViewAllLTEs, UserPermissionsNames.ManageAllLTEs]
    },
    {
      id: 'addressBook',
      title: 'Address Book',
      path: '/addressBook',
      icon: <FaAddressBook />,
      atLeastOnePermision: [UserPermissionsNames.ViewEntities, UserPermissionsNames.ManageEntities]
    },
    {
      id: 'chargingScheme',
      title: 'Charging Schemes',
      path: '/chargingScheme',
      icon: <BsFillFileEarmarkDiffFill />,
      atLeastOnePermision: [UserPermissionsNames.ViewChargingSchemes, UserPermissionsNames.ManageChargingSchemes]
    },
    {
      id: 'settings',
      title: 'Settings',
      path: '/settings',
      icon: <IoSettingsOutline />,
      atLeastOnePermision: [UserPermissionsNames.ViewDefaultSettings, UserPermissionsNames.ManageDefaultSettings, UserPermissionsNames.ViewLTESettings, UserPermissionsNames.ManageLTESettings],
      children: [
        {
          id: 'accountSettings',
          title: 'Accounts Settings',
          path: '/settings/accounts',
        },
        {
          id: 'entitySettings',
          title: 'Address Book Settings',
          path: '/settings/address-book',
        },
        {
          id: 'chargingSchemeSettings',
          title: 'Charging Scheme Settings',
          path: '/settings/charging-scheme',
        },
        {
          id: 'taxonomySettings',
          title: 'Taxonomy Settings',
          path: '/settings/taxonomy',
          atLeastOnePermision: [UserPermissionsNames.ViewLTESettings, UserPermissionsNames.ManageLTESettings],
        },
        {
          id: 'templateSettings',
          title: 'Template Settings',
          path: '/settings/template',
          atLeastOnePermision: [UserPermissionsNames.ViewLTESettings, UserPermissionsNames.ManageLTESettings],
        }
      ]
    },
    {
      id: 'chartOfAccounts',
      title: 'Chart Of Accounts',
      path: '/chartOfAccounts',
      icon: <MdAccountBalanceWallet />,
      atLeastOnePermision: [UserPermissionsNames.ViewChartOfAccounts, UserPermissionsNames.ManageChartOfAccounts]
    },
    {
      id: 'invoice',
      title: 'Invoices',
      path: '/invoice',
      icon: <FaFileInvoice />,
      permission: UserPermissionsNames.ViewInvoicePages,
      children: [
        {
          id: 'allInvoices',
          title: 'All',
          path: '/invoice/all',
        },
        {
          id: 'draftInvoices',
          title: 'Draft',
          path: '/invoice/draft',
        },
        {
          id: 'awaitingPaymentInvoices',
          title: 'Awaiting Payment',
          path: '/invoice/awaitingPayment',
        },
        {
          id: 'paidInvoices',
          title: 'Paid',
          path: '/invoice/paid',
        }
      ]
    },
    {
      id: 'billsToPay',
      title: 'Bills To Pay',
      path: '/billsToPay',
      icon: <MdOutlinePayment />,
      atLeastOnePermision: [UserPermissionsNames.ViewBillsToPay, UserPermissionsNames.ManageBillsToPay]
    },
    {
      id: 'timeAndIncidentalExpenses',
      title: 'Time & Incidental Expenses',
      path: '/timeAndIncidentalExpenses',
      icon: <MdMoreTime />,
      permission: UserPermissionsNames.ViewTimeAndIncidentalExpenses
    },
    ...(isDiaryManager ? [{
      id: 'calendarForDiaryManager',
      title: 'Calendar',
      path: '/calendar',
      icon: <IoCalendar />,
      children: [
        {
          id: 'userCalendar',
          title: 'My Calendar',
          path: '/calendar',
          atLeastOnePermision: [UserPermissionsNames.ViewCalendars, UserPermissionsNames.ManageCalendars]
        },
        {
          id: 'freeBusyCalenndar',
          title: 'Free/Busy Calendar',
          path: '/freeBusyCalendar',
          atLeastOnePermision: [UserPermissionsNames.ViewCalendars, UserPermissionsNames.ManageCalendars]
        },
        {
          id: 'diaryManagerCalendar',
          title: 'Diary Manager Calendar',
          path: '/diaryManagerCalendar',
        }
      ]
    }] : []),
    ...(!isDiaryManager ? [{
      id: 'calendar',
      title: 'Calendar',
      path: '/calendar',
      icon: <IoCalendar />,
      atLeastOnePermision: [UserPermissionsNames.ViewCalendars, UserPermissionsNames.ManageCalendars],
      children: [
        {
          id: 'userCalendar',
          title: 'My Calendar',
          path: '/calendar',
        },
        {
          id: 'freeBusyCalenndar',
          title: 'Free/Busy Calendar',
          path: '/freeBusyCalendar',
        }
      ]
    }] : []),
    /*{
      id: 'mailbox',
      title: 'Mailbox',
      path: '/mailbox',
      icon: <MdEmail />
    },
    {
      id: 'reports',
      title: 'Reports',
      path: '/reports',
      icon: <MdInsights />
    },
    {
      id: 'search',
      title: 'Search',
      path: '/search',
      icon: <MdSearch />
    },
    {
      id: 'help',
      title: 'Help & Support',
      path: '/help',
      icon: <MdHelpOutline />
    },*/
  ];

  return NavBarMenu;
}