import { ColDef } from "ag-grid-community";
import { LawPageTradingEntityModel } from "models/view/LawPageTradingEntityModel";
import CheckboxCellRenderer from "components/Grid/CheckboxCellRenderer";
import store from "state/store";
import { changeLteStatus, deleteLte, getLteTypes, lteHasMatters } from "actions/lte";
import { NavigateFunction } from "react-router-dom";
import { setRowData } from "state/gridSlice";
import { ModalState, setModalData, setModalErrors, toggleModal, toggleModalLoadingState } from "state/modalSlice";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { MdWarning } from "react-icons/md";
import { DropdownFilter } from "components/Grid/GridFilters/Filters/DropdownFilter";
import { getRegulators } from "actions/user";
import { BooleanFilter } from "components/Grid/GridFilters/Filters/BooleanFilter";
import { DropdownFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/DropdownFloatingFilter";
import { BooleanFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/BooleanFloatingFilter";
import { CheckboxFormatter } from "components/Grid/ValueFormatters/CheckboxFormatter";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

const viewCallback = (id: string, navigate: NavigateFunction) => {
  navigate(`/lte/${id}`);
};

const changeStatusModal = (id: string) => {
  let bodyMessage: React.ReactNode;
  const gridState = store.getState().grid;
  if (!gridState.rowData.find((x: LawPageTradingEntityModel) => x.id == id).isActive) {
    bodyMessage = "Are you sure you want to activate this LTE?";
  } else {
    bodyMessage = <div className="lp-modal-warning">
      <MdWarning />Deactivating this LTE will also deactivate all users that are active and linked to it!<br/>
      Are you sure you want to change the status for this LTE?
    </div>
  }
  let modal: ModalState = {
    title: "Confirmation",
    body: bodyMessage,
    onAction: () => changeStatus(id),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
};

const changeStatus = (id: string) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  changeLteStatus(id).then(() => {
    let index: number = gridState.rowData.findIndex((x: any) => x.id == id);
    let newEntry: LawPageTradingEntityModel = { ...gridState.rowData[index], isActive: !gridState.rowData[index].isActive };
    let newArray: Array<any> = [...gridState.rowData];
    newArray[index] = newEntry;
    store.dispatch(setRowData(newArray));
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

const deleteModalPotentialWarningBecauseOfExistingMatters = async (id: string) => {
  lteHasMatters(id).then((result) => {
    if(result.data == false) {
      return deleteModal(id);
    }
    let modal: ModalState = {
      title: "Warning!",
      body: <div className="lp-modal-warning">
      <MdWarning />Deleting this LTE will also delete all linked resources to it including users and matters!<br/>
      Are you sure you want to delete this LTE?
      </div>,
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallback(id),
      show: false
    }
    store.dispatch(setModalData(modal));
    store.dispatch(toggleModal());
  });
};

const deleteModal = (id: string) => {
  let modal: ModalState = {
    title: "Delete confirmation",
    body: "Are you sure you want to delete this LTE?",
    actionText: "Delete",
    actionVariant: "danger",
    onAction: () => deleteCallback(id),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
};

const deleteCallback = (id: string) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  deleteLte(id).then(() => {
    store.dispatch(setRowData(gridState.rowData.filter((x: any) => x.id !== id)));
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

export const getColumnDefs = () => {
  const loggedInUser = store.getState().user;
  const buttonsColumnWidth = loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageAllLTEs) ? 105 : 65;
  
  const columnDefs: (ColDef<LawPageTradingEntityModel> | {excludeFromExport: boolean})[] = [
    {
      headerName: 'Legal Name',
      field: 'legalName',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Code',
      field: 'code',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Trading Name',
      field: 'tradingName',
      filter: 'agTextColumnFilter'
    },
    {
      headerName: 'Type',
      field: 'lawPageTradingEntityType.name',
      filter: DropdownFilter,
      filterParams: {
        property: 'lawPageTradingEntityTypeId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getLteTypes
      },
      minWidth: 220
    },
    {
      headerName: 'Company Registration No.',
      field: 'companyRegistrationNo',
      filter: 'agTextColumnFilter'
    },
    {
      headerName: 'Regulator',
      field: 'regulator.name',
      filter: DropdownFilter,
      filterParams: {
        property: 'regulatorId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getRegulators
      },
      minWidth: 220
    },
    {
      headerName: 'Regulator Registration Reference',
      field: 'regulatorReference',
      filter: 'agTextColumnFilter'
    },
    {
      headerName: 'VAT Registration No.',
      field: 'vatRegistrationNo',
      filter: 'agTextColumnFilter'
    },
    {
      headerName: 'Is Active',
      field: 'isActive',
      minWidth: 100,
      width: 100,
      filter: BooleanFilter,
      filterParams: {
        property: 'isActive',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: BooleanFloatingFilter,
      floatingFilterComponentParams: {
        values: [{
          id: 'true',
          name: 'Yes'
        },
        {
          id: 'false',
          name: 'No'
        },
      ]},
      cellRenderer: CheckboxCellRenderer,
      valueFormatter: CheckboxFormatter
    },
    {
      cellClass: 'lp-actions-cell',
      suppressHeaderMenuButton: true,
      suppressColumnsToolPanel: true,
      cellRendererSelector: (params) => {
        return {
          component: ActionsCellRenderer,
          params: {
            buttonsData: [
              {
                type: ActionButtonTypes.View,
                callback: (id: string, navigate: NavigateFunction) => viewCallback(id, navigate)
              },
              ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageAllLTEs) ? [{
                type: params.data?.isActive ? ActionButtonTypes.Deactivate : ActionButtonTypes.Activate, 
                callback: (id: string) => changeStatusModal(id)
              }] : []),
              ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageAllLTEs) ? [{
                type: ActionButtonTypes.Delete,
                callback: (id: string) => deleteModalPotentialWarningBecauseOfExistingMatters(id)
              }] : [])
            ]
          },
        }
      },
      excludeFromExport: true,
      suppressSizeToFit: true,
      floatingFilter: false,
      sortable: false,
      minWidth: buttonsColumnWidth,
      maxWidth: buttonsColumnWidth,
      width: buttonsColumnWidth,
      suppressMovable: true,
      pinned: 'right',
      resizable: false
    }
  ];
  return columnDefs;
}
