import { ColDef } from "ag-grid-community";
import { MatterModel } from "models/view/MatterModel";
import RiskRatingCellRenderer from "components/Grid/RiskRatingCellRenderer";
import ConflictCellRenderer from "components/Grid/ConflictCellRenderer";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { NavigateFunction } from "react-router-dom";
import { ModalState, setModalData, setModalErrors, toggleModal, toggleModalLoadingState } from "state/modalSlice";
import store from "state/store";
import { deleteMatter, getConflicts, getFundingAssuranceChecks, getMatterTypes, getMatterTypesWithoutMatterStages, getRiskRatings } from 'actions/matter';
import { setRowData } from "state/gridSlice";
import { DropdownFilter } from "components/Grid/GridFilters/Filters/DropdownFilter";
import { DropdownFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/DropdownFloatingFilter";
import { getChargingSchemes } from "actions/chargingSchemes";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

const viewCallback = (id: string, navigate: NavigateFunction) => {
  navigate(`/matter/${id}`, {state: { "edit": false}});
};

const deleteModal = (id: string) => {
  let modal: ModalState = {
    title: "Delete confirmation",
    body: "Are you sure you want to delete this Matter?",
    actionText: "Delete",
    actionVariant: "danger",
    onAction: () => deleteCallback(id),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
};

const deleteCallback = (id: string) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  deleteMatter(id).then(() => {
    store.dispatch(setRowData(gridState.rowData.filter((x: any) => x.id !== id)));
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

export const getColumnDefs = () => {
  const loggedInUser = store.getState().user;
  const buttonsColumnWidth = loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.DeleteMatters) ? 105 : 65;
  
  const columnDefs: (ColDef<MatterModel> | {excludeFromExport: boolean})[] = [
    { 
      headerName: 'Matter Ref',
      field: 'fileNumberWithDisplayName',
      filter: 'agTextColumnFilter',
      suppressSizeToFit: true,
      minWidth: 300,
      width: 300
    },
    { 
      headerName: 'Description',
      field: 'description',
      filter: 'agTextColumnFilter',
      suppressSizeToFit: true,
      minWidth: 450,
      width: 450,
    },
    { 
      headerName: 'Type',
      field: 'matterType.name',
      filter: DropdownFilter,
      filterParams: {
        property: 'matterTypeId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getMatterTypesWithoutMatterStages
      },
      minWidth: 220
    },
    { 
      headerName: 'Stage',
      field: 'matterStage.name',
      filter: DropdownFilter,
      filterParams: {
        property: 'matterStageId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getMatterTypes
      },
      minWidth: 220
    },
    { 
      headerName: 'Conflict',
      field: 'conflictStatus.name',
      cellRenderer: ConflictCellRenderer,
      filter: DropdownFilter,
      filterParams: {
        property: 'conflictStatusId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getConflicts
      },
      minWidth: 220
    },
    { 
      headerName: 'Funding Assurance Check',
      field: 'fundingAssuranceStatus.name',
      cellRenderer: ConflictCellRenderer,
      filter: DropdownFilter,
      filterParams: {
        property: 'fundingAssuranceStatusId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getFundingAssuranceChecks
      },
      minWidth: 220
    },
    { 
      headerName: 'Risk Rating',
      field: 'riskRating.name',
      cellRenderer: RiskRatingCellRenderer,
      filter: DropdownFilter,
      filterParams: {
        property: 'riskRatingId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getRiskRatings
      },
      minWidth: 220
    },
    { 
      headerName: 'Charging Scheme',
      field: 'chargingScheme.name', 
      filter: DropdownFilter,
      filterParams: {
        property: 'chargingSchemeId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getChargingSchemes
      },
      minWidth: 220
    },
    { 
      excludeFromExport: true,
      cellClass: 'lp-actions-cell',
      suppressHeaderMenuButton: true,
      suppressColumnsToolPanel: true,
      cellRenderer: ActionsCellRenderer,
      cellRendererParams: (params: any) => {
        return { buttonsData: [
          {
            type: ActionButtonTypes.View,
            callback: (id: string, navigate: NavigateFunction) => viewCallback(id, navigate)
          },
          ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.DeleteMatters) ? [{
            type: ActionButtonTypes.Delete,
            callback: (id: string) => deleteModal(id)
          }] : [])
        ]};
      },
      suppressSizeToFit: true,
      floatingFilter: false,
      sortable: false,
      minWidth: buttonsColumnWidth,
      maxWidth: buttonsColumnWidth,
      width: buttonsColumnWidth,
      suppressMovable: true,
      pinned: 'right',
      resizable: false
    }
  ];
  return columnDefs;
}
