import { useEffect, useState } from 'react';
import Field from 'components/Fields/Field';
import Loader from 'components/Loader';
import { Row, Col, Button } from 'react-bootstrap';
import { MatterEmailModel } from 'models/view/MatterEmailModel';
import BooleanField from 'components/Fields/BooleanField';
import moment from 'moment';
import { DateFormat } from 'utils/constants';
import { getEmailTypeName } from 'utils/enums';
import ListSlidingPanel from 'components/List/ListSlidingPanel';
import { createForwardDraftUserEmail, createReplyAllDraftUserEmail, createReplyDraftUserEmail, getUserEmailById } from 'actions/user';
import { useAppSelector } from 'hooks/appSelector';
import { BiLinkExternal } from 'react-icons/bi';
import { MdReply, MdReplyAll } from 'react-icons/md';
import { formatEmailSender, openUrlInNewtab } from 'utils/misc';
import React from 'react';
import { UserPermissionsNames } from 'enums/UserPermissionsNames';

type Props = {
  emailGraphId: string
};

function ViewUserEmail(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [matterEmail, setMatterEmail] = useState<MatterEmailModel>();
  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    setIsLoading(true);
    getUserEmailById(props.emailGraphId, user.msalAccessToken!)
      .then((response) => {
        setMatterEmail(response.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  function createDraftReplyEmailHandler() {
    setIsLoading(true);
    createReplyDraftUserEmail(props.emailGraphId, user.msalAccessToken!)
      .then((response) => {
        openUrlInNewtab(response.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function createDraftReplyAllEmailHandler() {
    setIsLoading(true);
    createReplyAllDraftUserEmail(props.emailGraphId, user.msalAccessToken!)
      .then((response) => {
        openUrlInNewtab(response.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function createDraftForwardEmailHandler() {
    setIsLoading(true);
    createForwardDraftUserEmail(props.emailGraphId, user.msalAccessToken!)
      .then((response) => {
        openUrlInNewtab(response.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <div className="lp-email-view lp-iframe-panel">
      {isLoading && <Loader inlineLoader />}

      {genericErrors && <div className="lp-errors">{genericErrors}</div>}

      <Row>
        <Col className="lp-email-action-btns">
          {matterEmail?.webLink &&
            <Button variant="primary" onClick={() => openUrlInNewtab(matterEmail?.webLink)}>
              <BiLinkExternal />
              <span>Open with Outlook</span>
            </Button>
          }
          {user.userPermissions?.some(a => a == UserPermissionsNames.ManageDashboardEmails) &&
            <>
              <Button onClick={createDraftReplyEmailHandler} variant="secondary-400">
                <MdReply />
                <span>Reply</span>
              </Button>
              <Button onClick={createDraftReplyAllEmailHandler} variant="secondary-400">
                <MdReplyAll />
                <span>Reply all</span>
              </Button>
              <Button onClick={createDraftForwardEmailHandler} variant="secondary-400 reverse-icon">
                <MdReply />
                <span>Forward</span>
              </Button>
            </>
          }
        </Col>
      </Row>

      <Row>
        <Col>
          <Field label={'Subject'} value={matterEmail?.subject} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            className="lp-email-chips"
            label={'Sender'}
            value={formatEmailSender(matterEmail?.senderEmailAddress!, matterEmail?.senderName)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            className="lp-email-chips"
            label={'To'}
            value={
              matterEmail?.toRecipients && matterEmail?.toRecipients.length > 0
                ? matterEmail?.toRecipients.map((x, i) => <React.Fragment key={i}><span className="email">{x}</span></React.Fragment>)
                : '—'
            }
          />
        </Col>
      </Row>
      {matterEmail?.ccRecipients &&
        matterEmail?.ccRecipients.length > 0 && (
          <Row>
            <Col>
              <Field
                className="lp-email-chips"
                label={'Cc'}
                value={matterEmail?.ccRecipients.map((x, i) => <React.Fragment key={i}><span className="email">{x}</span></React.Fragment>)}
              />
            </Col>
          </Row>
        )}
      {matterEmail?.bccRecipients &&
        matterEmail?.bccRecipients.length > 0 && (
          <Row>
            <Col>
              <Field
                className="lp-email-chips"
                label={'Bcc'}
                value={matterEmail?.bccRecipients.map((x, i) => <React.Fragment key={i}><span className="email">{x}</span></React.Fragment>)}
              />
            </Col>
          </Row>
        )}
      {matterEmail?.matter &&
        <Row>
          <Col>
            <Field
              label={'On Matter'}
              value={matterEmail?.matter?.fileNumber}
            />
          </Col>
        </Row>
      }
      <Row>
        {matterEmail?.type == 0 && (
          <Col>
            <Field
              label={'Sent Date Time'}
              value={
                matterEmail?.sentDateTime
                  ? moment(matterEmail?.sentDateTime).format(
                      DateFormat.MomentWithTime
                    )
                  : '—'
              }
            />
          </Col>
        )}
        {matterEmail?.type != 0 && (
          <Col>
            <Field
              label={'Received Date Time'}
              value={
                matterEmail?.receivedDateTime
                  ? moment(matterEmail?.receivedDateTime).format(
                      DateFormat.MomentWithTime
                    )
                  : '—'
              }
            />
          </Col>
        )}
        <Col>
          <Field label={'Type'} value={getEmailTypeName(matterEmail?.type!)} />
        </Col>
      </Row>
      <Row>
        <Col>
          <BooleanField
            label={'Has Attachments'}
            value={matterEmail?.hasAttachments ?? false}
          />
        </Col>
        <Col>
          <BooleanField
            label={'Internal'}
            value={matterEmail?.isInternal ?? false}
          />
        </Col>
      </Row>
      
      {matterEmail?.hasAttachments &&
        <Row>
          <Col>
            <div className="departments">
              <ListSlidingPanel
                listItems={
                  matterEmail?.attachments?.map((x) => ({ id: x, name: x })) ?? []
                }
                listButtons={[]}
              >
                Attachments
              </ListSlidingPanel>
            </div>
          </Col>
        </Row>
      }

      {matterEmail?.bodyContent &&
        <>
          <Field label={'Email Content'} value={''} className="mt-4" />
          <Row className="full-height">
            <Col>
              <iframe srcDoc={matterEmail?.bodyContent} title="Email Preview" height="100%" width="100%"></iframe> 
            </Col>
          </Row>
        </>
      }
    </div>
  );
}

export default ViewUserEmail;
