import Title from "components/Title";
import { TabPanel } from "react-tabs";
import CustomTabs from "components/CustomTabs";
import TaxonomyList from "./Taxonomies/TaxonomyList";
import TaxonomyTermList from "./TaxonomyTerms/TaxonomyTermList";

export default function TaxonomySettings() {
  return (
    <div className="lp-page-content">
      <Title type="page" title="Taxonomy Settings"></Title>

      <CustomTabs 
        tabList={[
          {
            name: 'Taxonomies',
            panel: <TabPanel>
              <TaxonomyList />
            </TabPanel>
          },
          {
            name: 'Taxonomy Terms',
            panel: <TabPanel>
              <TaxonomyTermList />
            </TabPanel>
          }
        ]}
      />
    </div>
  );
}
