import { create, test, enforce, omitWhen } from "vest";

export const getValidationSuite = (showDelegateAccessFields?: boolean) => {
  return create((data = {}) => {
    omitWhen(!showDelegateAccessFields, () => {
      test('userRoleId', 'Please select an User Role', () => {
        enforce(data.userRoleId).isNotEmpty();
        enforce(data.userRoleId).isString();
      });
    });
  });
}