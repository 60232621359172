import { ColDef } from "ag-grid-community";
import { useDispatch } from "react-redux";
import {
  setColumnDefs,
  setId,
  setRowData,
  resetId,
  setAdvancedFilter,
  resetAdvancedFilter,
  setAdvancedFilterInfo,
  resetAdvancedFilterInfo,
  setAdvancedFilterClearCallback,
  resetAdvancedFilterClearCallback,
  resetGrid
} from "../state/gridSlice";

const useGridActions = () => {
  const dispatch = useDispatch();

  const setGridRowData = (rowData: any[]) => {
    dispatch(setRowData(rowData));
  };

  const setGridColumnDefs = (columnDefs: (ColDef<any> | {excludeFromExport: boolean})[]) => {
    dispatch(setColumnDefs(columnDefs));
  };

  const setGridAdvancedFilter = (advancedFilter: any) => {
    dispatch(setAdvancedFilter(advancedFilter));
  };

  const setGridAdvancedFilterInfo = (advancedFilterInfo: React.ReactNode) => {
    dispatch(setAdvancedFilterInfo(advancedFilterInfo));
  };

  const setGridAdvancedFilterClearCallback = (advancedFilterClearCallback: Function) => {
    dispatch(setAdvancedFilterClearCallback(advancedFilterClearCallback));
  };

  const setGridId = (id: string) => {
    dispatch(setId(id));
  };

  const resetGridId = () => {
    dispatch(resetId());
  };

  const resetGridAdvancedFilter = () => {
    dispatch(resetAdvancedFilter());
  };

  const resetGridAdvancedFilterInfo = () => {
    dispatch(resetAdvancedFilterInfo());
  };

  const resetGridAdvancedFilterClearCallback = () => {
    dispatch(resetAdvancedFilterClearCallback());
  };

  const resetGridModel = () => {
    dispatch(resetGrid());
  };

  return {
    setGridRowData,
    setGridColumnDefs,
    setGridAdvancedFilter,
    setGridAdvancedFilterInfo,
    setGridAdvancedFilterClearCallback,
    setGridId,
    resetGridId,
    resetGridAdvancedFilter,
    resetGridAdvancedFilterInfo,
    resetGridAdvancedFilterClearCallback,
    resetGridModel
  };
}

export default useGridActions;
