export const DEFAULT_COLUMN_DEFINITIONS = {
  sortable: true,
  resizable: true,
  suppressHeaderMenuButton: false,
  filter: true,
  floatingFilter: true,
  suppressFloatingFilterButton: true,
  suppressHeaderFilterButton: true,
  minWidth: 100,
  flex: 1,
  suppressSizeToFit: true,
  mainMenuItems: (e: any) => {
    // Remove the column filter button and the separator that comes after
    const columnFilterIndex = e.defaultItems.findIndex((item: any) => item === "columnFilter");
    return columnFilterIndex !== -1
      ? e.defaultItems.filter((e: any, i: number) => i !== columnFilterIndex && i !== columnFilterIndex + 1)
      : e.defaultItems;
  },
}
