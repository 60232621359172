import { ColDef, ColGroupDef } from "ag-grid-community";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import store from "state/store";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import { dateComparator } from "components/Grid/Comparators/DateComparator";
import { deleteMatterDisbursement } from "actions/matter";
import { ModalState, setModalData, setModalErrors, toggleModal, toggleModalLoadingState } from "state/modalSlice";
import { setRowData } from "state/gridSlice";
import { MatterDisbursementModel } from "models/view/MatterDisbursementModel";
import { DateFormatter } from "components/Grid/ValueFormatters/DateFormatter";
import { CurrencyFormatter } from "components/Grid/ValueFormatters/CurrencyFormatter";
import ViewMatterDisbursement from "../ActivityHistory/MatterDisbursement/ViewMatterDisbursement/ViewMatterDisbursement";
import ViewMatterDisbursementPayments from "../ActivityHistory/MatterDisbursement/ViewMatterDisbursementPayments/ViewMatterDisbursementPayments";
import MatterDisbursementWriteOffForm from "../ActivityHistory/MatterDisbursement/MatterDisbursementWriteOff/MatterDisbursementWriteOffForm";
import MatterDisbursementNameCellRenderer from "components/Grid/MatterDisbursementNameCellRenderer";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

export const viewCallback = (rowData: MatterDisbursementModel, reloadGridData?: Function) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        allowNavigation: true,
        width: "55rem",
        title: "View Disbursement",
        children: <ViewMatterDisbursement
          matterId={rowData.matterId}
          matterDisbursementId={rowData.id!}
          onEditCallback={reloadGridData}
          showMoreButtons
        />
      }
    )
  );
}

const deleteModal = (rowData: MatterDisbursementModel) => {
  let modal: ModalState = {
    title: "Delete confirmation",
    body: "Are you sure you want to delete this Matter Disbursement?",
    actionText: "Delete",
    actionVariant: "danger",
    onAction: () => deleteCallback(rowData.matterId, rowData.id!),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
      
};

const deleteCallback = (matterId: string, disbursementId: string) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  deleteMatterDisbursement(matterId, disbursementId).then(() => {
    store.dispatch(setRowData(gridState.rowData.filter((x: MatterDisbursementModel) => x.id !== disbursementId)));
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

const paymentCallback = (matterId: string, rowData: MatterDisbursementModel, reloadGridData?: Function) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        width: "55rem",
        title: "Disbursement Payments",
        children: <ViewMatterDisbursementPayments
          matterId={rowData.matterId}
          matterDisbursementId={rowData.id!}
          onPaymentsChangedCallback={() => reloadGridData ? reloadGridData() : {}}
        />
      }
    )
  );
}

const writeOffCallback = (matterId: string, rowData: MatterDisbursementModel, reloadGridData?: Function) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        width: "55rem",
        title: "Disbursement Write-Off",
        children: <MatterDisbursementWriteOffForm
          matterId={matterId}
          matterDisbursementId={rowData.id!}
          onSubmitCallback={() => reloadGridData ? reloadGridData() : {}}
          onDeleteCallback={() => reloadGridData ? reloadGridData() : {}}
        />
      }
    )
  );
}

export const getColumnDefs = (reloadGridData?: Function) => {
  const loggedInUser = store.getState().user;

  const columnDefs: (ColDef<MatterDisbursementModel> | ColGroupDef<MatterDisbursementModel> | {excludeFromExport: boolean})[] = [
    { 
      headerName: 'Display Name',
      field: 'displayName',
      filter: 'agTextColumnFilter',
      cellRenderer: MatterDisbursementNameCellRenderer,
      minWidth: 150,
      width: 150,
    },
    { 
      headerName: 'Date',
      field: 'date',
      filter: 'agDateColumnFilter',
      valueFormatter: DateFormatter,
      filterParams: {
        comparator: dateComparator
      },
      minWidth: 120
    },
    {
      headerName: 'Fee Earner',
      field: 'user.name',
      filter: 'agTextColumnFilter',
      minWidth: 150,
      width: 150
    },
    { 
      headerName: 'Supplier',
      field: 'supplier.name',
      filter: 'agTextColumnFilter',
      minWidth: 150,
      width: 150
    },
    { 
      headerName: 'Supplier Ref',
      field: 'supplierReference',
      filter: 'agTextColumnFilter',
      minWidth: 150,
      width: 150
    },
    { 
      headerName: 'Description',
      field: 'description',
      filter: 'agTextColumnFilter',
      minWidth: 150,
      width: 150
    },
    {
      headerName: 'Gross Value',
      field: 'grossValue',
      filter: 'agNumberColumnFilter',
      valueFormatter: CurrencyFormatter,
      cellClass: 'text-end',
    },
    {
      headerName: 'Net Value',
      field: 'netValue',
      filter: 'agNumberColumnFilter',
      valueFormatter: CurrencyFormatter,
      cellClass: 'text-end',
    },
    {
      headerName: 'Amount Due',
      field: 'amountDue',
      filter: 'agNumberColumnFilter',
      valueFormatter: CurrencyFormatter,
      cellClass: 'text-end',
    },
    { 
      headerName: 'Due Date',
      field: 'dueDate',
      filter: 'agDateColumnFilter',
      valueFormatter: DateFormatter,
      filterParams: {
        comparator: dateComparator
      },
      minWidth: 150
    },
    { 
      headerName: 'Invoice Ref Number',
      field: 'matterInvoice.invoiceRefNumber',
      filter: 'agTextColumnFilter',
      minWidth: 150,
      width: 150
    },
    {
      headerName: 'Gross Value On Invoice',
      field: 'grossValueOnInvoice',
      filter: 'agNumberColumnFilter',
      valueFormatter: CurrencyFormatter,
      cellClass: 'text-end',
    },
    { 
      headerName: 'Client',
      field: 'matterParticipatingEntity.name',
      filter: 'agTextColumnFilter',
      minWidth: 150,
      width: 150
    },
    { 
      excludeFromExport: true,
      cellClass: 'lp-actions-cell',
      suppressHeaderMenuButton: true,
      suppressColumnsToolPanel: true,
      cellRenderer: ActionsCellRenderer,
      cellRendererParams: (params: any) => {
        if(params.data.matter.isClosed) {
          return { buttonsData: [
            {
              type: ActionButtonTypes.View,
              callback: () => viewCallback(params.data)
            }]
          };
        }
        else {
          return { buttonsData: [
            {
              type: ActionButtonTypes.View,
              callback: () => viewCallback(params.data, reloadGridData)
            },
            {
              type: ActionButtonTypes.Payment,
              callback: () => paymentCallback(params.data.matterId, params.data, reloadGridData)
            },
            ...(!params.data.isBilled ? [{
              type: ActionButtonTypes.WriteOff,
              callback: () => writeOffCallback(params.data.matterId, params.data, reloadGridData)
            }] : []),
            ...((!loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterDisbursements) || params.data.isBilled) ? [] : [{
              type: ActionButtonTypes.Delete,
              callback: () => deleteModal(params.data)
            }]),
          ]};
        }
      },
      suppressSizeToFit: true,
      floatingFilter: false,
      sortable: false,
      minWidth: 105,
      maxWidth: 105,
      width: 105,
      suppressMovable: true,
      pinned: 'right',
      resizable: false
    }
  ];

  return columnDefs;
}