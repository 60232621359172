import { vestResolver } from "@hookform/resolvers/vest";
import {
  createMatterReceiveMoney,
  getMatterClientsSummary,
  getMatterDefaultClient,
  getMatterReceiveMoneyAccounts,
  getOpenMattersSummary,
  getReceiveMoneyByTypes,
  getReceiveMoneyReasons
} from "actions/matter";
import useSlidingPanelActions from "actions/slidingPanel";
import Loader from "components/Loader/index";
import CustomSelect from "components/Select/Select";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { DateFormat } from "utils/constants";
import { removeEmptyFields } from "utils/form";
import { validationSuite } from "./validationSuite";
import { getDateOnly } from "utils/date";
import { CreateOrUpdateMatterReceiveMoneyModel } from "models/create/CreateOrUpdateMatterReceiveMoneyModel";
import { DropDownOptionModel } from "models/view/DropDownOptionModel";
import { ReceiveMoneyReasonIds } from "enums/ReceiveMoneyReasonIds";

type Props = {
  matterId?: string,
  accountId?: string,
  onSubmitCallback?: Function
}

export default function CreateMatterReceiveMoneyForm(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const slidingPanelActions = useSlidingPanelActions();
  const currentDate: Date = getDateOnly(new Date());
  const [clients, setClients] = useState<DropDownOptionModel[]>([]);

  const { register, handleSubmit, control, setValue, watch, reset, resetField, formState: { errors } } = useForm<CreateOrUpdateMatterReceiveMoneyModel>({
    resolver: vestResolver(validationSuite),
    defaultValues: {
      date: currentDate,
      matterId: props.matterId,
      accountId: props.accountId
    }
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateOrUpdateMatterReceiveMoneyModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    createMatterReceiveMoney(data.matterId, data).then((response) => {
      slidingPanelActions.clearSlidingPanel();
      reset();

      props.onSubmitCallback && props.onSubmitCallback();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  useEffect(() => {
    loadClients(props.matterId);
  }, []);

  const loadClients = (matterId?: string) => {
    if(matterId) {
      setIsLoading(true);
      const promises: Promise<any>[] = [];
      promises.push(getMatterClientsSummary(matterId));
      promises.push(getMatterDefaultClient(matterId));
      
      Promise.all(promises).then(([matterClientsResponse, matterDefaultClientResponse]) => {
        setClients(matterClientsResponse.data);
        setValue('matterParticipatingEntityId', matterDefaultClientResponse.data?.id);
      }).catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
    }
    else {
      setClients([]);
      resetField("matterParticipatingEntityId");
    }
  }

  const onChangeMatter = (matterId?: string) => {
    loadClients(matterId);
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        {!props.matterId &&
          <Form.Group className="mb-4" controlId="matterId">
            <Form.Label className="required">Matter</Form.Label>
            <Controller
              control={control}
              name={`matterId`}
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  id="matterId"
                  inputRef={ref}
                  className={`lp-select${errors?.matterId?.message ? ' invalid' : ''}`}
                  endpointCall={getOpenMattersSummary}
                  value={value}
                  onChange={val => {onChange(val?.id ?? null); onChangeMatter(val?.id ?? undefined);}}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.matterId?.message && (errors.matterId?.message)}
            </Form.Text>
          </Form.Group>
        }

        <Form.Group className="mb-4" controlId="matterParticipatingEntityId">
          <Form.Label className="required">Client</Form.Label>
          <Controller
            control={control}
            name={`matterParticipatingEntityId`}
            shouldUnregister={true}
            render={({ field: { onChange, value, name, ref } }) => (
              <CustomSelect
                id="matterParticipatingEntityId"
                inputRef={ref}
                className={`lp-select${errors?.matterParticipatingEntityId?.message ? ' invalid' : ''}`}
                options={clients}
                value={value}
                onChange={val => onChange(val?.id ?? null)}
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.matterParticipatingEntityId?.message && (errors.matterParticipatingEntityId?.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="date">
          <Form.Label className="required">Date</Form.Label>
          <Controller
            control={control}
            name="date"
            shouldUnregister={true}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                className={`${errors?.date?.message ? 'invalid' : ''}`}
                id="date"
                dateFormat={DateFormat.Datepicker}
                selected={value ? getDateOnly(value) : null}
                onChange={(val) => onChange(val != null ? getDateOnly(val) : val)}
                showMonthDropdown
                showYearDropdown
                autoComplete="off"
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.date?.message && (errors.date.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="accountId">
          <Form.Label className="required">Received Into Account</Form.Label>
          <Controller
            control={control}
            name={`accountId`}
            shouldUnregister={true}
            render={({ field: { onChange, value, name, ref } }) => (
              <CustomSelect
                id="accountId"
                inputRef={ref}
                className={`lp-select${errors?.accountId?.message ? ' invalid' : ''}`}
                endpointCall={getMatterReceiveMoneyAccounts}
                value={value}
                onChange={val => onChange(val?.id ?? null)}
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.accountId?.message && (errors.accountId?.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="receivedById">
          <Form.Label className="required">Received By</Form.Label>
          <Controller
            control={control}
            name={`receivedById`}
            shouldUnregister={true}
            render={({ field: { onChange, value, name, ref } }) => (
              <CustomSelect
                id="receivedById"
                inputRef={ref}
                className={`lp-select${errors?.receivedById?.message ? ' invalid' : ''}`}
                endpointCall={getReceiveMoneyByTypes}
                value={value}
                onChange={val => onChange(val?.id ?? null)}
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.receivedById?.message && (errors.receivedById?.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="receivedFrom">
          <Form.Label className="required">Received From</Form.Label>
          <Form.Control
            type="text"
            className={`${errors?.receivedFrom?.message ? 'invalid' : ''}`}
            {...register(`receivedFrom`, {shouldUnregister: true})}
          />
          <Form.Text className="lp-error">
            {errors?.receivedFrom?.message && (errors.receivedFrom?.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="amount">
          <Form.Label className={"required"}>Amount</Form.Label>
          <Form.Control
            type="number"
            className={`${errors?.amount?.message ? 'invalid' : ''}`}
            {...register(`amount`, {shouldUnregister: true})}
            min="0.00"
            step="0.01"
            onWheel={e => e.currentTarget.blur()}
          />
          <Form.Text className="lp-error">
            {errors?.amount?.message && (errors.amount?.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="reasonId">
          <Form.Label className="required">Reason</Form.Label>
          <Controller
            control={control}
            name={`reasonId`}
            shouldUnregister={true}
            render={({ field: { onChange, value, name, ref } }) => (
              <CustomSelect
                id="reasonId"
                inputRef={ref}
                className={`lp-select${errors?.reasonId?.message ? ' invalid' : ''}`}
                endpointCall={getReceiveMoneyReasons}
                value={value}
                onChange={val => onChange(val?.id ?? null)}
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.reasonId?.message && (errors.reasonId?.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="description">
          <Form.Label className={`${watch("reasonId") == ReceiveMoneyReasonIds.ReceiptForAnotherReasonId ? 'required' : ''}`}>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            className={`${errors?.description?.message ? 'invalid' : ''}`}
            {...register(`description`, {shouldUnregister: true})}
          />
          <Form.Text className="lp-error">
            {errors?.description?.message && (errors.description?.message)}
          </Form.Text>
        </Form.Group>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <Button variant="success" type="submit">Create</Button>
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
