import { ColDef } from "ag-grid-community";
import store from "state/store";
import { ModalState, setModalData, setModalErrors, toggleModal, toggleModalLoadingState } from "state/modalSlice";
import { setRowData } from "state/gridSlice";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import { changeDefaultActivityStatus, deleteDefaultActivity, getActivityTypes } from "actions/settings";
import { DefaultActivityModel } from "models/view/DefaultActivityModel";
import CheckboxCellRenderer from "components/Grid/CheckboxCellRenderer";
import EditDefaultActivityForm from "./EditDefaultActivity/EditDefaultActivityForm";
import { BooleanFilter } from "components/Grid/GridFilters/Filters/BooleanFilter";
import { BooleanFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/BooleanFloatingFilter";
import { DropdownFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/DropdownFloatingFilter";
import { DropdownFilter } from "components/Grid/GridFilters/Filters/DropdownFilter";
import { CheckboxFormatter } from "components/Grid/ValueFormatters/CheckboxFormatter";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

const editCallback = (id: string) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        title: "Edit Default Activity",
        children: <EditDefaultActivityForm defaultActivityId={id}/>
      }
    )
  );
}

const changeStatusModal = (id: string) => {
  let bodyMessage: React.ReactNode;
  const gridState = store.getState().grid;
  if (!gridState.rowData.find((x: DefaultActivityModel) => x.id == id).active) {
    bodyMessage = "Are you sure you want to activate this Default Activity?";
  } else {
    bodyMessage = "Are you sure you want to deactivate this Default Activity?";
  }
  let modal: ModalState = {
    title: "Confirmation",
    body: bodyMessage,
    onAction: () => changeStatus(id),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
}

const changeStatus = (id: string) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  changeDefaultActivityStatus(id).then(() => {
    let index: number = gridState.rowData.findIndex((x: any) => x.id == id);
    let newEntry: DefaultActivityModel = { ...gridState.rowData[index], active: !gridState.rowData[index].active };
    let newArray: Array<any> = [...gridState.rowData];
    newArray[index] = newEntry;
    store.dispatch(setRowData(newArray));
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

const deleteModal = (id: string) => {
  let modal: ModalState = {
    title: "Delete confirmation",
    body: "Are you sure you want to delete this Default Activity?",
    actionText: "Delete",
    actionVariant: "danger",
    onAction: () => deleteCallback(id),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
};

const deleteCallback = (id: string) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  deleteDefaultActivity(id).then(() => {
    store.dispatch(setRowData(gridState.rowData.filter((x: any) => x.id !== id)));
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

export const getColumnDefs = () => {
  const loggedInUser = store.getState().user;
  
  const columnDefs: (ColDef<DefaultActivityModel> | {excludeFromExport: boolean})[] = [
    {
      headerName: 'Code',
      field: 'code',
      filter: 'agTextColumnFilter',
      suppressSizeToFit: true,
      minWidth: 120,
      width: 120,
    },
    {
      headerName: 'Name',
      field: 'name',
      filter: 'agTextColumnFilter',
      flex: 3,
    },
    {
      headerName: 'Description',
      field: 'description',
      filter: 'agTextColumnFilter',
      flex: 3,
    },
    {
      headerName: 'Type',
      field: 'activityType.name',
      filter: DropdownFilter,
      filterParams: {
        property: 'activityTypeId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getActivityTypes
      },
      minWidth: 220,
      flex: 2,
    },
    {
      headerName: 'Is Active',
      field: 'active',
      filter: BooleanFilter,
      filterParams: {
        property: 'active',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: BooleanFloatingFilter,
      floatingFilterComponentParams: {
        values: [{
          id: 'true',
          name: 'Yes'
        },
        {
          id: 'false',
          name: 'No'
        },
      ]},
      minWidth: 100,
      width: 100,
      cellRenderer: CheckboxCellRenderer,
      valueFormatter: CheckboxFormatter
    },
    { 
      excludeFromExport: true,
      cellClass: 'lp-actions-cell',
      suppressHeaderMenuButton: true,
      suppressColumnsToolPanel: true,
      cellRenderer: ActionsCellRenderer,
      cellRendererParams: (params: any) => {
        return { buttonsData: [
          {
            type: ActionButtonTypes.Edit,
            callback: editCallback
          },
          {
            type: params.data?.active ? ActionButtonTypes.Deactivate : ActionButtonTypes.Activate,
            callback: changeStatusModal
          },
          {
            type: ActionButtonTypes.Delete,
            callback: deleteModal
          }
        ]};
      },
      hide: !loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageDefaultSettings),
      suppressSizeToFit: true,
      floatingFilter: false,
      sortable: false,
      minWidth: 105,
      maxWidth: 105,
      width: 105,
      suppressMovable: true,
      pinned: 'right',
      resizable: false
    }
  ];
  return columnDefs;
}