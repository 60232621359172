import { ColDef } from "ag-grid-community";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { ChartOfAccountsBalanceSummaryModel } from "models/view/ChartOfAccountsBalanceSummaryModel";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { NavigateFunction } from "react-router-dom";
import { CurrencyFormatter } from "components/Grid/ValueFormatters/CurrencyFormatter";

const viewCallback = (rowData: ChartOfAccountsBalanceSummaryModel, navigate: NavigateFunction) => {
  navigate(`/chartOfAccounts/${rowData.accountId}`);
};

export const columnDefs: (ColDef<ChartOfAccountsBalanceSummaryModel> | {excludeFromExport: boolean})[] = [
  {
    headerName: 'Account Name',
    field: 'accountName',
    filter: 'agTextColumnFilter',
    flex: 2,
  },
  {
    headerName: 'DR',
    field: 'drAmount',
    filter: 'agNumberColumnFilter',
    valueFormatter: CurrencyFormatter,
    cellClass: 'text-end',
  },
  {
    headerName: 'CR',
    field: 'crAmount',
    filter: 'agNumberColumnFilter',
    valueFormatter: CurrencyFormatter,
    cellClass: 'text-end',
  },
  {
    headerName: 'Balance',
    field: 'balance',
    filter: 'agNumberColumnFilter',
    valueFormatter: CurrencyFormatter,
    cellClass: 'text-end',
  },
  {
    cellClass: 'lp-actions-cell',
    suppressHeaderMenuButton: true,
    suppressColumnsToolPanel: true,
    cellRenderer: ActionsCellRenderer,
    cellRendererParams: (params: any) => {
      return { buttonsData: [
        {
          type: ActionButtonTypes.View,
          callback: (id: string, navigate: NavigateFunction) => viewCallback(params.data, navigate)
        }
      ]};
    },
    excludeFromExport: true,
    suppressSizeToFit: true,
    floatingFilter: false,
    sortable: false,
    minWidth: 65,
    maxWidth: 65,
    width: 65,
    suppressMovable: true,
    pinned: 'right',
    resizable: false
  }
];
