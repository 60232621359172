import { ColDef } from "ag-grid-community";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { ModalState, setModalData, setModalErrors, toggleModal, toggleModalLoadingState } from "state/modalSlice";
import store from "state/store";
import { deleteParticipatingEntity } from 'actions/matter';
import { setRowData } from "state/gridSlice";
import { ParticipatingEntityModel } from "models/view/ParticipatingEntityModel";
import ViewParticipatingEntity from "./ViewParticipatingEntity/ViewParticipatingEntity"
import CheckboxCellRenderer from "components/Grid/CheckboxCellRenderer";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import { DropdownFilter } from "components/Grid/GridFilters/Filters/DropdownFilter";
import { DropdownFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/DropdownFloatingFilter";
import { getEntitiesSummary } from "actions/entity";
import { BooleanFilter } from "components/Grid/GridFilters/Filters/BooleanFilter";
import { BooleanFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/BooleanFloatingFilter";
import ParticipatingEntityNameCellRenderer from "components/Grid/ParticipatingEntityNameCellRenderer";
import { getEntityRolesSummary } from "actions/settings";
import { CheckboxFormatter } from "components/Grid/ValueFormatters/CheckboxFormatter";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

export const viewCallback = (id: string, matterTypeId: string, isMatterClosed: boolean) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        allowNavigation: true,
        width: "50rem",
        title: "Participating Entity Details",
        children: <ViewParticipatingEntity
          participatingEntityId={id}
          matterTypeId={matterTypeId}
          isMatterClosed={isMatterClosed}
        />
      }
    )
  );
}

const deleteModal = (id: string) => {
  let modal: ModalState = {
    title: "Delete confirmation",
    body: "Are you sure you want to delete this Participating Entity?",
    actionText: "Delete",
    actionVariant: "danger",
    onAction: () => deleteCallback(id),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
};

const deleteCallback = (id: string) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  deleteParticipatingEntity(id).then(() => {
    store.dispatch(setRowData(gridState.rowData.filter((x: any) => x.id !== id)));
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

export const getColumnDefs = (matterTypeId: string) => {
  const loggedInUser = store.getState().user;
  const buttonsColumnWidth = loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatters) ? 105 : 65;

  const columnDefs: (ColDef<ParticipatingEntityModel> | {excludeFromExport: boolean})[] = [
    {
      headerName: 'Name',
      field: 'entity.displayName',
      filter: DropdownFilter,
      filterParams: {
        property: 'entityId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getEntitiesSummary
      },
      cellRenderer: ParticipatingEntityNameCellRenderer,
      wrapHeaderText: true,
      minWidth: 220
    },
    {
      headerName: 'Role',
      field: 'entityRole.name',
      filter: DropdownFilter,
      filterParams: {
        property: 'entityRoleId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getEntityRolesSummary
      },
      wrapHeaderText: true,
      minWidth: 220
    },
    {
      headerName: 'Matter Reference',
      field: 'entityMatterReference',
      filter: 'agTextColumnFilter',
      wrapHeaderText: true,
      minWidth: 130,
    },
    {
      headerName: 'Is Invoicing Party',
      field: 'isInvoicingParty',
      filter: BooleanFilter,
      filterParams: {
        property: 'isInvoicingParty',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: BooleanFloatingFilter,
      floatingFilterComponentParams: {
        values: [{
          id: 'true',
          name: 'Yes'
        },
        {
          id: 'false',
          name: 'No'
        },
        ]
      },
      cellRenderer: CheckboxCellRenderer,
      valueFormatter: CheckboxFormatter,
      wrapHeaderText: true,
      minWidth: 140,
    },
    { 
      headerName: 'Purchase Order Reference',
      field: 'purchaseOrderReference',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Notes',
      field: 'notes',
      filter: 'agTextColumnFilter',
      wrapHeaderText: true
    },
    {
      excludeFromExport: true,
      cellClass: 'lp-actions-cell',
      suppressHeaderMenuButton: true,
      suppressColumnsToolPanel: true,
      cellRenderer: ActionsCellRenderer,
      cellRendererParams: (params: any) => {
        return {
          buttonsData: [
            {
              type: ActionButtonTypes.View,
              callback: (id: string) => viewCallback(id, matterTypeId, params.data.matter.isClosed)
            },
            ...((loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatters) && !params.data.matter.isClosed) ? [{
              type: ActionButtonTypes.Delete,
              callback: (id: string) => deleteModal(id)
            }] : [])
          ]
        };
      },
      suppressSizeToFit: true,
      floatingFilter: false,
      sortable: false,
      minWidth: buttonsColumnWidth,
      maxWidth: buttonsColumnWidth,
      width: buttonsColumnWidth,
      suppressMovable: true,
      pinned: 'right',
      resizable: false
    }
  ];

  return columnDefs;
}
