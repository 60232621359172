import React, { useCallback, useEffect, useRef, useState } from "react";
import useGridActions from 'actions/grid';
import Grid from "components/Grid";
import { getColumnDefs, viewCallback } from "./columnDefs";
import Title from 'components/Title';
import SlidingPanel from 'components/SlidingPanel';
import { Card } from 'react-bootstrap';
import { getMatterInvoicesForLte } from "actions/lte";
import { GridFilterTypes } from "enums/GridFilterTypes";
import { GridFilterButtonDataModel } from "models/view/GridFilterButtonDataModel";
import useSlidingPanelActions from "actions/slidingPanel";
import { getDateOnly } from "utils/date";
import { AdvancedGridFilterModel } from "models/view/AdvancedGridFilterModel";
import AdvancedGridFilterForm from "components/Grid/GridFilters/AdvancedGridFilter/AdvancedGridFilterForm";
import { AgGridReact } from "ag-grid-react";

function AllInvoicesList() {
  const gridActions = useGridActions();
  const [genericErrors, setGenericErrors] = useState(null);
  const slidingPanelActions = useSlidingPanelActions();
  const gridRef = useRef<AgGridReact>(null);

  const onGridReady = useCallback(() => {
    const columnDefs = getColumnDefs(populateInvoices);
    gridActions.setGridColumnDefs([...columnDefs]);
    gridActions.setGridAdvancedFilterClearCallback(gridAdvancedFilterClearCallback);
  }, []);

  useEffect(() => {
    populateInvoices();
  }, []);

  const populateInvoices = (advancedFilter?: AdvancedGridFilterModel) => {
    gridActions.setGridAdvancedFilter(advancedFilter);
    const filterStartDate = advancedFilter?.startDate ? getDateOnly(advancedFilter?.startDate) : undefined;
    const filterEndDate = advancedFilter?.endDate ? getDateOnly(advancedFilter?.endDate) : undefined;

    const nonEmptyFilterValues = [filterStartDate, filterEndDate].filter(x => x);
    if(nonEmptyFilterValues.length > 0) {
      gridActions.setGridAdvancedFilterInfo(<>{`${nonEmptyFilterValues.length} advanced filter(s) applied`}</>);
    }
    else {
      gridActions.setGridAdvancedFilterInfo(undefined);
    }

    getMatterInvoicesForLte(undefined, filterStartDate, filterEndDate).then((response) => {
      gridActions.setGridRowData(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
      gridActions.setGridRowData([]);
    });
  };

  const gridAdvancedFilterOnClickCallback = () => {
    slidingPanelActions.setSlidingPanel(
    {
      isShown: true,
      title: "Invoices Advanced Filter",
      children: <AdvancedGridFilterForm
        filterCallback={gridAdvancedFilterCallback}
        showDateFilter
      />
    });
  };

  const gridFilters: GridFilterButtonDataModel[] = [
    {
      type: GridFilterTypes.AdvancedFilters,
      callback: gridAdvancedFilterOnClickCallback
    }
  ];

  const gridAdvancedFilterCallback = (advancedFilter?: AdvancedGridFilterModel) => {
    populateInvoices(advancedFilter);
  };

  const gridAdvancedFilterClearCallback = () => {
    gridAdvancedFilterCallback(undefined);
  };

  return (
    <div className="lp-page-content">
      <Title
        type="page"
        title="Invoices"
      >
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Card className="with-grid">
        <Card.Body>
          <Grid
            ref={gridRef}
            onGridReady={onGridReady}
            gridFilters={gridFilters}
          />
        </Card.Body>
      </Card>

      <SlidingPanel gridRef={gridRef} onNavigation={(item: any) => viewCallback(item, populateInvoices)} />
    </div>
  );
}

export default AllInvoicesList;
