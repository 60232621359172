import { useEffect, useState } from "react";
import Select, { MenuPlacement } from "react-select";

type Props = {
  id: string,
  formatOptionLabel?: any,
  onChange?: (value: any) => any,
  onBlur?: (event: any) => any,
  onInputChange?: (keyword: any) => void,
  className?: string,
  classNamePrefix?: string,
  value?: string | string[] | number,
  defaultValue?: string | string[] | number,
  options?: any[],
  endpointCall?: () => Promise<any>,
  getOptionLabel?: (option: any) => any,
  getOptionValue?: (option: any) => any,
  isMulti?: boolean,
  isClearable?: boolean,
  isDisabled?: boolean,
  closeMenuOnSelect?: boolean,
  menuPlacement?: MenuPlacement,
  placeholder?: string,
  inputRef?: any,
  isLoading?: boolean,
  styles?: any,
  menuPortalTarget?: any
}

export default function CustomSelect(props: Props) {
  const [options, setOptions] = useState<any[] | undefined>(props.options);
  const [value, setValue] = useState<string | string[] | number | undefined>(props.value);
  const [isLoading, setIsLoading] = useState<boolean | undefined>(props.isLoading ?? true);

  const {
    id,
    endpointCall,
    formatOptionLabel,
    className,
    classNamePrefix,
    defaultValue,
    isDisabled,
    isMulti,
    isClearable,
    menuPlacement,
    closeMenuOnSelect,
    onChange,
    onBlur,
    onInputChange,
    inputRef,
    getOptionLabel,
    getOptionValue,
    styles,
    menuPortalTarget
  } = props;

  useEffect(() => {
    if(endpointCall) {
      try {
        endpointCall().then(response => {
          setOptions(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
      } catch (err) {
        setIsLoading(false);
      }
    }
    else {
      if(!props.isLoading)
      {
        setIsLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if(props.isLoading !== undefined) {
      setIsLoading(props.isLoading);
    }
  }, [props.isLoading]);

  const getValueOption = (option: string | string[] | number | undefined) => {
    if(option == undefined || option == null) {
      return null;
    }

    if(Array.isArray(option)) {
      return options?.filter(x => option.includes(x.id)) ?? [];
    }
    else {
      return options?.find(x => option === x.id);
    }
  }

  return (
    <Select
      id={id}
      value={getValueOption(value)}
      ref={inputRef}
      options={options}
      formatOptionLabel={formatOptionLabel}
      getOptionLabel={getOptionLabel ?? ((option) => option.name)}
      getOptionValue={getOptionValue ?? ((option) => option.id)}
      onChange={onChange}
      onBlur={onBlur}
      onInputChange={onInputChange}
      className={className ?? 'lp-select'}
      classNamePrefix={classNamePrefix ?? 'lp-select'}
      defaultValue={getValueOption(defaultValue)}
      isDisabled={isDisabled || isLoading}
      isMulti={isMulti ?? false}
      isClearable={isClearable}
      closeMenuOnSelect={closeMenuOnSelect}
      isLoading={isLoading}
      menuPlacement={menuPlacement}
      menuPortalTarget={menuPortalTarget}
      styles={styles}
      placeholder={props.placeholder ?? ""}
    />
  );
}
