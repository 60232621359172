import { ColDef } from "ag-grid-community";
import store from "state/store";
import { deleteLteUser, getUserPermissions, getUserRoles } from "actions/user";
import { ModalState, setModalData, setModalErrors, toggleModal, toggleModalLoadingState } from "state/modalSlice";
import { setRowData } from "state/gridSlice";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import CheckboxCellRenderer from "components/Grid/CheckboxCellRenderer";
import { LteUserModel } from "models/view/LteUserModel";
import { changeUserStatus } from "actions/user";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import { DropdownFilter } from "components/Grid/GridFilters/Filters/DropdownFilter";
import { DropdownFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/DropdownFloatingFilter";
import { BooleanFilter } from "components/Grid/GridFilters/Filters/BooleanFilter";
import { BooleanFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/BooleanFloatingFilter";
import { DropdownArraySearchFilter } from "components/Grid/GridFilters/Filters/DropdownArraySearchFilter";
import { getLteBranchesSummary, getLteBranchesWithDepartmentsAndTeamsSummary, getLteBranchesWithDepartmentsSummary } from "actions/lte";
import DropDownOptionListCellRenderer from "components/Grid/DropDownOptionListCellRenderer";
import { DropDownOptionListFormatter } from "components/Grid/ValueFormatters/DropDownOptionListFormatter";
import { CheckboxFormatter } from "components/Grid/ValueFormatters/CheckboxFormatter";
import ViewLteUser from "./ViewLteUser/ViewLteUser";
import { NavigateFunction } from "react-router-dom";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

export const viewCallback = (id: string) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        allowNavigation: true,
        title: "View Lte User",
        children: <ViewLteUser id={id} />
      }
    )
  );
}

const viewProfile = (id: string, navigate: NavigateFunction) => {
  navigate(`/user/${id}`);
};

const deleteModal = (id: string) => {
  let modal: ModalState = {
    title: "Delete confirmation",
    body: "Are you sure you want to delete this LTE user?",
    actionText: "Delete",
    actionVariant: "danger",
    onAction: () => deleteCallback(id),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
};


const deleteCallback = (id: string) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  deleteLteUser(id).then(() => {
    store.dispatch(setRowData(gridState.rowData.filter((x: any) => x.id !== id)));
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

const changeStatus = (id: string) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  changeUserStatus(id).then(() => {
    let index: number = gridState.rowData.findIndex((x: any) => x.id == id);
    let newEntry: LteUserModel = { ...gridState.rowData[index], active: !gridState.rowData[index].active };
    let newArray: Array<any> = [...gridState.rowData];
    newArray[index] = newEntry;
    store.dispatch(setRowData(newArray));
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

const changeStatusModal = (id: string) => {
  let modal: ModalState = {
    title: "Confirmation",
    body: "Are you sure you want to change the status for this User?",
    onAction: () => changeStatus(id),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
}

export const getColumnDefs = () => {
  const loggedInUser = store.getState().user;
  
  const columnDefs: (ColDef<LteUserModel> | {excludeFromExport: boolean})[] = [
    {
      headerName: 'Email Address',
      field: 'email',
      filter: 'agTextColumnFilter',
      minWidth: 300,
      width: 300,
    },
    {
      headerName: 'First Name',
      field: 'firstName',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Last Name',
      field: 'lastName',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: "Role",
      field: 'userRole.name',
      filter: DropdownFilter,
      filterParams: {
        property: 'userRoleId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: () => getUserRoles(store.getState().user.lawPageTradingEntityId!)
      },
      minWidth: 220
    },
    {
      headerName: 'Additional Permissions',
      field: 'userPermissions',
      filter: DropdownArraySearchFilter,
      filterParams: {
        property: 'userPermissions',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: () => getUserPermissions(store.getState().user.lawPageTradingEntityId!)
      },
      cellRenderer: DropDownOptionListCellRenderer,
      valueFormatter: DropDownOptionListFormatter,
      minWidth: 220
    },
    {
      headerName: "Branch",
      field: 'lteBranch.name',
      filter: DropdownFilter,
      filterParams: {
        property: 'lteBranchId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getLteBranchesSummary
      },
      minWidth: 220
    },
    {
      headerName: "Department",
      field: 'lteDepartment.name',
      filter: DropdownFilter,
      valueFormatter: params => params.value ?? "—",
      filterParams: {
        property: 'lteDepartmentId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getLteBranchesWithDepartmentsSummary
      },
      minWidth: 220
    },
    {
      headerName: "Team",
      field: 'lteTeam.name',
      valueFormatter: params => params.value ?? "—",
      filter: DropdownFilter,
      filterParams: {
        property: 'lteTeamId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getLteBranchesWithDepartmentsAndTeamsSummary
      },
      minWidth: 220
    },
    {
      headerName: 'Is Active',
      field: 'active',
      filter: BooleanFilter,
      filterParams: {
        property: 'active',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: BooleanFloatingFilter,
      floatingFilterComponentParams: {
        values: [{
          id: 'true',
          name: 'Yes'
        },
        {
          id: 'false',
          name: 'No'
        },
        ]
      },
      minWidth: 100,
      width: 100,
      cellRenderer: CheckboxCellRenderer,
      valueFormatter: CheckboxFormatter
    },
    {
      headerName: "Is Fee Earner",
      field: 'isFeeEarner',
      filter: BooleanFilter,
      filterParams: {
        property: 'isFeeEarner',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: BooleanFloatingFilter,
      floatingFilterComponentParams: {
        values: [{
          id: 'true',
          name: 'Yes'
        },
        {
          id: 'false',
          name: 'No'
        },
        ]
      },
      cellRenderer: CheckboxCellRenderer,
      valueFormatter: CheckboxFormatter,
      minWidth: 100,
      width: 100
    },
    {
      excludeFromExport: true,
      cellClass: 'lp-actions-cell',
      suppressHeaderMenuButton: true,
      suppressColumnsToolPanel: true,
      cellRendererSelector: (params) => {
        if (!params.data?.isLteActive) {
          return;
        }

        return {
          component: ActionsCellRenderer,
          params: {
            buttonsData: [
              {
                type: ActionButtonTypes.View,
                callback: (id: string) => viewCallback(id)
              },
              {
                type: ActionButtonTypes.ViewProfile,
                callback: (id: string, navigate: NavigateFunction) => viewProfile(id, navigate),
              },
              ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageLTEUsers) ? [{
                type: params.data?.active ? ActionButtonTypes.Deactivate : ActionButtonTypes.Activate,
                callback: (id: string) => changeStatusModal(id)
              }] : []),
              ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageLTEUsers) ? [{
                type: ActionButtonTypes.Delete,
                callback: (id: string) => deleteModal(id)
              }] : [])
            ]
          }
        }
      },
      suppressSizeToFit: true,
      floatingFilter: false,
      sortable: false,
      minWidth: 105,
      maxWidth: 105,
      width: 105,
      suppressMovable: true,
      pinned: 'right',
      resizable: false
    }
  ];
  return columnDefs;
}
