import { useGridFilter } from "ag-grid-react";
import { useCallback } from "react";
import _ from 'lodash';

type Props = {
  property: string,
  model: any,
}

export const DropdownFilter = ({ property, model }: Props) => {
  const doesFilterPass = useCallback(({ node }: any) => {
      // filtering logic
      return model.some((x: any) => x == _.get(node.data, property));
  }, [model]);

  // register filter callbacks with the grid
  useGridFilter({ doesFilterPass });

  return (
    <div style={{ display: "inline-block", width: "200px", height: "200px" }}>
      Use a floating filter!
    </div>
  );
}
