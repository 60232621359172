import { LawPageTradingEntityModel } from "models/view/LawPageTradingEntityModel";
import Field from "components/Fields/Field";
import { Card, Row, Col } from "react-bootstrap";
import BooleanField from "components/Fields/BooleanField";
import { useAppSelector } from "hooks/appSelector";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

type Props = {
  lte: LawPageTradingEntityModel | undefined,
}

export default function ViewLawPageTradingEntitySummary(props: Props) {
  const user = useAppSelector((state) => state.user);
      
  return (
    <>
      <Card>
        <Card.Body>
          <Row className="multiple">
            <Col sm={6} xxl={3}>
              <Field
                label={"Legal Name"}
                value={props.lte?.legalName}
              />
            </Col>
            <Col sm={6} xxl={3}>
              <Field
                label={"Trading Name"}
                value={props.lte?.tradingName}
              />
            </Col>
            <Col sm={6} xxl={3}>
              <Field
                label={"Type"}
                value={props.lte?.lawPageTradingEntityType?.name}
              />
            </Col>
            <Col sm={6} xxl={3}>
              <Field
                label={"Code"}
                value={props.lte?.code}
              />
            </Col>
            <Col sm={6} xxl={3}>
              <Field
                label={"Company Registration No."}
                value={props.lte?.companyRegistrationNo}
              />
            </Col>
            <Col sm={6} xxl={3}>
              <Field
                label={"Regulator"}
                value={props.lte?.regulator?.name}
              />
            </Col>
            <Col sm={6} xxl={3}>
              <Field
                label={"Regulator Registration Reference"}
                value={props.lte?.regulatorReference}
              />
            </Col>
            <Col sm={6} xxl={3}>
              <Field
                label={"VAT Registration No."}
                value={props.lte?.vatRegistrationNo}
              />
            </Col>
            <Col sm={6} xxl={3}>
              <Field
                label={"Entity URL"}
                value={props.lte?.entityUrl}
              />
            </Col>
            {user.userPermissions?.includes(UserPermissionsNames.ManageAllLTEs) &&
              <Col sm={6} xxl={3}>
                <BooleanField
                  label={"Is Active"}
                  value={props.lte?.isActive ?? false}
                />
              </Col>
            }
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}
