import Title from "components/Title/index";
import { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import useSlidingPanelActions from "actions/slidingPanel";
import { ModalState } from "state/modalSlice";
import { Card, Button, Dropdown } from "react-bootstrap";
import { MatterFeeEarnerModel } from "models/view/MatterFeeEarnerModel";
import { deleteMatterFeeEarner, getMatterFeeEarnersForMatter } from "actions/matter";
import Loader from "components/Loader/index";
import React from "react";
import ViewCaseManagerMatterFeeEarner from "./ViewCaseManagerMatterFeeEarner";
import CreateMatterFeeEarnerForm from "./CreateMatterFeeEarner/CreateMatterFeeEarnerForm";
import ViewMatterFeeEarner from "./ViewMatterFeeEarner/ViewMatterFeeEarner";
import MoreButton from "components/Buttons/MoreButton";
import useModalActions from "actions/modal";
import { useAppSelector } from 'hooks/appSelector';
import { UserPermissionsNames } from 'enums/UserPermissionsNames';
import IconButtonGrid from "components/Buttons/IconButtonGrid";
import { MatterFeeEarnerType } from "enums/MatterFeeEarnerType";

type Props = {
  matterId: string,
  matterIsClosed?: boolean,
  isCurrentUserAllowedToViewMatter?: boolean,
  lteHasDelegateUsers?: boolean
}

export default function MatterFeeEarnerList(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [caseManagerMatterFeeEarners, setCaseManagerMatterFeeEarners] = useState<MatterFeeEarnerModel[]>([]);
  const [otherMatterFeeEarners, setOtherMatterFeeEarners] = useState<MatterFeeEarnerModel[]>([]);
  const [delegateUserMatterFeeEarners, setDelegateUserMatterFeeEarners] = useState<MatterFeeEarnerModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const modalActions = useModalActions();
  const slidingPanelActions = useSlidingPanelActions();
  const loggedInUser = useAppSelector((state) => state.user);

  const loadMatterFeeEarners = () => {
    if(props.matterId) {
      setIsLoading(true);
      getMatterFeeEarnersForMatter(props.matterId)
        .then((response) => {
          setCaseManagerMatterFeeEarners(response.data.filter((x: MatterFeeEarnerModel) => x.isCaseManager == true));
          setOtherMatterFeeEarners(response.data.filter((x: MatterFeeEarnerModel) => x.isCaseManager == false && x.type == MatterFeeEarnerType.FeeEarner));
          setDelegateUserMatterFeeEarners(response.data.filter((x: MatterFeeEarnerModel) => x.isCaseManager == false && x.type == MatterFeeEarnerType.DelegateUser));
        })
        .catch((error) => {
          setGenericErrors(error.response?.data?.Message ?? error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  useEffect(() =>
  {
    loadMatterFeeEarners();
  }, [props.matterId]);

  const openCreateMatterFeeEarnerSlidingPanel = (matterFeeEarnerType: MatterFeeEarnerType) => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: `Add ${matterFeeEarnerType == MatterFeeEarnerType.FeeEarner ? 'Fee Earner' : 'Delegate User'}`,
      children: <CreateMatterFeeEarnerForm
                  matterId={props.matterId}
                  matterFeeEarnerType={matterFeeEarnerType}
                  submitCallback={loadMatterFeeEarners}
                />
    });
  };

  const openViewMatterFeeEarnerSlidingPanel = (id: string, matterFeeEarnerType: MatterFeeEarnerType) => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: `View ${matterFeeEarnerType == MatterFeeEarnerType.FeeEarner ? 'Fee Earner' : 'Delegate User'}`,
      children: <ViewMatterFeeEarner
                  matterId={props.matterId}
                  matterFeeEarnerId={id}
                  isMatterClosed={props.matterIsClosed}
                  editSubmitCallback={loadMatterFeeEarners}
                  deleteCallback={loadMatterFeeEarners}
                  isCurrentCaseManager={caseManagerMatterFeeEarners.find(x => x.id == id)?.isCaseManager ?? false}
                  isCurrentUserAllowedToViewMatter={props.isCurrentUserAllowedToViewMatter}
                />
    });
  };

  const deleteMatterFeeEarnerModal = (id: string, matterFeeEarnerType: MatterFeeEarnerType) => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: `Are you sure you want to delete this ${matterFeeEarnerType == MatterFeeEarnerType.FeeEarner ? 'Fee Earner' : 'Delegate User'}?`,
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteMatterFeeEarnerCallback(id),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  };
  
  const deleteMatterFeeEarnerCallback = (id: string) => {
    modalActions.toggleModalLoading();
    deleteMatterFeeEarner(id)
      .then(() => {
        loadMatterFeeEarners();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);

        modalActions.toggleModalLoading();
        modalActions.toggleModalShownStatus();
      });
  };

  return (
    <>
      <Title type="section" title={"Fee Earners"}>
        {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatters) && !props.matterIsClosed && props.isCurrentUserAllowedToViewMatter &&
          <>
            {props.lteHasDelegateUsers && 
              <Dropdown>
                <Dropdown.Toggle variant="success">
                  <MdAdd />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => openCreateMatterFeeEarnerSlidingPanel(MatterFeeEarnerType.FeeEarner)}>Fee Earner</Dropdown.Item>
                  <Dropdown.Item onClick={() => openCreateMatterFeeEarnerSlidingPanel(MatterFeeEarnerType.DelegateUser)}>Delegate User</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            }
            {!props.lteHasDelegateUsers && 
              <Button onClick={() => openCreateMatterFeeEarnerSlidingPanel(MatterFeeEarnerType.FeeEarner)} className="btn-icon" variant="success">
                <MdAdd />
              </Button>
            }
          </>
        }
      </Title>
      <Card className="managers-list">
        <Card.Body>
          {isLoading && <Loader inlineLoader={isLoading} />}

          {genericErrors && (
            <div className="lp-errors">
              {genericErrors}
            </div>
          )}

          <div>
            {caseManagerMatterFeeEarners.length === 0 &&
              <div className="lp-list-empty case-manager">No case manager is assigned to this matter</div>
            }

            {caseManagerMatterFeeEarners.map((x: any, index: number) => {
              return (
                <React.Fragment key={index}>
                  <ViewCaseManagerMatterFeeEarner 
                    matterFeeEarner={x}
                    viewAction={(id: string) => openViewMatterFeeEarnerSlidingPanel(id, x.type)}
                  />
                </React.Fragment>
              )
            })}
          </div>

          <div className="lp-simple-list">
            <div className="lp-color-title secondary">Other users</div>
            <ul>
              {otherMatterFeeEarners.length === 0 &&
                <li className="lp-list-empty">No other users found</li>
              }
              {otherMatterFeeEarners.map((x: any, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <li>
                      <span className="lp-simple-list-name">{x?.user?.displayName ? x.user.displayName : x.user.email}</span>
                      <div className="lp-actions-btns">
                        {props.matterIsClosed &&
                          <IconButtonGrid
                            type={ActionButtonTypes.View}
                            callback={() => openViewMatterFeeEarnerSlidingPanel(x.id, x.type)}
                          />
                        }

                        {!props.matterIsClosed &&
                          <MoreButton 
                            id={x.id}
                            listButtons={[
                              {
                                type: ActionButtonTypes.View,
                                callback: (id: string) => openViewMatterFeeEarnerSlidingPanel(id, x.type)
                              },
                              ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatters) && props.isCurrentUserAllowedToViewMatter ? [{
                                type: ActionButtonTypes.Delete,
                                callback: (id: string) => deleteMatterFeeEarnerModal(id, x.type)
                              }] : [])
                            ]}
                          />
                        }
                      </div>
                    </li>
                  </React.Fragment>
                )
              })}
            </ul>
          </div>

          {delegateUserMatterFeeEarners.length > 0 &&
            <div className="lp-simple-list">
              <div className="lp-color-title secondary">Delegate users</div>
              <ul>
                {delegateUserMatterFeeEarners.map((x: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <li>
                        <span className="lp-simple-list-name">{x?.user?.displayName ? x.user.displayName : x.user.email}</span>
                        <div className="lp-actions-btns">
                          {props.matterIsClosed &&
                            <IconButtonGrid
                              type={ActionButtonTypes.View}
                              callback={() => openViewMatterFeeEarnerSlidingPanel(x.id, x.type)}
                            />
                          }

                          {!props.matterIsClosed &&
                            <MoreButton 
                              id={x.id}
                              listButtons={[
                                {
                                  type: ActionButtonTypes.View,
                                  callback: (id: string) => openViewMatterFeeEarnerSlidingPanel(id, x.type)
                                },
                                ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatters) && props.isCurrentUserAllowedToViewMatter ? [{
                                  type: ActionButtonTypes.Delete,
                                  callback: (id: string) => deleteMatterFeeEarnerModal(id, x.type)
                                }] : [])
                              ]}
                            />
                          }
                        </div>
                      </li>
                    </React.Fragment>
                  )
                })}
              </ul>
            </div>
          }
        </Card.Body>
      </Card>
    </>
  );
}
