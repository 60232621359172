import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Row, Col, Form, Button } from "react-bootstrap";
import { getEntityAddressById, getPreferredEntityAddress, updateEntityAddress } from "actions/entity";
import useSlidingPanelActions from "actions/slidingPanel";
import Loader from "components/Loader";
import { validationSuite } from "./validationSuite";
import { removeEmptyFields } from "utils/form";
import { CreateOrUpdateEntityAddressModel } from "models/create/CreateOrUpdateEntityAddressModel";
import { useAppSelector } from "hooks/appSelector"
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import Field from "components/Fields/Field";
import BooleanField from "components/Fields/BooleanField";
import useModalActions from 'actions/modal';
import { MdWarning } from 'react-icons/md';
import { ModalState } from 'state/modalSlice';

type Props = {
  entityId: string,
  addressId?: string,
  submitCallback?: Function
}

export default function EditLteAddressOnlyIsPreferredForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();
  const loggedInUser = useAppSelector((state) => state.user);
  const modalActions = useModalActions();

  useEffect(() => {
    if(props.addressId)
    {
      setIsLoading(true);
      getEntityAddressById(props?.entityId, props.addressId)
        .then( (entityAddressResponse) => {
          const initialState = {
            addressId: entityAddressResponse.data?.addressId ?? '',
            lawPageTradingEntityId: entityAddressResponse.data?.lawPageTradingEntityId ?? '',
            countryId: entityAddressResponse.data?.address?.country?.id ?? '',
            countryName: entityAddressResponse.data?.address?.country?.name ?? '',
            addressTypeId: entityAddressResponse.data?.address?.addressType?.id ?? '',
            addressTypeName: entityAddressResponse.data?.address?.addressType?.name ?? '',
            addressLine1: entityAddressResponse.data?.address?.addressLine1 ?? '',
            addressLine2: entityAddressResponse.data?.address?.addressLine2 ?? '',
            addressLine3: entityAddressResponse.data?.address?.addressLine3 ?? '',
            city: entityAddressResponse.data?.address?.city ?? '',
            state: entityAddressResponse.data?.address?.state ?? '',
            county: entityAddressResponse.data?.address?.county ?? '',
            postCode: entityAddressResponse.data?.address?.postCode ?? '',
            isPreferred: entityAddressResponse.data?.isPreferred,
            systemOwned: entityAddressResponse.data?.address?.systemOwned
          }
          reset(initialState);
        }
      )
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
    }
  }, []);

  const { reset, control, watch, handleSubmit } = useForm<CreateOrUpdateEntityAddressModel>({
    resolver: vestResolver(validationSuite)
  });

  const onSubmit = handleSubmit((data) => submitData(data));
    
  async function submitData(data: CreateOrUpdateEntityAddressModel) {
    setIsLoading(true);
    if(data.isPreferred == true) {
      getPreferredEntityAddress(props.entityId).then((response) => {
        let bodyMessage: React.ReactNode = <></>;
        if(response.data?.addressId && response.data?.addressId != props.addressId) {
          bodyMessage = <div className="lp-modal-warning">
            <MdWarning />This Address is set as preferred which means that he will replace the current one.<br/>
            Are you sure you want to do that? If not, uncheck the Is Preferred checkbox.
          </div>;
        }
        else {
          updateAddress(data, false);
          return;
        }
        
        let modal: ModalState = {
          title: "Update confirmation",
          body: bodyMessage,
          actionText: "Update",
          onAction: () => updateAddress(data),
          show: false,
          onClose: () => setIsLoading(false)
        }
        modalActions.setModal(modal);
        modalActions.toggleModalShownStatus();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
        setIsLoading(false);
      });
    }
    else {
      updateAddress(data, false);
    }
  }

  function updateAddress(data: CreateOrUpdateEntityAddressModel, fromModal: boolean = true) {
    if(fromModal)
    {
      modalActions.toggleModalLoading();
    }

    data.entityId = props.entityId;
    data.shouldUpdateAllEntities = false;
    removeEmptyFields(data);
    updateEntityAddress(data.addressId!, props?.entityId, data).then(() => {
      if(props.submitCallback) {
        props.submitCallback();
      }
      slidingPanelActions.clearSlidingPanel();
      reset();
    })
    .catch((error: any) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
      if(fromModal)
      {
        modalActions.toggleModalLoading();
        modalActions.toggleModalShownStatus();
      }
    });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Col>
            <Field
              label={"Post Code"}
              value={watch('postCode')}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Field
              label={"Address Type"}
              value={watch('addressTypeName')}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Field
              label={"Address Line 1"}
              value={watch('addressLine1')}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Field
              label={"Address Line 2"}
              value={watch('addressLine2')}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Field
              label={"Address Line 3"}
              value={watch('addressLine3')}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Field
              label={"City"}
              value={watch('city')}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Field
              label={"County"}
              value={watch('county')}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Field
              label={"State"}
              value={watch('state')}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Field
              label={"Country"}
              value={watch('countryName')}
            />
          </Col>
        </Row>

        <Row className="mt-4 mb-4">
          <Form.Group as={Col} controlId="isPreferred">
            <Form.Label>Is Preferred</Form.Label>
            <Controller
              control={control}
              name="isPreferred"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <Form.Check 
                  type="switch"
                  id="isPreferred">
                    <Form.Check.Input
                      className= "form-check-input"
                      ref={ref}
                      checked={value}
                      onChange={(ev: any) => onChange(ev.target.checked)}
                    />
                </Form.Check>
              )}
            />
          </Form.Group>

          {loggedInUser.userPermissions?.find(a => a == UserPermissionsNames.ManageSystemOwnedContacts) &&
            <Col>
              <BooleanField
                label={"System Owned"}
                value={watch('systemOwned')}
              />
            </Col>
          }
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <Button variant="success" type="submit">Update</Button>
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
