import { CurrencyFormatter } from "components/Grid/ValueFormatters/CurrencyFormatter";
import { CustomTableColumnDefModel } from "models/view/CustomTableColumnDefModel";

export const columnDefsLedgerTransactionCustomTable = () => {
  const columnDefs: CustomTableColumnDefModel[] = [
    {
      field: "accountDisplayName",
      headerName: "Account"
    },
    {
      field: "drAmount",
      headerName: "DR",
      formatter: CurrencyFormatter,
      customCSSClass: "text-end",
    },
    {
      field: "crAmount",
      headerName: "CR",
      formatter: CurrencyFormatter,
      customCSSClass: "text-end",
    },
  ];

  return columnDefs;
}
