import { ColDef, ColGroupDef } from "ag-grid-community";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { dateComparator } from "components/Grid/Comparators/DateComparator";
import { DateFormatter } from "components/Grid/ValueFormatters/DateFormatter";
import { CurrencyFormatter } from "components/Grid/ValueFormatters/CurrencyFormatter";
import { MatterRecordableItemModel } from "models/view/MatterRecordableItemModel";
import { TimeFormatter } from "components/Grid/ValueFormatters/TimeFormatter";
import { DropdownFilter } from "components/Grid/GridFilters/Filters/DropdownFilter";
import { DropdownFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/DropdownFloatingFilter";
import { getChargingSchemes } from "actions/chargingSchemes";
import { getActivitiesSummary, getFeeEarnerLevelsSummary } from "actions/settings";
import ViewMatterRecordableItem from "../ActivityHistory/MatterRecordableEvent/ViewMatterRecordableItem/ViewMatterRecordableItem";
import store from "state/store";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import { ModalState, setModalData, setModalErrors, toggleModal, toggleModalLoadingState } from "state/modalSlice";
import { deleteMatterRecordableItem } from "actions/matter";
import { setRowData } from "state/gridSlice";
import { BooleanFilter } from "components/Grid/GridFilters/Filters/BooleanFilter";
import { BooleanFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/BooleanFloatingFilter";
import CheckboxCellRenderer from "components/Grid/CheckboxCellRenderer";
import { CheckboxFormatter } from "components/Grid/ValueFormatters/CheckboxFormatter";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

export const viewCallback = (rowData: MatterRecordableItemModel, reloadGridData?: Function) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        allowNavigation: true,
        title: "View Recordable Item",
        width: "55rem",
        children: <ViewMatterRecordableItem
          matterId={rowData.matterId!}
          recordableEventId={rowData.matterRecordableEventId!}
          recoradbleItemId={rowData.id}
          isMatterClosed={rowData.matter?.isClosed}
          isBilled={rowData.isBilled}
          onEditSubmitCallback={reloadGridData}
          showMoreButtons={!rowData.isBilled}
        />
      }
    )
  );
}

const deleteModal = (rowData: MatterRecordableItemModel) => {
  let modal: ModalState = {
    title: "Delete confirmation",
    body: "Are you sure you want to delete this Matter Recordable Item?",
    actionText: "Delete",
    actionVariant: "danger",
    onAction: () => deleteCallback(rowData.matterId!, rowData.matterRecordableEventId, rowData.id!),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
      
};

const deleteCallback = (matterId: string, recordableEventId: string, recordableItemId: string) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  deleteMatterRecordableItem(matterId, recordableEventId, recordableItemId).then(() => {
    store.dispatch(setRowData(gridState.rowData.filter((x: MatterRecordableItemModel) => x.id !== recordableItemId)));
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

export const getColumnDefs = (reloadGridData?: Function) => {
  const loggedInUser = store.getState().user;
  const buttonsColumnWidth = loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterRecordableEvents) ? 105 : 65;

  const columnDefs: (ColDef<MatterRecordableItemModel> | ColGroupDef<MatterRecordableItemModel> | {excludeFromExport: boolean})[] = [
    { 
      headerName: 'Date',
      field: 'date',
      filter: 'agDateColumnFilter',
      valueFormatter: DateFormatter,
      filterParams: {
        comparator: dateComparator
      },
      minWidth: 150
    },
    { 
      headerName: 'Recordable Event',
      field: 'recordableEventDisplayName',
      filter: 'agTextColumnFilter',
      minWidth: 150,
      width: 150
    },
    {
      headerName: 'Fee Earner',
      field: 'user.name',
      filter: 'agTextColumnFilter',
      minWidth: 150,
      width: 150
    },
    {
      headerName: 'Fee Earner Level',
      field: 'feeEarnerLevel.name',
      filter: DropdownFilter,
      filterParams: {
        property: 'feeEarnerLevelId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getFeeEarnerLevelsSummary
      },
      minWidth: 220
    },
    { 
      headerName: 'Activity',
      field: 'activity.name', 
      filter: DropdownFilter,
      filterParams: {
        property: 'activityId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getActivitiesSummary
      },
      minWidth: 220
    },
    { 
      headerName: 'Description',
      field: 'description',
      filter: 'agTextColumnFilter',
      minWidth: 150,
      width: 150
    },
    {
      headerName: "Start Time",
      field: 'startTime',
      valueFormatter: TimeFormatter,
      minWidth: 100,
      width: 100,
      floatingFilter: false
    },
    {
      headerName: "End Time",
      field: 'endTime',
      valueFormatter: TimeFormatter,
      minWidth: 100,
      width: 100,
      floatingFilter: false
    },
    {
      headerName: "Number of Minutes",
      field: 'numberOfMinutes',
      filter: 'agNumberColumnFilter',
    },
    {
      headerName: "Number Of Units",
      field: 'numberOfUnits',
      filter: 'agNumberColumnFilter',
    },
    { 
      headerName: 'Charging Scheme',
      field: 'chargingScheme.name', 
      filter: DropdownFilter,
      filterParams: {
        property: 'chargingSchemeId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getChargingSchemes
      },
      minWidth: 220
    },
    {
      headerName: "Charge Rate",
      field: 'chargeRate',
      filter: 'agNumberColumnFilter',
      valueFormatter: CurrencyFormatter,
      cellClass: 'text-end',
    },
    {
      headerName: "Amount Charged",
      field: 'amountCharged',
      filter: 'agNumberColumnFilter',
      valueFormatter: CurrencyFormatter,
      cellClass: 'text-end',
    },
    {
      headerName: 'Is Billed',
      field: 'isBilled',
      minWidth: 100,
      width: 100,
      filter: BooleanFilter,
      filterParams: {
        property: 'isBilled',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: BooleanFloatingFilter,
      floatingFilterComponentParams: {
        values: [{
          id: 'true',
          name: 'Yes'
        },
        {
          id: 'false',
          name: 'No'
        },
      ]},
      cellRenderer: CheckboxCellRenderer,
      valueFormatter: CheckboxFormatter
    },
    { 
      headerName: 'Invoice Ref Number',
      field: 'matterInvoice.invoiceRefNumber',
      filter: 'agTextColumnFilter',
      minWidth: 150,
      width: 150
    },
    {
      headerName: "Amount Invoiced",
      field: 'adjustedAmountChargedOnInvoice',
      filter: 'agNumberColumnFilter',
      valueFormatter: CurrencyFormatter,
      cellClass: 'text-end',
    },
    {
      headerName: "Calculated VAT Value",
      field: 'calculatedVATValueOnInvoice',
      filter: 'agNumberColumnFilter',
      valueFormatter: CurrencyFormatter,
      cellClass: 'text-end',
    },
    { 
      headerName: 'Client',
      field: 'matterParticipatingEntity.name',
      filter: 'agTextColumnFilter',
      minWidth: 150,
      width: 150
    },
    { 
      headerName: 'Activity Code',
      field: 'activity.code',
      filter: 'agTextColumnFilter',
    },
    { 
      excludeFromExport: true,
      cellClass: 'lp-actions-cell',
      suppressHeaderMenuButton: true,
      suppressColumnsToolPanel: true,
      cellRenderer: ActionsCellRenderer,
      cellRendererParams: (params: any) => {
        return { buttonsData: [
          {
            type: ActionButtonTypes.View,
            callback: () => viewCallback(params.data, reloadGridData)
          },
          ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterRecordableEvents) &&
            !params.data.matter.isClosed && !params.data.isBilled ? [{
              type: ActionButtonTypes.Delete,
              callback: () => deleteModal(params.data)
          }] : [])
        ]};
      },
      suppressSizeToFit: true,
      floatingFilter: false,
      sortable: false,
      minWidth: buttonsColumnWidth,
      maxWidth: buttonsColumnWidth,
      width: buttonsColumnWidth,
      suppressMovable: true,
      pinned: 'right',
      resizable: false
    }
  ];

  return columnDefs;
}