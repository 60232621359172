import { getEmailFolders, getEmailsForDashboard } from 'actions/user';
import React, { useEffect, useState } from 'react';
import Title from 'components/Title';
import Loader from 'components/Loader';
import { Button, Card, Form } from 'react-bootstrap';
import { MatterEmailModel } from 'models/view/MatterEmailModel';
import { DropDownOptionModel } from 'models/view/DropDownOptionModel';
import { useAppSelector } from 'hooks/appSelector';
import useSlidingPanelActions from 'actions/slidingPanel';
import CustomSelect from 'components/Select/Select';
import { getEmailTypeName } from 'utils/enums';
import moment from 'moment';
import { DateFormat } from 'utils/constants';
import { MdOutlineAdd, MdOutlineAttachment, MdRefresh } from 'react-icons/md';
import ManualAddEmails from "containers/Matter/AddEmails/ManualAddEmails";
import ViewUserEmail from './ViewUserEmail/ViewUserEmail';
import { formatEmailSender } from 'utils/misc';
import { UserPermissionsNames } from 'enums/UserPermissionsNames';

export default function DashboardUserEmails() {
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [existingNextPage, setExistingNextPage] = useState<boolean>(false);
  const [emails, setEmails] = useState<MatterEmailModel[]>();
  const [emailFolder, setEmailFolder] = useState<string>();
  const [emailFolderOptions, setEmailFolderOptions] = useState<DropDownOptionModel[]>([]);
  const [keyword, setKeyword] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [genericErrors, setGenericErrors] = useState(undefined);
  const user = useAppSelector((state) => state.user);
  const slidingPanelActions = useSlidingPanelActions();
  const pageSize = 10;
  const [windowOnFocus, setWindowOnFocus] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    getEmailFolders(user.msalAccessToken!).then((response) => {
      setEmailFolder(
        response.data.find((x: DropDownOptionModel) => x.name == 'Inbox')?.id
      );
      setEmailFolderOptions(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  const checkTokensOnFocus = () => {
    setWindowOnFocus(true);
  };

  const onBlur = () => {
    setWindowOnFocus(false);
  };

  useEffect(() => {
    window.addEventListener('focus', checkTokensOnFocus);
    return () => {
      window.removeEventListener('focus', checkTokensOnFocus);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('blur', onBlur);
    return () => {
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  useEffect(() => {
    //auto refresh emails every minute
    const getEmails = setInterval(() => {
      if(pageNumber == 0 && windowOnFocus) {
        loadUserEmails(pageNumber, pageSize, user.msalAccessToken!, emailFolder, keyword, true);
      }
    }, 60000);

    return () => clearInterval(getEmails);
  }, [pageNumber, emailFolder, keyword, windowOnFocus]);

  useEffect(() => {
    loadUserEmails(pageNumber, pageSize, user.msalAccessToken!, emailFolder, keyword);
  }, [pageNumber]);

  const loadUserEmails = (page: number, take: number, msalAccessToken: string, emailFolder?: string, keyword?: string, hideIsLoading?: boolean) => {
    if(!hideIsLoading) {
      setIsLoading(true);
    }

    getEmailsForDashboard(
      page,
      take,
      msalAccessToken,
      emailFolder,
      keyword
    )
    .then((response) => {
      setExistingNextPage(response.data.existingNextPage);
      setEmails(response.data.emails);
      setGenericErrors(undefined);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      if(!hideIsLoading) {
        setIsLoading(false);
      }
    });
  };

  const handlePageDown = () => {
    if (pageNumber > 0) {
      setIsLoading(true);
      setPageNumber(pageNumber - 1);
    }
  };

  const handlePageUp = () => {
    if (existingNextPage) {
      setIsLoading(true);
      setPageNumber(pageNumber + 1);
    }
  };

  const filter = () => {
    if(pageNumber == 0){
      loadUserEmails(pageNumber, pageSize, user.msalAccessToken!, emailFolder, keyword);
    }
    else {
      setPageNumber(0);
    }
  }

  const viewEmail = (emailGraphId: string) => {
    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        width: "65rem",
        title: "View Email Details",
        children: <ViewUserEmail emailGraphId={emailGraphId} />
      }
    );
  };

  const addEmailsToMatter = () => {
    slidingPanelActions.setSlidingPanel(
      {
        width: "70rem",
        isShown: true,
        title: "Import Emails To Matter",
        children: <ManualAddEmails onSubmitCallback={() => loadUserEmails(pageNumber, pageSize, user.msalAccessToken!, emailFolder, keyword)}/>
      }
    );
  }

  const refreshEmails = () => {
    if(pageNumber == 0){
      loadUserEmails(pageNumber, pageSize, user.msalAccessToken!, emailFolder);
    }
    else {
      setPageNumber(0);
    }
  }

  return (
    <>
      <Title type="section" title="Emails">
        {user.userPermissions?.some(a => a == UserPermissionsNames.ManageDashboardEmails) &&
          <div onClick={addEmailsToMatter} className="link">
            Import emails to matter
            <MdOutlineAdd />
          </div>
        }
        <Button onClick={refreshEmails} className="btn-icon" variant="primary">
          <MdRefresh />
        </Button>
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Card className="with-emails dashboard">
        <Card.Body>
          {isLoading && <Loader inlineLoader />}

          <div className="lp-emails-filter">
            <Form.Control
              type="text"
              placeholder="Keyword"
              onBlur={(e: any) => setKeyword(e.target.value)}
            />

            <CustomSelect
              id="attachmentsIds"
              menuPlacement="bottom"
              placeholder="Select folder"
              options={emailFolderOptions}
              onChange={(val) => setEmailFolder(val?.id ?? null)}
              value={emailFolder}
            />

            <Button onClick={filter}>
              Filter
            </Button>
          </div>

          <div className="lp-emails-list">
            {emails == undefined || emails.length == 0 &&
              <div className="lp-empty emails">
                <div className="image"></div>
                <div className="text">No emails found!</div>
              </div>
            }

            {emails &&
              emails?.map((x: MatterEmailModel, index: number) => (
                <React.Fragment key={index}>
                  <div
                    key={index * (pageNumber + 1)}
                    onClick={x.isDraft ? () => {} : (() => viewEmail(x.graphResourceId))}
                    className="lp-email-item"
                  >
                    <div className="lp-email-item-info">
                      <span className={`lp-email-item-status ${x.type == 0 ? "sent" : "received"}`}>{getEmailTypeName(x.type)}</span>
                      {x.existsInDatabase &&
                        <span className="lp-email-item-existing">{x?.matter?.fileNumber}</span>
                      }
                      {x.isDraft &&
                        <span className="lp-email-item-draft">Draft</span>
                      }
                    </div>
                    <div className="lp-email-item-date">
                      {x.type == 0
                        ?
                          <>
                            {
                              x?.sentDateTime
                                ? moment(x?.sentDateTime).format(
                                    DateFormat.MomentWithTime
                                  )
                                : '—'
                            }
                          </>
                        :
                          <>
                            {
                              x?.receivedDateTime
                                ? moment(x?.receivedDateTime).format(
                                    DateFormat.MomentWithTime
                                  )
                                : '—'
                            }
                          </>
                      }
                    </div>
                    <div className="lp-email-item-subject">
                      <strong>{x.subject}</strong>
                    </div>
                    <div className="lp-email-item-recipients">
                      <div className="lp-email-item-from">
                        <span className="label">From</span>
                        <span className="value">{formatEmailSender(x.senderEmailAddress, x.senderName)}</span>
                      </div>
                      <div className="lp-email-item-to">
                        <span className="label">To</span>
                        <span className="value">
                          {
                            x.toRecipients && x.toRecipients.length > 0
                              ? x.toRecipients.map((x, i) => <React.Fragment key={i}><span className="email">{x}</span></React.Fragment>)
                              : '—'
                          }
                        </span>
                      </div>
                    </div>
                    <div className="lp-email-item-toolbar">
                      <div className={`lp-email-item-type${x.isInternal ? ' internal' : ' external'}`}>
                        {x.isInternal ? 'Internal' : 'External'}
                      </div>
                      {x.hasAttachments && 
                        <span className="lp-email-item-file">
                          <MdOutlineAttachment />
                          With attachments
                        </span>
                      }
                    </div>
                  </div>
                </React.Fragment>
              ))
            }
          </div>

          {(pageNumber != 0 || existingNextPage) && (
            <div className="lp-emails-pagination">
              <Button
                onClick={handlePageDown}
                variant="secondary-400"
                disabled={pageNumber == 0}
              >
                Previous page
              </Button>

              <div className="lp-emails-current-page">
                Page
                {' ' + (pageNumber + 1)}
              </div>

              <Button
                onClick={handlePageUp}
                variant="secondary-400"
                disabled={!existingNextPage}
              >
                Next page
              </Button>
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
}