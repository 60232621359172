import { vestResolver } from "@hookform/resolvers/vest";
import {
  createMatterFundsTransfer,
  getFundsTransferTypes,
  getMatterClientsSummary,
  getMatterDefaultClient,
  getOpenMattersSummary
} from "actions/matter";
import useSlidingPanelActions from "actions/slidingPanel";
import Loader from "components/Loader/index";
import CustomSelect from "components/Select/Select";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { DateFormat } from "utils/constants";
import { removeEmptyFields } from "utils/form";
import { validationSuite } from "./validationSuite";
import { getDateOnly } from "utils/date";
import { DropDownOptionModel } from "models/view/DropDownOptionModel";
import { CreateOrUpdateMatterFundsTransferModel } from "models/create/CreateOrUpdateMatterFundsTransferModel";
import { FundsTransferTypeIds } from "enums/FundsTransferTypeIds";

type Props = {
  matterId: string,
  onSubmitCallback?: Function
}

export default function CreateMatterFundsTransferForm(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const slidingPanelActions = useSlidingPanelActions();
  const currentDate: Date = getDateOnly(new Date());
  const [clients, setClients] = useState<DropDownOptionModel[]>([]);
  const [transferToClients, setTransferToClients] = useState<DropDownOptionModel[]>([]);

  const { register, handleSubmit, control, setValue, watch, reset, resetField, formState: { errors } } = useForm<CreateOrUpdateMatterFundsTransferModel>({
    resolver: vestResolver(validationSuite),
    defaultValues: {
      date: currentDate
    }
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateOrUpdateMatterFundsTransferModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    createMatterFundsTransfer(props.matterId, data).then((response) => {
      slidingPanelActions.clearSlidingPanel();
      reset();

      props.onSubmitCallback && props.onSubmitCallback();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  useEffect(() => {
    loadClients(props.matterId);
  }, []);

  const loadClients = (matterId?: string) => {
    if(matterId) {
      setIsLoading(true);
      const promises: Promise<any>[] = [];
      promises.push(getMatterClientsSummary(matterId));
      promises.push(getMatterDefaultClient(matterId));
      
      Promise.all(promises).then(([matterClientsResponse, matterDefaultClientResponse]) => {
        setClients(matterClientsResponse.data);
        setValue('matterParticipatingEntityId', matterDefaultClientResponse.data?.id);
      }).catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
    }
    else {
      setClients([]);
      resetField("matterParticipatingEntityId");
    }
  }

  const onChangeTransferToMatter = (matterId?: string) => {
    if(matterId){
      const promises: Promise<any>[] = [];
      promises.push(getMatterClientsSummary(matterId));
      promises.push(getMatterDefaultClient(matterId));
      
      Promise.all(promises).then(([matterClientsResponse, matterDefaultClientResponse]) => {
        setTransferToClients(matterClientsResponse.data);
        setValue('transferToMatterParticipatingEntityId', matterDefaultClientResponse.data?.id);
      }).catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
    }
    else {
      setTransferToClients([]);
      resetField('transferToMatterParticipatingEntityId');
    }
  }

  const onChangeTransferType = (transferTypeId?: string) => {
    if(transferTypeId != FundsTransferTypeIds.InterClientId){
      resetField("transferToMatterId");
      resetField("transferToMatterParticipatingEntityId");
    }
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Form.Group className="mb-4" controlId="matterParticipatingEntityId">
          <Form.Label className="required">Client</Form.Label>
          <Controller
            control={control}
            name={`matterParticipatingEntityId`}
            shouldUnregister={true}
            render={({ field: { onChange, value, name, ref } }) => (
              <CustomSelect
                id="matterParticipatingEntityId"
                inputRef={ref}
                className={`lp-select${errors?.matterParticipatingEntityId?.message ? ' invalid' : ''}`}
                options={clients}
                value={value}
                onChange={val => onChange(val?.id ?? null)}
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.matterParticipatingEntityId?.message && (errors.matterParticipatingEntityId?.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="date">
          <Form.Label className="required">Date</Form.Label>
          <Controller
            control={control}
            name="date"
            shouldUnregister={true}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                className={`${errors?.date?.message ? 'invalid' : ''}`}
                id="date"
                dateFormat={DateFormat.Datepicker}
                selected={value ? getDateOnly(value) : null}
                onChange={(val) => onChange(val != null ? getDateOnly(val) : val)}
                showMonthDropdown
                showYearDropdown
                autoComplete="off"
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.date?.message && (errors.date.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="transferTypeId">
          <Form.Label className="required">Transfer Type</Form.Label>
          <Controller
            control={control}
            name={`transferTypeId`}
            shouldUnregister={true}
            render={({ field: { onChange, value, name, ref } }) => (
              <CustomSelect
                id="transferTypeId"
                inputRef={ref}
                className={`lp-select${errors?.transferTypeId?.message ? ' invalid' : ''}`}
                endpointCall={getFundsTransferTypes}
                value={value}
                onChange={val => {onChange(val?.id ?? null); onChangeTransferType(val?.id);}}
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.transferTypeId?.message && (errors.transferTypeId?.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="amount">
          <Form.Label className="required">Amount</Form.Label>
          <Form.Control
            type="number"
            className={`${errors?.amount?.message ? 'invalid' : ''}`}
            {...register(`amount`, {shouldUnregister: true})}
            min="0.00"
            step="0.01"
            onWheel={e => e.currentTarget.blur()}
          />
          <Form.Text className="lp-error">
            {errors?.amount?.message && (errors.amount?.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="description">
          <Form.Label className="required">Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            className={`${errors?.description?.message ? 'invalid' : ''}`}
            {...register(`description`, {shouldUnregister: true})}
          />
          <Form.Text className="lp-error">
            {errors?.description?.message && (errors.description?.message)}
          </Form.Text>
        </Form.Group>

        {watch("transferTypeId") == FundsTransferTypeIds.InterClientId &&
          <>
            <Form.Group className="mb-4" controlId="transferToMatterId">
              <Form.Label className="required">Transfer To Matter</Form.Label>
              <Controller
                control={control}
                name={`transferToMatterId`}
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <CustomSelect
                    id="transferToMatterId"
                    inputRef={ref}
                    className={`lp-select${errors?.transferToMatterId?.message ? ' invalid' : ''}`}
                    endpointCall={() => getOpenMattersSummary(props.matterId)}
                    value={value}
                    onChange={val => {onChange(val?.id ?? null); onChangeTransferToMatter(val?.id ?? undefined);}}
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.transferToMatterId?.message && (errors.transferToMatterId?.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-4" controlId="transferToMatterParticipatingEntityId">
              <Form.Label className="required">Transfer To Client</Form.Label>
              <Controller
                control={control}
                name={`transferToMatterParticipatingEntityId`}
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <CustomSelect
                    id="transferToMatterParticipatingEntityId"
                    inputRef={ref}
                    className={`lp-select${errors?.transferToMatterParticipatingEntityId?.message ? ' invalid' : ''}`}
                    options={transferToClients}
                    value={value}
                    onChange={val => onChange(val?.id ?? null)}
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.transferToMatterParticipatingEntityId?.message && (errors.transferToMatterParticipatingEntityId?.message)}
              </Form.Text>
            </Form.Group>
          </>
        }

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <Button variant="success" type="submit">Create</Button>
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
