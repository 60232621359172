export enum UserPermissionsNames {
  ViewMatters = "View Matters",
  ManageMatters = "Manage Matters",
  DeleteMatters = "Delete Matters",
  ViewMatterAppointments = "View Matter Appointments",
  ViewMatterActivityHistory = "View Matter Activity History",
  ManageMatterActivityHistory = "Manage Matter Activity History",
  ViewMatterLedger = "View Matter Ledger",
  ManageMatterLedger = "Manage Matter Ledger",
  ViewMatterRecordableEvents = "View Matter Recordable Events",
  ManageMatterRecordableEvents = "Manage Matter Recordable Events",
  ViewMatterIncidentalExpenses = "View Matter Incidental Expenses",
  ManageMatterIncidentalExpenses = "Manage Matter Incidental Expenses",
  ViewMatterDisbursements = "View Matter Disbursements",
  ManageMatterDisbursements = "Manage Matter Disbursements",
  ViewMatterInvoices = "View Matter Invoices",
  ManageMatterInvoices = "Manage Matter Invoices",

  ViewEntities = "View Address Book",
  ManageEntities = "Manage Address Book",

  ManageSystemOwnedContacts = "Manage System Owned Contacts",

  ViewLTE = "View LTE",
  ManageLTE = "Manage LTE",
  ViewLTEUsers = "View LTE Users",
  ManageLTEUsers = "Manage LTE Users",
  ManageUserBusinessProfile = "Manage User Business Profile",
  ViewLTESystemSettings = "View LTE System Settings",
  ManageLTESystemSettings = "Manage LTE System Settings",
  ViewLTEDelegateUsers = "View LTE Delegate Users",
  ManageLTEDelegateUsers = "Manage LTE Delegate Users",

  ViewAllLTEs = "View All LTEs",
  ManageAllLTEs = "Manage All LTEs",

  ViewChargingSchemes = "View Charging Schemes",
  ManageChargingSchemes = "Manage Charging Schemes",

  ViewLTESettings = "View LTE Settings",
  ManageLTESettings = "Manage LTE Settings",

  ViewDefaultSettings = "View Default Settings",
  ManageDefaultSettings = "Manage Default Settings",

  ViewChartOfAccounts = "View Chart of Accounts",
  ManageChartOfAccounts = "Manage Chart of Accounts",

  ViewInvoicePages = "View Invoice Pages",

  ViewBillsToPay = "View Bills to Pay",
  ManageBillsToPay = "Manage Bills to Pay",

  ViewTimeAndIncidentalExpenses = "View Time & Incidental Expenses",
        
  ViewCalendars = "View Calendars",
  ManageCalendars = "Manage Calendars",

  SyncUserEmailsAndCalendar = "Sync User Emails & Calendar",
  ViewDashboardStatistics = "View Dashboard Statistics",
  ViewDashboardMatterStatistics = "View Dashboard Matter Statistics",
  ViewDashboardRecordableEventStatistics = "View Dashboard Recordable Event Statistics",
  ViewDashboardInvoiceStatistics = "View Dashboard Invoice Statistics",
  ViewDashboardEmails = "View Dashboard Emails",
  ManageDashboardEmails = "Manage Dashboard Emails",
  ViewRecentMatters = "View Recent Matters",
  ViewRecentInvoices = "View Recent Invoices"
}