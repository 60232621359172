import { deleteDelegatedLTE, getDelegatedFromLTEs } from "actions/lte";
import Title from "components/Title/index";
import { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import useSlidingPanelActions from "actions/slidingPanel";
import useModalActions from "actions/modal";
import { ModalState } from "state/modalSlice";
import { Button } from "react-bootstrap";
import { useAppSelector } from 'hooks/appSelector';
import { UserPermissionsNames } from 'enums/UserPermissionsNames';
import { DelegatedLTEModel } from "models/view/DelegatedLTEModel";
import ListDelegatedLTEs from "components/List/ListDelegatedLTEs";
import CreateDelegatedLTEForm from "./CreateDelegatedLTE/CreateDelegatedLTEForm";
import EditDelegatedLTEForm from "./EditDelegatedLTE/EditDelegatedLTEForm";

type Props = {
  lteId: string
}

export default function DelegatedLTEsList(props: Props) {
  const [delegatedLTEs, setDelegatedLTEs] = useState<DelegatedLTEModel[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [genericErrors, setGenericErrors] = useState(null);

  const slidingPanelActions = useSlidingPanelActions();
  const modalActions = useModalActions();
  const loggedInUser = useAppSelector((state) => state.user);

  const loadDelegatedLTEs = () => {
    if(props.lteId) {
      setIsLoading(true);
      getDelegatedFromLTEs(props.lteId)
        .then((response) => {          
          setDelegatedLTEs(response.data);
        })
        .catch((error) => {
          setGenericErrors(error.response?.data?.Message ?? error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  useEffect(() =>
  {
    loadDelegatedLTEs();
  }, [props.lteId]);

  const openCreateDelegatedLTESlidingPanel = () => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Create Delegate Trading Entity',
      children: <CreateDelegatedLTEForm
                  lteId={props.lteId}
                  submitCallback={loadDelegatedLTEs} />
    });
  };

  const openEditDelegatedLTESlidingPanel = (id: string) => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Edit Delegate Trading Entity',
      children: <EditDelegatedLTEForm
                  delegatedLTEId={id}
                  submitCallback={loadDelegatedLTEs}
                />
    });
  };

  const deleteModal = (id: string) => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this Trading Entity?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallback(id),
      show: true
    }
    modalActions.setModal(modal);
  };

  const deleteCallback = (id: string) => {
    modalActions.toggleModalLoading();
    deleteDelegatedLTE(id).then(() => {
      loadDelegatedLTEs();
      modalActions.toggleModalShownStatus();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
  };

  return (
    <>
      <Title type="section" title={"Delegate Trading Entities"}>
        {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageAllLTEs) &&
          <Button onClick={openCreateDelegatedLTESlidingPanel} className="btn-icon" variant="success">
            <MdAdd />
          </Button>
        }
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <ListDelegatedLTEs
        listItems={delegatedLTEs ?? []}
        listButtons={
          [
            ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageAllLTEs) ?
              [{
                type: ActionButtonTypes.Edit,
                callback: (id: string) => openEditDelegatedLTESlidingPanel(id)
              },
              {
                type: ActionButtonTypes.Delete,
                callback: (id: string) => deleteModal(id)
              }] : [])
          ]
        }
        loadState={isLoading}
      />
    </>
  );
}
