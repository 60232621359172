import { forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import './style.scss';
import Grid from "components/Grid";
import useGridActions from 'actions/grid';
import { getColumnDefs } from './columnDefs';
import { Button, Card } from 'react-bootstrap';
import Title from 'components/Title/index';
import { MdAdd } from 'react-icons/md';
import { getParticipatingEntities } from 'actions/matter';
import CreateParticipatingEntityForm from './CreateParticipatingEntity/CreateParticipatingEntityForm';
import useSlidingPanelActions from 'actions/slidingPanel';
import { EntityRoleIds } from 'enums/EntityRoleIds';
import { RowDataUpdatedEvent } from 'ag-grid-community';
import store from "state/store";
import { GridIds } from 'enums/GridIds';
import TooltipIcon from 'components/TooltipIcon';
import { useAppSelector } from 'hooks/appSelector';
import { UserPermissionsNames } from 'enums/UserPermissionsNames';
import { AgGridReact } from 'ag-grid-react';

type Props = {
  matterId: string,
  matterTypeId: string,
  matterIsClosed?: boolean
}

const ParticipatingEntityList = forwardRef(function ParticipatingEntityList(props: Props, forwardedRef: Ref<AgGridReact | null>) {
  const [isMissingClientWarningVisible, setIsMissingClientWarningVisible] = useState<boolean>(false);
  const [isNoClientWarningVisible, setIsNoClientWarningVisible] = useState<boolean>(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const gridActions = useGridActions();
  const slidingPanelActions = useSlidingPanelActions();
  const loggedInUser = useAppSelector((state) => state.user);

  const gridRef = useRef<AgGridReact>(null);
  useImperativeHandle(forwardedRef, () => gridRef.current!);

  const onGridReady = useCallback(() =>
  {
    const columnDefs = getColumnDefs(props.matterTypeId);
    gridActions.setGridColumnDefs([...columnDefs]);
  }, [props.matterTypeId]); 

  useEffect(() => {
    getParticipatingEntities(props.matterId).then((response) => 
    {
      gridActions.setGridRowData(response.data as any);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
      gridActions.setGridRowData([]);
    });
  }, [props.matterId, props.matterIsClosed]);

  useEffect(() => {
    gridActions.setGridId(`${GridIds.ParticipatingEntities}/${props.matterId}`);
  }, []);

  const openCreateParticipatingEntitySlidingPanel = () => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Add Participating Entity',
      children: <CreateParticipatingEntityForm matterId={props.matterId} matterTypeId={props.matterTypeId}/>
    });
  };

  const onRowDataUpdated = useCallback((params: RowDataUpdatedEvent) => {
    const gridState = store.getState().grid;
    if(gridState.rowData){
      setIsMissingClientWarningVisible(gridState.rowData.find(x => x.entityRoleId === EntityRoleIds.LayClientId || x.entityRoleId === EntityRoleIds.InstructingSolicitorId) === undefined);
      setIsNoClientWarningVisible(gridState.rowData.find(x => x.isDefaultClient == true) === undefined);
    }
    else{
      setIsMissingClientWarningVisible(true);
    }
  }, []);

  return (
    <>
      <Title
        type="section"
        title={
          <>
            Participating Entities
            {isMissingClientWarningVisible &&
              <TooltipIcon type="error" text="You need to add at least one Lay Client or Instructing Solicitor" />
            }
            {!isMissingClientWarningVisible && isNoClientWarningVisible &&
              <TooltipIcon type="error" text="There is no Default Client set" />
            }
          </>
        }
      >
        {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatters) && !props.matterIsClosed &&
          <Button onClick={openCreateParticipatingEntitySlidingPanel} className="btn-icon" variant="success">
            <MdAdd />
          </Button>
        }
      </Title>
      
      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Card className='with-grid'>
        <Card.Body>
          <Grid ref={gridRef} onGridReady={onGridReady} onRowDataUpdated={onRowDataUpdated}/>
        </Card.Body>
      </Card>
    </>
  );
});

export default ParticipatingEntityList;
