import { deleteLteAddress, getLteAddresses } from "actions/lte";
import Title from "components/Title/index";
import { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import useSlidingPanelActions from "actions/slidingPanel";
import useModalActions from "actions/modal";
import { ModalState } from "state/modalSlice";
import { Button } from "react-bootstrap";
import ListContact from "components/List/ListContact";
import { ContactModel } from "models/view/ContactModel";
import { LteAddressModel } from "models/view/LteAddressModel";
import CreateLteAddressForm from "./CreateLteAddress/CreateLteAddressForm";
import { LinkedAddressModel } from "models/view/LinkedAddressModel";
import EditLteAddressForm from "./EditLteAddress/EditLteAddressForm";
import { checkIfAddressIsLinked } from "actions/address";
import { formatAddress } from 'utils/address';
import { useAppSelector } from 'hooks/appSelector';
import { UserPermissionsNames } from 'enums/UserPermissionsNames';

type Props = {
  lteId: string,
  edit?: boolean
}

export default function LteAddressList(props: Props) {
  const [lteAddresses, setLteAddresses] = useState<ContactModel[]>();
  const [loaderAddress, setLoaderAddress] = useState<boolean>(false);
  const [genericErrors, setGenericErrors] = useState(null);

  const slidingPanelActions = useSlidingPanelActions();
  const modalActions = useModalActions();
  const loggedInUser = useAppSelector((state) => state.user);

  const loadContacts = () => {
    if(props.lteId) {
      setLoaderAddress(true);
      getLteAddresses(props.lteId)
        .then((response) => 
        {
          const lteContactAddresses = [] as ContactModel[];
          response.data.forEach((x: LteAddressModel) => {
            lteContactAddresses.push({
              id: x.addressId,
              contact: formatAddress(x?.address),
              type: x.address?.addressType?.name,
              isPreferred: x.isPreferred,
              systemOwned: x.address?.systemOwned
            })
          });
          
          setLteAddresses(lteContactAddresses);
        })
        .catch((error) => {
          setGenericErrors(error.response?.data?.Message ?? error.message);
        })
        .finally(() => {
          setLoaderAddress(false);
        });
    }
  }

  useEffect(() =>
  {
    loadContacts();
  }, [props.lteId]);

  const openCreateLteAddressSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Create Address',
      children: <CreateLteAddressForm
                  lteId={props.lteId}
                  submitCallback={reloadAndResetContacts} />
    });
  };

  const openEditLteAddressSlidingPanel = (id: string) => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Edit Address',
      children: <EditLteAddressForm 
                  lteId={props.lteId}
                  addressId={id}
                  submitCallback={reloadAndResetContacts}
                />
    });
  };

  const reloadAndResetContacts = () => {
    loadContacts();
  }

  const deleteAddressModal = (id: string) => {
    checkIfAddressIsLinked(id, props.lteId)
      .then((response) => 
      {
        const filteredResponse = response.data.filter((p: LinkedAddressModel) => p.addressId !== id || p.entityId !== props.lteId);
        if(filteredResponse.length > 0){
          const linkedEntites = filteredResponse.map((x: LinkedAddressModel) =>
            x.lawPageTradingEntity ? x.lawPageTradingEntity.legalName + ' (trading entity)': (x.entity ? x.entity.displayName + ' (entity)' : undefined) );
          let modal: ModalState = {
            title: "Delete confirmation",
            actionText: "For all entities",
            secondActionText: "Just for this entity",
            body: 
              <>
                <p>This address is also linked to following entities:</p>
                <ul>
                  {linkedEntites.filter((x: LinkedAddressModel) => x !== undefined).map((entityName: any) => {
                    return <li>{entityName}</li>;
                  })}
                </ul>
                <div>Do you want to delete the address assigned only to this entity or for all entities?</div>
              </>,
            onAction: () => {
              modalActions.toggleModalLoading();
              deleteLteAddress(props.lteId, id, true).then(() => {
                reloadAndResetContacts();
                modalActions.toggleModalShownStatus();
              })
              .catch((error) => {
                modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
              })
              .finally(() => {
                modalActions.toggleModalLoading();
              });
            },
            onSecondAction: () => {
              modalActions.toggleModalLoading();
              deleteLteAddress(props.lteId, id, false).then(() => {
                reloadAndResetContacts();
                modalActions.toggleModalShownStatus();
              })
              .catch((error) => {
                modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
              })
              .finally(() => {
                modalActions.toggleModalLoading();
              });
            },
            show: true
          }
          modalActions.setModal(modal);
        }
        else{
          let modal: ModalState = {
            title: "Delete confirmation",
            body: "Are you sure you want to delete this address?",
            actionText: "Delete",
            actionVariant: "danger",
            onAction: () => {
              modalActions.toggleModalLoading();
              deleteLteAddress(props.lteId, id, true).then(() => {
                reloadAndResetContacts();
                modalActions.toggleModalShownStatus();
              })
              .catch((error) => {
                modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
              })
              .finally(() => {
                modalActions.toggleModalLoading();
              });
            },
            show: true
          }
          modalActions.setModal(modal);
        }
      })
  };

  return (
    <>
      <Title type="section" title={"Addresses"}>
        {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageAllLTEs || a == UserPermissionsNames.ManageLTE) && props.edit &&
          <Button onClick={openCreateLteAddressSlidingPanel} className="btn-icon" variant="success">
            <MdAdd />
          </Button>
        }
      </Title>
      
      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <ListContact
        listItems={lteAddresses ?? []}
        listButtons={
          [
            ...((loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageAllLTEs || a == UserPermissionsNames.ManageLTE) &&
              props.edit) ?
              [{
                type: ActionButtonTypes.Edit,
                callback: (id: string) => openEditLteAddressSlidingPanel(id)
              },
              {
                type: ActionButtonTypes.Delete,
                callback: (id: string) => deleteAddressModal(id)
              }] : [])
          ]
        }
        loadState={loaderAddress}
      >
        <>
          <div>{"Address"}</div>
          <div>{"Type"}</div>
          <div></div>
        </>
      </ListContact>
    </>
  );
}
