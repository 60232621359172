import { useEffect, useState } from "react";
import Field from "components/Fields/Field";
import Loader from "components/Loader";
import { Row, Col } from "react-bootstrap";
import { deleteMatterFile, deleteMatterFilePermission, getMatterFileById, getMatterFilePermissions } from "actions/matter";
import { MatterFileModel } from "models/view/MatterFileModel";
import BooleanField from "components/Fields/BooleanField";
import moment from "moment";
import { DateFormat } from "utils/constants";
import ListSlidingPanel from "components/List/ListSlidingPanel";
import { MatterFilePermissionsModel } from "models/view/MatterFilePermissionsModel";
import { formatFileSize, openUrlInNewtab } from "utils/misc";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { ModalState } from "state/modalSlice";
import useModalActions from "actions/modal";
import { DeleteMatterFilePermissionModel } from "models/delete/DeleteMatterFilePermissionModel";
import useSlidingPanelActions from "actions/slidingPanel";
import { useAppSelector } from "hooks/appSelector";
import EditMatterFileDetailsForm from "../EditMatterFileDetails/EditMatterFileDetailsForm";
import EditActivityHistoryTaxonomyTermsForm from "../../ActivityHistoryGrid/ActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTermsForm";
import PreviewMatterFile from "../PreviewMatterFile/PreviewMatterFile";
import CustomTable from "components/CustomTable/CustomTable";
import { columnDefsFileHistoryCustomTable } from "./columnDefsFileHistoryCustomTable";
import { UserRoleNames } from "enums/UserRoleNames";

type Props = {
  matterId: string,
  fileId: string,
  activityHistoryId?: string,
  isMatterClosed?: boolean,
  hasTaxonomies: boolean,
  syncMatterFiles?: Function,
  showMoreButtons?: boolean,
}

function ViewMatterFile(props: Props){
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [matterFile, setMatterFile] = useState<MatterFileModel>();
  const [matterFilePermissions, setMatterFilePermissions] = useState<MatterFilePermissionsModel>();
  const modalActions = useModalActions();
  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const loggedInUser = useAppSelector((state) => state.user);

  useEffect(() => {
    setIsLoading(true);
    getMatterFileById(props.matterId, props.fileId).then( response => {
      setMatterFile(response.data);
      
      if(response.data?.isDeletedOnStorage == false){
        getMatterFilePermissions(props.matterId, props.fileId).then( getMatterFilePermissionsResponse => {
          setMatterFilePermissions(getMatterFilePermissionsResponse.data);
        }).catch((error) => {
          setGenericErrors(error.response?.data?.Message ?? error.message);
        }).finally(() => {
          setIsLoading(false);
        });
      }
      else {
        setIsLoading(false);
      }
    }).catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
      setIsLoading(false);
    });
  }, [props.fileId]);

  // Set more buttons only after matterFile is loaded as some properties are needed for the buttons
  useEffect(() => {
    if (matterFile) {
      const canManagePdfFiles = matterFile.isInvoiceRelated || 
        (loggedInUser.userRole?.name != UserRoleNames.Clerk &&
        loggedInUser.userRole?.name != UserRoleNames.BackOffice && 
        loggedInUser.userRole?.name != UserRoleNames.Accountant &&
        loggedInUser.userRole?.name != UserRoleNames.EntryLevel &&
        !matterFile.isInvoiceRelated);

      if(canManagePdfFiles) {
        setMoreButtons();
        setEditButton();
      }
    }
  }, [matterFile]);

  const onChildPanelClose = () => {
    slidingPanelActions.clearSlidingPanel();
    slidingPanelActions.setSlidingPanel({
      ...currentSlidingPanelState,
    });
  }

  const setEditButton = () => {
    if (!props.isMatterClosed && !matterFile?.isDeletedOnStorage) {
      slidingPanelActions.setOnEdit(() => {
        slidingPanelActions.clearSlidingPanel();

        slidingPanelActions.setSlidingPanel(
        {
          isShown: true,
          width: "55rem",
          title: "Edit Matter File Details",
          children: <EditMatterFileDetailsForm
            matterId={props.matterId}
            fileId={props.fileId}
            onSubmitCallback={onChildPanelClose}
          />,
          onCancel: onChildPanelClose
        });
      });
    }
  }

  const setMoreButtons = () => {
    if (!props.isMatterClosed && props.showMoreButtons) {
      slidingPanelActions.setMoreButtons([
        ...(!matterFile?.isDeletedOnStorage ? [{
          type: ActionButtonTypes.PreviewFile,
          callback: () => onClickPreview(),
        }] : []),
        ...((props.hasTaxonomies && props.activityHistoryId) ? [{
          type: ActionButtonTypes.EditTaxonomyTerms,
          callback: () => onClickTaxonomies(),
        }] : []),
        ...((!matterFile?.isDeletedOnStorage && matterFile?.webUrl) ? [{
          type: ActionButtonTypes.OpenFile,
          callback: () => openUrlInNewtab(matterFile?.webUrl)
        }] : []),
        ...(!matterFile?.isDeletedOnStorage ? [{
          type: ActionButtonTypes.Delete,
          callback: () => onClickDelete(),
        }] : [])
      ]);
    }
  }

  const onClickPreview = () => {
    slidingPanelActions.clearSlidingPanel();

    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        allowNavigation: true,
        title: "Preview Matter File",
        children: <PreviewMatterFile
          matterId={props.matterId}
          fileId={props.fileId!}
          activityHistoryId={props.activityHistoryId}
          isMatterClosed={props.isMatterClosed}
          hasTaxonomies={props.hasTaxonomies}
          syncMatterFiles={props.syncMatterFiles}
          showMoreButtons
        />,
        width: "80rem",
      }
    );
  }

  const onClickTaxonomies = () => {
    slidingPanelActions.clearSlidingPanel();

    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Edit Taxonomy Terms",
        width: "55rem",
        children: <EditActivityHistoryTaxonomyTermsForm
          matterId={props.matterId}
          activityHistoryId={props.activityHistoryId!}
          onSubmitCallback={onChildPanelClose}
        />,
        onCancel: onChildPanelClose
      }
    );
  };

  const onClickDelete = () => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this Matter File?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallback(props.matterId, props.fileId, props.syncMatterFiles),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  }

  const deleteCallback = (matterId: string, activityId: string, syncMatterFiles?: Function) => {
    modalActions.toggleModalLoading();
    deleteMatterFile(matterId, activityId).then(() => {
      if(syncMatterFiles) {
        syncMatterFiles();
      }
      modalActions.toggleModalShownStatus();
      slidingPanelActions.clearSlidingPanel();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
  }

  const deletePermissionModal = (email: string, isEditPermission: boolean) => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this permission?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deletePermissionCallback(email, isEditPermission),
      show: true
    }
    modalActions.setModal(modal);
  };

  const deletePermissionCallback = (email: string, isEditPermission: boolean) => {
    setIsLoading(true);
    const data: DeleteMatterFilePermissionModel = {email: email};
    deleteMatterFilePermission(props.matterId, props.fileId, data).then(() => {
      if(isEditPermission) {
        const perm = matterFilePermissions?.editPermissions?.find(x => x.email == email);
        if(!perm?.isInherited) {
          setMatterFilePermissions({
            editPermissions: matterFilePermissions?.editPermissions?.filter(x => x.email != email) ?? [],
            viewPermissions: matterFilePermissions?.viewPermissions ?? []
          });
        }
      } 
      else {
        const perm = matterFilePermissions?.viewPermissions?.find(x => x.email == email);
        if(!perm?.isInherited) {
          setMatterFilePermissions({
            editPermissions: matterFilePermissions?.editPermissions ?? [],
            viewPermissions: matterFilePermissions?.viewPermissions?.filter(x => x.email != email) ?? []
          });
        }
      }
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return(
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col>
          <Field
            label={"Description"}
            value={matterFile?.description}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Field
            label={"File Name"}
            value={matterFile?.name}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"File Path"}
            value={matterFile?.pathToFile}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <BooleanField
            label={"Is Deleted"}
            value={matterFile?.isDeletedOnStorage ?? false}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Created On"}
            value={matterFile?.storageCreatedDate ?
              moment(matterFile?.storageCreatedDate).format(DateFormat.MomentWithTime) : "—"}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Created By"}
            value={matterFile?.storageCreatedBy}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Last Modified On"}
            value={matterFile?.storageLastModifiedDate ?
              moment(matterFile?.storageLastModifiedDate).format(DateFormat.MomentWithTime) : "—"}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Last Modified By"}
            value={matterFile?.storageLastModifiedBy}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Size"}
            value={matterFile?.size ? formatFileSize(matterFile?.size, 2) : "—"}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Additional Note"}
            value={matterFile?.additionalNote}
          />
        </Col>
      </Row>
      {matterFile?.isDeletedOnStorage == false && 
        <>
          <Row>
            <Col>
              <div className="departments">
                <ListSlidingPanel
                  listItems={matterFilePermissions?.editPermissions?.map(x => ({id: x.email, name: x.email, hideDeleteBtn: x.isInherited})) ?? []}
                  listButtons={!matterFile.matter?.isClosed ? [
                    {
                      type: ActionButtonTypes.Delete,
                      callback: (id: string) => deletePermissionModal(id, true)
                    }
                  ] : []}
                >
                  People that can view & edit
                </ListSlidingPanel>
              </div>
              <div className="departments">
                <ListSlidingPanel
                  listItems={matterFilePermissions?.viewPermissions?.map(x => ({id: x.email, name: x.email, hideDeleteBtn: x.isInherited})) ?? []}
                  listButtons={!matterFile.matter?.isClosed ? [
                    {
                      type: ActionButtonTypes.Delete,
                      callback: (id: string) => deletePermissionModal(id, false)
                    }
                  ] : []}
                >
                  People that can view
                </ListSlidingPanel>
              </div>
            </Col>
          </Row>
        </>
      }

      <Row>
        <Col>
          <div className="lp-color-title secondary" style={{margin: "0 -1.6rem"}}>
            History
          </div>

          <div className="mt-3">
            <CustomTable
              columnDefs={columnDefsFileHistoryCustomTable()}
              rowData={matterFile?.matterFileHistory ?? []}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}

export default ViewMatterFile;
