import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Row, Col, Form, Button } from "react-bootstrap";
import { getDelegatedLTEById, updateDelegatedLTE } from "actions/lte";
import useSlidingPanelActions from "actions/slidingPanel";
import Loader from "components/Loader";
import { validationSuite } from "./validationSuite";
import { CreateOrUpdateDelegatedLTEModel } from "models/create/CreateOrUpdateDelegatedLTEModel";
import { DelegatedLTEModel } from "models/view/DelegatedLTEModel";
import Field from "components/Fields/Field";

type Props = {
  delegatedLTEId: string,
  submitCallback?: Function
}

export default function EditDelegatedLTEForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [delegatedLTE, setDelegatedLTE] = useState<DelegatedLTEModel>();
  const slidingPanelActions = useSlidingPanelActions();

  useEffect(() => {
    setIsLoading(true);
    getDelegatedLTEById(props.delegatedLTEId).then( (response) => {
      setDelegatedLTE(response.data);
      reset(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  const {reset,control,  handleSubmit, formState: {errors}} = useForm<CreateOrUpdateDelegatedLTEModel>({
    resolver: vestResolver(validationSuite)
  });

  const onSubmit = handleSubmit((data) => submitData(data));
    
  async function submitData(data: CreateOrUpdateDelegatedLTEModel) {
    setIsLoading(true);
    updateDelegatedLTE(props.delegatedLTEId, data).then((response) => {
      if(props.submitCallback) {
        props.submitCallback();
      }
      slidingPanelActions.clearSlidingPanel();
      reset();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Col>
            <Field
              label={"Trading Entity"}
              value={delegatedLTE?.toLTE?.name ?? ""}
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Form.Group as={Col} controlId="managesDiary">
            <Form.Label>Manage Diary</Form.Label>
            <Controller
              control={control}
              name="managesDiary"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <Form.Check 
                  type="switch"
                  id="managesDiary">
                    <Form.Check.Input
                      className= "form-check-input"
                      ref={ref}
                      checked={value ?? false}
                      onChange={(ev: any) => onChange(ev.target.checked)}
                    />
                </Form.Check>
              )}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="delegatesAccess">
            <Form.Label>Delegate Access</Form.Label>
            <Controller
              control={control}
              name="delegatesAccess"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <Form.Check 
                  type="switch"
                  id="delegatesAccess">
                    <Form.Check.Input
                      className= "form-check-input"
                      ref={ref}
                      checked={value ?? false}
                      onChange={(ev: any) => onChange(ev.target.checked)}
                    />
                </Form.Check>
              )}
            />
          </Form.Group>
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <Button variant="success" type="submit">Update</Button>
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
