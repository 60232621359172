import { ICellRendererParams } from "ag-grid-community";
import { MatterActivityHistorySubtypeFormatter } from "./ValueFormatters/MatterActivityHistorySubtypeFormatter";
import { getMatterActivityHistorySubtypeName } from "utils/enums";

export default function ActivityTypeCellRenderer(props: ICellRendererParams) {
  const setActivityTypeCellClass = (params: any) => {
    let activityTypeCellClass = getMatterActivityHistorySubtypeName(params.value);
    activityTypeCellClass = "lp-activity-type-cell " + activityTypeCellClass.replace(/\s+/g, '-').toLowerCase();  // replace space with dash
    activityTypeCellClass = "lp-activity-type-cell " + activityTypeCellClass.replace(/\//g, '-').toLowerCase();  // replace audio/video forward slash with dash
    return activityTypeCellClass;
  }

  return (
    <>
      {props.value !== undefined &&
        <div className={setActivityTypeCellClass(props)}>
          <span>{MatterActivityHistorySubtypeFormatter(props)}</span>
        </div>
      }
    </>
  );
}
