import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Form, Button } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import CustomSelect from "components/Select/Select";
import Loader from "components/Loader";
import { validationSuite } from "./validationSuite";
import { removeEmptyFields } from "utils/form";
import { getMatterActivityHistory, getMatterRecordableEventsSummary, linkMatterIncidentalExpense } from "actions/matter";
import useGridActions from "actions/grid";
import { DropDownOptionModel } from "models/view/DropDownOptionModel";
import { LinkMatterIncidentalExpenseModel } from "models/update/LinkMatterIncidentalExpenseModel";
import { GridIds } from "enums/GridIds";
import store from "state/store";
import { useAppSelector } from "hooks/appSelector";

type Props = {
  matterId: string,
  matterIncidentalExpenseId: string,
  onSubmitCallback?: Function
}

export default function LinkMatterIncidentalExpenseForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingRecordableEvents, setIsLoadingRecordableEvents] = useState<boolean>(false);
  const [recordableEvents, setRecordableEvents] = useState<DropDownOptionModel[]>([]);
  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const gridActions = useGridActions();

  const {reset, control, handleSubmit, formState: {errors}} = useForm<LinkMatterIncidentalExpenseModel>({
    resolver: vestResolver(validationSuite)
  });

  useEffect(() => {
    setIsLoadingRecordableEvents(true);
    getMatterRecordableEventsSummary(props.matterId, props.matterIncidentalExpenseId)
    .then((response2) => {
      setRecordableEvents(response2.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoadingRecordableEvents(false);
    });
  }, []);

  const onSubmit = handleSubmit((data) => submitData(data));
    
  async function submitData(data: LinkMatterIncidentalExpenseModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    linkMatterIncidentalExpense(props.matterId, props.matterIncidentalExpenseId, data)
      .then((response) => {
        const gridState = store.getState().grid;
        if (gridState.id == `${GridIds.ActivityHistory}/${props.matterId}`) {
          getMatterActivityHistory(props.matterId ?? '').then((response) => {
            gridActions.setGridRowData(response.data);
          });
        }
        slidingPanelActions.clearSlidingPanel();
        reset();

        props.onSubmitCallback && props.onSubmitCallback();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();

    currentSlidingPanelState.onCancel && currentSlidingPanelState.onCancel();
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Form.Group className="mb-4" controlId="recordableEventId">
          <Form.Label>Recordable Event</Form.Label>
          <Controller
            control={control}
            name="recordableEventId"
            shouldUnregister={true}
            render={({ field: { onChange, value, name, ref } }) => (
              <CustomSelect
                id="recordableEventId"
                inputRef={ref}
                className={`lp-select${errors?.recordableEventId?.message ? ' invalid' : ''}`}
                options={recordableEvents}
                isLoading={isLoadingRecordableEvents}
                value={value}
                onChange={val => onChange(val?.id ?? null)}
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.recordableEventId?.message && (errors.recordableEventId.message)}
          </Form.Text>
        </Form.Group>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <Button variant="success" type="submit">Link</Button>
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
