import Title from 'components/Title';
import { TabPanel } from 'react-tabs';
import LTETemplates from './LTETemplates/LTETemplates';
import HeaderAndFooterTemplates from './HeaderAndFooterTemplates/HeaderAndFooterTemplates';
import MatterTemplates from './MatterTemplates/MatterTemplates';
import useSlidingPanelActions from 'actions/slidingPanel';
import TemplateFieldsInfo from './TemplateFieldsInfo/TemplateFieldsInfo';
import { Button } from 'react-bootstrap';
import { MdInfo } from 'react-icons/md';
import CustomTabs from 'components/CustomTabs';
import MatterTemplateTypeList from './MatterTemplateTypes/MatterTemplateTypeList';

export default function ViewSettings() {
  const slidingPanelActions = useSlidingPanelActions();

  const templateFieldsInfo = () => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      width: '70rem',
      title: 'Template Fields Info',
      children: <TemplateFieldsInfo />,
    });
  };

  return (
    <div className="lp-page-content">
      <Title type="page" title="Templates Settings">
        <Button
          variant="primary"
          className="btn-icon"
          onClick={() => templateFieldsInfo()}
        >
          <MdInfo />
        </Button>
      </Title>

      <CustomTabs 
        tabList={[
          {
            name: 'LTE Templates',
            panel: <TabPanel>
              <LTETemplates />
            </TabPanel>
          },
          {
            name: 'Header And Footer Templates',
            panel: <TabPanel>
              <HeaderAndFooterTemplates />
            </TabPanel>
          },
          {
            name: 'Matter Templates',
            panel: <TabPanel>
              <MatterTemplates />
            </TabPanel>
          },
          {
            name: 'Matter Template Types',
            panel: <TabPanel>
              <MatterTemplateTypeList />
            </TabPanel>
          }
        ]}
      />
    </div>
  );
}
