import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { vestResolver } from '@hookform/resolvers/vest';
import { useParams } from 'react-router-dom';
import { CreateOrUpdateLteBankAccountModel } from 'models/create/CreateOrUpdateLteBankAccountModel';
import { useAppSelector } from 'hooks/appSelector';
import { Form, Button } from 'react-bootstrap';
import { getLteBankAccount, updateLteBankAccount, getLtePayableBankAccount, getLteReceivableBankAccount } from 'actions/lte';
import useSlidingPanelActions from 'actions/slidingPanel';
import { validationSuite } from './validationSuite';
import Loader from 'components/Loader/index';
import useModalActions from 'actions/modal';
import { MdWarning } from 'react-icons/md';
import { ModalState } from 'state/modalSlice';

type Props = {
  bankAccountId: string,
  submitCallback?: Function
};

export default function EditLteBankAccountForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const user = useAppSelector((state) => state.user);
  const { id: lteId } = useParams();
  const modalActions = useModalActions();

  useEffect(() => {
    setIsLoading(true);
    getLteBankAccount(props.bankAccountId)
      .then((response) => {
        reset(response.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const {
    register,
    reset,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<CreateOrUpdateLteBankAccountModel>({
    resolver: vestResolver(validationSuite),
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateOrUpdateLteBankAccountModel) {
    setIsLoading(true);
    if(data.isPayableAccount == true || data.isReceivableAccount == true) {
      const promises = [
        getLtePayableBankAccount(lteId ?? user.lawPageTradingEntityId!),
        getLteReceivableBankAccount(lteId ?? user.lawPageTradingEntityId!)
      ];

      Promise.all(promises)
        .then(([payableBankAccountResponse, receivableBankAccountResponse]) => {

          let bodyMessage: React.ReactNode = <></>;
          if(data.isPayableAccount == true && payableBankAccountResponse.data?.id && payableBankAccountResponse.data?.id != props.bankAccountId && 
            data.isReceivableAccount == true && receivableBankAccountResponse.data?.id && receivableBankAccountResponse.data?.id != props.bankAccountId){
            bodyMessage = <div className="lp-modal-warning">
              <MdWarning />This Bank Account is set as Payable Account which means that he will replace the current one.<br/>
              <MdWarning />This Bank Account is set as Receivable Account which means that he will replace the current one.<br/>
              Are you sure you want to do that? If not, uncheck the Is Payable Account or the Is Receivable Account checkboxes.
            </div>;
          }
          else if(data.isPayableAccount == true && payableBankAccountResponse.data?.id && payableBankAccountResponse.data?.id != props.bankAccountId){
            bodyMessage = <div className="lp-modal-warning">
              <MdWarning />This Bank Account is set as Payable Account which means that he will replace the current one.<br/>
              Are you sure you want to do that? If not, uncheck the Is Payable Account checkbox.
            </div>;
          }
          else if(data.isReceivableAccount == true && receivableBankAccountResponse.data?.id && receivableBankAccountResponse.data?.id != props.bankAccountId){
            bodyMessage = <div className="lp-modal-warning">
              <MdWarning />This Bank Account is set as Receivable Account which means that he will replace the current one.<br/>
              Are you sure you want to do that? If not, uncheck the Is Receivable Account checkbox.
            </div>;
          }
          else {
            updateBankAccount(data, false);
            return;
          }

          let modal: ModalState = {
            title: "Update confirmation",
            body: bodyMessage,
            actionText: "Update",
            onAction: () => updateBankAccount(data),
            show: false,
            onClose: () => setIsLoading(false)
          }
          modalActions.setModal(modal);
          modalActions.toggleModalShownStatus();
        })
        .catch((error) => {
          setGenericErrors(error.response?.data?.Message ?? error.message);
          setIsLoading(false);
        });
    }
    else {
      updateBankAccount(data, false);
    }
  }

  function updateBankAccount(data: CreateOrUpdateLteBankAccountModel, fromModal: boolean = true) {
    if(fromModal)
    {
      modalActions.toggleModalLoading();
    }

    data.lteId = lteId ?? user.lawPageTradingEntityId!;
    updateLteBankAccount(props.bankAccountId, data)
      .then((response) => {
        slidingPanelActions.clearSlidingPanel();
        reset();

        if(props.submitCallback) {
          props.submitCallback(response.data);
        }
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
        if(fromModal)
        {
          modalActions.toggleModalLoading();
          modalActions.toggleModalShownStatus();
        }
      });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();

    currentSlidingPanelState.onCancel && currentSlidingPanelState.onCancel();
  };

  const onChangeIsPayableAccount = (value: boolean) => {
    if(!value) {
      setValue("isClientAccount", false);
    }
  }

  const onChangeIsClientAccount = (value: boolean) => {
    if(!value) {
      setValue("isPayableAccount", false);
    }
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && <div className="lp-errors">{genericErrors}</div>}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Form.Group className="mb-4" controlId="bankName">
          <Form.Label className="required">Bank Name</Form.Label>
          <Form.Control
            type="text"
            className={`${errors?.bankName?.message ? 'invalid' : ''}`}
            {...register('bankName', {shouldUnregister: true})}
          />
          <Form.Text className="lp-error">
            {errors?.bankName?.message && errors.bankName.message}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="bankAccountName">
          <Form.Label className="required">Bank Account Name</Form.Label>
          <Form.Control
            type="text"
            className={`${errors?.bankAccountName?.message ? 'invalid' : ''}`}
            {...register('bankAccountName', {shouldUnregister: true})}
          />
          <Form.Text className="lp-error">
            {errors?.bankAccountName?.message && errors.bankAccountName.message}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="bankSortCode">
          <Form.Label className="required">Bank Sort Code</Form.Label>
          <Form.Control
            type="text"
            className={`${errors?.bankSortCode?.message ? 'invalid' : ''}`}
            placeholder="NN-NN-NN"
            {...register('bankSortCode', {shouldUnregister: true})}
          />
          <Form.Text className="lp-error">
            {errors?.bankSortCode?.message && errors.bankSortCode.message}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="bankAccountNumber">
          <Form.Label className="required">Bank Account Number</Form.Label>
          <Form.Control
            type="text"
            className={`${errors?.bankAccountNumber?.message ? 'invalid' : ''}`}
            {...register('bankAccountNumber', {shouldUnregister: true})}
          />
          <Form.Text className="lp-error">
            {errors?.bankAccountNumber?.message &&
              errors.bankAccountNumber.message}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="bankIban">
          <Form.Label>Bank IBAN</Form.Label>
          <Form.Control type="text" {...register('bankIban', {shouldUnregister: true})} />
        </Form.Group>

        <Form.Group className="mb-4" controlId="isReceivableAccount">
          <Form.Label>Is Receivable Account</Form.Label>
          <Controller
            control={control}
            name="isReceivableAccount"
            shouldUnregister={true}
            render={({ field: { onChange, value, name, ref } }) => (
              <Form.Check type="switch" id="isReceivableAccount">
                <Form.Check.Input
                  className="form-check-input"
                  ref={ref}
                  checked={value ?? false}
                  onChange={(ev: any) => {
                    onChange(ev.target.checked);
                  }}
                />
              </Form.Check>
            )}
          />
        </Form.Group>

        <Form.Group className="mb-4" controlId="isClientAccount">
          <Form.Label>Is Client Account</Form.Label>
          <Controller
            control={control}
            name="isClientAccount"
            shouldUnregister={true}
            render={({ field: { onChange, value, name, ref } }) => (
              <Form.Check type="switch" id="">
                <Form.Check.Input
                  className="form-check-input"
                  ref={ref}
                  checked={value ?? false}
                  onChange={(ev: any) => {
                    onChange(ev.target.checked);
                    onChangeIsClientAccount(ev.target.checked);
                  }}
                  disabled={watch("isPayableAccount")}
                />
              </Form.Check>
            )}
          />
        </Form.Group>

        <Form.Group className="mb-4" controlId="isPayableAccount">
          <Form.Label>Is Payable Account</Form.Label>
          <Controller
            control={control}
            name="isPayableAccount"
            shouldUnregister={true}
            render={({ field: { onChange, value, name, ref } }) => (
              <Form.Check type="switch" id="isPayableAccount">
                <Form.Check.Input
                  className="form-check-input"
                  ref={ref}
                  checked={value ?? false}
                  onChange={(ev: any) => {
                    onChange(ev.target.checked);
                    onChangeIsPayableAccount(ev.target.checked);
                  }}
                  disabled={watch("isClientAccount")}
                />
              </Form.Check>
            )}
          />
        </Form.Group>

        <Form.Group className="mb-4" controlId="description">
          <Form.Label className="required">Description</Form.Label>
          <Form.Control
            as="textarea" rows={5}
            className={`${errors?.description?.message ? 'invalid' : ''}`}
            {...register('description', {shouldUnregister: true})}
          />
          <Form.Text className="lp-error">
            {errors?.description?.message && errors.description.message}
          </Form.Text>
        </Form.Group>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <Button variant="success" type="submit">
              Update
            </Button>
            <Button variant="secondary-400" onClick={cancelForm}>
              Cancel
            </Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
