import { useEffect, useState } from "react";
import { LawPageTradingEntityModel } from "models/view/LawPageTradingEntityModel";
import Title from "components/Title";
import { TabPanel } from "react-tabs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getLteById } from "actions/lte";
import LteUserList from "containers/LawPageTradingEntity/ViewLawPageTradingEntity/LteUsers/LteUserList";
import ViewLawPageTradingEntityDetails from "./LawPageTradingEntityDetails/ViewLawPageTradingEntityDetails";
import { useAppSelector } from "hooks/appSelector";
import ViewLteSystemSettings from "./LteSystemSettings/ViewLteSystemSettings";
import CustomTabs from "components/CustomTabs";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import DelegateUsers from "./DelegateUsers/DelegateUsers";
import DelegatedUsers from "./DelegatedUsers/DelegatedUsers";

type State = {
  edit: boolean
}

export default function ViewLawPageTradingEntity() {
  const [lte, setLte] = useState<LawPageTradingEntityModel | undefined>(undefined);
  const location = useLocation();
  const state = location.state as State ?? undefined;
  const navigate = useNavigate();

  const user = useAppSelector((state) => state.user);
  const { id } = useParams();

  useEffect(() => {
    if(id && id != user.lawPageTradingEntityId &&
      !user.userPermissions?.some(a => a == UserPermissionsNames.ViewAllLTEs || a == UserPermissionsNames.ManageAllLTEs)) {
        navigate('/error404');
    }

    getLteById(id ?? user.lawPageTradingEntityId!).then((response) => {
      if(response.data.tradingName === "") {
        response.data.tradingName = response.data.legalName;
      }
      setLte(response.data);
    }).catch((error) => {
      if(error.response.status == 404 || error.response.status == 400) {
        navigate('/error404');
      }
    });
  }, [id]);

  return (
    <div className="lp-page-content">
      <Title type="page" title={[<strong key={0}>View </strong>, <span key={1}>{lte?.legalName}</span>]}></Title>
      <CustomTabs 
        tabList={[
          {
            name: 'Details',
            panel: <TabPanel>
              <ViewLawPageTradingEntityDetails
                lte={lte}
                setLte={setLte}
                edit={state?.edit}
              />
            </TabPanel>
          },
          ...(user.userPermissions?.some(a => a == UserPermissionsNames.ViewLTEUsers || a == UserPermissionsNames.ManageLTEUsers) ? [{
            name: 'Users',
            panel: <TabPanel>
              <LteUserList
                lte={lte ?? undefined}
              />
            </TabPanel>
          }] : []),
          ...(user.userPermissions?.some(a => a == UserPermissionsNames.ViewLTESystemSettings || a == UserPermissionsNames.ManageLTESystemSettings) ? [{
            name: 'System Settings',
            panel: <TabPanel>
              <ViewLteSystemSettings
                lteId={id ?? user.lawPageTradingEntityId!}
              />
            </TabPanel>
          }] : []),
          ...((user.userPermissions?.some(a => a == UserPermissionsNames.ViewLTEDelegateUsers || a == UserPermissionsNames.ManageLTEDelegateUsers) &&
            lte && lte.hasDelegateLTEs) ? [{
            name: 'Delegate Users',
            panel: <TabPanel>
              <DelegateUsers
                lteId={id ?? user.lawPageTradingEntityId!}
              />
            </TabPanel>
          }] : []),
          ...((user.userPermissions?.some(a => a == UserPermissionsNames.ViewLTEDelegateUsers || a == UserPermissionsNames.ManageLTEDelegateUsers) && 
            lte && lte.isDelegatedToAnotherLTE) ? [{
            name: 'Delegated Users',
            panel: <TabPanel>
              <DelegatedUsers
                lteId={id ?? user.lawPageTradingEntityId!}
              />
            </TabPanel>
          }] : [])
        ]}
      />
    </div>
  );
}
