import useGridActions from "actions/grid";
import Grid from "components/Grid/index";
import { forwardRef, Ref, useCallback, useImperativeHandle, useRef, useState } from "react";
import { MdAdd } from "react-icons/md";
import { getColumnDefs } from "./columnDefs";
import { Card, Button } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import Title from "components/Title/index";
import CreateEntityBankAccountForm from "./CreateEntityBankAccount/CreateEntityBankAccountForm";
import { getEntityBankAccounts } from "actions/entity";
import { useAppSelector } from "hooks/appSelector";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import { AgGridReact } from "ag-grid-react";

type Props = {
  entityId: string
}

const EntityBankAccountList = forwardRef(function EntityBankAccountList(props: Props, forwardedRef: Ref<AgGridReact | null>) {
  const gridActions = useGridActions();
  const slidingPanelActions = useSlidingPanelActions();
  const [genericErrors, setGenericErrors] = useState(null);
  const loggedInUser = useAppSelector((state) => state.user);

  const gridRef = useRef<AgGridReact>(null);
  useImperativeHandle(forwardedRef, () => gridRef.current!);

  const onGridReady = useCallback(() =>
  {
    const columnDefs = getColumnDefs();
    gridActions.setGridColumnDefs([...columnDefs]);
    getEntityBankAccounts(props.entityId).then((response) => 
    {
      gridActions.setGridRowData(response.data as any)
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
      gridActions.setGridRowData([]);
    });
  }, []);  

  const addBankAccount = () => {
    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Add Bank Account",
        children: <CreateEntityBankAccountForm entityId={props.entityId}/>
      });
  }

  return (
    <>
      <Title type="section" title={"Bank Accounts"}>
        {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageEntities) &&
          <Button onClick={addBankAccount} className="btn-icon" variant="success">
            <MdAdd />
          </Button>
        }
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Card className="with-grid">
        <Card.Body>
          <Grid 
            ref={gridRef}
            onGridReady={onGridReady}
          />
        </Card.Body>
      </Card>
    </>
  );
});

export default EntityBankAccountList;
