import { vestResolver } from "@hookform/resolvers/vest";
import { 
  getMatterActivityHistory,
  getMatterClientsSummary,
  getMatterIncidentalExpenseById,
  updateMatterIncidentalExpense
} from "actions/matter";
import useSlidingPanelActions from "actions/slidingPanel";
import { getUserSummaryForLte } from "actions/user";
import Loader from "components/Loader/index";
import CustomSelect from "components/Select/Select";
import { useAppSelector } from "hooks/appSelector";
import React, { useEffect, useState } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { DateFormat } from "utils/constants";
import { removeEmptyFields } from "utils/form";
import { validationSuite } from "./validationSuite";
import useGridActions from "actions/grid";
import store from "state/store";
import { GridIds } from "enums/GridIds";
import { MatterFileModel } from "models/view/MatterFileModel";
import MatterFileSelect from "components/Select/MatterFileSelect";
import { UpdateMatterIncidentalExpenseModel } from "models/update/UpdateMatterIncidentalExpenseModel";
import { getDateOnly } from "utils/date";
import currency from 'currency.js';

type Props = {
  matterId: string,
  matterIncidentalExpenseId: string,
  onSubmitCallback?: Function
}

export default function EditMatterDisbursementForm(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const user = useAppSelector((state) => state.user);
  const slidingPanelActions = useSlidingPanelActions();
  const gridActions = useGridActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);

  const { register, handleSubmit, control, setValue, watch, reset, formState: { errors } } = useForm<UpdateMatterIncidentalExpenseModel>({
    resolver: vestResolver(validationSuite)
  });

  useEffect(() => {
    setIsLoading(true);
    getMatterIncidentalExpenseById(props.matterId, props.matterIncidentalExpenseId).then((response) => {
      let initialState: UpdateMatterIncidentalExpenseModel = {
        ...response.data,
        date: getDateOnly(response.data.date),
        fileIds: response.data.files?.map((x: MatterFileModel) => x.id)
      };

      reset(initialState);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: UpdateMatterIncidentalExpenseModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    updateMatterIncidentalExpense(props.matterId, props.matterIncidentalExpenseId, data).then((response) => {
      const gridState = store.getState().grid;
      if (gridState.id == `${GridIds.ActivityHistory}/${props.matterId}`) {
        getMatterActivityHistory(props.matterId).then((response) => {
          gridActions.setGridRowData(response.data);
        });
      }
      slidingPanelActions.clearSlidingPanel();
      reset();

      props.onSubmitCallback && props.onSubmitCallback();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();

    currentSlidingPanelState.onCancel !== undefined && currentSlidingPanelState?.onCancel();
  }

  const computeNetValue = () => {
    const grossValue: number = watch('grossValue') ?? 0;
    const vatValue: number = watch('vatValue') ?? 0;

    setValue('netValue', currency(grossValue).subtract(vatValue).value);
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Form.Group className="mb-4" controlId="userId">
          <Form.Label className="required">Fee Earner</Form.Label>
          <Controller
            control={control}
            name={`userId`}
            shouldUnregister={true}
            render={({ field: { onChange, value, name, ref } }) => (
              <CustomSelect
                id="userId"
                inputRef={ref}
                className={`lp-select${errors?.userId?.message ? ' invalid' : ''}`}
                endpointCall={() => getUserSummaryForLte(user.lawPageTradingEntityId!)}
                value={value}
                onChange={val => onChange(val?.id ?? null)}
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.userId?.message && (errors.userId?.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="matterParticipatingEntityId">
          <Form.Label className="required">Client</Form.Label>
          <Controller
            control={control}
            name={`matterParticipatingEntityId`}
            shouldUnregister={true}
            render={({ field: { onChange, value, name, ref } }) => (
              <CustomSelect
                id="matterParticipatingEntityId"
                inputRef={ref}
                className={`lp-select${errors?.matterParticipatingEntityId?.message ? ' invalid' : ''}`}
                endpointCall={() => getMatterClientsSummary(props.matterId)}
                value={value}
                onChange={val => onChange(val?.id ?? null)}
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.matterParticipatingEntityId?.message && (errors.matterParticipatingEntityId?.message)}
          </Form.Text>
        </Form.Group>

        <Row>
          <Form.Group as={Col} xs={12} sm={6} className="mb-4" controlId="date">
            <Form.Label className="required">Date</Form.Label>
            <Controller
              control={control}
              name="date"
              shouldUnregister={true}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  className={`${errors?.date?.message ? 'invalid' : ''}`}
                  id="date"
                  dateFormat={DateFormat.Datepicker}
                  selected={value ? getDateOnly(value) : null}
                  onChange={(val) => onChange(val != null ? getDateOnly(val) : val)}
                  showMonthDropdown
                  showYearDropdown
                  autoComplete="off"
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.date?.message && (errors.date.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} xs={12} sm={6} className="mb-4" controlId="displayName">
            <Form.Label className="required">Display Name</Form.Label>
            <Form.Control
              type="text"
              className={`${errors?.displayName?.message ? 'invalid' : ''}`}
              {...register(`displayName`, {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.displayName?.message && (errors.displayName?.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Form.Group className="mb-4" controlId="description">
          <Form.Label className="required">Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            className={`${errors?.description?.message ? 'invalid' : ''}`}
            {...register(`description`, {shouldUnregister: true})}
          />
          <Form.Text className="lp-error">
            {errors?.description?.message && (errors.description?.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="paidByUserOwnFunds">
          <Form.Label>Paid By User's Own Funds</Form.Label>
          <Controller
            control={control}
            name="paidByUserOwnFunds"
            shouldUnregister={true}
            render={({field: { onChange, value, name, ref }}) => (
              <Form.Check 
                type="switch"
                id="paidByUserOwnFunds">
                  <Form.Check.Input
                    className= "form-check-input"
                    ref={ref}
                    checked={value ?? false}
                    onChange={(ev: any) => onChange(ev.target.checked)}
                  />
              </Form.Check>
            )}
          />
        </Form.Group>

        <Row>
          <Form.Group as={Col} xs={12} sm={4} className="mb-4" controlId="grossValue">
            <Form.Label className={"required"}>Gross Value</Form.Label>
            <Form.Control
              type="number"
              className={`${errors?.grossValue?.message ? 'invalid' : ''}`}
              {...register(`grossValue`, {shouldUnregister: true})}
              min="0.00"
              step="0.01"
              onBlur={computeNetValue}
              onWheel={e => e.currentTarget.blur()}
            />
            <Form.Text className="lp-error">
              {errors?.grossValue?.message && (errors.grossValue?.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} xs={12} sm={4} className="mb-4" controlId="vatValue">
            <Form.Label>VAT Value</Form.Label>
            <Form.Control
              type="number"
              className={`${errors?.vatValue?.message ? 'invalid' : ''}`}
              {...register(`vatValue`, {shouldUnregister: true})}
              min="0.00"
              step="0.01"
              onBlur={computeNetValue}
              onWheel={e => e.currentTarget.blur()}
            />
            <Form.Text className="lp-error">
              {errors?.vatValue?.message && (errors.vatValue?.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} xs={12} sm={4} className="mb-4" controlId="netValue">
            <Form.Label>Net Value</Form.Label>
            <Form.Control
              type="number"
              disabled
              className={`${errors?.netValue?.message ? 'invalid' : ''}`}
              {...register(`netValue`, {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.netValue?.message && (errors.netValue?.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Form.Group className="mb-4" controlId="fileIds">
          <Form.Label>Proofs of Purchase</Form.Label>
          <Controller
            control={control}
            name={`fileIds`}
            shouldUnregister={true}
            render={({ field: { onChange, value, name, ref } }) => (
              <MatterFileSelect
                id="fileIds"
                matterId={props.matterId}
                inputRef={ref}
                className={`lp-select${errors?.fileIds?.message ? ' invalid' : ''}`}
                value={value}
                onChange={val => onChange(val ?? null)}
                isMulti
                isClearable
                menuPlacement="top"
                canUploadNewFiles
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.fileIds?.message && (errors.fileIds?.message)}
          </Form.Text>
        </Form.Group>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <Button variant="success" type="submit">Update</Button>
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
