import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Form, Button, InputGroup } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import Loader from "components/Loader/index";
import { getValidationSuite } from "./validationSuite";
import { CreateOrUpdateTaxonomyTermModel } from "models/create/CreateOrUpdateTaxonomyTermModel";
import CustomSelect from "components/Select/Select";
import { DropDownOptionModel } from "models/view/DropDownOptionModel";
import { addLteTaxonomyTerm, getLteTaxonomyById, getLteTaxonomyTermsSummary, getTaxonomyNextSequence } from "actions/taxonomy";
import { TaxonomyModel } from "models/view/TaxonomyModel";
import CustomTreeSelect from "components/TreeSelect/CustomTreeSelect";

type Props = {
  taxonomyId: string,
  onSubmitCallback?: Function
}

export default function CreateTaxonomyTermForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const slidingPanelActions = useSlidingPanelActions();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [taxonomy, setTaxonomy] = useState<TaxonomyModel>();
  const [matterTypes, setMatterTypes] = useState<DropDownOptionModel[]>([]);
  const [isLoadingMatterTypes, setIsLoadingMatterTypes] = useState<boolean>(false);
  const [parentSequence, setParentSequence] = useState<string | undefined>(undefined);
  const [nextSequence, setNextSequence] = useState<number | undefined>(undefined);

  useEffect(() => {
    setIsLoadingMatterTypes(true);
    getLteTaxonomyById(props.taxonomyId).then((response) => {
      setTaxonomy(response.data);
      setMatterTypes(response.data.matterTypes);

      getNextSequence(response.data);
    }).catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoadingMatterTypes(false);
    });
  }, []);

  async function submitData(data: CreateOrUpdateTaxonomyTermModel) {
    setIsLoading(true);
    addLteTaxonomyTerm(props.taxonomyId, data).then((response) => {
      props.onSubmitCallback && props.onSubmitCallback();

      slidingPanelActions.clearSlidingPanel();
      reset();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const {register, reset, control, setValue, handleSubmit, formState: {errors}} = useForm<CreateOrUpdateTaxonomyTermModel>({
    resolver: vestResolver(getValidationSuite(props.taxonomyId, taxonomy?.isHierarchical))
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  const getNextSequence = (taxonomy: TaxonomyModel, parentTaxonomyTermId?: string) => {
    if(taxonomy.isHierarchical) {
      getTaxonomyNextSequence(props.taxonomyId, parentTaxonomyTermId).then((response) => {
        setParentSequence(response.data.parentSequence);
        setNextSequence(response.data.nextSequence);
        setValue('sequence', response.data.nextSequence);
      }).catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      });
    }
  }
      
  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Form.Group className="mb-4" controlId="displayName">
          <Form.Label className="required">Display Name</Form.Label>
          <Form.Control
            type="text"
            className={`${errors?.displayName?.message ? 'invalid' : ''}`}
            {...register("displayName", {shouldUnregister: true})}
          />
          <Form.Text className="lp-error">
            {errors?.displayName?.message && (errors.displayName.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="description">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            className={`${errors?.description?.message ? 'invalid' : ''}`}
            {...register("description", {shouldUnregister: true})}
          />
          <Form.Text className="lp-error">
            {errors?.description?.message && (errors.description.message)}
          </Form.Text>
        </Form.Group>

        {taxonomy && taxonomy?.isHierarchical &&
          <Form.Group className="mb-4" controlId="parentTaxonomyTermId">
            <Form.Label>Parent Term</Form.Label>
            <Controller
              control={control}
              name="parentTaxonomyTermId"
              shouldUnregister={true}
              render={({field: { onChange, value }}) => (
                <CustomTreeSelect
                  id="parentTaxonomyTermId"
                  className={`lp-select w-100${errors?.parentTaxonomyTermId?.message ? ' invalid' : ''}`}
                  endpointCall={() => getLteTaxonomyTermsSummary(props.taxonomyId, true)}
                  value={value}
                  onChange={(val: string) => { onChange(val ?? null); getNextSequence(taxonomy, val);}}
                  dropdownMatchSelectWidth={true}
                  treeDefaultExpandAll
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.parentTaxonomyTermId?.message && (errors.parentTaxonomyTermId.message)}
            </Form.Text>
          </Form.Group>
        }

        <Form.Group className="mb-4" controlId="matterTypeIds">
          <Form.Label className="required">Matter Types</Form.Label>
          <Controller
            control={control}
            name="matterTypeIds"
            shouldUnregister={true}
            render={({field: { onChange, value, name, ref }}) => (
              <CustomSelect
                id="matterTypeIds"
                inputRef={ref}
                className={`lp-select${errors?.matterTypeIds?.message ? ' invalid' : ''}`}
                options={matterTypes}
                isLoading={isLoadingMatterTypes}
                value={value}
                onChange={val => onChange(val?.map((x: { id: any; }) => x.id) ?? null)}
                isMulti
                isClearable
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.matterTypeIds?.message && (errors.matterTypeIds.message)}
          </Form.Text>
        </Form.Group>

        {taxonomy?.isHierarchical &&
          <Form.Group className="mb-4" controlId="note">
            <Form.Label className="required">Sequence</Form.Label>
            <InputGroup className="mb-3">
              {parentSequence &&
                <InputGroup.Text id="basic-addon1">{parentSequence}.</InputGroup.Text>
              }
              <Form.Control
                type="number"
                className={`${errors?.sequence?.message ? 'invalid' : ''}`}
                {...register('sequence', { shouldUnregister: true })}
                min="1"
                max={nextSequence}
                step="1"
                onWheel={e => e.currentTarget.blur()}
              />
            </InputGroup>
            <Form.Text className="lp-error">
              {errors?.sequence?.message && errors.sequence.message}
            </Form.Text>
          </Form.Group>
        }

        <Form.Group className="mb-4" controlId="active">
          <Form.Label>Active</Form.Label>
          <Controller
            control={control}
            name="active"
            shouldUnregister={true}
            render={({field: { onChange, value, name, ref }}) => (
              <Form.Check 
                type="switch"
                id="active">
                  <Form.Check.Input
                    className= "form-check-input"
                    ref={ref}
                    checked={value ?? false}
                    onChange={(ev: any) => onChange(ev.target.checked)}
                  />
              </Form.Check>
            )}
          />
        </Form.Group>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <Button variant="success" type="submit">Create</Button>
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
