import Title from "components/Title/index";
import { LteUserModel } from "models/view/LteUserModel";
import { Dispatch, SetStateAction, useState } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { MdEdit } from "react-icons/md";
import { useLocation } from "react-router-dom";
import EditUserProfile from "./UserPersonalInfo/EditUserPersonalInfo/EditUserPersonalInfo";
import ViewUserPersonalInfo from "./UserPersonalInfo/ViewUserPersonalInfo/ViewUserPersonalInfo";
import '../style.scss';
import SlidingPanel from "components/SlidingPanel/index";
import EntityEmailList from "components/EntityEmails/EntityEmailList";
import EntityDocumentExchangeList from "components/EntityDocumentExchanges/EntityDocumentExchangeList";
import { SimpleUserModel } from "models/view/SimpleUserModel";
import { getUserName, getUserNameInitials } from "utils/user";
import EntityPhoneNumberList from "components/EntityPhoneNumbers/EntityPhoneNumberList";
import EntityAddressList from "components/EntityAddresses/EntityAddressList";

type Props = {
  user: LteUserModel | undefined,
  setUserData: Dispatch<SetStateAction<LteUserModel | undefined>>
}

type State = {
  edit: boolean
}

export default function UserProfile(props: Props) {
  const location = useLocation();

  const state = location.state as State ?? undefined;
  const [edit, setEdit] = useState<boolean>(state?.edit ?? false);

  const getUserInitials = () => {
    const simpleUserModel: SimpleUserModel | undefined = props.user ? {
      email: props.user?.email ?? '',
      displayName: props.user?.displayName,
      firstName: props.user?.firstName,
      lastName: props.user?.lastName
    } : undefined;
    if(simpleUserModel) {
      return getUserNameInitials(simpleUserModel);
    }
    else {
      return '';
    }
  }

  const getName = () => {
    const simpleUserModel: SimpleUserModel | undefined = props.user ? {
      email: props.user?.email ?? '',
      displayName: props.user?.displayName,
      firstName: props.user?.firstName,
      lastName: props.user?.lastName
    } : undefined;

    if(simpleUserModel) {
      return getUserName(simpleUserModel);
    }
    else {
      return '';
    }
  }

  return (
    <>
      <Row>
        <Col lg={4} xxl={3}>
          <Card>
            <Card.Body>
              <div className="lp-user-profile">
                <div className="lp-user-photo">
                  {getUserInitials()}
                  <span className={`lp-user-status${props.user?.active ? " active" : " inactive"}`} />
                </div>
                <div className="lp-user-name">
                  {getName()}
                </div>
                <div className="lp-user-email">
                  {props.user?.email ?? ""}
                </div>
                <div className="lp-user-role">
                  {props.user?.userRole.name ?? ""}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={8} xxl={9} className="mt-4 mt-lg-0">
          <Title type="section" title={"Personal Info"}>
            {edit !== true &&
              <Button onClick={() => setEdit(true)} className="btn-icon" variant="primary">
                <MdEdit />
              </Button>
            }
          </Title>
          {edit === true ?
            <EditUserProfile
              user={props.user}
              setEdit={setEdit}
              setUserData={props.setUserData}
            /> :
            <ViewUserPersonalInfo user={props.user} />
          }
        </Col>
      </Row>
      <Row>
        <Col xxl={6}>
          {props.user?.entityId && 
            <EntityPhoneNumberList 
              entityId={props.user?.entityId}
              edit={edit}
              isUserEntity
            />
          }
        </Col>
        <Col xxl={6} className="mt-4 mt-xxl-0">
          {props.user?.entityId && 
            <EntityEmailList 
              entityId={props.user?.entityId}
              edit={edit}
              isUserEntity
            />
          }
        </Col>
      </Row>
      <Row>
        <Col xxl={6}>
          {props.user?.entityId && 
            <EntityAddressList 
              entityId={props.user?.entityId}
              edit={edit}
              isUserEntity
            />
          }
        </Col>
        <Col xxl={6} className="mt-4 mt-xxl-0">
          {props.user?.entityId && 
            <EntityDocumentExchangeList
              entityId={props.user?.entityId}
              edit={edit}
              isUserEntity
            />
          }
        </Col>
      </Row>
      <SlidingPanel />
    </>
  );
}
