import { ColDef, ColGroupDef } from "ag-grid-community";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { BooleanFilter } from "components/Grid/GridFilters/Filters/BooleanFilter";
import { BooleanFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/BooleanFloatingFilter";
import CheckboxCellRenderer from "components/Grid/CheckboxCellRenderer";
import store from "state/store";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import { dateComparator } from "components/Grid/Comparators/DateComparator";
import { deleteMatterIncidentalExpense } from "actions/matter";
import { ModalState, setModalData, setModalErrors, toggleModal, toggleModalLoadingState } from "state/modalSlice";
import { setRowData } from "state/gridSlice";
import { DateFormatter } from "components/Grid/ValueFormatters/DateFormatter";
import { CurrencyFormatter } from "components/Grid/ValueFormatters/CurrencyFormatter";
import { MatterIncidentalExpenseModel } from "models/view/MatterIncidentalExpenseModel";
import ViewMatterIncidentalExpense from "../ActivityHistory/MatterIncidentalExpense/ViewMatterIncidentalExpense/ViewMatterIncidentalExpense";
import LinkMatterIncidentalExpenseForm from "../ActivityHistory/MatterIncidentalExpense/LinkMatterIncidentalExpense/LinkMatterIncidentalExpenseForm";
import MatterIncidentalExpenseWriteOffForm from "../ActivityHistory/MatterIncidentalExpense/MatterIncidentalExpenseWriteOff/MatterIncidentalExpenseWriteOffForm";
import MatterIncidentalExpenseNameCellRenderer from "components/Grid/MatterIncidentalExpenseNameCellRenderer";
import { CheckboxFormatter } from "components/Grid/ValueFormatters/CheckboxFormatter";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

export const viewCallback = (rowData: MatterIncidentalExpenseModel, reloadGridData?: Function) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        allowNavigation: true,
        width: "55rem",
        title: "View Incidental Expense",
        children: <ViewMatterIncidentalExpense
          matterId={rowData.matterId}
          matterIncidentalExpenseId={rowData.id!}
          onEditCallback={reloadGridData}
          showMoreButtons
        />
      }
    )
  );
}

const deleteModal = (rowData: MatterIncidentalExpenseModel) => {
  let modal: ModalState = {
    title: "Delete confirmation",
    body: "Are you sure you want to delete this Matter Incidental Expense?",
    actionText: "Delete",
    actionVariant: "danger",
    onAction: () => deleteCallback(rowData.matterId, rowData.id!),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
};

const deleteCallback = (matterId: string, incidentalExpenseId: string) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  deleteMatterIncidentalExpense(matterId, incidentalExpenseId).then(() => {
    store.dispatch(setRowData(gridState.rowData.filter((x: MatterIncidentalExpenseModel) => x.id !== incidentalExpenseId)));
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

const linkMatterIncidentalExpenseCallback = (matterId: string, matterIncidentalExpenseId: string, reloadGridData?: Function) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        width: "55rem",
        title: "Link Incidental Expense",
        children: <LinkMatterIncidentalExpenseForm
          matterId={matterId}
          matterIncidentalExpenseId={matterIncidentalExpenseId}
          onSubmitCallback={() => reloadGridData ? reloadGridData() : {}}
        />
      }
    )
  );
}

const writeOffCallback = (matterId: string, rowData: MatterIncidentalExpenseModel, reloadGridData?: Function) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        width: "55rem",
        title: "Incidental Expense Write-Off",
        children: <MatterIncidentalExpenseWriteOffForm
          matterId={matterId}
          matterIncidentalExpenseId={rowData.id!}
          onSubmitCallback={() => reloadGridData ? reloadGridData() : {}}
          onDeleteCallback={() => reloadGridData ? reloadGridData() : {}}
        />
      }
    )
  );
}

export const getColumnDefs = (reloadGridData?: Function) => {
  const loggedInUser = store.getState().user;

  const columnDefs: (ColDef<MatterIncidentalExpenseModel> | ColGroupDef<MatterIncidentalExpenseModel> | {excludeFromExport: boolean})[] = [
    { 
      headerName: 'Display Name',
      field: 'displayName',
      cellRenderer: MatterIncidentalExpenseNameCellRenderer,
      filter: 'agTextColumnFilter',
      minWidth: 150,
      width: 150,
    },
    { 
      headerName: 'Date',
      field: 'date',
      filter: 'agDateColumnFilter',
      valueFormatter: DateFormatter,
      filterParams: {
        comparator: dateComparator
      },
      minWidth: 150
    },
    {
      headerName: 'Fee Earner',
      field: 'user.name',
      filter: 'agTextColumnFilter',
      minWidth: 150,
      width: 150
    },
    { 
      headerName: 'Recordable Event',
      field: 'matterRecordableEvent.name',
      filter: 'agTextColumnFilter',
      minWidth: 150,
      width: 150
    },
    { 
      headerName: 'Description',
      field: 'description',
      filter: 'agTextColumnFilter',
      minWidth: 150,
      width: 150
    },
    {
      headerName: 'Gross Value',
      field: 'grossValue',
      filter: 'agNumberColumnFilter',
      valueFormatter: CurrencyFormatter,
      cellClass: 'text-end',
    },
    {
      headerName: 'VAT Value',
      field: 'vatValue',
      filter: 'agNumberColumnFilter',
      valueFormatter: CurrencyFormatter,
      cellClass: 'text-end',
    },
    {
      headerName: 'Net Value',
      field: 'netValue',
      filter: 'agNumberColumnFilter',
      valueFormatter: CurrencyFormatter,
      cellClass: 'text-end',
    },
    {
      headerName: 'Is Billed',
      field: 'isBilled',
      minWidth: 100,
      width: 100,
      filter: BooleanFilter,
      filterParams: {
        property: 'isBilled',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: BooleanFloatingFilter,
      floatingFilterComponentParams: {
        values: [{
          id: 'true',
          name: 'Yes'
        },
        {
          id: 'false',
          name: 'No'
        },
      ]},
      cellRenderer: CheckboxCellRenderer,
      valueFormatter: CheckboxFormatter
    },
    { 
      headerName: 'Invoice Ref Number',
      field: 'matterInvoice.invoiceRefNumber',
      filter: 'agTextColumnFilter',
      minWidth: 150,
      width: 150
    },
    {
      headerName: 'Invoiced Gross Value',
      field: 'grossValueOnInvoice',
      filter: 'agNumberColumnFilter',
      valueFormatter: CurrencyFormatter,
      cellClass: 'text-end',
    },
    {
      headerName: 'Invoiced VAT Value',
      field: 'vatValueOnInvoice',
      filter: 'agNumberColumnFilter',
      valueFormatter: CurrencyFormatter,
      cellClass: 'text-end',
    },
    {
      headerName: 'Paid By User Own Funds',
      field: 'paidByUserOwnFunds',
      filter: BooleanFilter,
      filterParams: {
        property: 'paidByUserOwnFunds',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: BooleanFloatingFilter,
      floatingFilterComponentParams: {
        values: [{
          id: 'true',
          name: 'Yes'
        },
        {
          id: 'false',
          name: 'No'
        },
      ]},
      minWidth: 100,
      width: 100,
      cellRenderer: CheckboxCellRenderer,
      valueFormatter: CheckboxFormatter
    },
    { 
      headerName: 'Client',
      field: 'matterParticipatingEntity.name',
      filter: 'agTextColumnFilter',
      minWidth: 150,
      width: 150
    },
    { 
      excludeFromExport: true,
      cellClass: 'lp-actions-cell',
      suppressHeaderMenuButton: true,
      suppressColumnsToolPanel: true,
      cellRenderer: ActionsCellRenderer,
      cellRendererParams: (params: any) => {
        if(params.data.matter.isClosed) {
          return { buttonsData: [
            {
              type: ActionButtonTypes.View,
              callback: () => viewCallback(params.data)
            }]
          };
        }
        else {
          return { buttonsData: [
            {
              type: ActionButtonTypes.View,
              callback: () => viewCallback(params.data, reloadGridData)
            },
            ...(!loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterIncidentalExpenses) || params.data.matterRecordableEventId ? [] : [{
              type: ActionButtonTypes.LinkToRecordableEvent,
              callback: () => linkMatterIncidentalExpenseCallback(params.data.matterId, params.data.id, reloadGridData)
            }]),
            ...(!params.data.isBilled ? [{
              type: ActionButtonTypes.WriteOff,
              callback: () => writeOffCallback(params.data.matterId, params.data, reloadGridData)
            }] : []),
            ...(!loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterIncidentalExpenses) || params.data.isBilled ? [] : [{
              type: ActionButtonTypes.Delete,
              callback: () => deleteModal(params.data)
            }]),
          ]};
        }
      },
      suppressSizeToFit: true,
      floatingFilter: false,
      sortable: false,
      minWidth: 105,
      maxWidth: 105,
      width: 105,
      suppressMovable: true,
      pinned: 'right',
      resizable: false
    }
  ];

  return columnDefs;
}