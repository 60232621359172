import { useEffect, useState } from "react";
import { MenuPlacement } from "react-select";
import CreatableSelect from 'react-select/creatable';

type Props = {
  id: string,
  onChange?: (value: any) => any,
  onBlur?: (event: any) => any,
  onInputChange?: (keyword: any) => void,
  className?: string,
  classNamePrefix?: string,
  value?: string | string[],
  options?: any[],
  endpointCall?: () => Promise<any>,
  isMulti?: boolean,
  isClearable?: boolean,
  isDisabled?: boolean,
  closeMenuOnSelect?: boolean,
  menuPlacement?: MenuPlacement,
  placeholder?: string,
  isLoading?: boolean,
  styles?: any,
  menuPortalTarget?: any
}

export default function CustomCreatableSelect(props: Props) {
  const [options, setOptions] = useState<any[]>(props.options ?? []);
  const [value, setValue] = useState<any | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean | undefined>(props.isLoading ?? true);

  const {
    id,
    endpointCall,
    className,
    classNamePrefix,
    isDisabled,
    isMulti,
    isClearable,
    menuPlacement,
    closeMenuOnSelect,
    onChange,
    onBlur,
    onInputChange,
    styles,
    menuPortalTarget
  } = props;

  useEffect(() => {
    if(endpointCall) {
      try {
        endpointCall().then(response => {
          setOptions(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
      } catch (err) {
        setIsLoading(false);
      }
    }
    else {
      if(!props.isLoading)
      {
        setIsLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    setOptions(props.options ?? []);
  }, [props.options]);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if(props.isLoading !== undefined) {
      setIsLoading(props.isLoading);
    }
  }, [props.isLoading]);

  const getValueOption = (option: string | string[] | undefined) => {
    if(option == undefined || option == null) {
      return null;
    }

    if(Array.isArray(option)) {
      return option.map(v => {
        return options?.find(x => v === x.value) ?? {label: v, value: v};
      });
    }
    else {
      return options?.find(x => option === x.value) ?? {label: props.value, value: props.value};
    }
  }

  return (
    <CreatableSelect
      id={id}
      value={getValueOption(value)}
      options={options}
      onChange={onChange}
      onBlur={onBlur}
      onInputChange={onInputChange}
      className={className ?? 'lp-select'}
      classNamePrefix={classNamePrefix ?? 'lp-select'}
      isDisabled={isDisabled || isLoading}
      isMulti={isMulti ?? false}
      isClearable={isClearable}
      closeMenuOnSelect={closeMenuOnSelect}
      isLoading={isLoading}
      menuPlacement={menuPlacement}
      menuPortalTarget={menuPortalTarget}
      styles={styles}
      placeholder={props.placeholder ?? ""}
    />
  );
}
