import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { vestResolver } from '@hookform/resolvers/vest';
import { Form, Button, Row, Col } from 'react-bootstrap';
import useSlidingPanelActions from 'actions/slidingPanel';
import Loader from 'components/Loader';
import { getValidationSuite } from './validationSuite';
import Upload from 'components/Upload';
import { downloadTemplate, getTemplate, getAllTemplateTypesSummary, updateTemplate, getTemplateTypeById, getEntityRolesSummary } from 'actions/settings';
import { CreateOrUpdateTemplateFormModel } from 'models/create/CreateOrUpdateTemplateFormModel';
import CustomSelect from 'components/Select/Select';
import { useAppSelector } from 'hooks/appSelector';
import useNotificationActions from 'actions/notification';
import { NotificationTypes } from 'enums/NotificationTypes';
import { downloadFile } from 'utils/misc';
import "./style.scss";
import { HeaderAndFooterTemplateTypeIds } from 'enums/HeaderAndFooterTemplateTypeIds';
import { DropDownOptionModel } from 'models/view/DropDownOptionModel';

type Props = {
  templateId: string,
  submitCallback?: Function,
  isMatterTemplate?: boolean
}

export default function EditTemplate(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();
  const user = useAppSelector((state) => state.user);
  const notificationActions = useNotificationActions();
  const [hasFileOnBlobStorage, setHasFileOnBlobStorage] = useState<boolean>(false);
  const [matterTypes, setMatterTypes] = useState<DropDownOptionModel[]>([]);
  const [isLoadingMatterTypes, setIsLoadingMatterTypes] = useState<boolean>(false);

  const {
    reset,
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<CreateOrUpdateTemplateFormModel>({
    resolver: vestResolver(getValidationSuite(props.isMatterTemplate ?? false))
  });

  useEffect(() => {
    setIsLoading(true);
    getTemplate(props.templateId).then((response) => {
      loadMatterTypes(response.data.typeId);

      const initialState = {
        name: response.data.name,
        description: response.data.description,
        typeId: response.data.typeId,
        lteId: response.data.lteId,
        useHeaderAndFooter: response.data.useHeaderAndFooter,
        useParticipatingEntity: response.data.useParticipatingEntity,
        participatingEntityIsInvoicingParty: response.data.participatingEntityIsInvoicingParty,
        participatingEntityEntityRoleId: response.data.participatingEntityEntityRoleId,
        removeEmptyTables: response.data.removeEmptyTables,
        matterTypeIds: response.data.matterTypes?.map((x: any) => x.id)
      };
      setHasFileOnBlobStorage(response.data.hasFileOnBlobStorage);
      reset(initialState);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  const downloadTemplateCallback = () => {
    downloadTemplate(props.templateId).then((response) => {
      downloadFile(response);
    })
    .catch((error) => {
      error.response.data.text().then((text: any) => {
        const responseData = JSON.parse(text);
        notificationActions.addNotificationMessage(
          {
            type: NotificationTypes.Error,
            title: "Download Template Error",
            body: `${responseData.Message ?? "There was an error downloading the template."}`,
            isDismissable: true
          }
        );
      });
    });
  }

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateOrUpdateTemplateFormModel) {
    const formData = new FormData();

    if(data.file) {
      formData.append('file', data.file[0]);
    }
    formData.append('name', data.name);
    formData.append('description', data.description);
    formData.append('useHeaderAndFooter', data.useHeaderAndFooter ? "true" : "false");
    formData.append('useParticipatingEntity', data.useParticipatingEntity ? "true" : "false");
    formData.append('participatingEntityIsInvoicingParty', data.participatingEntityIsInvoicingParty ? "true" : "false");
    if(data.participatingEntityEntityRoleId) {
      formData.append('participatingEntityEntityRoleId', data.participatingEntityEntityRoleId);
    }
    formData.append('removeEmptyTables', data.removeEmptyTables ? "true" : "false");
    formData.append('typeId', data.typeId);
    formData.append('lteId', user.lawPageTradingEntityId!);

    if(data.matterTypeIds) {
      for (let matterTypeId of data.matterTypeIds) {
        formData.append('matterTypeIds', matterTypeId);
      }
    }

    setIsLoading(true);

    updateTemplate(props.templateId, formData)
      .then(() => {
        slidingPanelActions.clearSlidingPanel();
        reset();
        props.submitCallback && props.submitCallback();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  };

  const onChangeTypeId = (value?: string) => {
    if(value){
      loadMatterTypes(value!);
    }
    else {
      setMatterTypes([]);
    }
  }

  const loadMatterTypes = (templateTypeId: string) => {
    setIsLoadingMatterTypes(true);
    getTemplateTypeById(templateTypeId).then((response) => {
      setMatterTypes(response.data.matterTypes);
    }).catch((error) => {
      setGenericErrors(error.response?.data?.message ?? error.message);
      setMatterTypes([]);
    })
    .finally(() => {
      setIsLoadingMatterTypes(false);
    });
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && <div className="lp-errors">{genericErrors}</div>}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Form.Group className="mb-4" controlId="name">
          <Form.Label className="required">Name</Form.Label>
          <Form.Control
            type="text"
            className={`${
              errors?.name?.message
                ? 'invalid'
                : ''
            }`}
            {...register('name', {shouldUnregister: true})}
          />
          <Form.Text className="lp-error">
            {errors?.name?.message &&
              errors.name.message}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="description">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            {...register('description', {shouldUnregister: true})}
          />
        </Form.Group>

        <Form.Group className="mb-4" controlId="typeId">
          <Form.Label className="required">Type</Form.Label>
          <Controller
            control={control}
            name="typeId"
            shouldUnregister={true}
            render={({field: { onChange, value, name, ref }}) => (
              <CustomSelect
                isDisabled={true}
                id="typeId"
                inputRef={ref}
                className={`lp-select${errors?.typeId?.message ? ' invalid' : ''}`}
                endpointCall={getAllTemplateTypesSummary}
                value={value}
                onChange={val => { onChange(val?.id ?? null); onChangeTypeId(val?.id); }}
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.typeId?.message && (errors.typeId.message)}
          </Form.Text>
        </Form.Group>
        
        {watch('typeId') != HeaderAndFooterTemplateTypeIds.HeaderAndFooterTemplateId && 
          <Row className="mb-4">
            <Form.Group as={Col} controlId="useHeaderAndFooter">
              <Form.Label>Use Header & Footer Template</Form.Label>
              <Controller
                control={control}
                name="useHeaderAndFooter"
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <Form.Check type="switch" id="useHeaderAndFooter">
                    <Form.Check.Input
                      className="form-check-input"
                      ref={ref}
                      checked={value ?? false}
                      onChange={(ev: any) => {
                        onChange(ev.target.checked);
                      }}
                    />
                  </Form.Check>
                )}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="removeEmptyTables">
              <Form.Label>Remove Empty Tables</Form.Label>
              <Controller
                control={control}
                name="removeEmptyTables"
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <Form.Check type="switch" id="removeEmptyTables">
                    <Form.Check.Input
                      className="form-check-input"
                      ref={ref}
                      checked={value ?? false}
                      onChange={(ev: any) => {
                        onChange(ev.target.checked);
                      }}
                    />
                  </Form.Check>
                )}
              />
            </Form.Group>
          </Row>
        }
        
        {props.isMatterTemplate &&
          <>
            <Form.Group className="mb-4" controlId="matterTypeIds">
              <Form.Label className="required">Matter Types</Form.Label>
              <Controller
                control={control}
                name="matterTypeIds"
                shouldUnregister={true}
                render={({field: { onChange, value, name, ref }}) => (
                  <CustomSelect
                    id="matterTypeIds"
                    inputRef={ref}
                    className={`lp-select${errors?.matterTypeIds?.message ? ' invalid' : ''}`}
                    options={matterTypes}
                    isLoading={isLoadingMatterTypes}
                    value={value}
                    onChange={val => onChange(val?.map((x: { id: any; }) => x.id) ?? null)}
                    isMulti
                    isClearable
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.matterTypeIds?.message && (errors.matterTypeIds.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-4" controlId="useParticipatingEntity">
              <Form.Label>Use Participating Entity</Form.Label>
              <Controller
                control={control}
                name="useParticipatingEntity"
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <Form.Check type="switch" id="useParticipatingEntity">
                    <Form.Check.Input
                      className="form-check-input"
                      ref={ref}
                      checked={value ?? false}
                      onChange={(ev: any) => {
                        onChange(ev.target.checked);
                      }}
                    />
                  </Form.Check>
                )}
              />
            </Form.Group>

            {watch('useParticipatingEntity') && 
              <>
                <Row className="mb-4">
                  <Form.Group as={Col} xs={12}>
                    <div className="lp-text-title">Restrict Participating Entities to</div>
                  </Form.Group>
                  
                  <Form.Group as={Col} controlId="participatingEntityIsInvoicingParty">
                    <Form.Label>Is Invoicing Party Entity</Form.Label>
                    <Controller
                      control={control}
                      name="participatingEntityIsInvoicingParty"
                      shouldUnregister={true}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Form.Check type="switch" id="participatingEntityIsInvoicingParty">
                          <Form.Check.Input
                            className="form-check-input"
                            ref={ref}
                            checked={value ?? false}
                            onChange={(ev: any) => {
                              onChange(ev.target.checked);
                            }}
                          />
                        </Form.Check>
                      )}
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="participatingEntityEntityRoleId">
                    <Form.Label>Participating Entity Role</Form.Label>
                    <Controller
                      control={control}
                      name="participatingEntityEntityRoleId"
                      shouldUnregister={true}
                      render={({ field: { onChange, value } }) => (
                        <CustomSelect
                          id="participatingEntityEntityRoleId"
                          endpointCall={getEntityRolesSummary}
                          value={value}
                          onChange={(val) => onChange(val?.id ?? null)}
                          isClearable
                        />
                      )}
                    />
                  </Form.Group>
                </Row>
              </>
            }
          </>
        }

        {hasFileOnBlobStorage && 
          (
            <div className="lp-template-existing-file">
              This template already has a file. By adding another one, you will replace it. If you want to download the file, click <span onClick={downloadTemplateCallback}>here</span>.
            </div>
          )
        }
        <Form.Group className="mb-4" controlId="file">
          <Form.Label>File</Form.Label>
          <Controller
            control={control}
            name="file"
            shouldUnregister={true}
            render={({ field: { onChange } }) => (
              <Upload
                className={`${errors.file?.message ? 'invalid' : ''}`}
                onChange={(val) => onChange(val ?? null)}
                accept='.docx'
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors.file?.message && errors.file.message}
          </Form.Text>
        </Form.Group>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <Button variant="success" type="submit">
              Update
            </Button>
            <Button variant="secondary-400" onClick={cancelForm}>
              Cancel
            </Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
