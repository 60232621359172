import { DateTimeFormatter } from "components/Grid/ValueFormatters/DateTimeFormatter";
import { CustomTableColumnDefModel } from "models/view/CustomTableColumnDefModel";

export const columnDefsFileHistoryCustomTable = () => {
  const columnDefs: CustomTableColumnDefModel[] = [
    { headerName: "Description", field: "description", width: 140 },
    { headerName: "Date", field: "modifiedDate", width: 180, formatter: DateTimeFormatter },
    { headerName: "Author", field: "modifiedBy" },
  ];

  return columnDefs;
}
