import { useInsertionEffect, useState, useEffect, useRef } from 'react';
import { Navigate, useRoutes, useLocation, RouteObject } from 'react-router-dom';
import Login from 'containers/Login/index';
import AuthLayout from 'layouts/AuthLayout/index';
import MainLayout from 'layouts/MainLayout/index';
import Dashboard from 'containers/Dashboard/index';
import PageNotFound from 'containers/PageNotFound';
import { useUserActions } from 'actions/user';
import WelcomeUser from "containers/User/WelcomeUser/WelcomeUser";
import './App.scss';
import { useAppSelector } from 'hooks/appSelector';
import LawPageTradingEntityList from 'containers/LawPageTradingEntity/LawPageTradingEntityList';
import ViewLawPageTradingEntity from 'containers/LawPageTradingEntity/ViewLawPageTradingEntity/ViewLawPageTradingEntity';
import MatterList from 'containers/Matter/MatterList';
import ViewMatter from 'containers/Matter/ViewMatter/ViewMatter';
import EntityList from 'containers/Entity/EntityList';
import ViewEntity from 'containers/Entity/ViewEntity/ViewEntity';
import AccountSettings from 'containers/User/UserAccountSettings/UserAccountSettings';
import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute';
import { UserState } from 'state/userSlice';
import { UserPermissionsNames } from 'enums/UserPermissionsNames';
import { checkIfTokenNotExpired, getTokenTimeoutInterval } from 'utils/token';
import AccountsSettings from 'containers/Settings/AccountsSettings/AccountsSettings';
import ChargingSchemeSettings from 'containers/Settings/ChargingSchemeSettings/ChargingSchemeSettings';
import TemplateSettings from 'containers/Settings/TemplateSettings/TemplateSettings';
import ChargingScheme from 'containers/ChargingScheme/ChargingSchemeList';
import ViewChargingScheme from 'containers/ChargingScheme/ViewChargingScheme/ViewChargingScheme';
import ChartOfAccounts from 'containers/ChartOfAccounts/ChartOfAccounts';
import ChartOfAccountsLedgerEntries from 'containers/ChartOfAccounts/ChartOfAccountsLedgerEntries/ChartOfAccountsLedgerEntries';
import AllInvoicesList from 'containers/Invoice/AllInvoicesList';
import DraftInvoicesList from 'containers/Invoice/DraftInvoicesList';
import AwaitingPaymentInvoicesList from 'containers/Invoice/AwaitingPaymentInvoicesList';
import PaidInvoicesList from 'containers/Invoice/PaidInvoicesList';
import BillsToPayList from 'containers/BillsToPay/BillsToPayList';
import UserCalendar from 'containers/Calendar/UserCalendar';
import DiaryManagerCalendar from 'containers/Calendar/DiaryManagerCalendar/DiaryManagerCalendar';
import FreeBusyCalendar from 'containers/Calendar/FreeBusyCalendar/FreeBusyCalendar';
import EntitySettings from 'containers/Settings/EntitySettings/EntitySettings';
import TaxonomySettings from 'containers/Settings/TaxonomySettings/TaxonomySettings';
import ViewMatterTaxonomies from 'containers/Matter/ViewMatterTaxonomies/ViewMatterTaxonomies';
import TimeAndIncidentalExpenses from 'containers/TimeAndIncidentalExpenses/TimeAndIncidentalExpenses';

const defaultAuthorization = (isLoggedIn: boolean, hasInfo: boolean, returnIfAuthorized: JSX.Element) => {
  return (
    <ProtectedRoute
      isAllowed={isLoggedIn}
      redirectPath={"/login"}
    >
      <ProtectedRoute
        isAllowed={hasInfo}
        redirectPath={"/welcome"}
      >
        {returnIfAuthorized}
      </ProtectedRoute>
    </ProtectedRoute>
  );
}

const getRoutes = (isLoggedIn: boolean, pathname: string, user: UserState) => {
  const hasInfo: boolean = user.hasInfo ?? false;
  return ([
    {
      path: '*',
      element: <PageNotFound />
    },
    {
      path: '/',
      element: defaultAuthorization(isLoggedIn, hasInfo, <MainLayout />),
      children: [
        { path: '/', element: <Dashboard /> }
      ]
    },
    {
      path: '/login',
      element: <ProtectedRoute
        isAllowed={!isLoggedIn}
        redirectPath={"/"}
      >
        <AuthLayout />
      </ProtectedRoute>,
      children: [
        { path: '/login', element: <Login /> },
        { path: '*', element: <Navigate to='/login' /> }
      ]
    },
    {
      path: '/matter',
      element: defaultAuthorization(isLoggedIn, hasInfo, <MainLayout />),
      children: [
        {
          path: '/matter/:id',
          element:
            <ProtectedRoute
              isAllowed={user.userPermissions?.some(x =>
                x === UserPermissionsNames.ViewMatters || x === UserPermissionsNames.ManageMatters) ?? false
              }
              redirectPath="/error404"
            >
              <ViewMatter />
            </ProtectedRoute>
        },
        {
          path: '/matter/:id/taxonomy',
          element:
            <ProtectedRoute
              isAllowed={user.userPermissions?.some(x =>
                x === UserPermissionsNames.ManageMatters) ?? false
              }
              redirectPath="/error404"
            >
              <ViewMatterTaxonomies />
            </ProtectedRoute>
        },
        {
          path: '/matter',
          element:
            <ProtectedRoute
              isAllowed={user.userPermissions?.some(x =>
                x === UserPermissionsNames.ViewMatters || x === UserPermissionsNames.ManageMatters) ?? false
              }
              redirectPath="/error404"
            >
              <MatterList />
            </ProtectedRoute>
        },
        {
          path: '*',
          element: <Navigate to='/matter' />
        }
      ]
    },
    {
      path: '/addressBook',
      element: defaultAuthorization(isLoggedIn, hasInfo, <MainLayout />),
      children: [
        {
          path: '/addressBook/:id',
          element:
            <ProtectedRoute
              isAllowed={user.userPermissions?.some(x =>
                x === UserPermissionsNames.ViewEntities || x === UserPermissionsNames.ManageEntities) ?? false
              }
              redirectPath="/error404"
            >
              <ViewEntity />
            </ProtectedRoute>
        },
        {
          path: '/addressBook',
          element:
            <ProtectedRoute
              isAllowed={user.userPermissions?.some(x =>
                x === UserPermissionsNames.ViewEntities || x === UserPermissionsNames.ManageEntities) ?? false
              }
              redirectPath="/error404"
            >
              <EntityList />
            </ProtectedRoute>
        },
        {
          path: '*',
          element: <Navigate to='/addressBook' />
        }
      ]
    },
    {
      path: '/accountSettings',
      element: defaultAuthorization(isLoggedIn, hasInfo, <MainLayout />),
      children: [
        {
          path: '/accountSettings',
          element: <AccountSettings />
        },
        {
          path: '*',
          element: <Navigate to='/accountSettings' />
        },
      ],
    },
    {
      path: '/chartOfAccounts',
      element: defaultAuthorization(isLoggedIn, hasInfo, <MainLayout />),
      children: [
        {
          path: '/chartOfAccounts/:id',
          element:
            <ProtectedRoute
              isAllowed={user.userPermissions?.some(x =>
                x === UserPermissionsNames.ViewChartOfAccounts || x === UserPermissionsNames.ManageChartOfAccounts) ?? false
              }
              redirectPath="/error404"
            >
              <ChartOfAccountsLedgerEntries />
            </ProtectedRoute>
        },
        {
          path: '/chartOfAccounts',
          element:
            <ProtectedRoute
              isAllowed={user.userPermissions?.some(x =>
                x === UserPermissionsNames.ViewChartOfAccounts || x === UserPermissionsNames.ManageChartOfAccounts) ?? false
              }
              redirectPath="/error404"
            >
              <ChartOfAccounts />
            </ProtectedRoute>
        },
        {
          path: '*',
          element: <Navigate to='/chartOfAccounts' />
        },
      ],
    },
    {
      path: '/user',
      element: defaultAuthorization(isLoggedIn, hasInfo, <MainLayout />),
      children: [
        {
          path: '/user/:id',
          element:
            <ProtectedRoute
              isAllowed={user.userPermissions?.some(x =>
                x === UserPermissionsNames.ViewLTEUsers || x === UserPermissionsNames.ManageLTEUsers) ?? false
              }
              redirectPath="/error404"
            >
              <AccountSettings />
            </ProtectedRoute>
        },
        {
          path: '*',
          element: <Navigate to='/user' /> },
      ],
    }, 
    {
      path: '/welcome',
      element:
        <ProtectedRoute
          isAllowed={isLoggedIn}
          redirectPath={"/login"}
        >
          <ProtectedRoute
            isAllowed={!hasInfo}
            redirectPath={"/"}
          >
            <AuthLayout />
          </ProtectedRoute>
        </ProtectedRoute>,
      children: [
        {
          path: '/welcome',
          element: <WelcomeUser />
        },
        {
          path: '*',
          element: <Navigate to='/welcome' />
        },
      ],
    }, 
    {
      path: '/lteProfile',
      element: defaultAuthorization(isLoggedIn, hasInfo, <MainLayout />),
      children: [
        {
          path: '/lteProfile',
          element:
            <ProtectedRoute
              isAllowed={user.userPermissions?.some(x =>
                x === UserPermissionsNames.ViewLTE || x === UserPermissionsNames.ManageLTE) ?? false
              }
              redirectPath="/error404"
            >
              <ViewLawPageTradingEntity />
            </ProtectedRoute>
        },
        {
          path: '*',
          element: <Navigate to='/lteProfile' />
        },
      ],
    },
    {
      path: '/lte',
      element: defaultAuthorization(isLoggedIn, hasInfo, <MainLayout />),
      children: [
        {
          path: '/lte/:id',
          element:
            <ProtectedRoute
              isAllowed={user.userPermissions?.some(x =>
                x === UserPermissionsNames.ViewAllLTEs || x === UserPermissionsNames.ManageAllLTEs) ?? false
              }
              redirectPath="/error404"
            >
              <ViewLawPageTradingEntity />
            </ProtectedRoute>
        },
        {
          path: '/lte',
          element:
            <ProtectedRoute
              isAllowed={user.userPermissions?.some(x =>
                x === UserPermissionsNames.ViewAllLTEs || x === UserPermissionsNames.ManageAllLTEs) ?? false
              }
              redirectPath="/error404"
            >
              <LawPageTradingEntityList />
            </ProtectedRoute>
        },
        {
          path: '*',
          element: <Navigate to='/lte' />
        }
      ]
    },
    {
      path: '/chargingScheme',
      element: defaultAuthorization(isLoggedIn, hasInfo, <MainLayout />),
      children: [
        {
          path: '/chargingScheme/:id',
          element:
            <ProtectedRoute
              isAllowed={user.userPermissions?.some(x =>
                x === UserPermissionsNames.ViewChargingSchemes || x === UserPermissionsNames.ManageChargingSchemes) ?? false
              }
              redirectPath="/error404"
            >
              <ViewChargingScheme />
            </ProtectedRoute>
        },
        {
          path: '/chargingScheme',
          element:
            <ProtectedRoute
              isAllowed={user.userPermissions?.some(x =>
                x === UserPermissionsNames.ViewChargingSchemes || x === UserPermissionsNames.ManageChargingSchemes) ?? false
              }
              redirectPath="/error404"
            >
              <ChargingScheme />
            </ProtectedRoute>
        },
        {
          path: '*',
          element: <Navigate to='/chargingScheme' />
        }
      ]
    },
    {
      path: '/settings',
      element: defaultAuthorization(isLoggedIn, hasInfo, <MainLayout />),
      children: [
        {
          path: '/settings',
          element: <Navigate to='/settings/accounts' />
        },
        {
          path: '/settings/accounts',
          element:
            <ProtectedRoute
              isAllowed={user.userPermissions?.some(x =>
                x === UserPermissionsNames.ViewDefaultSettings || x === UserPermissionsNames.ManageDefaultSettings ||
                x === UserPermissionsNames.ViewLTESettings || x === UserPermissionsNames.ManageLTESettings) ?? false
              }
              redirectPath="/error404"
            >
              <AccountsSettings />
            </ProtectedRoute>
        },
        {
          path: '/settings/address-book',
          element:
            <ProtectedRoute
              isAllowed={user.userPermissions?.some(x =>
                x === UserPermissionsNames.ViewDefaultSettings || x === UserPermissionsNames.ManageDefaultSettings ||
                x === UserPermissionsNames.ViewLTESettings || x === UserPermissionsNames.ManageLTESettings) ?? false
              }
              redirectPath="/error404"
            >
              <EntitySettings />
            </ProtectedRoute>
        },
        {
          path: '/settings/charging-scheme',
          element:
            <ProtectedRoute
              isAllowed={user.userPermissions?.some(x =>
                x === UserPermissionsNames.ViewDefaultSettings || x === UserPermissionsNames.ManageDefaultSettings ||
                x === UserPermissionsNames.ViewLTESettings || x === UserPermissionsNames.ManageLTESettings) ?? false
              }
              redirectPath="/error404"
            >
              <ChargingSchemeSettings />
            </ProtectedRoute>
        },
        {
          path: '/settings/taxonomy',
          element:
            <ProtectedRoute
              isAllowed={user.userPermissions?.some(x =>
                x === UserPermissionsNames.ViewLTESettings || x === UserPermissionsNames.ManageLTESettings) ?? false
              }
              redirectPath="/error404"
            >
              <TaxonomySettings />
            </ProtectedRoute>
        },
        {
          path: '/settings/template',
          element:
            <ProtectedRoute
              isAllowed={user.userPermissions?.some(x =>
                x === UserPermissionsNames.ViewLTESettings || x === UserPermissionsNames.ManageLTESettings) ?? false
              }
              redirectPath="/error404"
            >
              <TemplateSettings />
            </ProtectedRoute>
        },
        {
          path: '*',
          element: <Navigate to='/settings/accounts' />
        },
      ],
    },
    {
      path: '/invoice',
      element: defaultAuthorization(isLoggedIn, hasInfo, <MainLayout />),
      children: [
        {
          path: '/invoice',
          element: <Navigate to='/invoice/all' />
        },
        {
          path: '/invoice/all',
          element:
            <ProtectedRoute
              isAllowed={user.userPermissions?.some(x =>
                x === UserPermissionsNames.ViewInvoicePages) ?? false
              }
              redirectPath="/error404"
            >
              <AllInvoicesList />
            </ProtectedRoute>
        },
        {
          path: '/invoice/draft',
          element:
            <ProtectedRoute
              isAllowed={user.userPermissions?.some(x =>
                x === UserPermissionsNames.ViewInvoicePages) ?? false
              }
              redirectPath="/error404"
            >
              <DraftInvoicesList />
            </ProtectedRoute>
        },
        {
          path: '/invoice/awaitingPayment',
          element:
            <ProtectedRoute
              isAllowed={user.userPermissions?.some(x =>
                x === UserPermissionsNames.ViewInvoicePages) ?? false
              }
              redirectPath="/error404"
            >
              <AwaitingPaymentInvoicesList />
            </ProtectedRoute>
        },
        {
          path: '/invoice/paid',
          element:
            <ProtectedRoute
              isAllowed={user.userPermissions?.some(x =>
                x === UserPermissionsNames.ViewInvoicePages) ?? false
              }
              redirectPath="/error404"
            >
              <PaidInvoicesList />
            </ProtectedRoute>
        },
        {
          path: '*',
          element: <Navigate to='/invoice/all' />
        },
      ],
    },
    {
      path: '/billsToPay',
      element: defaultAuthorization(isLoggedIn, hasInfo, <MainLayout />),
      children: [
        {
          path: '/billsToPay',
          element:
            <ProtectedRoute
              isAllowed={user.userPermissions?.some(x =>
                x === UserPermissionsNames.ViewBillsToPay || x === UserPermissionsNames.ManageBillsToPay) ?? false
              }
              redirectPath="/error404"
            >
              <BillsToPayList />
            </ProtectedRoute>
        },
        {
          path: '*',
          element: <Navigate to='/billsToPay' />
        },
      ],
    },
    {
      path: '/timeAndIncidentalExpenses',
      element: defaultAuthorization(isLoggedIn, hasInfo, <MainLayout />),
      children: [
        { 
          path: '/timeAndIncidentalExpenses',
          element:
            <ProtectedRoute
              isAllowed={user.userPermissions?.some(x =>
                x === UserPermissionsNames.ViewTimeAndIncidentalExpenses) ?? false
              }
              redirectPath="/error404"
            >
              <TimeAndIncidentalExpenses />
            </ProtectedRoute>
        },
        {
          path: '*',
          element: <Navigate to='/timeAndIncidentalExpenses' />
        },
      ],
    },
    {
      path: '/calendar',
      element: defaultAuthorization(isLoggedIn, hasInfo, <MainLayout />),
      children: [
        {
          path: '/calendar',
          element:
            <ProtectedRoute
              isAllowed={user.userPermissions?.some(x =>
                x === UserPermissionsNames.ViewCalendars || x === UserPermissionsNames.ManageCalendars) ?? false
              }
              redirectPath="/error404"
            >
              <UserCalendar />
            </ProtectedRoute>
        },
        {
          path: '*',
          element: <Navigate to='/calendar' />
        },
      ]
    },
    {
      path: '/diaryManagerCalendar',
      element: defaultAuthorization(isLoggedIn, hasInfo, <MainLayout />),
      children: [
        {
          path: '/diaryManagerCalendar',
          element:
            <ProtectedRoute
              isAllowed={user.isDiaryManager ?? false
              }
              redirectPath="/error404"
            >
              <DiaryManagerCalendar />
            </ProtectedRoute>
        },
        {
          path: '*',
          element: <Navigate to='/calendar' />
        },
      ]
    },
    {
      path: '/freeBusyCalendar',
      element: defaultAuthorization(isLoggedIn, hasInfo, <MainLayout />),
      children: [
        {
          path: '/freeBusyCalendar',
          element:
            <ProtectedRoute
              isAllowed={user.userPermissions?.some(x =>
                x === UserPermissionsNames.ViewCalendars || x === UserPermissionsNames.ManageCalendars) ?? false
              }
              redirectPath="/error404"
            >
              <FreeBusyCalendar />
            </ProtectedRoute>
        },
        {
          path: '*',
          element: <Navigate to='/calendar' />
        },
      ]
    },
    {
      path: 'error404',
      element: <PageNotFound />
    },
  ])
};

const documentHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty('--vh', `${window.innerHeight}px`);
}
window.addEventListener('resize', documentHeight);
documentHeight();

export default function App() {
  let location = useLocation();
  const [routing, setRouting] = useState<RouteObject[]>([]);
  const userActions = useUserActions();
  const user = useAppSelector((state) => state.user);
  const [windowOnFocus, setWindowOnFocus] = useState(true);
  const refreshTokenInProgress = useRef(false);
  
  const currentRoute = useLocation(); 

  const restoreUser = async () => {
    const isLoggedIn = await userActions.restoreUser();
    if (isLoggedIn !== undefined) {
      const routes = getRoutes(isLoggedIn, location.pathname, user);
      setRouting(routes);
    }
  };

  const checkTokens = async () => {
    if(user.userId ||
      (!checkIfTokenNotExpired(user.apiToken ?? localStorage.getItem('msalIdToken')) ||
        !checkIfTokenNotExpired(user.msalIdToken ?? localStorage.getItem('apiJwt')))) {
      await restoreUser();
    }
  }

  useEffect(() => {
    const apiTokenTimeoutInterval = getTokenTimeoutInterval(user.apiToken ?? localStorage.getItem('apiJwt'));
    const msalTokenTimeoutInterval = getTokenTimeoutInterval(user.msalIdToken ?? localStorage.getItem('msalIdToken'));

    const myInterval = setInterval(async () => {
      if (!user.userId || refreshTokenInProgress.current) {
        return;
      }

      if (!windowOnFocus) {
        return;
      }

      refreshTokenInProgress.current = true;
      checkTokens().finally(() => {
        refreshTokenInProgress.current = false;
      });
      
    }, apiTokenTimeoutInterval > msalTokenTimeoutInterval ? msalTokenTimeoutInterval : apiTokenTimeoutInterval);

    return () => clearInterval(myInterval);
  }, [windowOnFocus, user.apiToken]);

  useEffect(() => {
    restoreUser();
  }, [user.apiToken, user.hasInfo]);

  const checkTokensOnFocus = () => {
    setWindowOnFocus(true);
  };

  const onBlur = () => {
    setWindowOnFocus(false);
  };

  useEffect(() => {
    window.addEventListener('focus', checkTokensOnFocus);
    return () => {
      window.removeEventListener('focus', checkTokensOnFocus);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('blur', onBlur);
    return () => {
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  // This is redundant for page changes because the grid is reseting itself when unmounted
  // And in case of a matter change (only path slug changes) this will cause unpleasant re-rendering
  // useEffect(() => {
  //   gridActions.resetGridModel();
  // }, [currentRoute]);

  useInsertionEffect(() => {
    userActions.getUserTheme();
  }, []);

  return <>{useRoutes(routing)}</>;
}

