import React from "react";
import { CustomTableColumnDefModel } from "models/view/CustomTableColumnDefModel";
import { CustomTableFooterRowModel } from "models/view/CustomTableFooterRowModel";
import './style.scss';

type Props = {
  columnDefs: CustomTableColumnDefModel[],
  rowData: any[],
  footerRow?: CustomTableFooterRowModel[];
}

export default function CustomTable(props: Props) {
  return (
    <table className="lp-custom-table">
      <thead>
        <tr>
          {props.columnDefs.map((columnDef, index) => (
            <th style={{ width: columnDef.width }} key={index}>{columnDef.headerName}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {props.rowData.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {props.columnDefs.map((columnDef, colIndex) => {
              // Get field value - go through nested objects (example: obj.user.name)
              const fieldValue = columnDef.field.split(".").reduce((obj, i) => obj[i], row);

              if (!fieldValue) {
                return <td className={columnDef.customCSSClass ? columnDef.customCSSClass : ''} key={`${rowIndex}-${colIndex}`}>—</td>
              }

              return (
                <td className={columnDef.customCSSClass ? columnDef.customCSSClass : ''} key={`${rowIndex}-${colIndex}`}>
                  {
                    columnDef.formatter
                      ? columnDef.formatter({value: fieldValue})
                      : fieldValue
                  }
                </td>
              )
            })}
          </tr>
        ))}
      </tbody>
      {props.footerRow ? (
        <tfoot>
          <tr>
            {props.footerRow.map((footerRow, index) => (
              <td style={footerRow.style} key={index}>
                {
                  footerRow.formatter
                    ? footerRow.formatter({value: footerRow.value})
                    : footerRow.value
                }
              </td>
            ))}
          </tr>
        </tfoot>
      ) : null}
    </table>
  );
}
