import BooleanFieldValue from "./BooleanFieldValue";
import FieldWithImage from "./FieldWithImage";

interface Props {
  label: string,
  value: boolean
}

export default function BooleanField(props: Props) {
  return (
    <FieldWithImage
      label={props.label}
    >
      <BooleanFieldValue value={props.value} />
    </FieldWithImage>
  );
}
