import { ColDef } from "ag-grid-community";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { CurrencyFormatter } from "components/Grid/ValueFormatters/CurrencyFormatter";
import { dateComparator } from "components/Grid/Comparators/DateComparator";
import { DateFormatter } from "components/Grid/ValueFormatters/DateFormatter";
import store from "state/store";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import { MatterDisbursementModel } from "models/view/MatterDisbursementModel";
import ViewMatterDisbursement from "containers/Matter/ViewMatter/ActivityHistory/MatterDisbursement/ViewMatterDisbursement/ViewMatterDisbursement";
import MatterRefCellRenderer from "components/Grid/MatterRefCellRenderer";

export const viewCallback = (rowData: MatterDisbursementModel, reloadGridData?: Function) => {
  const gridState = store.getState().grid;
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        allowNavigation: true,
        width: "55rem",
        title: "View Matter Disbursement",
        children: <ViewMatterDisbursement
          matterId={rowData.matterId}
          matterDisbursementId={rowData.id}
          onDeleteCallback={() => reloadGridData ? reloadGridData(gridState.advancedFilter) : {}}
          onEditCallback={() => reloadGridData ? reloadGridData(gridState.advancedFilter) : {}}
          onRecordPaymentCallback={() => reloadGridData ? reloadGridData(gridState.advancedFilter) : {}}
          showMoreButtons
        />
      }
    )
  );
};

export const getColumnDefs = (reloadGridData?: Function) => {
  const columnDefs: (ColDef<MatterDisbursementModel> | {excludeFromExport: boolean})[] = [
    {
      headerName: 'Display Name',
      field: 'displayName',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Matter Ref',
      field: 'matter.fileNumberWithDisplayName',
      filter: 'agTextColumnFilter',
      cellRenderer: MatterRefCellRenderer,
    },
    {
      headerName: 'Client',
      field: 'matterParticipatingEntity.name',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Date',
      field: 'date',
      filter: 'agDateColumnFilter',
      valueFormatter: DateFormatter,
      filterParams: {
        comparator: dateComparator
      },
      minWidth: 120
    },
    {
      headerName: 'Due Date',
      field: 'dueDate',
      filter: 'agDateColumnFilter',
      valueFormatter: DateFormatter,
      filterParams: {
        comparator: dateComparator
      },
      minWidth: 120
    },
    {
      headerName: 'Amount Due',
      field: 'amountDue',
      filter: 'agNumberColumnFilter',
      valueFormatter: CurrencyFormatter,
      cellClass: 'text-end',
    },
    {
      headerName: 'Supplier',
      field: 'supplier.name',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Supplier Reference',
      field: 'supplierReference',
      filter: 'agTextColumnFilter',
    },
    {
      cellClass: 'lp-actions-cell',
      suppressHeaderMenuButton: true,
      suppressColumnsToolPanel: true,
      cellRenderer: ActionsCellRenderer,
      cellRendererParams: (params: any) => {
        return { buttonsData: [
          {
            type: ActionButtonTypes.View,
            callback: () => viewCallback(params.data, reloadGridData)
          }
        ]};
      },
      excludeFromExport: true,
      suppressSizeToFit: true,
      floatingFilter: false,
      sortable: false,
      minWidth: 65,
      maxWidth: 65,
      width: 65,
      suppressMovable: true,
      pinned: 'right',
      resizable: false
    }
  ];
  return columnDefs;
}