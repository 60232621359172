import { useEffect, useState } from "react";
import Field from "components/Fields/Field";
import Loader from "components/Loader";
import { Row, Col } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import { useAppSelector } from "hooks/appSelector";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { ModalState } from "state/modalSlice";
import useModalActions from "actions/modal";
import useGridActions from "actions/grid";
import { changeUserStatus, deleteLteUser, getLteUser } from "actions/user";
import { LteUserModel } from "models/view/LteUserModel";
import EditLteUserForm from "../EditLteUser/EditLteUserForm";
import { useNavigate } from "react-router-dom";
import BooleanField from "components/Fields/BooleanField";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

type Props = {
  id: string,
}

function ViewLteUser(props: Props){
  const [isLoading, setIsLoading] = useState(true);
  const [lteUser, setLteUser] = useState<LteUserModel>();
  const [genericErrors, setGenericErrors] = useState(null);
  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const modalActions = useModalActions();
  const gridActions = useGridActions();
  const currentGridState = useAppSelector((state) => state.grid);
  const navigate = useNavigate();
  const loggedInUser = useAppSelector((state) => state.user);

  useEffect(() => {
    getLteUserData();
    setSlidingPanelEditButton();
  }, [props.id]);

  useEffect(() => {
    lteUser && setSlidingPanelMoreButtons(lteUser.active);
  }, [lteUser]);

  const getLteUserData = () => {
    setIsLoading(true);
    getLteUser(props.id).then((response) => {
      setLteUser(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const setSlidingPanelEditButton = () => {
    // Set edit button action
    if(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageLTEUsers)) {
      slidingPanelActions.setOnEdit(() => {
        slidingPanelActions.clearSlidingPanel();

        slidingPanelActions.setSlidingPanel(
        {
          isShown: true,
          title: "Edit Lte User",
          children: <EditLteUserForm
            id={props.id}
            onSubmitCallback={onChildPanelClose}
          />,
          onCancel: onChildPanelClose
        });
      });
    }
  }

  const setSlidingPanelMoreButtons = (isActive: boolean) => {
    slidingPanelActions.setMoreButtons([
      {
        type: ActionButtonTypes.ViewProfile,
        callback: () => viewProfile(),
      },
      ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageLTEUsers) ? [{
          type: isActive ? ActionButtonTypes.Deactivate : ActionButtonTypes.Activate,
          callback: () => changeStatusModal()
      }] : []),
      ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageLTEUsers) ? [{
        type: ActionButtonTypes.Delete,
        callback: () => deleteModal(),
      }] : [])
    ]);
  }

  const onChildPanelClose = () => {
    slidingPanelActions.clearSlidingPanel();
    slidingPanelActions.setSlidingPanel({
      ...currentSlidingPanelState,
    });
  };

  const changeStatusModal = () => {
    let modal: ModalState = {
      title: "Confirmation",
      body: "Are you sure you want to change the status for this User?",
      onAction: () => changeStatus(props.id),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  };

  const changeStatus = (id: string) => {
    const gridState = currentGridState;
    modalActions.toggleModalLoading();
    changeUserStatus(id).then(() => {
      getLteUserData();

      let index: number = gridState.rowData.findIndex((x: any) => x.id == id);
      let newEntry: LteUserModel = { ...gridState.rowData[index], active: !gridState.rowData[index].active };
      let newArray: Array<any> = [...gridState.rowData];
      newArray[index] = newEntry;
      gridActions.setGridRowData(newArray);
      modalActions.toggleModalShownStatus();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
  };

  const deleteModal = () => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this LTE user?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallback(props.id),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  };

  const deleteCallback = (id: string) => {
    const gridState = currentGridState;
    modalActions.toggleModalLoading();
    deleteLteUser(id).then(() => {
      gridActions.setGridRowData(gridState.rowData.filter((x: any) => x.id !== id));
      modalActions.toggleModalShownStatus();
      slidingPanelActions.clearSlidingPanel();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
  };

  const viewProfile = () => {
    slidingPanelActions.clearSlidingPanel();
    navigate(`/user/${props.id}`); 
  };

  return(
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col>
          <Field
            label={"First Name"}
            value={lteUser?.firstName}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Last Name"}
            value={lteUser?.lastName}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Email Address"}
            value={lteUser?.email}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"User Role"}
            value={lteUser?.userRole.name}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Additional Permissions"}
            value={lteUser?.userPermissions?.length ? lteUser?.userPermissions?.map(e => e.name)?.join(", ") : "—"}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <BooleanField
            label={"Active"}
            value={lteUser?.active ?? false}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <BooleanField
            label={"Fee earner"}
            value={lteUser?.isFeeEarner ?? false}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"LTE Branch"}
            value={lteUser?.lteBranch?.name}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"LTE Department"}
            value={lteUser?.lteDepartment?.name}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"LTE Team"}
            value={lteUser?.lteTeam?.name}
          />
        </Col>
      </Row>
    </>
  );
}

export default ViewLteUser;
