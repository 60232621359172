export enum MatterTypeIds {
  ActionsAgainstPoliceAndOtherPublicAuthoritiesId = '2e3b599d-e92c-4d27-a364-04219894c5a2',
  ArbitrationLawId = 'b3079c1f-1723-4ff6-a72b-695449184b76',
  BankingAndFinanceLawId = '24f48566-3d01-443e-8281-076503b98626',
  BankruptcyLawId = '8e611fb8-ba94-4b7c-bb54-510033aa074f',
  CivilLitigationId = '445d0f27-91e4-46c6-be5b-d396f298b543',
  ConsumerLawId = 'd626bac6-c797-4c7d-9ac6-5550e169489f',
  ContractLawId = '3fbc7e5f-c0b7-443a-b4d2-ec8c5518b482',
  CorporateLawId = 'a8eecd04-028f-4d47-90c7-9ff17c4aaa77',
  CriminalLawActingForAccusedId = 'f5237b3f-8974-4da5-a502-3d0125eee964',
  CriminalLawActingForProsecutionId = 'ae3e3141-cc38-4c9a-9039-543729b0d0ac',
  EmploymentLawId = '20767afc-92ae-4d41-8ecd-21dffcdb0dcd',
  EnvironmentalLawId = '03101e49-c7bd-4e2f-bbec-cc70b79e5b72',
  EquityAndTrustLawId = 'f633288b-e247-4f9f-84af-3178c602ffc5',
  EstatePlanningId = 'ae5cea3f-4833-485f-8a51-5065b40ecbe0',
  FamilyLawAllAspectsId = '6fdb0b57-23e0-4bc8-a67d-1bb4408e38a4',
  FamilyLawChildArrangementsId = 'd4e50aac-df81-43b9-889e-de14de3c855b',
  FamilyLawDivorceId = 'a2310990-e691-4103-839e-f6b457cc58be',
  FamilyLawFinancialRemediesId = 'cecd4f51-739c-49ed-b6d1-2e3f16acad16',
  FinancialServicesLawId = '83a78a7b-0018-49b4-b6cb-87696e74ec67',
  ImmigrationLawId = 'ad4718a3-6f6c-4c32-8fb1-8d36a4dcda24',
  InformationDataLawId = '168b1938-b7d7-4e9d-a7bd-95ecab23eedd',
  InsuranceLawId = '87b118b0-3256-4d89-81e5-7298c978705d',
  IntellectualPropertyLawId = '20f841da-25cd-4625-98ed-1a128754a490',
  LeisureAndLicensingLawId = 'd5683c9b-4ed6-4456-bf09-ceb03b93b921',
  PersonalInjuryId = '44d6a91f-c676-452f-939b-ab544dbc122d',
  PlanningLawId = '37c8b758-9612-419a-a9d8-fd2dcf6558e8',
  PrisonLawId = 'fdfe667a-9ae0-4eb0-a651-0c33cdaeb159',
  ProbateLawId = '47a470eb-5829-490f-a033-07e93d629b23',
  ProfessionalDisciplineId = '3c245bbc-74fa-42df-b72a-5d6c0d2bc92e',
  PropertyLawId = 'd2d1fb84-2a7d-44ac-addf-bad28d4e76be',
  PublicLawId = 'd6830d77-29f3-4754-91c2-7ee5c425be10',
  RegulatoryEnforcementComplianceId = '5161bc7b-595c-46ef-ace6-6756773813a9',
  TaxLawId = '20ab44cb-2c43-4da3-a810-a13441ff4128',
  UnspecifiedId = '6ebeab81-7d1f-402a-841a-58b08f99c782',
  WelfareHousingAndSocialSecurityLawId = '3f79afa8-4dd3-4a34-90dd-51c814aa81fb'
}