import { ColDef } from "ag-grid-community";
import store from "state/store";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import EditDefaultAccountTypeCategoryForm from "./EditDefaultAccountTypeCategory/EditDefaultAccountTypeCategoryForm";
import { DefaultAccountTypeCategoryModel } from "models/view/DefaultAccountTypeCategoryModel";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

const editCallback = (id: string) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        width: "50rem",
        title: "Edit Default Account Type Category",
        children: <EditDefaultAccountTypeCategoryForm defaultAccountTypeCategoryId={id}/>
      }
    )
  );
}

export const getColumnDefs = () => {
  const loggedInUser = store.getState().user;
  
  const columnDefs: (ColDef<DefaultAccountTypeCategoryModel> | {excludeFromExport: boolean})[] = [
    {
      headerName: 'Display Name',
      field: 'displayName',
      filter: 'agTextColumnFilter',
      minWidth: 120
    },
    { 
      excludeFromExport: true,
      cellClass: 'lp-actions-cell',
      suppressHeaderMenuButton: true,
      suppressColumnsToolPanel: true,
      cellRenderer: ActionsCellRenderer,
      cellRendererParams: (params: any) => {
        return { 
          buttonsData: [{
            type: ActionButtonTypes.Edit,
            callback: editCallback
          }]
        };
      },
      hide: !loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageDefaultSettings),
      suppressSizeToFit: true,
      floatingFilter: false,
      sortable: false,
      minWidth: 65,
      maxWidth: 65,
      width: 65,
      suppressMovable: true,
      pinned: 'right',
      resizable: false
    }
  ];
  return columnDefs;
}
