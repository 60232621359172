import Title from "components/Title/index";
import { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import useSlidingPanelActions from "actions/slidingPanel";
import useModalActions from "actions/modal";
import { ModalState } from "state/modalSlice";
import { Button } from "react-bootstrap";
import ListContact from "components/List/ListContact";
import { ContactModel } from "models/view/ContactModel";
import { checkIfPhoneNumberIsLinked } from "actions/phoneNumber";
import { LinkedPhoneNumberModel } from "models/view/LinkedPhoneNumberModel";
import { deleteEntityPhoneNumber, getEntityPhoneNumbers } from "actions/entity";
import { EntityPhoneNumberModel } from "models/view/EntityPhoneNumberModel";
import EditEntityPhoneNumberForm from "./EditEntityPhoneNumber/EditEntityPhoneNumberForm";
import CreateEntityPhoneNumberForm from "./CreateEntityPhoneNumber/CreateEntityPhoneNumberForm";
import EditEntityPhoneNumberOnlyIsPreferredForm from "./EditEntityPhoneNumberOnlyIsPreferred/EditEntityPhoneNumberOnlyIsPreferredForm";
import { useAppSelector } from "hooks/appSelector";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

type Props = {
  entityId?: string,
  edit: boolean,
  isUserEntity?: boolean
}

export default function EntityPhoneNumberList(props: Props) {
  const [entityPhoneNumbers, setEntityPhoneNumbers] = useState<ContactModel[]>();
  const [loaderPhoneNumber, setLoaderPhoneNumber] = useState<boolean>(false);

  const slidingPanelActions = useSlidingPanelActions();
  const modalActions = useModalActions();
  const [genericErrors, setGenericErrors] = useState(null);
  const loggedInUser = useAppSelector((state) => state.user);

  const loadContacts = () => {
    if(props.entityId) {
      setLoaderPhoneNumber(true);
      getEntityPhoneNumbers(props.entityId)
        .then((response) => 
        {
          const entityContactPhoneNumbers = [] as ContactModel[];
          response.data.forEach((x: EntityPhoneNumberModel) => {
            entityContactPhoneNumbers.push({
              id: x.phoneNumberId,
              contact: x.phoneNumber?.number,
              type: x.phoneNumber?.phoneType?.name,
              isPreferred: x.isPreferred,
              systemOwned: x.phoneNumber?.systemOwned,
              additionalInfo: x.phoneNumber?.extension,
              isLinked: x.sourcePhoneNumberId != undefined && x.sourceEntityId != undefined
            })
          });
          
          setEntityPhoneNumbers(entityContactPhoneNumbers);
          setLoaderPhoneNumber(false);
        })
        .catch((error) => {
          setGenericErrors(error.response?.data?.Message ?? error.message);
          setLoaderPhoneNumber(false);
        });
    }
  }

  useEffect(() =>
  {
    loadContacts();
  }, [props.entityId]);

  const openCreateEntityPhoneNumberSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Create Phone Number',
      children: <CreateEntityPhoneNumberForm
                  entityId={props.entityId ?? ''}
                  submitCallback={reloadAndResetContacts} />
    });
  };

  const openEditEntityPhoneNumberSlidingPanel = (id: string) => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Edit Phone Number',
      children: <EditEntityPhoneNumberForm 
                  entityId={props.entityId ?? ''}
                  phoneNumberId={id}
                  submitCallback={reloadAndResetContacts}
                />
    });
  };

  const openEditEntityPhoneNumberOnlyIsPreferredSlidingPanel = (id: string) => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Edit Phone Number',
      children: <EditEntityPhoneNumberOnlyIsPreferredForm 
                  entityId={props.entityId ?? ''}
                  phoneNumberId={id}
                  submitCallback={reloadAndResetContacts}
                />
    });
  };

  const reloadAndResetContacts = () => {
    loadContacts();
  }

  const deletePhoneNumberModal = (id: string) => {
    checkIfPhoneNumberIsLinked(id, undefined, props?.entityId ?? '')
      .then((response) => 
      {
        const filteredResponse = response.data.filter((p: any) => p.phoneNumberId !== id || p.entityId !== props?.entityId);
        if(filteredResponse.length > 0){
          const linkedEntites = filteredResponse.map((x: LinkedPhoneNumberModel) =>
            x.lawPageTradingEntity ? x.lawPageTradingEntity.legalName + ' (trading entity)': (x.entity ? x.entity.displayName + ' (entity)' : undefined) );
          let modal: ModalState = {
            title: "Delete confirmation",
            actionText: "For all entities",
            secondActionText: "Just for this entity",
            body: 
              <>
                <p>This phone number is also linked to following entities:</p>
                <ul>
                  {linkedEntites.filter((x: any) => x !== undefined).map((entityName: any, index: number) => {
                    return <li key={index}>{entityName}</li>;
                  })}
                </ul>
                <div>Do you want to delete the number assigned only to this entity or for all entities?</div>
              </>,
            onAction: () => {
              modalActions.toggleModalLoading();
              deleteEntityPhoneNumber(props?.entityId ?? '', id, true).then(() => {
                reloadAndResetContacts();
              })
              .catch((error) => {
                setGenericErrors(error.response?.data?.Message ?? error.message);
              })
              .finally(() => {
                modalActions.toggleModalLoading();
                modalActions.toggleModalShownStatus();
              });
            },
            onSecondAction: () => {
              modalActions.toggleModalLoading();
              deleteEntityPhoneNumber(props?.entityId ?? '', id, false).then(() => {
                reloadAndResetContacts();
              })
              .catch((error) => {
                setGenericErrors(error.response?.data?.Message ?? error.message);
              })
              .finally(() => {
                modalActions.toggleModalLoading();
                modalActions.toggleModalShownStatus();
              });
            },
            show: true
          }
          modalActions.setModal(modal);
        }
        else{
          let modal: ModalState = {
            title: "Delete confirmation",
            body: "Are you sure you want to delete this phone number?",
            actionText: "Delete",
            actionVariant: "danger",
            onAction: () => {
              modalActions.toggleModalLoading();
              deleteEntityPhoneNumber(props?.entityId ?? '', id, true).then(() => {
                reloadAndResetContacts();
              })
              .catch((error) => {
                setGenericErrors(error.response?.data?.Message ?? error.message);
              })
              .finally(() => {
                modalActions.toggleModalLoading();
                modalActions.toggleModalShownStatus();
              });
            },
            show: true
          }
          modalActions.setModal(modal);
        }
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      });
  };

  const unlinkCallbackEntityPhoneNumber = (id: string) => {
    modalActions.toggleModalLoading();
    deleteEntityPhoneNumber(props.entityId!, id, false).then(() => {
      reloadAndResetContacts();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
      modalActions.toggleModalShownStatus();
    });
  };

  const unlinkEntityPhoneNumberModal = (id: string) => {
    let modal: ModalState = {
      title: "Unlink confirmation",
      body: "Are you sure you want to unlink this phone number?",
      actionText: "Unlink",
      actionVariant: "danger",
      onAction: () => unlinkCallbackEntityPhoneNumber(id),
      show: true
    }
    modalActions.setModal(modal);
   };

  return (
    <>
      <Title type="section" title={"Phone Numbers"}>
        {(props.isUserEntity || (loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageEntities) && !props.isUserEntity)) &&
          <Button onClick={openCreateEntityPhoneNumberSlidingPanel} className="btn-icon" variant="success">
            <MdAdd />
          </Button>
        }
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <ListContact
        listItems={entityPhoneNumbers ?? []}
        listButtons={
          [
            {
              type: ActionButtonTypes.Call,
            },
            ...((props.isUserEntity || (loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageEntities) && !props.isUserEntity)) ? [{
              type: ActionButtonTypes.Edit,
              callback: (id: string) => openEditEntityPhoneNumberSlidingPanel(id)
            }] : []),
            ...((props.isUserEntity || (loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageEntities) && !props.isUserEntity)) ? [{
              type: ActionButtonTypes.EditOnlyIsPreferred,
              callback: (id: string) => openEditEntityPhoneNumberOnlyIsPreferredSlidingPanel(id)
            }] : []),
            ...((props.isUserEntity || (loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageEntities) && !props.isUserEntity)) ? [{
              type: ActionButtonTypes.Delete,
              callback: (id: string) => deletePhoneNumberModal(id)
            }] : []),
            ...((props.isUserEntity || (loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageEntities) && !props.isUserEntity)) ? [{
              type: ActionButtonTypes.Unlink,
              callback: (id: string) => unlinkEntityPhoneNumberModal(id)
            }] : [])
          ]
        }
        loadState={loaderPhoneNumber}
      >
        <>
          <div>{"Phone Number"}</div>
          <div>{"Type"}</div>
          <div></div>
        </>
      </ListContact>
    </>
  );
}
