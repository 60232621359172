import Loader from "components/Loader/index";
import { getLteUserWithEntity } from "actions/user";
import Title from "components/Title/index";
import { useAppSelector } from "hooks/appSelector";
import { LteUserModel } from "models/view/LteUserModel";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TabPanel } from "react-tabs";
import LteInfo from "./UserLteInfo/UserLteInfo";
import UserProfile from "./UserProfile/UserProfile";
import CustomTabs from "components/CustomTabs";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

export default function AccountSettings() {
  const [userData, setUserData] = useState<LteUserModel>();

  const navigate = useNavigate();

  const user = useAppSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { id } = useParams();

  useEffect(() => {
    if(id && id != user.userId &&
      !user.userPermissions?.some(a => a == UserPermissionsNames.ViewLTEUsers || a == UserPermissionsNames.ManageLTEUsers)) {
        navigate('/error404');
    }

    getLteUserWithEntity(id ?? user.userId!).then((response) => {
      if (response?.data.languagesSpoken) {
        response.data.languagesSpokenIds = response.data.languagesSpoken.map((a: any) => a.id);
      }

      if (response?.data.userPermissions) {
        response.data.userPermissionsIds = response.data.userPermissions.map((a: any) => a.id);
      }

      if (response?.data.managedDiaries) {
        response.data.managedDiariesIds = response.data.managedDiaries.map((a: any) => a.id);
      }

      setUserData(response.data);
    })
    .catch((error) => {
      if(error.response.status == 404 || error.response.status == 400) {
        navigate('/error404');
      }
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, [id]);

  return (
    <div className="lp-page-content">
      <Title type="page" title={id ? "User Details" : "Personal Details"}></Title>
      {isLoading && <Loader inlineLoader />}

      <CustomTabs 
        tabList={[
          {
            name: 'User Personal Profile',
            panel: <TabPanel>
              <UserProfile
                user={userData}
                setUserData={setUserData}
              />
            </TabPanel>
          },
          {
            name: 'User Business Profile',
            panel: <TabPanel>
              <LteInfo
                user={userData}
                setUserData={setUserData}
              />
            </TabPanel>
          }
        ]}
      />
    </div>
  );
}
