import { Form } from "react-bootstrap";
import Loader from "components/Loader";
import moment from "moment";
import { DateFormat } from "utils/constants";
import Field from "components/Fields/Field";
import { formatCurrency } from "utils/misc";
import { MatterDisbursementModel } from "models/view/MatterDisbursementModel";

type Props = {
  isLoading: boolean,
  items: MatterDisbursementModel[],
}

export default function ViewMatterInvoiceDisbursements(props: Props) {
  return (
    <Form.Group className="d-table">
      <div className="lp-box-list">
        {props.isLoading && <Loader inlineLoader />}

        {props.items.length > 0 &&
          props.items.map((x: MatterDisbursementModel, index: number) => (
            <div
              key={x.id}
              className="lp-box-list-item read-only"
            >
              <span className="type-icon date">
                {moment(x?.date).format(DateFormat.Moment) + (x.user ? ' - ' + x.user?.name : '')}
              </span>

              <div className="lp-box-list-full-row">
                <Field label={'Description'} value={x.displayName} />
              </div>

              <Field
                label={'Net Value'}
                value={formatCurrency(x.netValue)}
              />
              <Field
                label={'VAT Value'}
                value={formatCurrency(x.vatValue)}
              />
            </div>
          ))
        }
        {props.items.length === 0 && (
          <div className="lp-list-empty">No unbilled disbursements found</div>
        )}
      </div>
    </Form.Group>
  );
}
