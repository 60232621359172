import { ColDef } from "ag-grid-community";
import { EntityBankAccountModel } from "models/view/EntityBankAccountModel";
import store from "state/store";
import { ModalState, setModalData, setModalErrors, toggleModal, toggleModalLoadingState } from "state/modalSlice";
import { setRowData } from "state/gridSlice";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import CheckboxCellRenderer from "components/Grid/CheckboxCellRenderer";
import { BooleanFilter } from "components/Grid/GridFilters/Filters/BooleanFilter";
import { BooleanFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/BooleanFloatingFilter";
import ViewEntityBankAccount from "./ViewEntityBankAccount/ViewLteBankAccount";
import { deleteEntityBankAccount } from "actions/entity";
import { CheckboxFormatter } from "components/Grid/ValueFormatters/CheckboxFormatter";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

export const viewCallback = (rowData: EntityBankAccountModel) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        allowNavigation: true,
        title: "View Bank Account",
        children: <ViewEntityBankAccount entityId={rowData.entityId} bankAccountId={rowData.id}/>
      }
    )
  );
}

const deleteModal = (rowData: EntityBankAccountModel) => {
  let modal: ModalState = {
    title: "Delete confirmation",
    body: "Are you sure you want to delete this bank account?",
    actionText: "Delete",
    actionVariant: "danger",
    onAction: () => deleteCallback(rowData),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
};

const deleteCallback = (rowData: EntityBankAccountModel) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  deleteEntityBankAccount(rowData.entityId, rowData.id).then(() => {
    store.dispatch(setRowData(gridState.rowData.filter((x: any) => x.id !== rowData.id)));
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

export const getColumnDefs = () => {
  const loggedInUser = store.getState().user;
  const buttonsColumnWidth = loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageEntities) ? 105 : 65;
  
  const columnDefs: (ColDef<EntityBankAccountModel> | {excludeFromExport: boolean})[] = [
    {
      headerName: 'Bank Account Name',
      field: 'bankAccountName',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Bank Name',
      field: 'bankName',
      filter: 'agTextColumnFilter'
    },
    {
      headerName: 'Bank Sort Code',
      field: 'bankSortCode',
      filter: 'agTextColumnFilter'
    },
    {
      headerName: 'Bank Account Number',
      field: 'bankAccountNumber',
      filter: 'agTextColumnFilter'
    },
    {
      headerName: 'Bank IBAN',
      field: 'bankIBAN',
      filter: 'agTextColumnFilter'
    },
    {
      headerName: 'Is Preferred',
      field: 'isPreferred',
      filter: BooleanFilter,
      filterParams: {
        property: 'isPreferred',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: BooleanFloatingFilter,
      floatingFilterComponentParams: {
        values: [{
          id: 'true',
          name: 'Yes'
        },
        {
          id: 'false',
          name: 'No'
        },
      ]},
      minWidth: 100,
      cellRenderer: CheckboxCellRenderer,
      valueFormatter: CheckboxFormatter
    },
    {
      headerName: 'Description',
      field: 'description',
      filter: 'agTextColumnFilter'
    },
    { 
      excludeFromExport: true,
      cellClass: 'lp-actions-cell',
      suppressHeaderMenuButton: true,
      suppressColumnsToolPanel: true,
      cellRenderer: ActionsCellRenderer, 
      cellRendererParams: (params: any) => {
        return { buttonsData: [
          { 
            type: ActionButtonTypes.View,
            callback: () => viewCallback(params.data)
          },
          ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageEntities) ? [{
            type: ActionButtonTypes.Delete,
            callback: () => deleteModal(params.data)
          }] : [])
        ]};
      },
      suppressSizeToFit: true,
      floatingFilter: false,
      sortable: false,
      minWidth: buttonsColumnWidth,
      maxWidth: buttonsColumnWidth,
      width: buttonsColumnWidth,
      suppressMovable: true,
      pinned: 'right',
      resizable: false
    }
  ];
  return columnDefs;
}