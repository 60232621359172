import { ColDef } from "ag-grid-community";
import store from "state/store";
import { NavigateFunction } from "react-router-dom";
import { setRowData } from "state/gridSlice";
import { ModalState, setModalData, setModalErrors, toggleModal, toggleModalLoadingState } from "state/modalSlice";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { ChargingSchemeModel } from "models/view/ChargingSchemeModel";
import { changeChargingSchemeStatus, deleteChargingScheme } from "actions/chargingSchemes";
import CopyChargingSchemeForm from "./CopyChargingScheme/CopyChargingSchemeForm";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import { BooleanFilter } from "components/Grid/GridFilters/Filters/BooleanFilter";
import { BooleanFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/BooleanFloatingFilter";
import CheckboxCellRenderer from "components/Grid/CheckboxCellRenderer";
import { CheckboxFormatter } from "components/Grid/ValueFormatters/CheckboxFormatter";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

const viewCallback = (id: string, navigate: NavigateFunction) => {
  navigate(`/chargingScheme/${id}`);
};

const changeStatusModal = (id: string) => {
  let bodyMessage: React.ReactNode;
  const gridState = store.getState().grid;
  if (!gridState.rowData.find((x: ChargingSchemeModel) => x.id == id).active) {
    bodyMessage = "Are you sure you want to activate this Charging Scheme?";
  } else {
    bodyMessage = "Are you sure you want to deactivate this Charging Scheme?";
  }
  let modal: ModalState = {
    title: "Confirmation",
    body: bodyMessage,
    onAction: () => changeStatus(id),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
}

const changeStatus = (id: string) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  changeChargingSchemeStatus(id).then(() => {
    let index: number = gridState.rowData.findIndex((x: any) => x.id == id);
    let newEntry: ChargingSchemeModel = { ...gridState.rowData[index], active: !gridState.rowData[index].active };
    let newArray: Array<any> = [...gridState.rowData];
    newArray[index] = newEntry;
    store.dispatch(setRowData(newArray));
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

const copyCallback = (id: string) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        title: "Copy Charging Scheme",
        children: <CopyChargingSchemeForm sourceChargingSchemeId={id} />
      }
    )
  );
};

const deleteModal = (id: string) => {
  let modal: ModalState = {
    title: "Delete confirmation",
    body: "Are you sure you want to delete this Charging Scheme?",
    actionText: "Delete",
    actionVariant: "danger",
    onAction: () => deleteCallback(id),
    show: true
  }
  store.dispatch(setModalData(modal));
};

const deleteCallback = (id: string) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  deleteChargingScheme(id).then(() => {
    store.dispatch(setRowData(gridState.rowData.filter((x: any) => x.id !== id)));
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

export const getColumnDefs = () => {
  const loggedInUser = store.getState().user;
  const buttonsColumnWidth = loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageChargingSchemes) ? 105 : 65;

  const columnDefs: (ColDef<ChargingSchemeModel> | {excludeFromExport: boolean})[] = [
    {
      headerName: "Display Name",
      field: 'displayName',
      filter: 'agTextColumnFilter',
      minWidth: 300,
      width: 300,
      flex: 3,
    },
    {
      headerName: "Minutes per Unit",
      field: 'minutesPerUnit',
      filter: 'agNumberColumnFilter',
      minWidth: 150,
      width: 150
    },
    {
      headerName: "Description",
      field: 'description',
      filter: 'agTextColumnFilter',
      minWidth: 250,
      flex: 2,
      valueFormatter: (params) => {
        if(params.value == '' || params.value == null || params.value == undefined) {
          return "—";
        }
        return params.value;
      }
    },
    {
      headerName: 'Is Active',
      field: 'active',
      filter: BooleanFilter,
      filterParams: {
        property: 'active',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: BooleanFloatingFilter,
      floatingFilterComponentParams: {
        values: [{
          id: 'true',
          name: 'Yes'
        },
        {
          id: 'false',
          name: 'No'
        },
      ]},
      minWidth: 100,
      width: 100,
      cellRenderer: CheckboxCellRenderer,
      valueFormatter: CheckboxFormatter
    },
    {
      excludeFromExport: true,
      suppressHeaderMenuButton: true,
      suppressColumnsToolPanel: true,
      cellClass: 'lp-actions-cell',
      cellRendererSelector: (params) => {
        return {
          component: ActionsCellRenderer,
          params: {
            buttonsData: [
              {
                type: ActionButtonTypes.View,
                callback: (id: string, navigate: NavigateFunction) => viewCallback(id, navigate)
              },
              ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageChargingSchemes) ? [{
                type: params.data?.active ? ActionButtonTypes.Deactivate : ActionButtonTypes.Activate,
                callback: (id: string) => changeStatusModal(id)
              }] : []),
              ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageChargingSchemes) ? [{
                type: ActionButtonTypes.Copy,
                callback: (id: string) => copyCallback(id)
              }] : []),
              ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageChargingSchemes) ? [{
                type: ActionButtonTypes.Delete,
                callback: (id: string) => deleteModal(id)
              }] : [])
            ]
          }
        }
      },
      suppressSizeToFit: true,
      floatingFilter: false,
      sortable: false,
      minWidth: buttonsColumnWidth,
      maxWidth: buttonsColumnWidth,
      width: buttonsColumnWidth,
      suppressMovable: true,
      pinned: 'right',
      resizable: false
    }
  ];
  return columnDefs;
}
