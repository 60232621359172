import useNotificationActions from 'actions/notification';
import { syncEmails } from 'actions/user';
import { NotificationTypes } from 'enums/NotificationTypes';
import { useAppSelector } from 'hooks/appSelector';
import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import DashboardCalendar from './DashboardCalendar';
import DashboardSummaryInfo from './DashboardSummaryInfo';
import DashboardMatters from './DashboardMatters';
import DashboardMatterInvoices from './DashboardMatterInvoices';
import DashboardUserEmails from './DashboardUserEmails';
import SlidingPanel from "components/SlidingPanel";
import './style.scss';
import { UserPermissionsNames } from 'enums/UserPermissionsNames';

function Dashboard() {
  const user = useAppSelector((state) => state.user);
  const notificationActions = useNotificationActions();

  const synchronizeEmails = () => {
    //hack to avoid the clearNotifications from the NotificationMessages
    setTimeout(() => {
      notificationActions.addNotificationMessage(
        {
          type: NotificationTypes.Success,
          title: "Email Sync Started",
          body: "Emails for your User are being synced.",
          isDismissable: true
        }
      );
    }, 1);

    syncEmails(user.apiToken, user.msalAccessToken).then((response) => {
      if(!response.ok) {
        //turn ReadableStream into JSON 
        response.json().then((data: any) => {
          notificationActions.addNotificationMessage(
            {
              type: NotificationTypes.Error,
              title: "Email Sync Error",
              body: data.Message ?? "Emails for your User have not been synced due to an error.",
              isDismissable: true
            }
          );
        });
        return;
      }
      notificationActions.addNotificationMessage(
        {
          type: NotificationTypes.Success,
          title: "Email Sync Successful",
          body: "Emails for your User have been synced successfully.",
          isDismissable: true
        }
      );
    }).catch((error) => {
      notificationActions.addNotificationMessage(
        {
          type: NotificationTypes.Error,
          title: "Email Sync Error",
          body: "Emails for your User have not been synced due to an error.",
          isDismissable: true
        }
      );
    });
  };

  useEffect(() => {
    if(user.userPermissions?.some(a => a == UserPermissionsNames.SyncUserEmailsAndCalendar && !user.isDelegated)) {
      synchronizeEmails();
    }
  }, []);

  return (
    <div className="lp-page-content lp-dashboard-page">
      {user.userPermissions?.some(a => a == UserPermissionsNames.ViewDashboardStatistics) &&
        <Row>
          <Col>
              <DashboardSummaryInfo />
          </Col>
        </Row>
      }
      <Row>
        {user.userPermissions?.some(a => a == UserPermissionsNames.ViewCalendars || a == UserPermissionsNames.ManageCalendars) &&
          <Col xxl={8} className="mb-4 mb-xxl-0">
            <DashboardCalendar />
          </Col>
          }
        {user.userPermissions?.some(a => a == UserPermissionsNames.ViewDashboardEmails || a == UserPermissionsNames.ManageDashboardEmails) &&
          <Col xxl={4}>
            <DashboardUserEmails />
          </Col>
        }
      </Row>
      <Row>
        {user.userPermissions?.some(a => a == UserPermissionsNames.ViewRecentMatters) &&
          <Col xxl={6} className="mb-4 mb-xxl-0">
            <DashboardMatters />
          </Col>
        }
        {user.userPermissions?.some(a => a == UserPermissionsNames.ViewRecentInvoices) &&
          <Col xxl={6}>
            <DashboardMatterInvoices />
          </Col>
        }
      </Row>

      <SlidingPanel />
    </div>
  );
}

export default Dashboard;
