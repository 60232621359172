import { cloneElement, ReactElement, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Loader from "components/Loader";
import { getAccountLedgerTransactionById, getAccountLedgerTransactionEntriesForTransaction } from "actions/matter";
import { AccountLedgerTransactionEntryModel } from "models/view/AccountLedgerTransactionEntryModel";
import Field from "components/Fields/Field";
import { AccountLedgerTransactionModel } from "models/view/AccountLedgerTransactionModel";
import moment from "moment";
import { DateFormat } from "utils/constants";
import { AccountDisplayOn } from "enums/AccountDisplayOn";
import useSlidingPanelActions from "actions/slidingPanel";
import { useAppSelector } from "hooks/appSelector";
import ViewAccountLedgerTransactionTable from "./ViewAccountLedgerTransactionTable";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { AccountLedgerTransactionTypes } from "enums/AccountLedgerTransactionTypes";
import ViewMatterDisbursement from "containers/Matter/ViewMatter/ActivityHistory/MatterDisbursement/ViewMatterDisbursement/ViewMatterDisbursement";
import ViewMatterDisbursementPayments from "containers/Matter/ViewMatter/ActivityHistory/MatterDisbursement/ViewMatterDisbursementPayments/ViewMatterDisbursementPayments";
import MatterDisbursementWriteOffForm from "containers/Matter/ViewMatter/ActivityHistory/MatterDisbursement/MatterDisbursementWriteOff/MatterDisbursementWriteOffForm";
import ViewMatterIncidentalExpense from "containers/Matter/ViewMatter/ActivityHistory/MatterIncidentalExpense/ViewMatterIncidentalExpense/ViewMatterIncidentalExpense";
import MatterIncidentalExpenseWriteOffForm from "containers/Matter/ViewMatter/ActivityHistory/MatterIncidentalExpense/MatterIncidentalExpenseWriteOff/MatterIncidentalExpenseWriteOffForm";
import ViewMatterInvoice from "containers/Matter/ViewMatter/ActivityHistory/MatterInvoice/ViewMatterInvoice/ViewMatterInvoice";
import ViewMatterInvoicePayments from "containers/Matter/ViewMatter/ActivityHistory/MatterInvoice/ViewMatterInvoicePayments/ViewMatterInvoicePayments";
import MatterInvoiceWriteOffForm from "containers/Matter/ViewMatter/ActivityHistory/MatterInvoice/MatterInvoiceWriteOff/MatterInvoiceWriteOffForm";
import MatterInvoiceAbatementForm from "containers/Matter/ViewMatter/ActivityHistory/MatterInvoice/MatterInvoiceAbatement/MatterInvoiceAbatementForm";
import ViewMatterReceiveMoney from "containers/Matter/ViewMatter/ActivityHistory/MatterReceiveMoney/ViewMatterReceiveMoney/ViewMatterReceiveMoney";
import ViewMatterReturnMoney from "containers/Matter/ViewMatter/ActivityHistory/MatterReturnMoney/ViewMatterReturnMoney/ViewMatterReturnMoney";
import ViewMatterFundsTransfer from "containers/Matter/ViewMatter/ActivityHistory/MatterFundsTransfer/ViewMatterFundsTransfer/ViewMatterFundsTransfer";

type Props = {
  accountLedgerTransactionId: string,
  accountLedgerTransactionEntryId: string,
  showMatterInfo?: boolean,
  editComponent?: ReactElement,
  parentResourceData?: {
    accountLedgerTransactionType: AccountLedgerTransactionTypes,
    matterId: string,
    matterDisbursementId?: string,
    matterIncidentalExpenseId?: string,
    matterInvoiceId?: string,
    matterReceiveMoneyId?: string,
    matterReturnMoneyId?: string,
    matterFundsTransferId?: string,
  },
  reloadGridData?: Function,
}

export default function ViewAccountLedgerTransaction(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [accountLedgerTransaction, setAccountLedgerTransaction] = useState<AccountLedgerTransactionModel>();
  const [accountLedgerTransactionEntries, setAccountLedgerTransactionEntries] = useState<AccountLedgerTransactionEntryModel[]>([]);
  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const loggedInUser = useAppSelector((state) => state.user);

  useEffect(() => {
    setIsLoading(true);

    const promises = [
      getAccountLedgerTransactionById(props.accountLedgerTransactionId),
      getAccountLedgerTransactionEntriesForTransaction(props.accountLedgerTransactionId)
    ];

    Promise.all(promises)
      .then(([transactionResponse, transactionEntriesResponse]) => {
        setAccountLedgerTransaction(transactionResponse.data);
        setAccountLedgerTransactionEntries(transactionEntriesResponse.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });

    // Set edit button action
    if(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageChartOfAccounts || 
      a == UserPermissionsNames.ManageMatterLedger || a == UserPermissionsNames.ManageEntities)) {
      slidingPanelActions.setOnEdit(() => {
        if (!props.editComponent) {
          return;
        }

        slidingPanelActions.clearSlidingPanel();

        slidingPanelActions.setSlidingPanel(
        {
          isShown: true,
          width: "70rem",
          title: "Edit Ledger Entry",
          children: cloneElement(props.editComponent!, {onSubmitCallback: onChildPanelClose}),
          onCancel: onChildPanelClose
        });
      });
    }

    if(props.parentResourceData) {
      slidingPanelActions.setMoreButtons([{
        type: ActionButtonTypes.ViewParentResource,
        callback: () => viewParentResourceCallback()
      }]);
    }
  }, [props.accountLedgerTransactionEntryId]);

  const onChildPanelClose = () => {
    slidingPanelActions.clearSlidingPanel();
    slidingPanelActions.setSlidingPanel({
      ...currentSlidingPanelState,
    });
  };

  const viewParentResourceCallback = () => {
    switch(props.parentResourceData?.accountLedgerTransactionType) { 
      case AccountLedgerTransactionTypes.Disbursement: { 
        slidingPanelActions.setSlidingPanel(
          {
            isShown: true,
            width: "55rem",
            title: "View Disbursement",
            children: <ViewMatterDisbursement
              matterId={props.parentResourceData.matterId}
              matterDisbursementId={props.parentResourceData.matterDisbursementId!}
              onDeleteCallback={props.reloadGridData}
              onEditCallback={props.reloadGridData}
              onRecordPaymentCallback={props.reloadGridData}
              onWriteOffCallback={props.reloadGridData}
              showMoreButtons
            />,
            onCancel: onChildPanelClose
          }
        );
        return;
      }
      case AccountLedgerTransactionTypes.DisbursementPayment: { 
        slidingPanelActions.setSlidingPanel(
          {
            isShown: true,
            width: "55rem",
            title: "Disbursement Payments",
            children: <ViewMatterDisbursementPayments
              matterId={props.parentResourceData.matterId}
              matterDisbursementId={props.parentResourceData.matterDisbursementId!}
              onPaymentsChangedCallback={props.reloadGridData}
            />,
            onCancel: onChildPanelClose
          }
        );
        return;
      }
      case AccountLedgerTransactionTypes.DisbursementWriteOff: { 
        slidingPanelActions.setSlidingPanel(
          {
            isShown: true,
            title: "Disbursement Write-off",
            children: <MatterDisbursementWriteOffForm
              matterId={props.parentResourceData.matterId}
              matterDisbursementId={props.parentResourceData.matterDisbursementId!}
              onSubmitCallback={props.reloadGridData}
              onDeleteCallback={props.reloadGridData}
            />,
            onCancel: onChildPanelClose
          }
        );
        return;
      }
      case AccountLedgerTransactionTypes.IncidentalExpense: { 
        slidingPanelActions.setSlidingPanel(
          {
            isShown: true,
            width: "55rem",
            title: "View Incidental Expense",
            children: <ViewMatterIncidentalExpense
              matterId={props.parentResourceData.matterId}
              matterIncidentalExpenseId={props.parentResourceData.matterIncidentalExpenseId!}
              onDeleteCallback={props.reloadGridData}
              onEditCallback={props.reloadGridData}
              onWriteOffCallback={props.reloadGridData}
              showMoreButtons
            />,
            onCancel: onChildPanelClose
          }
        );
        return;
      }
      case AccountLedgerTransactionTypes.IncidentalExpenseWriteOff: { 
        slidingPanelActions.setSlidingPanel(
          {
            isShown: true,
            title: "Incidental Expense Write-off",
            children: <MatterIncidentalExpenseWriteOffForm
              matterId={props.parentResourceData.matterId}
              matterIncidentalExpenseId={props.parentResourceData.matterIncidentalExpenseId!}
              onSubmitCallback={props.reloadGridData}
              onDeleteCallback={props.reloadGridData}
            />,
            onCancel: onChildPanelClose
          }
        );
        return;
      }
      case AccountLedgerTransactionTypes.Invoice: { 
        slidingPanelActions.setSlidingPanel(
          {
            isShown: true,
            width: "70rem",
            title: "View Invoice",
            children: <ViewMatterInvoice
              matterId={props.parentResourceData.matterId}
              matterInvoiceId={props.parentResourceData.matterInvoiceId!}
              onMarkAsDraftCallback={props.reloadGridData}
              onDeleteCallback={props.reloadGridData}
              onEditCallback={props.reloadGridData}
              onRecordPaymentCallback={props.reloadGridData}
              onWriteOffCallback={props.reloadGridData}
              onAbatementCallback={props.reloadGridData}
              showMoreButtons
            />,
            onCancel: onChildPanelClose
          }
        );
        return;
      }
      case AccountLedgerTransactionTypes.InvoicePayment: { 
        slidingPanelActions.setSlidingPanel(
          {
            isShown: true,
            width: "55rem",
            title: "Invoice Payments",
            children: <ViewMatterInvoicePayments
              matterId={props.parentResourceData.matterId}
              matterInvoiceId={props.parentResourceData.matterInvoiceId!}
            />,
            onCancel: () => {
              onChildPanelClose();
              props.reloadGridData && props.reloadGridData();
            }
          }
        );
        return;
      }
      case AccountLedgerTransactionTypes.InvoiceWriteOff: { 
        slidingPanelActions.setSlidingPanel(
          {
            isShown: true,
            title: "Invoice Write-Off",
            children: <MatterInvoiceWriteOffForm
              matterId={props.parentResourceData.matterId}
              matterInvoiceId={props.parentResourceData.matterInvoiceId!}
              onSubmitCallback={props.reloadGridData}
              onDeleteCallback={props.reloadGridData}
            />,
            onCancel: onChildPanelClose
          }
        );
        return;
      }
      case AccountLedgerTransactionTypes.InvoiceAbatement: { 
        slidingPanelActions.setSlidingPanel(
          {
            isShown: true,
            title: "Invoice Abatement",
            children: <MatterInvoiceAbatementForm
              matterId={props.parentResourceData.matterId}
              matterInvoiceId={props.parentResourceData.matterInvoiceId!}
              onSubmitCallback={props.reloadGridData}
              onDeleteCallback={props.reloadGridData}
            />,
            onCancel: onChildPanelClose
          }
        );
        return;
      }
      case AccountLedgerTransactionTypes.ReceiveMoney: { 
        slidingPanelActions.setSlidingPanel(
          {
            isShown: true,
            title: "View Receive Money",
            children: <ViewMatterReceiveMoney
              matterId={props.parentResourceData.matterId}
              matterReceiveMoneyId={props.parentResourceData.matterReceiveMoneyId!}
              onDeleteCallback={props.reloadGridData}
              onEditCallback={props.reloadGridData}
              showMoreButtons
            />,
            onCancel: onChildPanelClose
          }
        );
        return;
      }
      case AccountLedgerTransactionTypes.ReturnMoney: { 
        slidingPanelActions.setSlidingPanel(
          {
            isShown: true,
            title: "View Return Money",
            children: <ViewMatterReturnMoney
              matterId={props.parentResourceData.matterId}
              matterReturnMoneyId={props.parentResourceData.matterReturnMoneyId!}
              onDeleteCallback={props.reloadGridData}
              onEditCallback={props.reloadGridData}
              showMoreButtons
            />,
            onCancel: onChildPanelClose
          }
        );
        return;
      }
      case AccountLedgerTransactionTypes.FundsTransfer: { 
        slidingPanelActions.setSlidingPanel(
          {
            isShown: true,
            title: "View Funds Transfer",
            children: <ViewMatterFundsTransfer
              matterId={props.parentResourceData.matterId}
              matterFundsTransferId={props.parentResourceData.matterFundsTransferId!}
              onDeleteCallback={props.reloadGridData}
              onEditCallback={props.reloadGridData}
              showMoreButtons
            />,
            onCancel: onChildPanelClose
          }
        );
        return;
      }
      default: { 
        return;
      }
    }
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col>
          <Field
            label={"Date"}
            value={accountLedgerTransaction?.date ? moment(accountLedgerTransaction?.date).format(DateFormat.Moment) : "—"}
          />
        </Col>
        {props.showMatterInfo && 
          <Col>
            <Field
              label={"Matter"}
              value={accountLedgerTransaction?.matter?.name ?? "—"}
            />
          </Col>
        }
      </Row>
      <Row>
        <Col>
          <Field
            label={"Client"}
            value={accountLedgerTransaction?.matterParticipatingEntity?.name ?? "—"}
          />
        </Col>
        <Col>
          <Field
            label={"Display Name"}
            value={accountLedgerTransaction?.displayName}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Field
            label={"Description"}
            value={accountLedgerTransaction?.description}
          />
        </Col>
      </Row>

      <div>
        {accountLedgerTransactionEntries.some((x) => x.account?.displayOn == AccountDisplayOn.BusinessLedger ||
          x.account?.displayOn == AccountDisplayOn.ClientLedgerBusinessSide ) &&
          <>
            <div className="lp-color-title primary full-width-no-bottom-padding my-4">Business Ledger</div>
            <ViewAccountLedgerTransactionTable entries={accountLedgerTransactionEntries.filter((x) =>
              x.account?.displayOn == AccountDisplayOn.BusinessLedger || x.account?.displayOn == AccountDisplayOn.ClientLedgerBusinessSide)}
            />
          </>
        }
      </div>
      <div>
        {accountLedgerTransactionEntries.some((x) => x.account?.displayOn == AccountDisplayOn.ClientLedgerClientSide) &&
          <>
            <div className="lp-color-title primary full-width-no-bottom-padding my-4">Client Ledger</div>
            <ViewAccountLedgerTransactionTable entries={accountLedgerTransactionEntries.filter((x) => x.account?.displayOn == AccountDisplayOn.ClientLedgerClientSide)} />
          </>
        }
      </div>
    </>
  );
}