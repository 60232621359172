import React, { useEffect, useState } from 'react';
import { useUserActions } from 'actions/user';
import MSLogo from 'assets/images/microsoft-logo.webp';
import './style.scss';
import { useIsAuthenticated } from '@azure/msal-react';
import { isMobile } from 'react-device-detect';

function Login() {
  const userActions = useUserActions();
  const [genericErrors, setGenericErrors] = useState<string | undefined>(undefined);
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if(isAuthenticated && isMobile){
      const fetchData = async () => {
        await userActions.acquireUserTokenSilent()
          .then(() => {})
          .catch((err) => {
            setGenericErrors('Authentication failed: ' + (err.response?.data?.Message ?? err.errorMessage ?? err));
            userActions.logout();
          });
      }

      fetchData();
    }
  }, [isAuthenticated]);

  const onLogin = async () => {
    try {
      await userActions.login();
      setGenericErrors(undefined);
    } catch (err: any) {
      if(err?.errorCode != 'user_cancelled') {
        setGenericErrors('Authentication failed: ' + (err.response?.data?.Message ?? err.errorMessage ?? err));
      }
      else {
        setGenericErrors(undefined);
      }
      await userActions.logout();
    }
  }

  return (
    <>
      <div className="lp-auth-page-title">
        <h1>Sign<span>in</span></h1>
      </div>
      <div className="lp-login-box">
        <div onClick={() => onLogin()} className="lp-msal-login-btn">
          <img src={MSLogo} alt="Microsoft" />
          <span>Click to login with Microsoft account</span>
        </div>
        {genericErrors && (
          <div className="lp-errors">
            {genericErrors}
          </div>
        )}
      </div>
    </>
  );
}

export default Login;
