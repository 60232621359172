import { useCallback, useState } from "react";
import useGridActions from 'actions/grid';
import Grid from "components/Grid";
import { getColumnDefs } from "./columnDefs";
import { MdAddCircle } from 'react-icons/md';
import Title from 'components/Title';
import SlidingPanel from 'components/SlidingPanel';
import { Card, Button } from 'react-bootstrap';
import useSlidingPanelActions from "actions/slidingPanel";
import { useAppSelector } from "hooks/appSelector";
import { getEntities } from "actions/entity";
import EntityCreateForm from "./CreateEntity/EntityCreateForm";
import './style.scss';
import { UserPermissionsNames } from "enums/UserPermissionsNames";

function EntityList() {
  const gridActions = useGridActions();
  const slidingPanelActions = useSlidingPanelActions();
  const slidingPanelState = useAppSelector((state) => state.slidingPanel);
  const [genericErrors, setGenericErrors] = useState(null);
  const loggedInUser = useAppSelector((state) => state.user);

  const onGridReady = useCallback(() =>
  {
    const columnDefs = getColumnDefs();
    gridActions.setGridColumnDefs([...columnDefs]);
    getEntities().then((response) => 
    {
      gridActions.setGridRowData(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
      gridActions.setGridRowData([]);
    });
  }, []);

  const toggleSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel({
      isShown: !slidingPanelState.isShown,
      title: "Add Entity",
      children: <EntityCreateForm />
    });
  };

  return (
    <div className="lp-page-content">
      <Title
        type="page"
        title="Address Book"
      >
        {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageEntities) &&
          <Button variant="success" onClick={toggleSlidingPanel}>
            <MdAddCircle />
            Add Entity
          </Button>
        }
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Card className="with-grid">
        <Card.Body>
          <Grid
            onGridReady={onGridReady}
          />
        </Card.Body>
      </Card>

      <SlidingPanel />
    </div>
  );
}

export default EntityList;
