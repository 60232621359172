import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Row, Col, Form, Button } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import Loader from "components/Loader";
import { getValidationSuite } from "./validationSuite";
import Field from "components/Fields/Field";
import { DelegatedUserModel } from "models/view/DelegatedUserModel";
import { getDelegatedUserById, getUserPermissions, getUserRoles, updateDelegatedUser } from "actions/user";
import { UpdateDelegatedUserModel } from "models/update/UpdateDelegatedUserModel";
import useGridActions from "actions/grid";
import { useAppSelector } from "hooks/appSelector";
import CustomSelect from "components/Select/Select";

type Props = {
  lteId: string,
  delegatedUserId: string,
  onSubmitCallback?: Function,
  showDelegateAccessFields?: boolean,
  showManageDiaryFields?: boolean
}

export default function EditDelegatedUserForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [delegatedUser, setDelegatedUser] = useState<DelegatedUserModel>();
  const slidingPanelActions = useSlidingPanelActions();
  const gridActions = useGridActions();
  const grid = useAppSelector((state) => state.grid);
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);

  useEffect(() => {
    setIsLoading(true);

    getDelegatedUserById(props.delegatedUserId).then( (response) => {
      setDelegatedUser(response.data);

      let initialValues: UpdateDelegatedUserModel = {
        delegatedLTEId: response.data.delegatedLTEId,
        userId: response.data.userId,
        active: response.data.active,
        userRoleId: response.data.userRoleId,
        managesDiary: response.data.managesDiary,
        userPermissions: response.data.userPermissions?.map((x: any) => x.id)
      };
      reset(initialValues);      
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  const {reset,control,  handleSubmit, formState: {errors}} = useForm<UpdateDelegatedUserModel>({
    resolver: vestResolver(getValidationSuite(props.showDelegateAccessFields))
  });

  const onSubmit = handleSubmit((data) => submitData(data));
    
  async function submitData(data: UpdateDelegatedUserModel) {
    setIsLoading(true);
    updateDelegatedUser(props.delegatedUserId, data).then((response) => {
      let index: number = grid.rowData.findIndex((x: any) => x.id === response.data.id);
      let newEntry: DelegatedUserModel = {...response.data};
      let newArray: Array<any> = [...grid.rowData];
      newArray[index] = newEntry;
      gridActions.setGridRowData(newArray);
      slidingPanelActions.clearSlidingPanel();
      reset();

      props.onSubmitCallback && props.onSubmitCallback();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();

    currentSlidingPanelState.onCancel && currentSlidingPanelState.onCancel();
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Col>
            <Field
              label={"User"}
              value={delegatedUser?.user?.name ?? ""}
            />
          </Col>
        </Row>

        {props?.showDelegateAccessFields &&
          <>
            <Form.Group className="mt-4 mb-4" controlId="userRoleId">
              <Form.Label className="required">User Role</Form.Label>
              <Controller
                control={control}
                name="userRoleId"
                shouldUnregister={true}
                render={({field: { onChange, value, name, ref }}) => (
                  <CustomSelect
                    id="userRoleId"
                    inputRef={ref}
                    className={`lp-select${errors?.userRoleId?.message ? ' invalid' : ''}`}
                    endpointCall={() => getUserRoles(props.lteId)}
                    value={value}
                    onChange={val => onChange(val?.id ?? null)}
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.userRoleId?.message && (errors.userRoleId.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-4" controlId="userPermissions">
              <Form.Label>Additional Permissions</Form.Label>
              <Controller
                control={control}
                name="userPermissions"
                shouldUnregister={true}
                render={({field: { onChange, value, name, ref }}) => (
                  <CustomSelect
                    id="userPermissions"
                    inputRef={ref}
                    endpointCall={() => getUserPermissions(props.lteId)}
                    value={value}
                    isMulti
                    isClearable
                    onChange={val => onChange(val?.map((x: { id: any; }) => x.id) ?? null)}
                  />
                )}
              />
            </Form.Group>
            </>
        }

        <Row className="mb-4">
          {props?.showManageDiaryFields &&
            <Form.Group as={Col} controlId="managesDiary">
              <Form.Label>Manage Diary</Form.Label>
              <Controller
                control={control}
                name="managesDiary"
                shouldUnregister={true}
                render={({field: { onChange, value, name, ref }}) => (
                  <Form.Check 
                    type="switch"
                    id="managesDiary">
                      <Form.Check.Input
                        className= "form-check-input"
                        ref={ref}
                        checked={value ?? false}
                        onChange={(ev: any) => onChange(ev.target.checked)}
                      />
                  </Form.Check>
                )}
              />
            </Form.Group>
          }

          <Form.Group as={Col} controlId="active">
            <Form.Label>Active</Form.Label>
            <Controller
              control={control}
              name="active"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <Form.Check 
                  type="switch"
                  id="active">
                    <Form.Check.Input
                      className= "form-check-input"
                      ref={ref}
                      checked={value ?? false}
                      onChange={(ev: any) => onChange(ev.target.checked)}
                    />
                </Form.Check>
              )}
            />
          </Form.Group>
        </Row>
    
        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <Button variant="success" type="submit">Update</Button>
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
