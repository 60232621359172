import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Form, Button } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import CustomSelect from "components/Select/Select";
import Loader from "components/Loader";
import { getValidationSuite } from "./validationSuite";
import { removeEmptyFields } from "utils/form";
import { addDelegatedUser, getUserSummaryForLte } from "actions/user";
import { useAppSelector } from "hooks/appSelector";
import useGridActions from "actions/grid";
import { CreateDelegatedUserModel } from "models/create/CreateDelegatedUserModel";

type Props = {
  lteId: string,
  delegatedLTEId: string
}

export default function CreateDelegatedUserForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();
  const gridActions = useGridActions();
  const grid = useAppSelector((state) => state.grid);

  const {reset, control, handleSubmit, formState: {errors}} = useForm<CreateDelegatedUserModel>({
    resolver: vestResolver(getValidationSuite(props.delegatedLTEId))
  });

  const onSubmit = handleSubmit((data) => submitData(data));
    
  async function submitData(data: CreateDelegatedUserModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    data.delegatedLTEId = props.delegatedLTEId;
    addDelegatedUser(data).then((response) => {
      gridActions.setGridRowData(grid.rowData.concat(response.data));
      slidingPanelActions.clearSlidingPanel();
      reset();
    }).catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Form.Group className="mb-4" controlId="userId">
          <Form.Label className="required">User</Form.Label>
          <Controller
            control={control}
            name="userId"
            shouldUnregister={true}
            render={({field: { onChange, value, ref }}) => (
              <CustomSelect
                id="userId"
                inputRef={ref}
                className={`lp-select${errors?.userId?.message ? ' invalid' : ''}`}
                endpointCall={() => getUserSummaryForLte(props.lteId, true)}
                value={value}
                onChange={val => onChange(val?.id ?? null)}
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.userId?.message && (errors.userId.message)}
          </Form.Text>
        </Form.Group>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <Button variant="success" type="submit">Create</Button>
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
