import { MatterFeeEarnerModel } from "models/view/MatterFeeEarnerModel";
import React from "react";
import ViewMatterFeeEarnerPreferredContacts from "./ViewMatterFeeEarnerPreferredContacts";
import ViewMatterFeeEarnerProfile from "./ViewMatterFeeEarnerProfile";

type Props = {
  matterFeeEarner: MatterFeeEarnerModel,
  viewAction?: Function
}

export default function ViewCaseManagerMatterFeeEarner(props: Props) {

  return (
    <React.Fragment key={props.matterFeeEarner.id}>
      <ViewMatterFeeEarnerProfile 
        matterFeeEarner={props.matterFeeEarner}
        viewAction={props.viewAction}
        showRole
      />
      <ViewMatterFeeEarnerPreferredContacts matterFeeEarner={props.matterFeeEarner}/>
    </React.Fragment>
  );
}
