import { vestResolver } from "@hookform/resolvers/vest";
import {
  createMatterIncidentalExpenses,
  getMatterActivityHistory,
  getMatterClientsSummary,
  getMatterDefaultClient
} from "actions/matter";
import useSlidingPanelActions from "actions/slidingPanel";
import { getUserSummaryForLte } from "actions/user";
import Loader from "components/Loader/index";
import CustomSelect from "components/Select/Select";
import { useAppSelector } from "hooks/appSelector";
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { DateFormat } from "utils/constants";
import { removeEmptyFields } from "utils/form";
import { validationSuite } from "./validationSuite";
import useGridActions from "actions/grid";
import store from "state/store";
import { GridIds } from "enums/GridIds";
import MatterFileSelect from "components/Select/MatterFileSelect";
import { CreateOrUpdateMatterIncidentalExpensesModel } from "models/create/CreateOrUpdateMatterIncidentalExpensesModel";
import { MdAdd, MdClose } from "react-icons/md";
import { getDateOnly } from "utils/date";
import currency from 'currency.js';

type Props = {
  matterId: string
}

export default function CreateMatterIncidentalExpensesForm(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const user = useAppSelector((state) => state.user);
  const slidingPanelActions = useSlidingPanelActions();
  const gridActions = useGridActions();

  const { register, handleSubmit, control, setValue, watch, reset, formState: { errors } } = useForm<CreateOrUpdateMatterIncidentalExpensesModel>({
    resolver: vestResolver(validationSuite),
    defaultValues: {
      userId: user.userId,
      incidentalExpenses: [{
        displayName: "",
        description: "",
        date: undefined,
        grossValue: undefined,
        vatValue: undefined,
        netValue: undefined,
        paidByUserOwnFunds: false,
        fileIds: []
      }]
    }
  });

  const { fields, append, remove } = useFieldArray({
    name: "incidentalExpenses",
    control,
    rules: {
      required: "Please append at least 1 incidental expense"
    }
  });

  const appendNewIncidentalExpense = () => {
    append({
      displayName: "",
      description: "",
      date: undefined,
      grossValue: undefined,
      vatValue: undefined,
      netValue: undefined,
      paidByUserOwnFunds: false,
      fileIds: []
    }, { shouldFocus: false });
  }

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateOrUpdateMatterIncidentalExpensesModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    createMatterIncidentalExpenses(props.matterId, data).then((response) => {
      const gridState = store.getState().grid;
      if (gridState.id == `${GridIds.ActivityHistory}/${props.matterId}`) {
        getMatterActivityHistory(props.matterId).then((response) => {
          gridActions.setGridRowData(response.data);
        });
      }
      slidingPanelActions.clearSlidingPanel();
      reset();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  useEffect(() => {
    setIsLoading(true);
    getMatterDefaultClient(props.matterId).then((response: any) => {
      setValue('matterParticipatingEntityId', response.data?.id);
    }).catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  const computeNetValue = (index: number) => {
    const grossValue: number = watch(`incidentalExpenses.${index}.grossValue`) ?? 0;
    const vatValue: number = watch(`incidentalExpenses.${index}.vatValue`) ?? 0;

    setValue(`incidentalExpenses.${index}.netValue`, currency(grossValue).subtract(vatValue).value);
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Form.Group className="mb-4" controlId="userId">
          <Form.Label className="required">Fee Earner</Form.Label>
          <Controller
            control={control}
            name={`userId`}
            shouldUnregister={true}
            render={({ field: { onChange, value, name, ref } }) => (
              <CustomSelect
                id="userId"
                inputRef={ref}
                className={`lp-select${errors?.userId?.message ? ' invalid' : ''}`}
                endpointCall={() => getUserSummaryForLte(user.lawPageTradingEntityId!)}
                value={value}
                onChange={val => onChange(val?.id ?? null)}
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.userId?.message && (errors.userId?.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="matterParticipatingEntityId">
          <Form.Label className="required">Client</Form.Label>
          <Controller
            control={control}
            name={`matterParticipatingEntityId`}
            shouldUnregister={true}
            render={({ field: { onChange, value, name, ref } }) => (
              <CustomSelect
                id="matterParticipatingEntityId"
                inputRef={ref}
                className={`lp-select${errors?.matterParticipatingEntityId?.message ? ' invalid' : ''}`}
                endpointCall={() => getMatterClientsSummary(props.matterId)}
                value={value}
                onChange={val => onChange(val?.id ?? null)}
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.matterParticipatingEntityId?.message && (errors.matterParticipatingEntityId?.message)}
          </Form.Text>
        </Form.Group>

        {fields.map((field, index) => {
          return (
            <section key={field.id} className="lp-charge-rate-item">
              <Button
                variant="danger"
                onClick={() => remove(index)}
                className={`delete-item btn-icon${fields.length === 1 ? ' disabled' : ''}`}
              >
                <MdClose />
              </Button>

              <Row>
                <Form.Group as={Col} xs={12} sm={6} className="mb-4" controlId={`incidentalExpenses.${index}.date`}>
                  <Form.Label className="required">Date</Form.Label>
                  <Controller
                    control={control}
                    name={`incidentalExpenses.${index}.date`}
                    shouldUnregister={true}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        className={`${errors?.incidentalExpenses?.[index]?.date?.message ? 'invalid' : ''}`}
                        id={`incidentalExpenses.${index}.date`}
                        dateFormat={DateFormat.Datepicker}
                        selected={value ? getDateOnly(value) : null}
                        onChange={(val) => onChange(val != null ? getDateOnly(val) : val)}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                      />
                    )}
                  />
                  <Form.Text className="lp-error">
                    {errors?.incidentalExpenses?.[index]?.date?.message && (errors.incidentalExpenses?.[index]?.date?.message)}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} xs={12} sm={6} className="mb-4" controlId={`incidentalExpenses.${index}.displayName`}>
                  <Form.Label className="required">Display Name</Form.Label>
                  <Form.Control
                    type="text"
                    className={`${errors?.incidentalExpenses?.[index]?.displayName?.message ? 'invalid' : ''}`}
                    {...register(`incidentalExpenses.${index}.displayName`, {shouldUnregister: true})}
                  />
                  <Form.Text className="lp-error">
                    {errors?.incidentalExpenses?.[index]?.displayName?.message && (errors.incidentalExpenses?.[index]?.displayName?.message)}
                  </Form.Text>
                </Form.Group>
              </Row>

              <Form.Group className="mb-4" controlId={`incidentalExpenses.${index}.description`}>
                <Form.Label className="required">Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  className={`${errors?.incidentalExpenses?.[index]?.description?.message ? 'invalid' : ''}`}
                  {...register(`incidentalExpenses.${index}.description`, {shouldUnregister: true})}
                />
                <Form.Text className="lp-error">
                  {errors?.incidentalExpenses?.[index]?.description?.message && (errors.incidentalExpenses?.[index]?.description?.message)}
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-4" controlId={`incidentalExpenses.${index}.paidByUserOwnFunds`}>
                <Form.Label>Paid By User's Own Funds</Form.Label>
                <Controller
                  control={control}
                  name={`incidentalExpenses.${index}.paidByUserOwnFunds`}
                  shouldUnregister={true}
                  render={({field: { onChange, value, name, ref }}) => (
                    <Form.Check 
                      type="switch"
                      id={`incidentalExpenses.${index}.paidByUserOwnFunds`}>
                        <Form.Check.Input
                          className= "form-check-input"
                          ref={ref}
                          checked={value ?? false}
                          onChange={(ev: any) => onChange(ev.target.checked)}
                        />
                    </Form.Check>
                  )}
                />
              </Form.Group>

              <Row>
                <Form.Group as={Col} xs={12} sm={4} className="mb-4" controlId={`incidentalExpenses.${index}.grossValue`}>
                  <Form.Label className={"required"}>Gross Value</Form.Label>
                  <Form.Control
                    type="number"
                    className={`${errors?.incidentalExpenses?.[index]?.grossValue?.message ? 'invalid' : ''}`}
                    {...register(`incidentalExpenses.${index}.grossValue`, {shouldUnregister: true})}
                    min="0.00"
                    step="0.01"
                    onBlur={() => computeNetValue(index)}
                    onWheel={e => e.currentTarget.blur()}
                  />
                  <Form.Text className="lp-error">
                    {errors?.incidentalExpenses?.[index]?.grossValue?.message && (errors.incidentalExpenses?.[index]?.grossValue?.message)}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} xs={12} sm={4} className="mb-4" controlId={`incidentalExpenses.${index}.vatValue`}>
                  <Form.Label>VAT Value</Form.Label>
                  <Form.Control
                    type="number"
                    className={`${errors?.incidentalExpenses?.[index]?.vatValue?.message ? 'invalid' : ''}`}
                    {...register(`incidentalExpenses.${index}.vatValue`, {shouldUnregister: true})}
                    min="0.00"
                    step="0.01"
                    onBlur={() => computeNetValue(index)}
                    onWheel={e => e.currentTarget.blur()}
                  />
                  <Form.Text className="lp-error">
                    {errors?.incidentalExpenses?.[index]?.vatValue?.message && (errors.incidentalExpenses?.[index]?.vatValue?.message)}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} xs={12} sm={4} className="mb-4" controlId={`incidentalExpenses.${index}.netValue`}>
                  <Form.Label>Net Value</Form.Label>
                  <Form.Control
                    type="number"
                    disabled
                    className={`${errors?.incidentalExpenses?.[index]?.netValue?.message ? 'invalid' : ''}`}
                    {...register(`incidentalExpenses.${index}.netValue`, {shouldUnregister: true})}
                  />
                  <Form.Text className="lp-error">
                    {errors?.incidentalExpenses?.[index]?.netValue?.message && (errors.incidentalExpenses?.[index]?.netValue?.message)}
                  </Form.Text>
                </Form.Group>
              </Row>

              <Form.Group controlId={`incidentalExpenses.${index}.fileIds`}>
                <Form.Label>Proofs of Purchase</Form.Label>
                <Controller
                  control={control}
                  name={`incidentalExpenses.${index}.fileIds`}
                  shouldUnregister={true}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <MatterFileSelect
                      id={`incidentalExpenses.${index}.fileIds`}
                      matterId={props.matterId}
                      inputRef={ref}
                      className={`lp-select${errors?.incidentalExpenses?.[index]?.fileIds?.message ? ' invalid' : ''}`}
                      value={value}
                      onChange={val => onChange(val ?? null)}
                      isMulti
                      isClearable
                      menuPlacement="top"
                      canUploadNewFiles
                    />
                  )}
                />
                <Form.Text className="lp-error">
                  {errors?.incidentalExpenses?.[index]?.fileIds?.message && (errors.incidentalExpenses?.[index]?.fileIds?.message)}
                </Form.Text>
              </Form.Group>
            </section>
          );
        })}

        <Form.Group className="mb-4">
          <Button variant="light" onClick={() => appendNewIncidentalExpense()}>
            <MdAdd />
            Add new Incidental Expense
          </Button>
        </Form.Group>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <Button variant="success" type="submit">Create</Button>
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
