import SlidingPanel from "components/SlidingPanel/index";
import useGridActions from "actions/grid";
import Grid from "components/Grid/index";
import { useCallback, useEffect, useRef, useState } from "react";
import { getColumnDefs, viewTransactionCallback } from "./columnDefs";
import { Row, Col, Card } from "react-bootstrap";
import Title from "components/Title/index";
import { getEntityLedgerEntries } from "actions/entity";
import { useAppSelector } from "hooks/appSelector";
import { AgGridReact } from "ag-grid-react";

type Props = {
  entityId: string
}

export default function EntityLedger(props: Props) {
  const gridActions = useGridActions();
  const [genericErrors, setGenericErrors] = useState(null);
  const loggedInUser = useAppSelector((state) => state.user);
  const gridRef = useRef<AgGridReact>(null);

  const onGridReady = useCallback(() =>
  {
    const columnDefs = getColumnDefs(!loggedInUser.lteHasClientBankAccounts);
    gridActions.setGridColumnDefs([...columnDefs]);
  }, [props.entityId]);

  useEffect(() => {
    if(props.entityId) {
      getEntityLedgerEntries(props.entityId)
        .then((response) => {
          gridActions.setGridRowData(response.data);
        })
        .catch((error) => {
          setGenericErrors(error.response?.data?.Message ?? error.message);
          gridActions.setGridRowData([]);
        });
    }
    else {
      gridActions.setGridRowData([]);
    }
  }, [props.entityId]);

  return (
    <>
      <Row>
        <Col>
          <Title
            type="section"
            title={<>Ledger</>}
          >
          </Title>

          {genericErrors && (
            <div className="lp-errors">
              {genericErrors}
            </div>
          )}

          <Card className="with-grid">
            <Card.Body>
              <Grid 
                ref={gridRef}
                onGridReady={onGridReady}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <SlidingPanel gridRef={gridRef} onNavigation={(item: any) => viewTransactionCallback(item)} />
    </>
  );
}
