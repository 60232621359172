import React, { useEffect, useState } from "react";
import Field from "components/Fields/Field";
import Loader from "components/Loader";
import { Row, Col, Button } from "react-bootstrap";
import { changeMatterInvoiceShowOnMatterSummary, createMatterInvoiceDocument, createMatterInvoiceReceipt, deleteMatterInvoice, getMatterActivityHistory, getMatterDisbursementsForInvoice, getMatterIncidentalExpensesForInvoice, getMatterInvoiceById, getMatterRecordableItemsForInvoice, markMatterInvoiceAsDraft } from "actions/matter";
import moment from "moment";
import { DateFormat } from "utils/constants";
import { MatterInvoiceModel } from "models/view/MatterInvoiceModel";
import { formatCurrency } from "utils/misc";
import { MatterInvoiceStatusesIds } from "enums/MatterInvoiceStatusesIds";
import useSlidingPanelActions from "actions/slidingPanel";
import useModalActions from "actions/modal";
import { ModalState } from "state/modalSlice";
import ViewMatterInvoicePayments from "../ViewMatterInvoicePayments/ViewMatterInvoicePayments";
import { useAppSelector } from "hooks/appSelector";
import EditMatterInvoiceForm from "../EditMatterInvoice/EditMatterInvoiceForm";
import store from "state/store";
import { GridIds } from "enums/GridIds";
import useGridActions from "actions/grid";
import PreviewMatterFile from "../../MatterFile/PreviewMatterFile/PreviewMatterFile";
import MatterInvoiceWriteOffForm from "../MatterInvoiceWriteOff/MatterInvoiceWriteOffForm";
import InvoiceStatusField from "components/Fields/InvoiceStatusField";
import BooleanField from "components/Fields/BooleanField";
import MatterInvoiceAbatementForm from "../MatterInvoiceAbatement/MatterInvoiceAbatementForm";
import { MatterRecordableItemModel } from "models/view/MatterRecordableItemModel";
import { MatterIncidentalExpenseModel } from "models/view/MatterIncidentalExpenseModel";
import { MatterDisbursementModel } from "models/view/MatterDisbursementModel";
import { MatterRecordableItemForInvoiceModel } from "models/view/MatterRecordableItemForInvoiceModel";
import currency from "currency.js";
import { MatterIncidentalExpenseForInvoiceModel } from "models/view/MatterIncidentalExpenseForInvoiceModel";
import ViewMatterInvoiceRecordableItems from "../ViewMatterInvoiceItemList/ViewMatterInvoiceRecordableItems";
import ViewMatterInvoiceIncidentalExpenses from "../ViewMatterInvoiceItemList/ViewMatterInvoiceIncidentalExpenses";
import ViewMatterInvoiceDisbursements from "../ViewMatterInvoiceItemList/ViewMatterInvoiceDisbursements";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import EditActivityHistoryTaxonomyTermsForm from "../../ActivityHistoryGrid/ActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTermsForm";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

type Props = {
  matterId: string,
  matterInvoiceId: string,
  onMarkAsDraftCallback?: Function,
  onDeleteCallback?: Function,
  onEditCallback?: Function,
  onWriteOffCallback?: Function,
  onAbatementCallback?: Function,
  onRecordPaymentCallback?: Function,
  showDocumentActions?: boolean,
  activityHistoryId?: string,
  isMatterClosed?: boolean,
  hasTaxonomies?: boolean,
  syncMatterFiles?: Function,
  showMoreButtons?: boolean,
}

function ViewMatterInvoice(props: Props){
  const [isLoading, setIsLoading] = useState(true);
  const [matterInvoice, setMatterInvoice] = useState<MatterInvoiceModel>();
  const [matterInvoiceRecordableItems, setMatterInvoiceRecordableItems] = useState<MatterRecordableItemForInvoiceModel[]>([]);
  const [matterInvoiceIncidentalExpenses, setMatterInvoiceIncidentalExpenses] = useState<MatterIncidentalExpenseForInvoiceModel[]>([]);
  const [matterInvoiceDisbursements, setMatterInvoiceDisbursements] = useState<MatterDisbursementModel[]>([]);
  const [genericErrors, setGenericErrors] = useState(null);
  const slidingPanelActions = useSlidingPanelActions();
  const modalActions = useModalActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const gridActions = useGridActions();
  const loggedInUser = useAppSelector((state) => state.user);

  useEffect(() => {
    loadMatterInvoice();
  }, [props.matterInvoiceId]);

  useEffect(() => {
    if (matterInvoice) {
      setSlidingPanelEditButton();
      setSlidingPanelMoreButtons();
    }
  }, [matterInvoice]);

  const loadMatterInvoice = () => {
    setIsLoading(true);

    const promises = [
      getMatterInvoiceById(props.matterId, props.matterInvoiceId),
      getMatterRecordableItemsForInvoice(props.matterId, props.matterInvoiceId),
      getMatterIncidentalExpensesForInvoice(props.matterId, props.matterInvoiceId),
      getMatterDisbursementsForInvoice(props.matterId, props.matterInvoiceId),
    ];

    Promise.all(promises)
      .then(([invoiceResponse, recordableItemsResponse, incidentalExpensesResponse, disbursementsResponse]) => {
        const currentVATRate = invoiceResponse.data.vatRate?.vatRateValue;

        setMatterInvoice(invoiceResponse.data);

        const recordableItemsForInvoice = recordableItemsResponse.data.map((x: MatterRecordableItemModel) => ({
          ...x,
          calculatedVatValue: x.calculatedVATValueOnInvoice ? x.calculatedVATValueOnInvoice : currency(x.amountCharged * currentVATRate / 100).value,
          adjustmentAmountCharged: x.adjustedAmountChargedOnInvoice ? currency(x.adjustedAmountChargedOnInvoice).subtract(x.amountCharged).value : 0
        }));
        setMatterInvoiceRecordableItems(recordableItemsForInvoice);

        const incidentalExpensesForInvoice = incidentalExpensesResponse.data.map((x: MatterIncidentalExpenseModel) => ({
          ...x,
          netValue: currency(x.netValue - (x.netValue / x.grossValue * (x.writeOffAmount ?? 0))).value,
          vatValue: x.vatValue ? currency(x.vatValue - (x.vatValue / x.grossValue * (x.writeOffAmount ?? 0))).value : undefined,
          originalVATValue: x.vatValue,
          calculatedVatValue: x.vatValueOnInvoice ? currency(x.vatValueOnInvoice - (x.vatValueOnInvoice / x.grossValue * (x.writeOffAmount ?? 0))).value
            : (x.vatValue ? 0 : currency((x.netValue - (x.writeOffAmount ?? 0)) * currentVATRate / 100).value)
        }));
        setMatterInvoiceIncidentalExpenses(incidentalExpensesForInvoice);

        const disbursementsForInvoice = disbursementsResponse.data.map((x: MatterDisbursementModel) => ({
          ...x,
          netValue: currency(x.netValue - (x.netValue / x.grossValue * (x.writeOffAmount ?? 0))).value,
          vatValue: x.vatValue ? currency(x.vatValue - (x.vatValue / x.grossValue * (x.writeOffAmount ?? 0))).value : undefined
        }));
        setMatterInvoiceDisbursements(disbursementsForInvoice);
      }).catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      }).finally(() => {
        setIsLoading(false);
      });
  }

  const setSlidingPanelEditButton = () => {
    if(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterInvoices) &&
      !matterInvoice?.matter?.isClosed && matterInvoice?.matterInvoiceStatusId === MatterInvoiceStatusesIds.DraftId) {
      slidingPanelActions.setOnEdit(() => {
        slidingPanelActions.clearSlidingPanel();
  
        slidingPanelActions.setSlidingPanel(
        {
          isShown: true,
          width: "70rem",
          title: "Edit Matter Invoice",
          children: <EditMatterInvoiceForm
            matterId={props.matterId}
            matterInvoiceId={props.matterInvoiceId}
            onSubmitCallback={() => {
              props.syncMatterFiles && props.syncMatterFiles();
              props.onEditCallback && props.onEditCallback();
              onChildPanelClose()
            }}
          />,
          onCancel: onChildPanelClose
        });
      });
    }
  }

  const setSlidingPanelMoreButtons = () => {
    if (!matterInvoice?.matter?.isClosed && props.showMoreButtons) {
      slidingPanelActions.setMoreButtons([
        ...((loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterInvoices) && 
          props.hasTaxonomies && props.activityHistoryId) ? [{
            type: ActionButtonTypes.EditTaxonomyTerms,
            callback: () => onClickTaxonomies(),
        }] : []),
        ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterInvoices) && 
          matterInvoice?.matterInvoiceStatusId === MatterInvoiceStatusesIds.AwaitingPaymentId ? [{
            type: ActionButtonTypes.MarkAsDraft,
            callback: () => onClickMarkAsDraft(),
        }] : []),
        ...(matterInvoice?.matterInvoiceStatusId !== MatterInvoiceStatusesIds.DraftId ? [
          {
            type: ActionButtonTypes.WriteOff,
            callback: () => onClickWriteOff(),
          },
          {
            type: ActionButtonTypes.Payment,
            callback: () => onClickRecordPayment(),
          },
        ] : []),
        ...(matterInvoice?.matterInvoiceStatusId === MatterInvoiceStatusesIds.AwaitingPaymentId ? [{
            type: ActionButtonTypes.Abate,
            callback: () => onClickAbatement(),
        }] : []),
        ...((loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterInvoices) && 
          props.activityHistoryId && !matterInvoice?.showOnMatterSummary) ? [{
            type: ActionButtonTypes.ShowOnMatterSummary,
            callback: () => showInvoiceOnMatterSummaryModal()
        }] : []),
        ...((loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterInvoices) && 
          props.activityHistoryId && matterInvoice?.showOnMatterSummary) ? [{
            type: ActionButtonTypes.HideFromFromMatterSummary,
            callback: () => hideInvoiceFromMatterSummaryModal()
        }] : []),
        ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterInvoices) ? [{
          type: ActionButtonTypes.Delete,
          callback: () => onClickDelete(),
        }] : [])
      ]);
    }
  }

  const onChildPanelClose = () => {
    slidingPanelActions.clearSlidingPanel();
    slidingPanelActions.setSlidingPanel({
      ...currentSlidingPanelState,
    });
  };

  const onClickTaxonomies = () => {
    slidingPanelActions.clearSlidingPanel();

    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Edit Taxonomy Terms",
        width: "70rem",
        children: <EditActivityHistoryTaxonomyTermsForm
          matterId={props.matterId}
          activityHistoryId={props.activityHistoryId!}
          onSubmitCallback={onChildPanelClose}
        />,
        onCancel: onChildPanelClose
      }
    );
  };

  const showInvoiceOnMatterSummaryModal = () => {
    let modal: ModalState = {
      title: "Show On Summary confirmation",
      body: "Are you sure you want to show Invoice on Summary?",
      actionText: "Show on Matter Summary",
      onAction: () => changeMatterInvoiceShowOnMatterSummaryCallback(),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  };

  const hideInvoiceFromMatterSummaryModal = () => {
    let modal: ModalState = {
      title: "Hide From Summary confirmation",
      body: "Are you sure you want to hide Invoice from Summary?",
      actionText: "Hide from Matter Summary",
      onAction: () => changeMatterInvoiceShowOnMatterSummaryCallback(),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  };

  const changeMatterInvoiceShowOnMatterSummaryCallback = () => {
    modalActions.toggleModalLoading();
    changeMatterInvoiceShowOnMatterSummary(props.matterId, props.matterInvoiceId).then(() => {
      setMatterInvoice({ ...matterInvoice!, showOnMatterSummary: !matterInvoice?.showOnMatterSummary });
      props.syncMatterFiles && props.syncMatterFiles();
      modalActions.toggleModalShownStatus();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
  };

  const onClickCreateDocument = (detailedDocument: boolean = false) => {
    setIsLoading(true);
    createMatterInvoiceDocument(props.matterId, props.matterInvoiceId, detailedDocument).then(() => {
      loadMatterInvoice();

      const gridState = store.getState().grid;
      if (gridState.id == `${GridIds.ActivityHistory}/${props.matterId}`) {
        getMatterActivityHistory(props.matterId).then((response2) => {
          gridActions.setGridRowData(response2.data);
        });
      }
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });  
  }

  const onClickCreateReceipt = () => {
    setIsLoading(true);
    createMatterInvoiceReceipt(props.matterId, props.matterInvoiceId).then(() => {
      loadMatterInvoice();

      const gridState = store.getState().grid;
      if (gridState.id == `${GridIds.ActivityHistory}/${props.matterId}`) {
        getMatterActivityHistory(props.matterId).then((response2) => {
          gridActions.setGridRowData(response2.data);
        });
      }
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });  
  }

  function onClickViewDocument(fileId: string) {
    //close View Matter Invoice sliding panel
    slidingPanelActions.clearSlidingPanel();
    //open Preview Matter File sliding panel having onCancel event to reopen 
    //Preview Matter File sliding panel
    slidingPanelActions.setSlidingPanel(
    {
      isShown: true,
      title: "Preview Matter File",
      children: <PreviewMatterFile
        matterId={props.matterId}
        fileId={fileId}
        activityHistoryId={props.activityHistoryId}
        isMatterClosed={props.isMatterClosed}
        hasTaxonomies={props.hasTaxonomies ?? false}
        syncMatterFiles={props.syncMatterFiles}
      />,
      width: "80rem",
      onCancel: onCancelOpenMatterInvoice
    });
  }

  function onClickMarkAsDraft() {
    setIsLoading(true);
    let modal: ModalState = {
      title: "Mark as Draft confirmation",
      body: "Are you sure you want to mark as Draft this Matter Invoice?",
      actionText: "Mark as Draft",
      onAction: () => markAsDraftCallback(props.matterId, props.matterInvoiceId),
      onClose: () => {setIsLoading(false);},
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();  
  }

  function markAsDraftCallback(matterId: string, matterInvoiceId: string) {
    modalActions.toggleModalLoading();
    markMatterInvoiceAsDraft(matterId, matterInvoiceId).then(() => {
      slidingPanelActions.clearSlidingPanel();
      modalActions.toggleModalShownStatus();

      props.onMarkAsDraftCallback && props.onMarkAsDraftCallback();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
      setIsLoading(false);
    });
  }

  function onClickDelete() {
    setIsLoading(true);
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this Matter Invoice?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallback(props.matterId, props.matterInvoiceId),
      onClose: () => {setIsLoading(false);},
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();  
  }

  const deleteCallback = (matterId: string, matterInvoiceId: string) => {
    modalActions.toggleModalLoading();
    deleteMatterInvoice(matterId, matterInvoiceId).then(() => {
      slidingPanelActions.clearSlidingPanel();
      modalActions.toggleModalShownStatus(); 

      props.onDeleteCallback && props.onDeleteCallback();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
      setIsLoading(false);
    });
  };

  function onClickRecordPayment() {
    //close View Matter Invoice sliding panel
    slidingPanelActions.clearSlidingPanel();
    //open View Matter Invoice Payments sliding panel having onCancel event to reopen 
    //View Matter Invoice Payments sliding panel
    slidingPanelActions.setSlidingPanel(
    {
      isShown: true,
      width: "70rem",
      title: "Invoice Payments",
      children: <ViewMatterInvoicePayments
          matterId={props.matterId}
          matterInvoiceId={props.matterInvoiceId}
        />,
      onCancel: onCancelMatterInvoicePaymnents
    });
  }

  function onCancelMatterInvoicePaymnents() {
    //open back View Matter Invoice sliding panel with onRecordPaymentCallback on onCancel event
    slidingPanelActions.setSlidingPanel(
    { 
      ...currentSlidingPanelState,
      onCancel: props.onRecordPaymentCallback
    });
  }

  function onCancelOpenMatterInvoice() {
    //open back View Matter Invoice sliding panel
    slidingPanelActions.setSlidingPanel(
    { 
      ...currentSlidingPanelState
    });
  }

  function onClickWriteOff() {
    //close View Matter Invoice sliding panel
    slidingPanelActions.clearSlidingPanel();
    //open View Matter Invoice WriteOff sliding panel having onCancel event to reopen 
    //View Matter Invoice sliding panel
    slidingPanelActions.setSlidingPanel(
    {
      isShown: true,
      width: "70rem",
      title: "Invoice Write-Off",
      children: <MatterInvoiceWriteOffForm
          matterId={props.matterId}
          matterInvoiceId={props.matterInvoiceId}
          onSubmitCallback={onSubmitMatterInvoiceWriteOff}
          onDeleteCallback={onSubmitMatterInvoiceWriteOff}
        />,
      onCancel: onCancelMatterInvoiceWriteOff
    });
  }

  function onSubmitMatterInvoiceWriteOff() {
    //open back View Matter Invoice sliding panel with onWriteOffCallback on onCancel event
    slidingPanelActions.setSlidingPanel(
    { 
      ...currentSlidingPanelState,
      onCancel: props.onWriteOffCallback
    });
  }

  function onCancelMatterInvoiceWriteOff() {
    //open back View Matter Invoice sliding panel
    slidingPanelActions.setSlidingPanel(
    { 
      ...currentSlidingPanelState
    });
  }

  function onClickAbatement() {
    //close View Matter Invoice sliding panel
    slidingPanelActions.clearSlidingPanel();
    //open View Matter Invoice Abatement sliding panel having onCancel event to reopen 
    //View Matter Invoice sliding panel
    slidingPanelActions.setSlidingPanel(
    {
      isShown: true,
      width: "70rem",
      title: "Invoice Abatement",
      children: <MatterInvoiceAbatementForm
          matterId={props.matterId}
          matterInvoiceId={props.matterInvoiceId}
          onSubmitCallback={onSubmitMatterInvoiceAbatement}
          onDeleteCallback={onSubmitMatterInvoiceAbatement}
        />,
      onCancel: onCancelMatterInvoiceAbatement
    });
  }

  function onSubmitMatterInvoiceAbatement() {
    //open back View Matter Invoice sliding panel with onAbatementCallback on onCancel event
    slidingPanelActions.setSlidingPanel(
    { 
      ...currentSlidingPanelState,
      onCancel: props.onAbatementCallback
    });
  }

  function onCancelMatterInvoiceAbatement() {
    //open back View Matter Invoice sliding panel
    slidingPanelActions.setSlidingPanel(
    { 
      ...currentSlidingPanelState
    });
  }

  return(
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <div className="d-flex flex-column h-100">
        <Row>
          <Col>
            <Field
              label={"Clients"}
              value={matterInvoice?.matterParticipatingEntities?.map(x => x.name)?.join(", ") ?? "—"}
            />
          </Col>
          <Col>
            <Field
              label={"Invoicing Party"}
              value={matterInvoice?.invoicingParty?.name}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              label={"Date"}
              value={matterInvoice?.date ? moment(matterInvoice?.date).format(DateFormat.Moment) : "—"}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              label={"Invoice Ref Number"}
              value={matterInvoice?.invoiceRefNumber}
            />
          </Col>
          <Col>
            <Field
              label={"Display Name"}
              value={matterInvoice?.displayName}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              label={"Description"}
              value={matterInvoice?.description}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            {matterInvoice?.matterInvoiceStatus?.name ?
              <InvoiceStatusField
                label={"Status"}
                value={matterInvoice?.matterInvoiceStatus}
              />
              :
              <Field
                label={"Status"}
                value={"—"}
              />
            }
          </Col>
          <Col>
            <Field
              label={"VAT Rate"}
              value={matterInvoice?.vatRate?.name}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              label={"Amount Paid"}
              value={formatCurrency(matterInvoice?.amountPaid)}
            />
          </Col>
          <Col>
            <Field
              label={"Amount Due"}
              value={formatCurrency(matterInvoice?.amountDue)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              label={"Due Date"}
              value={matterInvoice?.dueDate ? moment(matterInvoice?.dueDate).format(DateFormat.Moment) : "—"}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <BooleanField
              label={'Show On Matter Summary'}
              value={matterInvoice?.showOnMatterSummary ?? false}
            />
          </Col>
        </Row>

        {matterInvoice?.writeOffAmount &&
          <>
            <Row>
              <Col>
                <Field
                  label={"Write-Off Date"}
                  value={matterInvoice?.writeOffDate ? moment(matterInvoice?.writeOffDate).format(DateFormat.Moment) : "—"}
                />
              </Col>
              <Col>
                <Field
                  label={"Write-Off Amount"}
                  value={matterInvoice?.writeOffAmount ? formatCurrency(matterInvoice?.writeOffAmount) : "—"}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  label={"Write-Off Description"}
                  value={matterInvoice?.writeOffDescription}
                />
              </Col>
            </Row>
            {matterInvoice?.writeOffForSingleClient &&
              <Row>
                <Col>
                  <Field
                    label={"Write-Off Client"}
                    value={matterInvoice?.writeOffMatterParticipatingEntity?.name ?? "—"}
                  />
                </Col>
              </Row>
            }
          </>
        }

        <div className="lp-color-title primary full-width mt-4">Recordable Items</div>
        <ViewMatterInvoiceRecordableItems
          isLoading={isLoading}
          items={matterInvoiceRecordableItems}
        />

        <div className="lp-color-title primary full-width">Incidental Expenses</div>
        <ViewMatterInvoiceIncidentalExpenses
          isLoading={isLoading}
          items={matterInvoiceIncidentalExpenses}
        />

        <div className="lp-color-title primary full-width">Disbursements</div>
        <ViewMatterInvoiceDisbursements
          isLoading={isLoading}
          items={matterInvoiceDisbursements}
        />

        <div className="lp-color-title primary full-width">Total Values</div>
        <Row>
          <Col className="mb-4">
            <Field
              label={"Gross Value"}
              value={formatCurrency(matterInvoice?.totalGrossValue)}
            />
          </Col>
          <Col className="mb-4">
            <Field
              label={"VAT Value"}
              value={formatCurrency(matterInvoice?.totalVATValue)}
            />
          </Col>
          <Col className="mb-4">
            <Field
              label={"Net Value"}
              value={formatCurrency(matterInvoice?.totalNetValue)}
            />
          </Col>
        </Row>

        <div className="lp-color-title primary full-width">Invoice Values</div>
        <Row>
          <Col className="mb-4">
            <Field
              label={"Gross Value"}
              value={formatCurrency(matterInvoice?.invoicedTotalGrossValue)}
            />
          </Col>
          <Col className="mb-4">
            <Field
              label={"VAT Value"}
              value={formatCurrency(matterInvoice?.invoicedTotalVATValue)}
            />
          </Col>
          <Col className="mb-4">
            <Field
              label={"Net Value"}
              value={formatCurrency(matterInvoice?.invoicedTotalNetValue)}
            />
          </Col>
        </Row>

        <div className="lp-color-title primary full-width">Adjustments</div>
        <Row>
          <Col className="mb-4">
            <Field
              label={"Gross Value"}
              value={formatCurrency((matterInvoice?.invoicedTotalGrossValue ?? 0) - (matterInvoice?.totalGrossValue ?? 0))}
            />
          </Col>
          <Col as={Col} className="mb-4">
            <Field
              label={"VAT Value"}
              value={formatCurrency((matterInvoice?.invoicedTotalVATValue ?? 0) - (matterInvoice?.totalVATValue ?? 0))}
            />
          </Col>
          <Col as={Col} className="mb-4">
            <Field
              label={"Net Value"}
              value={formatCurrency((matterInvoice?.invoicedTotalNetValue ?? 0) - (matterInvoice?.totalNetValue ?? 0))}
            />
          </Col>
        </Row>

        {props.showDocumentActions && !matterInvoice?.matter?.isClosed &&
          <div className="lp-slide-panel-sticky-bottom">
            <Row>
              <Col className="d-flex flex-row-reverse justify-content-between">
                {matterInvoice?.detailedDocumentId ?
                  <Button onClick={() => onClickViewDocument(matterInvoice?.detailedDocumentId!)} variant="secondary-400">
                    {matterInvoice?.matterInvoiceStatusId != MatterInvoiceStatusesIds.DraftId ? "View detailed document" : "Preview detailed document"}
                  </Button>
                  :
                  <Button onClick={() => onClickCreateDocument(true)} variant="secondary-400">
                    Create detailed document
                  </Button>
                }
                {matterInvoice?.summaryDocumentId ?
                  <Button onClick={() => onClickViewDocument(matterInvoice?.summaryDocumentId!)} variant="secondary-400">
                    {matterInvoice?.matterInvoiceStatusId != MatterInvoiceStatusesIds.DraftId ? "View summary document" : "Preview summary document"}
                  </Button>
                  :
                  <Button onClick={() => onClickCreateDocument(false)} variant="secondary-400">
                    Create summary document
                  </Button>
                }
              </Col>
            </Row>
            {matterInvoice?.hasPayments &&
              <Row>
                <Col className="d-flex justify-content-between">
                  {matterInvoice?.latestReceiptDocumentId && matterInvoice?.allowNewReceiptCreation == false ?
                    <Button onClick={() => onClickViewDocument(matterInvoice?.latestReceiptDocumentId!)} variant="secondary-400">
                      View Latest Receipt
                    </Button>
                    :
                    <Button onClick={() => onClickCreateReceipt()} variant="secondary-400">
                      Create Receipt
                    </Button>
                  }
                </Col>
              </Row>
            }
          </div>
        }
      </div>
    </>
  );
}

export default ViewMatterInvoice;
