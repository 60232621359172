import { useEffect, useState } from "react";
import { CheckedStrategy } from "rc-tree-select/lib/utils/strategyUtil";
import TreeSelectForFilter from "components/TreeSelect";

type Props = {
  endpointCall: Function,
  showCheckedStrategy?: CheckedStrategy,
  value: any,
  onChange: Function,
  className?: string,
  classNamePrefix?: string,
  id?: string,
  dropdownMatchSelectWidth?: boolean,
  treeCheckStrictly?: boolean,
  treeCheckable?: boolean,
  treeDefaultExpandAll?: boolean,
  maxTagCount?: number | 'responsive',
  maxTagTextLength?: number
}

export default function CustomTreeSelect(props: Props) {
  const [currentValue, setCurrentValue] = useState<any>(props.value);
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    props.endpointCall().then((response: any) => {
      setData(response.data);
    })
    .catch((error: any) => {
    });
  }, []);

  useEffect(() => {
    setCurrentValue(props.value);
  }, [props.value]);

  return (
    <TreeSelectForFilter
      data={data}
      value={currentValue}
      onChange={props.onChange}
      showCheckedStrategy={props.showCheckedStrategy}
      id={props.id}
      className={props.className}
      classNamePrefix={props.classNamePrefix}
      dropdownMatchSelectWidth={props.dropdownMatchSelectWidth}
      treeCheckStrictly={props.treeCheckStrictly}
      treeCheckable={props.treeCheckable}
      treeDefaultExpandAll={props.treeDefaultExpandAll}
      maxTagCount={props.maxTagCount}
      maxTagTextLength={props.maxTagTextLength}
    />
  );
}