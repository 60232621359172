import { validateDelegatedLTEToLTE } from "actions/lte";
import { create, test, enforce } from "vest";

export const getValidationSuite = (lteId: string) => {
  return create((data = {}) => {
    test('toLTEId', 'Please select a Trading Entity', () => {
      enforce(data.toLTEId).isNotEmpty();
      enforce(data.toLTEId).isString();
    });

    test.memo('toLTEId', 'Trading Entity is already delegated', async () => {
      enforce((await validateDelegatedLTEToLTE(lteId, data.toLTEId)).data).isTruthy();
    }, [data.name]);
  });
}