import { validateParticipatingEntityForMatterParticipatingEntity, validateParticipatingEntityClientRole } from "actions/matter";
import { BailStatusIds } from "enums/BailStatusIds";
import { EntityRoleIds } from "enums/EntityRoleIds";
import { create, test, enforce, omitWhen, each } from "vest";
import isDate from "validator/lib/isDate";
import { MatterTypeIds } from "enums/MatterTypeIds";

export const getValidationSuite = (matterId: string, matterTypeId: string) => {
  return create((data = {}) => {
    enforce.extend({isDate});
    
    test('entityId', 'Please select an Entity', () => {
      enforce(data.entityId).isNotEmpty();
      enforce(data.entityId).isString();
    });

    test('entityId', 'Entity already on Matter', async () => {
      enforce((await validateParticipatingEntityForMatterParticipatingEntity(data.entityId, undefined, matterId)).data).isTruthy();
    });

    test('entityRoleId', 'Please select a Role', () => {
      enforce(data.entityRoleId).isNotEmpty();
      enforce(data.entityRoleId).isString();
    });

    test.memo('entityRoleId', 'There is already a Instructing Solicitor.', async () => {
      enforce((await validateParticipatingEntityClientRole(data.entityRoleId, undefined, matterId)).data).isTruthy();
    }, [data.entityRoleId]);

    omitWhen(!data.isInvoicingParty, () => {
      omitWhen(!data.invoiceDueDateSettingNumber, () => {
        test('invoiceDueDateSettingNumber', 'Number of days has to be at least 0', () => {
          enforce(data.invoiceDueDateSettingNumber).greaterThanOrEquals(0);
        });
      });

      omitWhen(!data.invoiceDueDateSettingNumber || data.invoiceDueDateSettingNumber < 0, () => {
        test('invoiceDueDateSettingTypeId', 'Please select a Type', () => {
          enforce(data.invoiceDueDateSettingTypeId).isNotEmpty();
          enforce(data.invoiceDueDateSettingTypeId).isString();
        });
      });
    });

    omitWhen(matterTypeId !== MatterTypeIds.CriminalLawActingForAccusedId ||
      (data.entityRoleId !== EntityRoleIds.LayClientId),
      () => {
        test('bailStatusId', 'Please select a Bail Status', () => {
          enforce(data.bailStatusId).isNotEmpty();
          enforce(data.bailStatusId).isString();
        });
        omitWhen(
          data.bailStatusId !== BailStatusIds.RemandedInCustodyId,
          () => {
            test('custodyTimeLimitExpiryDate', 'Custody Time Limit Expiry Date is required', () => {
              enforce(data.custodyTimeLimitExpiryDate).isDate({strictMode: false});
            });
        })
        omitWhen(
          data.bailStatusId !== BailStatusIds.ReleasedOnConditionalPoliceBailId &&
          data.bailStatusId !== BailStatusIds.RemandedOnConditionalBailId,
          () => {
            each(data.createMatterCriminalBailConditions, (field: any, index: number) => {
              test(`createMatterCriminalBailConditions.${index}.bailConditionId`, 'Please select a Bail Condition', () => {
                enforce(field.bailConditionId).isNotEmpty();
                enforce(field.bailConditionId).isString();
              });
          
              test(`createMatterCriminalBailConditions.${index}.additionalText`, 'Additional Text is required', () => {
                enforce(field.additionalText).isNotEmpty();
                enforce(field.additionalText).isString();
              });
            })
        })
      }
    );
  });
}