import useWindowSize from "hooks/windowSize";
import React, { useEffect, useState, useRef } from "react";
import { Accordion } from "react-bootstrap";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import Title from "components/Title";

type Props = {
  children: React.ReactNode
}

export default function SummaryBoxesSection(props: Props) {
  const [width] = useWindowSize();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [summaryBoxesNumber, setSummaryBoxesNumber] = useState(0);
  const rowRef = useRef<HTMLDivElement>(null);

  const calculateRowHeight = () => {
    if (rowRef.current) {
      const height = isExpanded ? `${rowRef.current.scrollHeight + 37}px` : '37px';
      document.documentElement.style.setProperty('--summary-row-height', height);
    }
  };
  const toggleSummaryBoxes = () => {
    setIsExpanded(!isExpanded);
  }

  useEffect(() => {
    calculateRowHeight();
  }, [isExpanded]);

  useEffect(() => {
    const summaryArea = document.querySelectorAll('.lp-summary-boxes-area')?.[0];
    if (summaryArea) {
      setSummaryBoxesNumber(summaryArea.children.length);
    }
    calculateRowHeight();
  }, []);

  return (
    <>
      {width < 1200 ?
        <Accordion>
          <Accordion.Item eventKey="0" className="lp-summary-boxes-accordion">
            <Accordion.Header>View statistics cards</Accordion.Header>
            <Accordion.Body>
              <div className={`lp-summary-boxes-area boxes-${summaryBoxesNumber}`}>
                {props.children}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      : 
        <>
          <Title type="section" title={"Overview"} className="lp-title boxes" onClick={toggleSummaryBoxes}>
            {isExpanded ? <MdExpandLess /> : <MdExpandMore />}
          </Title>
          <div className={`${isExpanded ? 'expanded' : 'collapsed'}`} ref={rowRef}>
            <div className={`lp-summary-boxes-area boxes-${summaryBoxesNumber}`}>
              {props.children}
            </div>
          </div>
        </>
      }
    </>
  );
}