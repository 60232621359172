import { Dropdown } from "react-bootstrap";
import { GridOptionTypes } from "enums/GridOptionTypes";
import { GridOptionButtonDataModel } from "../../../models/view/GridOptionButtonDataModel";

const GridOptions = (
  buttonData: GridOptionButtonDataModel
) => {
  switch (buttonData.type) {
    case GridOptionTypes.ExportSelectedCSV: {
      return <Dropdown.Item
        key={"0"}
        className={buttonData.selectedRows?.length == 0 ? "disabled" : ""}
        onClick={() => buttonData.callback!(true)}
      >Export (CSV)</Dropdown.Item>;
    }
    case GridOptionTypes.ExportSelectedExcel: {
      return <Dropdown.Item
        key={"1"}
        className={buttonData.selectedRows?.length == 0 ? "disabled" : ""}
        onClick={() => buttonData.callback!(true)}
      >Export (Excel)</Dropdown.Item>;
    }
    case GridOptionTypes.ExportAllCSV: {
      return <Dropdown.Item
        key={"2"}
        onClick={() => buttonData.callback!(false)}
      >Export All (CSV)</Dropdown.Item>;
    }
    case GridOptionTypes.ExportAllExcel: {
      return <Dropdown.Item
        key={"3"}
        onClick={() => buttonData.callback!(false)}
      >Export All (Excel)</Dropdown.Item>;
    }
    case GridOptionTypes.Download: {
      return <Dropdown.Item
        key={"4"}
        className={buttonData.selectedRows?.length == 0 ? "disabled" : ""}
        onClick={buttonData.callback != undefined ? () => buttonData.callback!(buttonData.selectedRows ?? []) : () => { }}
      >{buttonData.text ?? "Download"}</Dropdown.Item>;
    }
    case GridOptionTypes.Copy: {
      return <Dropdown.Item
        key={"5"}
        className={buttonData.selectedRows?.length == 0 ? "disabled" : ""}
        onClick={buttonData.callback != undefined ? () => buttonData.callback!(buttonData.selectedRows ?? []) : () => { }}
      >{buttonData.text ?? "Copy"}</Dropdown.Item>;
    }
    case GridOptionTypes.Move: {
      return <Dropdown.Item
        key={"6"}
        className={buttonData.selectedRows?.length == 0 ? "disabled" : ""}
        onClick={buttonData.callback != undefined ? () => buttonData.callback!(buttonData.selectedRows ?? []) : () => { }}
      >{buttonData.text ?? "Move"}</Dropdown.Item>;
    }
    case GridOptionTypes.Share: {
      return <Dropdown.Item
        key={"7"}
        className={buttonData.selectedRows?.length == 0 ? "disabled" : ""}
        onClick={buttonData.callback != undefined ? () => buttonData.callback!(buttonData.selectedRows ?? []) : () => { }}
      >{buttonData.text ?? "Share"}</Dropdown.Item>;
    }
    case GridOptionTypes.Merge: {
      return <Dropdown.Item
        key={"8"}
        className={buttonData.selectedRows?.length == 0 ? "disabled" : ""}
        onClick={buttonData.callback != undefined ? () => buttonData.callback!(buttonData.selectedRows ?? []) : () => { }}
      >{buttonData.text ?? "Merge"}</Dropdown.Item>;
    }
    case GridOptionTypes.Print: {
      return <Dropdown.Item
        key={"9"}
        className={buttonData.selectedRows?.length == 0 ? "disabled" : ""}
        onClick={buttonData.callback != undefined ? () => buttonData.callback!(buttonData.selectedRows ?? []) : () => { }}
      >{buttonData.text ?? "Print"}</Dropdown.Item>;
    }
    case GridOptionTypes.ExportActivityHistory: {
      return <Dropdown.Item
        key={"10"}
        onClick={buttonData.callback != undefined ? () => buttonData.callback!(buttonData.selectedRows ?? []) : () => { }}
      >{buttonData.text ?? "Export Activity History"}</Dropdown.Item>;
    }
    case GridOptionTypes.AddChargingRateToRecordableEventType: {
      return <Dropdown.Item
        key={"11"}
        className={buttonData.selectedRows?.length == 0 ? "disabled" : ""}
        onClick={buttonData.callback != undefined ? () => buttonData.callback!(buttonData.selectedRows ?? []) : () => { }}
      >{buttonData.text ?? "Add to Recordable Event Type"}</Dropdown.Item>;
    }
    case GridOptionTypes.SendFiles: {
      return <Dropdown.Item
        key={"12"}
        className={
          buttonData.selectedRows?.length === 0 ||
          buttonData.selectedRows?.every(row => row.activityType != 2)
            ? "disabled"
            : ""
        }
        onClick={buttonData.callback != undefined ? () => buttonData.callback!(buttonData.selectedRows ?? []) : () => { }}
      >{buttonData.text ?? "Send Files"}</Dropdown.Item>;
    }
    case GridOptionTypes.MakePayment: {
      return <Dropdown.Item
        key={"13"}
        className={buttonData.selectedRows?.length == 0 ? "disabled" : ""}
        onClick={buttonData.callback != undefined ? () => buttonData.callback!(buttonData.selectedRows ?? []) : () => { }}
      >{buttonData.text ?? "Make Payment"}</Dropdown.Item>;
    }
    case GridOptionTypes.ConvertToMail: {
      return <Dropdown.Item
        key={"14"}
        className={buttonData.selectedRows?.length == 0 ? "disabled" : ""}
        onClick={buttonData.callback != undefined ? () => buttonData.callback!(buttonData.selectedRows ?? []) : () => { }}
      >{buttonData.text ?? "Convert to Mail"}</Dropdown.Item>;
    }
    case GridOptionTypes.BulkAddTaxonomyTerms: {
      return <Dropdown.Item
        key={"15"}
        className={buttonData.selectedRows?.length == 0 ? "disabled" : ""}
        onClick={buttonData.callback != undefined ? () => buttonData.callback!(buttonData.selectedRows ?? []) : () => { }}
      >{buttonData.text ?? "Add Taxonomy Terms"}</Dropdown.Item>;
    }
    case GridOptionTypes.BulkDeleteTaxonomyTerms: {
      return <Dropdown.Item
        key={"16"}
        className={buttonData.selectedRows?.length == 0 ? "disabled" : ""}
        onClick={buttonData.callback != undefined ? () => buttonData.callback!(buttonData.selectedRows ?? []) : () => { }}
      >{buttonData.text ?? "Delete Taxonomy Terms"}</Dropdown.Item>;
    }
    default:
      return <></>;
  }
}

export default GridOptions;
