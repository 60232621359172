import { Form } from "react-bootstrap";
import Loader from "components/Loader";
import moment from "moment";
import { DateFormat } from "utils/constants";
import Field from "components/Fields/Field";
import { formatCurrency } from "utils/misc";
import { MatterIncidentalExpenseForInvoiceModel } from "models/view/MatterIncidentalExpenseForInvoiceModel";

type Props = {
  isLoading: boolean,
  items: MatterIncidentalExpenseForInvoiceModel[],
}

export default function ViewMatterInvoiceIncidentalExpenses(props: Props) {
  return (
    <Form.Group className="d-table">
      <div className="lp-box-list">
        {props.isLoading && <Loader inlineLoader />}

        {props.items.length > 0 &&
          props.items.map((x: MatterIncidentalExpenseForInvoiceModel) => (
            <div key={x.id} className={`lp-box-list-item read-only`}>
              <span className="type-icon date">
                {moment(x?.date).format(DateFormat.Moment) + (x.user ? ' - ' + x.user?.name : '')}
              </span>

              <div className="lp-box-list-full-row">
                <Field label={'Description'} value={x.displayName} />
              </div>

              <Field
                label={'Net Value'}
                value={formatCurrency(x.netValue)}
              />
              {x.originalVATValue &&
                <Field
                  label={'VAT Value'}
                  value={formatCurrency(x.vatValue)}
                />
              }
              {!x.originalVATValue &&
                <Field
                  label={'VAT Value'}
                  value={formatCurrency(x.calculatedVatValue)}
                  className="highlighted"
                />
              }
            </div>
          ))
        }
        {props.items.length === 0 && (
          <div className="lp-list-empty">No unbilled incidental expenses found</div>
        )}
      </div>
    </Form.Group>
  );
}
