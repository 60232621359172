import { get, post, put, del } from "utils/request";
import { DropDownOptionModel } from "models/view/DropDownOptionModel";
import { EntityModel } from "models/view/EntityModel";
import { CreateOrUpdateEntityModel } from "models/create/CreateOrUpdateEntityModel";
import { CreateOrUpdateEntityNoteModel } from "models/create/CreateOrUpdateEntityNoteModel";
import { EntityNoteModel } from "models/view/EntityNoteModel";
import { EntityEmailModel } from "models/view/EntityEmailModel";
import { CreateOrUpdateEntityEmailModel } from "models/create/CreateOrUpdateEntityEmailModel";
import { CreateOrUpdateEntityDocumentExchangeModel } from "models/create/CreateOrUpdateEntityDocumentExchangeModel";
import { EntityDocumentExchangeModel } from "models/view/EntityDocumentExchangeModel";
import { EntityPhoneNumberModel } from "models/view/EntityPhoneNumberModel";
import { CreateOrUpdateEntityPhoneNumberModel } from "models/create/CreateOrUpdateEntityPhoneNumberModel";
import { EntityAddressModel } from "models/view/EntityAddressModel";
import { CreateOrUpdateEntityAddressModel } from "models/create/CreateOrUpdateEntityAddressModel";
import { EntityPreferredContactModel } from "models/view/EntityPreferredContactModel";
import { LinkedEntitiesModel } from "models/view/LinkedEntitiesModel";
import { EntityLinkModel } from "models/view/EntityLinkModel";
import { CreateOrUpdateEntityLinkModel } from "models/create/CreateOrUpdateEntityLinkModel";
import { EntityTypeFilter } from "enums/EntityTypeFilter";
import { LinkedEntityModel } from "models/view/LinkedEntityModel";
import { LinkEntityEmailModel } from "models/create/LinkEntityEmailModel";
import { LinkEntityDocumentExchangeModel } from "models/create/LinkEntityDocumentExchangeModel";
import { LinkEntityPhoneNumberModel } from "models/create/LinkEntityPhoneNumberModel";
import { LinkEntityAddressModel } from "models/create/LinkEntityAddressModel";
import { BailConditionModel } from "models/view/BailConditionModel";
import { EntityLedgerEntryModel } from "models/view/EntityLedgerEntryModel";
import { EntityBankAccountModel } from "models/view/EntityBankAccountModel";
import { CreateOrUpdateEntityBankAccountModel } from "models/create/CreateOrUpdateEntityBankAccountModel";

const getEntities = async (): Promise<any> => {
  const response = await get<EntityModel[]>('/management/entity');
  return response;
}

const getEntityById = async (entityId: string, includeUserEntities?: boolean): Promise<any> => {
  let endpoint = `/management/entity/${entityId}`;
  if(includeUserEntities){
    endpoint += `?includeUserEntities=${includeUserEntities}`;
  }
  const response = await get<EntityModel>(endpoint);
  return response;
}

const addEntity = async (model: CreateOrUpdateEntityModel): Promise<any> => {
  const response = await post<CreateOrUpdateEntityModel, EntityModel>('/management/entity', model);
  return response;
}

const updateEntity = async (entityId: string, model: CreateOrUpdateEntityModel): Promise<any> => {
  var response = await put<CreateOrUpdateEntityModel, EntityModel>(`/management/entity/${entityId}`, model);
  return response;
}

const deleteEntity = async (entityId: string): Promise<any> => {
  await del(`/management/entity/${entityId}`);
}

const getEntityNotes = async (entityId: string): Promise<any> => {
  const response = await get<EntityNoteModel[]>(`/management/entity/${entityId}/note`);
  return response;
}

const getEntityNote = async (entityNoteId: string): Promise<any> => {
  const response = await get<EntityNoteModel>(`/management/entityNote/${entityNoteId}`);
  return response;
}

const addEntityNote = async (data: CreateOrUpdateEntityNoteModel): Promise<any> => {
  const response = await post<CreateOrUpdateEntityNoteModel, EntityNoteModel>(`/management/entityNote`, data);
  return response;
}

const updateEntityNote = async (entityNoteId: string, data: CreateOrUpdateEntityNoteModel): Promise<any> => {
  const response = await put<CreateOrUpdateEntityNoteModel, EntityNoteModel>(`/management/entityNote/${entityNoteId}`, data);
  return response;
}

const deleteEntityNote  = async (entityNoteId: string): Promise<any> => {
  const response = await del(`management/entityNote/${entityNoteId}`);
  return response;
}

const getEntityEmails = async (entityId: string): Promise<any> => {
  const response = await get<EntityEmailModel[]>(`/management/entity/${entityId}/email`);
  return response;
}

const getPreferredEntityEmail = async (entityId: string): Promise<any> => {
  const response = await get<EntityEmailModel | undefined>(`/management/entity/${entityId}/email/preferred`);
  return response;
}

const getEntityEmail = async (entityEmailId: string): Promise<any> => {
  const response = await get<EntityEmailModel>(`/management/entityEmail/${entityEmailId}`);
  return response;
}

const addEntityEmail = async (data: CreateOrUpdateEntityEmailModel): Promise<any> => {
  const response = await post<CreateOrUpdateEntityEmailModel, EntityEmailModel>(`/management/entityEmail`, data);
  return response;
}

const linkEntityEmail = async (entityId: string, data: LinkEntityEmailModel): Promise<any> => {
  const response = await post<LinkEntityEmailModel, EntityEmailModel>(`/management/entity/${entityId}/emailLink`, data);
  return response;
}

const updateEntityEmail = async (entityEmailId: string, data: CreateOrUpdateEntityEmailModel): Promise<any> => {
  const response = await put<CreateOrUpdateEntityEmailModel, EntityEmailModel>(`/management/entityEmail/${entityEmailId}`, data);
  return response;
}

const deleteEntityEmail  = async (entityEmailId: string): Promise<any> => {
  const response = await del(`management/entityEmail/${entityEmailId}`);
  return response;
}

const getEntityDocumentExchanges = async (entityId: string): Promise<any> => {
  const response = await get<EntityDocumentExchangeModel[]>(`/management/entity/${entityId}/documentExchange`);
  return response;
}

const getPreferredEntityDocumentExchange = async (entityId: string): Promise<any> => {
  const response = await get<EntityDocumentExchangeModel | undefined>(`/management/entity/${entityId}/documentExchange/preferred`);
  return response;
}

const getEntityDocumentExchange = async (entityDocumentExchangeId: string): Promise<any> => {
  const response = await get<EntityDocumentExchangeModel>(`/management/entityDocumentExchange/${entityDocumentExchangeId}`);
  return response;
}

const addEntityDocumentExchange = async (data: CreateOrUpdateEntityDocumentExchangeModel): Promise<any> => {
  const response = await post<CreateOrUpdateEntityDocumentExchangeModel, EntityDocumentExchangeModel>(`/management/entityDocumentExchange`, data);
  return response;
}

const linkEntityDocumentExchange = async (entityId: string, data: LinkEntityDocumentExchangeModel): Promise<any> => {
  const response = await post<LinkEntityDocumentExchangeModel, EntityDocumentExchangeModel>(`/management/entity/${entityId}/documentExchangeLink`, data);
  return response;
}

const updateEntityDocumentExchange = async (entityDocumentExchangeId: string, data: CreateOrUpdateEntityDocumentExchangeModel): Promise<any> => {
  const response = await put<CreateOrUpdateEntityDocumentExchangeModel, EntityDocumentExchangeModel>(`/management/entityDocumentExchange/${entityDocumentExchangeId}`, data);
  return response;
}

const deleteEntityDocumentExchange  = async (entityDocumentExchangeId: string): Promise<any> => {
  const response = await del(`management/entityDocumentExchange/${entityDocumentExchangeId}`);
  return response;
}

const getEntityPhoneNumbers = async (entityId: string): Promise<any> => {
  const response = await get<EntityPhoneNumberModel[]>(`/management/entity/${entityId}/phoneNumber`);
  return response;
}

const getPreferredEntityPhoneNumber = async (entityId: string): Promise<any> => {
  const response = await get<EntityPhoneNumberModel | undefined>(`/management/entity/${entityId}/phoneNumber/preferred`);
  return response;
}

const getEntityPhoneNumberById = async (entityId: string, phoneNumberId: string): Promise<any> => {
  const response = await get<EntityPhoneNumberModel>(`/management/entity/${entityId}/phoneNumber/${phoneNumberId}`);
  return response;
}

const addEntityPhoneNumber = async (entityId: string, data: CreateOrUpdateEntityPhoneNumberModel): Promise<any> => {
  const response = await post<CreateOrUpdateEntityPhoneNumberModel, EntityPhoneNumberModel>(`/management/entity/${entityId}/phoneNumber`, data);
  return response;
}

const linkEntityPhoneNumber = async (entityId: string, data: LinkEntityPhoneNumberModel): Promise<any> => {
  const response = await post<LinkEntityPhoneNumberModel, EntityPhoneNumberModel>(`/management/entity/${entityId}/phoneNumberLink`, data);
  return response;
}

const updateEntityPhoneNumber = async (phoneNumberId: string, entityId: string, data: CreateOrUpdateEntityPhoneNumberModel): Promise<any> => {
  const response = await put<CreateOrUpdateEntityPhoneNumberModel, EntityPhoneNumberModel>(`/management/entity/${entityId}/phoneNumber/${phoneNumberId}`, data);
  return response;
}

const deleteEntityPhoneNumber = async (entityId: string, phoneNumberId: string, shouldDeleteAllEntities: boolean): Promise<any> => {
  const response = await del(`management/entity/${entityId}/phoneNumber/${phoneNumberId}?shouldDeleteAllEntities=${shouldDeleteAllEntities}`);
  return response;
}

const getEntityAddresses = async (entityId: string): Promise<any> => {
  const response = await get<EntityAddressModel[]>(`/management/entity/${entityId}/address`);
  return response;
}

const getPreferredEntityAddress = async (entityId: string): Promise<any> => {
  const response = await get<EntityAddressModel | undefined>(`/management/entity/${entityId}/address/preferred`);
  return response;
}

const getEntityAddressById = async (entityId: string, addressId: string): Promise<any> => {
  const response = await get<EntityAddressModel>(`/management/entity/${entityId}/address/${addressId}`);
  return response;
}

const addEntityAddress = async (entityId: string, data: CreateOrUpdateEntityAddressModel): Promise<any> => {
  const response = await post<CreateOrUpdateEntityAddressModel, EntityAddressModel>(`/management/entity/${entityId}/address`, data);
  return response;
}

const linkEntityAddress = async (entityId: string, data: LinkEntityAddressModel): Promise<any> => {
  const response = await post<LinkEntityAddressModel, EntityPhoneNumberModel>(`/management/entity/${entityId}/addressLink`, data);
  return response;
}

const updateEntityAddress = async (addressId: string, entityId: string, data: CreateOrUpdateEntityAddressModel): Promise<any> => {
  const response = await put<CreateOrUpdateEntityAddressModel, EntityAddressModel>(`/management/entity/${entityId}/address/${addressId}`, data);
  return response;
}

const deleteEntityAddress = async (entityId: string, addressId: string, shouldDeleteAllEntities: boolean): Promise<any> => {
  const response = await del(`management/entity/${entityId}/address/${addressId}?shouldDeleteAllEntities=${shouldDeleteAllEntities}`);
  return response;
}

const getEntityLinks = async (entityId: string): Promise<any> => {
  const response = await get<LinkedEntitiesModel>(`/management/entity/${entityId}/link`);
  return response;
}

const getOwnedByEntityLinks = async (entityId: string): Promise<any> => {
  const response = await get<LinkedEntityModel[]>(`/management/entity/${entityId}/ownedByLink`);
  return response;
}

const getOwnsEntityLinks = async (entityId: string): Promise<any> => {
  const response = await get<LinkedEntityModel[]>(`/management/entity/${entityId}/ownsLink`);
  return response;
}

const getEntityLink = async (entityLinkId: string): Promise<any> => {
  const response = await get<EntityLinkModel>(`/management/entityLink/${entityLinkId}`);
  return response;
}

const addEntityLink = async (data: CreateOrUpdateEntityLinkModel): Promise<any> => {
  const response = await post<CreateOrUpdateEntityLinkModel, EntityLinkModel>(`/management/entityLink`, data);
  return response;
}

const deleteEntityLink  = async (entityLinkId: string): Promise<any> => {
  const response = await del(`management/entityLink/${entityLinkId}`);
  return response;
}

const getDisabilityStatuses = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/disabilityStatus/summary');
  return response;
}

const getBailStatuses = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/bailStatus/summary');
  return response;
}

const getBailConditions = async (): Promise<any> => {
  const response = await get<BailConditionModel[]>('/management/bailCondition');
  return response;
}

const getEntityTypes = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/entityType/summary');
  return response;
}

const getEthnicities = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/ethnicity/summary');
  return response;
}

const getGenders = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/gender/summary');
  return response;
}

const getGenderPronouns = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/genderPronoun/summary');
  return response;
}

const getLanguages = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/language/summary');
  return response;
}

const getMaritalStatuses = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/maritalStatus/summary');
  return response;
}

const getSexes = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/sex/summary');
  return response;
}

const getSexualOrientations = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/sexualOrientation/summary');
  return response;
}

const getSuffixes = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/suffix/summary');
  return response;
}

const getPrefixes = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/prefix/summary');
  return response;
}

const getEmailTypes = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/emailType/summary');
  return response;
}

const getPreferredContacts = async (entityId: string): Promise<any> => {
  const response = await get<EntityPreferredContactModel>(`/management/entity/${entityId}/preferredContacts`);
  return response;
}

const getEntitiesSummary = async (typeFilter?: EntityTypeFilter, includeUserEntities: boolean = false): Promise<any> => {
  let endpoint = `/management/entity/summary?includeUserEntities=${includeUserEntities}`;
  if(typeFilter != undefined){
    endpoint += `&typeFilter=${typeFilter}`;
  }
  const response = await get<DropDownOptionModel[]>(endpoint);
  return response;
}

const getEntityLedgerEntries = async (entityId: string): Promise<any> => {
  const response = await get<EntityLedgerEntryModel[]>(`/management/entity/${entityId}/ledger`);
  return response;
}

const getEntityBankAccounts = async (entityId: string): Promise<any> => {
  const response = await get<EntityBankAccountModel[]>(`/management/entity/${entityId}/bankAccount`);
  return response;
}

const getEntityBankAccountById = async (entityId: string, bankAccountId: string): Promise<any> => {
  const response = await get<EntityBankAccountModel>(`/management/entity/${entityId}/bankAccount/${bankAccountId}`);
  return response;
}

const getPreferredEntityBankAccount = async (entityId: string): Promise<any> => {
  const response = await get<EntityBankAccountModel | undefined>(`/management/entity/${entityId}/preferredBankAccount`);
  return response;
}

const addEntityBankAccount = async (entityId: string, data: CreateOrUpdateEntityBankAccountModel): Promise<any> => {
  const response = await post<CreateOrUpdateEntityBankAccountModel, EntityBankAccountModel>(`/management/entity/${entityId}/bankAccount`, data);
  return response;
}

const updateEntityBankAccount = async (entityId: string, bankAccountId: string, data: CreateOrUpdateEntityBankAccountModel): Promise<any> => {
  const response = await put<CreateOrUpdateEntityBankAccountModel, EntityBankAccountModel>(`/management/entity/${entityId}/bankAccount/${bankAccountId}`, data);
  return response;
}

const deleteEntityBankAccount = async (entityId: string, bankAccountId: string): Promise<any> => {
  const response = await del(`management/entity/${entityId}/bankAccount/${bankAccountId}`);
  return response;
}

export {
  getEntities,
  getEntityById,
  addEntity,
  updateEntity,
  deleteEntity,

  getEntityNotes,
  getEntityNote,
  addEntityNote,
  updateEntityNote,
  deleteEntityNote,

  getEntityEmails,
  getPreferredEntityEmail,
  getEntityEmail,
  addEntityEmail,
  linkEntityEmail,
  updateEntityEmail,
  deleteEntityEmail,

  getEntityDocumentExchanges,
  getPreferredEntityDocumentExchange,
  getEntityDocumentExchange,
  addEntityDocumentExchange,
  linkEntityDocumentExchange,
  updateEntityDocumentExchange,
  deleteEntityDocumentExchange,

  getEntityPhoneNumbers,
  getPreferredEntityPhoneNumber,
  getEntityPhoneNumberById,
  addEntityPhoneNumber,
  linkEntityPhoneNumber,
  updateEntityPhoneNumber,
  deleteEntityPhoneNumber,

  getEntityAddresses,
  getPreferredEntityAddress,
  getEntityAddressById,
  addEntityAddress,
  linkEntityAddress,
  updateEntityAddress,
  deleteEntityAddress,

  getEntityLinks,
  getOwnedByEntityLinks,
  getOwnsEntityLinks,
  getEntityLink,
  addEntityLink,
  deleteEntityLink,

  getDisabilityStatuses,
  getEntityTypes,
  getEthnicities,
  getGenders,
  getGenderPronouns,
  getLanguages,
  getMaritalStatuses,
  getSexes,
  getSexualOrientations,
  getSuffixes,
  getPrefixes,
  getEmailTypes,
  getPreferredContacts,
  getEntitiesSummary,
  getBailStatuses,
  getBailConditions,

  getEntityBankAccounts,
  getEntityBankAccountById,
  getPreferredEntityBankAccount,
  addEntityBankAccount,
  updateEntityBankAccount,
  deleteEntityBankAccount,

  getEntityLedgerEntries
};
