import { validateDelegatedUserUser } from "actions/user";
import { create, test, enforce } from "vest";

export const getValidationSuite = (delegatedLTEId: string) => {
  return create((data = {}) => {
    test('userId', 'Please select a User', () => {
      enforce(data.userId).isNotEmpty();
      enforce(data.userId).isString();
    });

    test.memo('userId', 'User is already delegated', async () => {
      enforce((await validateDelegatedUserUser(delegatedLTEId, data.userId)).data).isTruthy();
    }, [data.name]);
  });
}