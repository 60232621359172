import { useEffect, useState } from "react";
import Loader from "components/Loader/index";
import Field from "components/Fields/Field";
import { Row, Col } from "react-bootstrap";
import { ChargingRateModel } from "models/view/ChargingRateModel";
import { deleteChargingRate, getChargingRate } from "actions/chargingSchemes";
import { formatCurrency } from "utils/misc";
import UpdateChargingRate from "../UpdateChargingRate/UpdateChargingRate";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { ModalState } from "state/modalSlice";
import useSlidingPanelActions from "actions/slidingPanel";
import { useAppSelector } from "hooks/appSelector";
import useGridActions from "actions/grid";
import useModalActions from "actions/modal";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

type Props = {
  chargingRateId: string
}

export default function ViewChargingRate(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [chargingRate, setChargingRate] = useState<ChargingRateModel | undefined>(undefined);
  const [loading, setIsLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const modalActions = useModalActions();
  const gridActions = useGridActions();
  const currentGridState = useAppSelector((state) => state.grid);
  const loggedInUser = useAppSelector((state) => state.user);

  useEffect(() => {
    setIsLoading(true);
    getChargingRate(props.chargingRateId!).then((response) => { 
      setChargingRate(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });

    // Set edit button action
    if(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageChargingSchemes)) {
      slidingPanelActions.setOnEdit(() => {
        slidingPanelActions.clearSlidingPanel();

        slidingPanelActions.setSlidingPanel(
        {
          isShown: true,
          title: "Edit Charge Rate",
          children: <UpdateChargingRate
            chargingRateId={props.chargingRateId}
            onSubmitCallback={onChildPanelClose}
          />,
          onCancel: onChildPanelClose
        });
      });
    }

    if(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageChargingSchemes)) {
      slidingPanelActions.setMoreButtons([{
          type: ActionButtonTypes.Delete,
          callback: () => deleteModal(),
      }]);
    }
  }, [props.chargingRateId]);

  const onChildPanelClose = () => {
    slidingPanelActions.clearSlidingPanel();
    slidingPanelActions.setSlidingPanel({
      ...currentSlidingPanelState,
    });
  };

  const deleteModal = () => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this Charge Rate?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallback(props.chargingRateId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  };
  
  const deleteCallback = (id: string) => {
    const gridState = currentGridState;
    modalActions.toggleModalLoading();
    deleteChargingRate(id).then(() => {
      gridActions.setGridRowData(gridState.rowData.filter((x: any) => x.id !== id));
      modalActions.toggleModalShownStatus();
      slidingPanelActions.clearSlidingPanel();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
  };

  return (
    <>
      {loading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col>
          <Field
            label={"Activity"}
            value={chargingRate?.activity?.name}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Fee Earner Level"}
            value={chargingRate?.feeEarnerLevel?.name}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Charging Basis"}
            value={chargingRate?.chargingBasis?.name}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Charged Rate"}
            value={chargingRate?.chargeRate ? formatCurrency(chargingRate?.chargeRate) : "—"}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Minutes per Unit"}
            value={chargingRate?.minutesPerUnit}
          />
        </Col>
      </Row>
    </>
  );
}
