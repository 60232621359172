import React from "react";
import Loader from "components/Loader/index";
import IconButtonGrid from "components/Buttons/IconButtonGrid";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { Card } from "react-bootstrap";
import './style.scss';
import { DelegatedLTEModel } from "models/view/DelegatedLTEModel";
import BooleanFieldValue from "components/Fields/BooleanFieldValue";

type Props = {
  listItems: DelegatedLTEModel[],
  listButtons: Array<{type: ActionButtonTypes, callback?: Function}>,
  loadState?: boolean
}

export default function ListDelegatedLTEs(props: Props) {
  return (
    <Card className="with-list delegate-list">
      <Card.Header>
        <div className="lp-list-grid-item-name">{"Trading Entity"}</div>
        <div className="lp-list-grid-item-type">{"Manage Diary"}</div>
        <div className="lp-list-grid-item-type">{"Delegate Access"}</div>
        {props.listButtons.length != 0 && <div className="lp-list-grid-item-actions"></div>}
      </Card.Header>
      {props.loadState && <Loader inlineLoader={props.loadState} />}
      <Card.Body>
        <ul className="lp-list-grid">
          {props.listItems.length == 0 &&
            <div className="lp-empty list">
              <div className="image"></div>
              <div className="text">No rows found!</div>
            </div>
          }

          {props.listItems.map((x: DelegatedLTEModel, index: number) => {
            return (
              <React.Fragment key={x.id + index.toString()}>
                <li>
                  <div className="lp-list-grid-item-name">
                    {x.toLTE?.name}
                  </div>
                  <div className="lp-list-grid-item-type">
                    <BooleanFieldValue value={x.managesDiary} />
                  </div>
                  <div className="lp-list-grid-item-type">
                    <BooleanFieldValue value={x.delegatesAccess} />
                  </div>
                  {props.listButtons.length != 0 && 
                    <div className="lp-list-grid-item-actions">
                      {
                        props.listButtons.map((el: {type: ActionButtonTypes, callback?: Function}, index: number) => 
                          IconButtonGrid({...el, callback: () => {
                            el.callback && el.callback(x.id)
                          }, id: x.id + index.toString()})
                        )
                      }
                    </div>
                  }
                </li>
              </React.Fragment>
            )
          })}
        </ul>
      </Card.Body>
    </Card>
  );
}
