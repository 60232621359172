import { Controller, useForm } from "react-hook-form";
import React, { useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { CreateOrUpdateLawPageTradingEntityModel } from "models/create/CreateOrUpdateLawPageTradingEntityModel";
import { useNavigate } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';
import { addLte, getLteTypes } from "actions/lte";
import { getRegulators } from "actions/user";
import useSlidingPanelActions from "actions/slidingPanel";
import { removeEmptyFields } from "utils/form";
import Loader from "components/Loader";
import CustomSelect from "components/Select/Select";
import { validationSuite } from "./validationSuite";
import { CompanyLteTypes } from "utils/constants";
import { RegulatorTypeIds } from "enums/RegulatorTypeIds";

export default function LawPageTradingEntityCreateForm() {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const slidingPanelActions = useSlidingPanelActions();

  const {register, reset, control, watch, setValue, trigger, handleSubmit, formState: {errors}} = useForm<CreateOrUpdateLawPageTradingEntityModel>({
    resolver: vestResolver(validationSuite)
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateOrUpdateLawPageTradingEntityModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    addLte(data).then((response) => {
      navigate(`/lte/${response.data.id}`);
      slidingPanelActions.clearSlidingPanel();
      reset();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const onBlurLegalName = () => {
    if(watch("legalName") == '' || watch("legalName") == undefined) {
      return;
    }
    if (watch("tradingName") == '' || watch("tradingName") == undefined) {
      setValue('tradingName', watch("legalName"));
      trigger('tradingName');
    }
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Form.Group className="mb-4" controlId="legalName">
          <Form.Label className="required">Legal Name</Form.Label>
          <Form.Control
            type="text"
            className={`${errors?.legalName?.message ? 'invalid' : ''}`}
            {...register("legalName", {shouldUnregister: true})}
            onBlur={onBlurLegalName}
          />
          <Form.Text className="lp-error">
            {errors?.legalName?.message && (errors.legalName.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="tradingName">
          <Form.Label className="required">Trading Name</Form.Label>
          <Form.Control
            type="text"
            className={`${errors?.tradingName?.message ? 'invalid' : ''}`}
            {...register("tradingName", {shouldUnregister: true})}
          />
          <Form.Text className="lp-error">
            {errors?.tradingName?.message && (errors.tradingName.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="lawPageTradingEntityTypeId">
          <Form.Label className="required">Type</Form.Label>
          <Controller
            control={control}
            name="lawPageTradingEntityTypeId"
            shouldUnregister={true}
            render={({field: { onChange, value, name, ref }}) => (
              <CustomSelect
                id="lawPageTradingEntityTypeId"
                inputRef={ref}
                className={`lp-select${errors?.lawPageTradingEntityTypeId?.message ? ' invalid' : ''}`}
                endpointCall={getLteTypes}
                value={value}
                onChange={val => onChange(val?.id ?? null)}
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.lawPageTradingEntityTypeId?.message && (errors.lawPageTradingEntityTypeId.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="code">
          <Form.Label className="required">Code</Form.Label>
          <Form.Control
            type="text"
            className={`${errors?.code?.message ? 'invalid' : ''}`}
            {...register("code", {shouldUnregister: true})}
          />
          <Form.Text className="lp-error">
            {errors?.code?.message && (errors.code.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="companyRegistrationNo">
          <Form.Label
            className={`${CompanyLteTypes.includes(watch('lawPageTradingEntityTypeId')) ? "required" : ""}`}>
            Company Registration No.
          </Form.Label>
          <Form.Control
            type="text"
            className={`${errors?.companyRegistrationNo?.message ? 'invalid' : ''}`}
            {...register("companyRegistrationNo", {shouldUnregister: true})}
          />
          <Form.Text className="lp-error">
            {errors?.companyRegistrationNo?.message && (errors.companyRegistrationNo.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="regulatorId">
          <Form.Label className="required">Regulator</Form.Label>
          <Controller
            control={control}
            name="regulatorId"
            shouldUnregister={true}
            render={({field: { onChange, value, name, ref }}) => (
              <CustomSelect
                id="regulatorId"
                inputRef={ref}
                className={`lp-select${errors?.regulatorId?.message ? ' invalid' : ''}`}
                endpointCall={getRegulators}
                value={value}
                onChange={val => onChange(val?.id ?? null)}
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.regulatorId?.message && (errors.regulatorId.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="regulatorReference">
          <Form.Label 
            className={`${(watch("regulatorId") && watch("regulatorId") != RegulatorTypeIds.UnregulatedId)? " required" : ""}`}
          >
            Regulator Registration Reference
          </Form.Label>
          <Form.Control
            type="text"
            className={`${errors?.regulatorReference?.message ? 'invalid' : ''}`}
            {...register("regulatorReference", {shouldUnregister: true})}
          />
          <Form.Text className="lp-error">
            {errors?.regulatorReference?.message && (errors.regulatorReference.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="vatRegistrationNo">
          <Form.Label>VAT Registration No.</Form.Label>
          <Form.Control
            type="text"
            {...register("vatRegistrationNo", {shouldUnregister: true})}
          />
        </Form.Group>

        <Form.Group className="mb-4" controlId="entityUrl">
          <Form.Label>Entity URL</Form.Label>
          <Form.Control
            type="text"
            className={`${errors?.entityUrl?.message ? 'invalid' : ''}`}
            {...register("entityUrl", {shouldUnregister: true})}
          />
          <Form.Text className="lp-error">
            {errors?.entityUrl?.message && (errors.entityUrl.message)}
          </Form.Text>
        </Form.Group>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <Button variant="success" type="submit">Create</Button>
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
