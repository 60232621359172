import { ColDef } from "ag-grid-community";
import { EntityModel } from "models/view/EntityModel";
import store from "state/store";
import { deleteEntity, getEntityTypes } from "actions/entity";
import { NavigateFunction } from "react-router-dom";
import { setRowData } from "state/gridSlice";
import { ModalState, setModalData, setModalErrors, toggleModal, toggleModalLoadingState } from "state/modalSlice";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { DropdownFilter } from "components/Grid/GridFilters/Filters/DropdownFilter";
import { DropdownFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/DropdownFloatingFilter";
import { getEntityRolesSummary } from "actions/settings";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

const viewCallback = (id: string, navigate: NavigateFunction) => {
  navigate(`/addressBook/${id}`);
};

const deleteModal = (id: string) => {
  let modal: ModalState = {
    title: "Delete confirmation",
    body: "Are you sure you want to delete this entity?",
    actionText: "Delete",
    actionVariant: "danger",
    onAction: () => deleteCallback(id),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
};

const deleteCallback = (id: string) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  deleteEntity(id).then(() => {
    store.dispatch(setRowData(gridState.rowData.filter((x: any) => x.id !== id)));
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

export const getColumnDefs = () => {
  const loggedInUser = store.getState().user;
  const buttonsColumnWidth = loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageEntities) ? 105 : 65;
  
  const columnDefs: (ColDef<EntityModel> | {excludeFromExport: boolean})[] = [
    {
      headerName: 'Display Name',
      field: 'displayName',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Type',
      field: 'entityType.name',
      filter: DropdownFilter,
      filterParams: {
        property: 'entityTypeId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getEntityTypes
      },
      minWidth: 220
    },
    {
      headerName: 'Default Role',
      field: 'defaultRole.name',
      filter: DropdownFilter,
      filterParams: {
        property: 'defaultRoleId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getEntityRolesSummary
      },
      minWidth: 220
    },
    {
      cellClass: 'lp-actions-cell',
      suppressHeaderMenuButton: true,
      suppressColumnsToolPanel: true,
      cellRenderer: ActionsCellRenderer,
      cellRendererParams: {
        buttonsData: [
          {
            type: ActionButtonTypes.View,
            callback: (id: string, navigate: NavigateFunction) => viewCallback(id, navigate)
          },
          ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageEntities) ? [{
            type: ActionButtonTypes.Delete,
            callback: (id: string) => deleteModal(id)
          }] : [])
        ]
      },
      excludeFromExport: true,
      suppressSizeToFit: true,
      floatingFilter: false,
      sortable: false,
      minWidth: buttonsColumnWidth,
      maxWidth: buttonsColumnWidth,
      width: buttonsColumnWidth,
      suppressMovable: true,
      pinned: 'right',
      resizable: false
    }
  ];
  return columnDefs;
}
