import { useState } from 'react';
import { useDetectClickOutside } from "react-detect-click-outside";
import { FiLogOut } from 'react-icons/fi';
import { MdAccountCircle, MdManageAccounts, MdError, MdSync, MdSwitchAccount } from 'react-icons/md';
import { syncEmails, useUserActions } from 'actions/user';
import { useAppSelector } from 'hooks/appSelector';
import { NavLink } from 'react-router-dom';
import './style.scss';
import { SimpleUserModel } from 'models/view/SimpleUserModel';
import { getUserNameInitials, getUserName } from 'utils/user';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import useNotificationActions from 'actions/notification';
import { NotificationTypes } from 'enums/NotificationTypes';
import { HiIdentification } from 'react-icons/hi';
import { UserPermissionsNames } from 'enums/UserPermissionsNames';
import ChangeLTEModal from 'components/Modal/ChangeLTEModal';

function UserMenu() {
  const [isAccountOpened, setAccountOpened] = useState(false);
  const userActions = useUserActions();
  const [genericErrors, setGenericErrors] = useState(null);
  const user = useAppSelector((state) => state.user);
  const notificationActions = useNotificationActions();
  const [showChangeLTEModal, setShowChangeLTEModal] = useState<boolean>(false);

  const synchronizeEmails = () => {
    notificationActions.addNotificationMessage(
      {
        type: NotificationTypes.Success,
        title: "Email Sync Started",
        body: "Emails for your User are being synced.",
        isDismissable: true
      }
    );
    syncEmails(user.apiToken, user.msalAccessToken).then((response) => {
      if(!response.ok) {
        //turn ReadableStream into JSON 
        response.json().then((data: any) => {
          notificationActions.addNotificationMessage(
            {
              type: NotificationTypes.Error,
              title: "Email Sync Error",
              body: data.Message ?? "Emails for your user have not been synced due to an error.",
              isDismissable: true
            }
          );
        });
        return;
      }
      notificationActions.addNotificationMessage(
        {
          type: NotificationTypes.Success,
          title: "Email Sync Successful",
          body: "Emails for your User have been synced successfully.",
          isDismissable: true
        }
      );
    })
    .catch((error) => {
      notificationActions.addNotificationMessage(
        {
          type: NotificationTypes.Error,
          title: "Email Sync Error",
          body: "Emails for your User have not been synced due to an error.",
          isDismissable: true
        }
      );
    });
  };

  const toggleUserContainer = (e: any) => {
    e.preventDefault();
    setAccountOpened(!isAccountOpened);
  };

  const getInitials = () => {
    const simpleUserModel: SimpleUserModel | undefined = user ? {
      email: user?.email ?? '',
      displayName: user?.displayName,
      firstName: user?.firstName,
      lastName: user?.lastName
    } : undefined;
    
    if(simpleUserModel) {
      return getUserNameInitials(simpleUserModel);
    }
    else {
      return '';
    }
  }

  const getName = () => {
    const simpleUserModel: SimpleUserModel | undefined = user ? {
      email: user?.email ?? '',
      displayName: user?.displayName,
      firstName: user?.firstName,
      lastName: user?.lastName
    } : undefined;

    if(simpleUserModel) {
      return getUserName(simpleUserModel);
    }
    else {
      return '';
    }
  }

  const closeUserContainer = () => {
    setAccountOpened(false);
  };

  const onLogout = async () => {
    try {
      await userActions.logout();
      setGenericErrors(null);
    } catch (err: any) {
      setGenericErrors(err.response?.data?.Message ?? err.message);
    }
  }

  const changeLTEModal = () => {
    setShowChangeLTEModal(true);
  };

  const refUserContainer = useDetectClickOutside({ onTriggered: closeUserContainer });

  return (
    <>
      <div className={`lp-user-container${isAccountOpened ? ' user-opened' : ''}`} ref={refUserContainer}>
        <div className="lp-user-container-item" onClick={toggleUserContainer}>
          <div className="lp-user-container-avatar">{getInitials()}</div>
          {genericErrors && (
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="tooltip-right">{genericErrors}</Tooltip>
              }
            >
              <div className="lp-user-error">
                <MdError />
              </div>
            </OverlayTrigger>
          )}
          <div className="lp-user-container-info">
            <div className="lp-user-container-name">{getName()}</div>
            <div className="lp-user-container-role">{user?.userRole?.name ?? ''}</div>
          </div>
        </div>
        <ul className="lp-user-container-dropdown">
          <li className="lp-user-container-dropdown-email">
            <MdAccountCircle />
            {user?.email ?? ''}
          </li>
          {!user.isDelegated &&
            <li onClick={() => closeUserContainer()}>
              <NavLink to="/accountSettings">
                <MdManageAccounts />
                Personal Details
              </NavLink>
            </li>
          }
          {(user.userPermissions?.some(a => a == UserPermissionsNames.ViewLTE || a == UserPermissionsNames.ManageLTE || 
            a == UserPermissionsNames.ViewAllLTEs || a == UserPermissionsNames.ManageAllLTEs) ?? false) &&
            <li onClick={() => closeUserContainer()}>
              <NavLink to="/lteProfile">
                <HiIdentification />
                Business Details
              </NavLink>
            </li>
          }
          {user.userPermissions?.some(a => a == UserPermissionsNames.SyncUserEmailsAndCalendar) && !user.isDelegated &&
            <li className="lp-user-container-dropdown-sync" onClick={() => synchronizeEmails()}>
              <MdSync />
              Sync Emails
            </li>
          }
          {user.canChangeLTE &&
            <li className="lp-user-container-dropdown-change-lte" onClick={() => {changeLTEModal(); closeUserContainer();}}>
              <MdSwitchAccount />
              Change Trading Entity
            </li>
          }
          <li className="lp-user-container-dropdown-logout" onClick={() => {onLogout(); closeUserContainer();}}>
            <FiLogOut />
            Sign out
          </li>
        </ul>
      </div>

      <ChangeLTEModal showModal={showChangeLTEModal} onAction={() => setShowChangeLTEModal(false)} onCancel={() => setShowChangeLTEModal(false)} />
    </>
  );
};

export default UserMenu;
