import { ColDef } from "ag-grid-community";
import store from "state/store";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { dateComparator } from "components/Grid/Comparators/DateComparator";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import { getAvailableLtesForDiaryManagerFilter } from "actions/lte";
import ViewCalendarEvent from "../ViewCalendarEvent/ViewCalendarEvent";
import { DiaryManagerCalendarEventModel } from "models/DiaryManagerCalendarEventModel";
import { DropdownFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/DropdownFloatingFilter";
import { DropdownFilter } from "components/Grid/GridFilters/Filters/DropdownFilter";
import { DateTimeFormatterForMatterActivityHistory } from "components/Grid/ValueFormatters/DateTimeFormatterForMatterActivityHistory";

export const viewCallback = (graphId: string) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        allowNavigation: true,
        width: "55rem",
        title: "View Appointment",
        children: <ViewCalendarEvent
          graphId={graphId}
        />
      }
    )
  );
};

export const columnDefs: (ColDef<DiaryManagerCalendarEventModel> | {excludeFromExport: boolean})[] = [
  { 
    headerName: 'Start Date',
    field: 'startDate',
    filter: 'agDateColumnFilter',
    valueFormatter: DateTimeFormatterForMatterActivityHistory,
    filterParams: {
      comparator: dateComparator
    },
    minWidth: 150
  },
  { 
    headerName: 'End Date',
    field: 'endDate',
    filter: 'agDateColumnFilter',
    valueFormatter: DateTimeFormatterForMatterActivityHistory,
    filterParams: {
      comparator: dateComparator
    },
    minWidth: 150
  },
  {
    headerName: 'Title',
    field: 'title',
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Location',
    field: 'location',
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'LTE',
    field: 'lawPageTradingEntity.name',
    filter: DropdownFilter,
    filterParams: {
      property: 'lawPageTradingEntity.id',
      suppressFilterButtons: false,
    },
    floatingFilterComponent: DropdownFloatingFilter,
    floatingFilterComponentParams: {
      endpointCall: getAvailableLtesForDiaryManagerFilter
    },
    minWidth: 220
  },
  {
    headerName: 'Matter Reference',
    field: 'matterReference',
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Required Attendees',
    field: 'requiredAttendees',
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Optional Attendees',
    field: 'optionalAttendees',
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Rooms',
    field: 'rooms',
    filter: 'agTextColumnFilter',
  },
  {
    cellClass: 'lp-actions-cell',
    suppressHeaderMenuButton: true,
    suppressColumnsToolPanel: true,
    cellRendererSelector: (params: any) => {
      return {
        component: ActionsCellRenderer,
        params: {
          buttonsData: [{
            type: ActionButtonTypes.View,
            callback: () => viewCallback(params.data.graphId)
          },
          ]
        },
      }
    },
    excludeFromExport: true,
    suppressSizeToFit: true,
    floatingFilter: false,
    sortable: false,
    minWidth: 65,
    maxWidth: 65,
    width: 65,
    suppressMovable: true,
    pinned: 'right',
    resizable: false
  },
];
