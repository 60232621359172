import { useEffect, useRef, useState } from "react";
import Title from "components/Title";
import { Row, Col, Button } from 'react-bootstrap';
import { MdEdit } from "react-icons/md";
import { ChargingSchemeModel } from "models/view/ChargingSchemeModel";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getChargingScheme } from "actions/chargingSchemes";
import { TabPanel } from "react-tabs";
import ViewChargingSchemeSummary from "./ViewChargingSchemeSummary/ViewChargingSchemeSummary";
import EditChargingSchemeSummary from "./EditChargingSchemeSummary/EditChargingSchemeSummary";
import ChargingRatesList from "./ViewChargingRatesList/ChargingRatesList";
import SlidingPanel from "components/SlidingPanel/index";
import ConfigureRecordableTypes from "./ConfigureRecordableEventTypes/ConfigureRecordableTypes";
import CustomTabs from "components/CustomTabs";
import { useAppSelector } from 'hooks/appSelector';
import { UserPermissionsNames } from 'enums/UserPermissionsNames';
import { AgGridReact } from "ag-grid-react";
import { viewCallback } from "./ViewChargingRatesList/columnDefs";

type State = {
  edit: boolean
}

export default function ViewChargingScheme() {
  const location = useLocation();
  const state = location.state as State ?? undefined;
  const navigate = useNavigate();
  const [edit, setEdit] = useState(state?.edit ?? false);
  const [chargingScheme, setChargingScheme] = useState<ChargingSchemeModel>();
  const loggedInUser = useAppSelector((state) => state.user);
  const gridRef = useRef<AgGridReact>(null);

  const { id } = useParams();

  useEffect(() => {
    getChargingScheme(id!).then((response) => {
      setChargingScheme(response.data);
    }).catch((error) => {
      if (error.response.status == 404 || error.response.status == 400) {
        navigate('/error404');
      }
    });
  }, [id]);

  return (
    <div className="lp-page-content">
      <Title type='page' title={[<strong key={0}>Charging Scheme: </strong>, <span key={1}>{chargingScheme?.displayName}</span>]} />
      
      <CustomTabs 
        tabList={[
          {
            name: 'Details',
            panel: <TabPanel>
              <>
                <Row>
                  <Col>
                    <Title type="section" title={"Summary"}>
                      {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageChargingSchemes) &&
                        edit !== true &&
                        <Button variant="primary" className="btn-icon" onClick={() => setEdit(true)}>
                          <MdEdit />
                        </Button>
                      }
                    </Title>
                    {chargingScheme &&
                      <>
                        {edit === true ?
                          (<EditChargingSchemeSummary
                            chargingScheme={chargingScheme}
                            setChargingScheme={setChargingScheme}
                            setEdit={setEdit}
                          />)
                          :
                          (<ViewChargingSchemeSummary
                            chargingScheme={chargingScheme}
                          />)
                        }
                      </>
                    }
                  </Col>
                </Row>
                {chargingScheme &&
                <Row>
                  <Col>
                    <ChargingRatesList
                      ref={gridRef}
                      chargingScheme={chargingScheme!}
                    />
                  </Col>
                </Row>
                }
              </>
            </TabPanel>
          },
          // commented it because Recordable Event Types are not used for now
          /*{
            name: 'Configure Recordable Event Types',
            panel: <TabPanel>
              <ConfigureRecordableTypes id={id!}/>
            </TabPanel>
          }*/
        ]}
      />

      <SlidingPanel gridRef={gridRef} onNavigation={(item: any) => viewCallback(item.id)} />
    </div>
  );
}
