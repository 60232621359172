import { getActivitiesSummaryForLte } from 'actions/settings';
import { vestResolver } from '@hookform/resolvers/vest';
import {
  addLinksToRecordableEventTypeToChargingRate,
  getAllRecordableEventTypes,
  getAllRecordableEventTypeToChargingRate,
  getSummaryChargingRates,
  getSummaryChargingRatesFromActivity,
} from 'actions/chargingSchemes';
import useSlidingPanelActions from 'actions/slidingPanel';
import Loader from 'components/Loader/index';
import CustomSelect from 'components/Select/Select';
import { useAppSelector } from 'hooks/appSelector';
import { CreateOrUpdateRecordableEventTypeToChargingRateType } from 'models/create/CreateOrUpdateRecordableEventTypeToChargingRateModel';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { removeEmptyFields } from 'utils/form';
import { validationSuite } from './validationSuite';
import { DropDownOptionModel } from 'models/view/DropDownOptionModel';
import { ChargingRatesWithSpecificActivitiesModel } from 'models/create/ChargingRatesWithSpecificActivitiesModel';

type Props = {
  chargingSchemeId: string;
  eventTypeId?: string;
  setRecordableEventTypesToChargingRates?: any;
  chargingRates?: string[];
};

export default function CreateRecordableEventTypeToChargingRate(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const [chargingRateOptions, setChargingRateOptions] = useState<DropDownOptionModel[]>([]);
  const [isLoadingChargingRateOptions, setIsLoadingChargingRateOptions] = useState<boolean>(false);
  
  const slidingPanelActions = useSlidingPanelActions();
  const loggedInUser = useAppSelector((state) => state.user);

  useEffect(() => {
    onChangeActivity([]);
  }, []);

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm<CreateOrUpdateRecordableEventTypeToChargingRateType>({
    resolver: vestResolver(validationSuite),
    defaultValues: {
      recordableEventTypeId: props.eventTypeId,
      chargingRates: props.chargingRates,
    },
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(
    data: CreateOrUpdateRecordableEventTypeToChargingRateType
  ) {
    setIsLoading(true);
    removeEmptyFields(data);
    data.chargingSchemeId = props.chargingSchemeId;
    addLinksToRecordableEventTypeToChargingRate(data)
      .then((response) => {
        if (props.setRecordableEventTypesToChargingRates) {
          getAllRecordableEventTypeToChargingRate(props.chargingSchemeId).then(
            (response) => {
              props.setRecordableEventTypesToChargingRates(response.data);
            }
          );
        }
        setIsLoading(false);
        slidingPanelActions.clearSlidingPanel();
        reset();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  };

  const onChangeActivity = (activitiesIds: string[]) => {
    if(!activitiesIds || activitiesIds.length == 0)
    {
      setIsLoadingChargingRateOptions(true);
      getSummaryChargingRates(props.chargingSchemeId).then((response) => {
        setChargingRateOptions(response.data);
        setValue('chargingRates', []);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoadingChargingRateOptions(false);
      });
    }
    else {
      const chargingRatesWithSpecificActivitiesModel: ChargingRatesWithSpecificActivitiesModel = {
        activitiesIds: activitiesIds
      }
      setIsLoadingChargingRateOptions(true);
      getSummaryChargingRatesFromActivity(props.chargingSchemeId, chargingRatesWithSpecificActivitiesModel).then((response) => {
        setChargingRateOptions(response.data);
        
        const idsOnly: string[] = response.data.map((y: DropDownOptionModel) => y.id);
        setValue('chargingRates', idsOnly);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoadingChargingRateOptions(false);
      });
    }
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && <div className="lp-errors">{genericErrors}</div>}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Form.Group className="mb-4" controlId="recordableEventTypeId">
          <Form.Label className="required">Recordable Event Type</Form.Label>
          <Controller
            control={control}
            name="recordableEventTypeId"
            shouldUnregister={true}
            render={({ field: { onChange, value, name, ref } }) => (
              <CustomSelect
                id="recordableEventTypeId"
                inputRef={ref}
                className={`lp-select${errors?.recordableEventTypeId?.message ? ' invalid' : ''}`}
                endpointCall={getAllRecordableEventTypes}
                value={value}
                isDisabled={props.eventTypeId != undefined}
                onChange={(val) => onChange(val?.id ?? null)}
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.recordableEventTypeId?.message &&
              errors.recordableEventTypeId.message}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="activity">
          <Form.Label>Activity</Form.Label>
          <Controller
            control={control}
            name="activity"
            shouldUnregister={true}
            render={({ field: { onChange, value, name, ref } }) => (
              <CustomSelect
                id="activity"
                inputRef={ref}
                className="lp-select"
                endpointCall={() => getActivitiesSummaryForLte(loggedInUser.lawPageTradingEntityId!)}
                value={value}
                onChange={(val) =>
                  (onChange(val?.map((x: { id: any; }) => x.id) ?? null), onChangeActivity(val?.map((x: { id: any; }) => x.id) ?? null))
                }
                isMulti
                isClearable
              />
            )}
          />
        </Form.Group>

        <Form.Group className="mb-4" controlId="chargingRates">
          <Form.Label className="required">Charging Rates</Form.Label>
          <Controller
            control={control}
            name="chargingRates"
            shouldUnregister={true}
            render={({ field: { onChange, value, name, ref } }) => (
              <CustomSelect
                id="chargingRates"
                inputRef={ref}
                className={`lp-select${errors?.chargingRates?.message ? ' invalid' : ''}`}
                options={chargingRateOptions}
                isLoading={isLoadingChargingRateOptions}
                value={value}
                onChange={(val) => onChange(val?.map((x: { id: any }) => x.id) ?? null)}
                isMulti
                isClearable
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.chargingRates?.message && errors.chargingRates.message}
          </Form.Text>
        </Form.Group>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <Button variant="success" type="submit">
              Create
            </Button>
            <Button variant="secondary-400" onClick={cancelForm}>
              Cancel
            </Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
