import { ColDef } from "ag-grid-community";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import store from "state/store";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { DelegatedUserModel } from "models/view/DelegatedUserModel";
import { BooleanFilter } from "components/Grid/GridFilters/Filters/BooleanFilter";
import { BooleanFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/BooleanFloatingFilter";
import CheckboxCellRenderer from "components/Grid/CheckboxCellRenderer";
import { CheckboxFormatter } from "components/Grid/ValueFormatters/CheckboxFormatter";
import { DropdownFilter } from "components/Grid/GridFilters/Filters/DropdownFilter";
import { DropdownFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/DropdownFloatingFilter";
import { changeDelegatedUserStatus, getUserPermissions, getUserRoles } from "actions/user";
import { DropdownArraySearchFilter } from "components/Grid/GridFilters/Filters/DropdownArraySearchFilter";
import DropDownOptionListCellRenderer from "components/Grid/DropDownOptionListCellRenderer";
import { DropDownOptionListFormatter } from "components/Grid/ValueFormatters/DropDownOptionListFormatter";
import ViewDelegatedUser from "./ViewDelegatedUser/ViewDelegatedUser";
import { ModalState, setModalData, setModalErrors, toggleModal, toggleModalLoadingState } from "state/modalSlice";
import { setRowData } from "state/gridSlice";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import { DelegatedLTEModel } from "models/view/DelegatedLTEModel";

export const viewCallback = (lteId: string, rowData: DelegatedUserModel, delegatedLTE?: DelegatedLTEModel) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        allowNavigation: true,
        title: "View User",
        children: <ViewDelegatedUser
          lteId={lteId}
          delegatedUserId={rowData.id}
          showDelegateAccessFields={delegatedLTE?.delegatesAccess}
          showManageDiaryFields={delegatedLTE?.managesDiary}
        />
      }
    )
  );
}

const changeStatus = (id: string) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  changeDelegatedUserStatus(id).then(() => {
    let index: number = gridState.rowData.findIndex((x: any) => x.id == id);
    let newEntry: DelegatedUserModel = { ...gridState.rowData[index], active: !gridState.rowData[index].active };
    let newArray: Array<any> = [...gridState.rowData];
    newArray[index] = newEntry;
    store.dispatch(setRowData(newArray));
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

const changeStatusModal = (id: string) => {
  let modal: ModalState = {
    title: "Confirmation",
    body: "Are you sure you want to change the status for this User?",
    onAction: () => changeStatus(id),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
}

export const getColumnDefs = (lteId: string, delegatedLTE?: DelegatedLTEModel) => {
  const loggedInUser = store.getState().user;
  const buttonsColumnWidth = loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageLTEDelegateUsers) ? 105 : 65;
  
  const columnDefs: (ColDef<DelegatedUserModel> | {excludeFromExport: boolean})[] = [
    {
      headerName: 'User',
      field: 'user.name',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: "Role",
      field: 'userRole.name',
      filter: DropdownFilter,
      filterParams: {
        property: 'userRoleId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: () => getUserRoles(lteId)
      },
      minWidth: 220,
      hide: !delegatedLTE?.delegatesAccess,
      suppressColumnsToolPanel: !delegatedLTE?.delegatesAccess,
    },
    {
      headerName: 'Additional Permissions',
      field: 'userPermissions',
      filter: DropdownArraySearchFilter,
      filterParams: {
        property: 'userPermissions',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: () => getUserPermissions(lteId)
      },
      cellRenderer: DropDownOptionListCellRenderer,
      valueFormatter: DropDownOptionListFormatter,
      minWidth: 220,
      hide: !delegatedLTE?.delegatesAccess,
      suppressColumnsToolPanel: !delegatedLTE?.delegatesAccess,
    },
    {
      headerName: "Manage Diary",
      field: 'managesDiary',
      filter: BooleanFilter,
      filterParams: {
        property: 'managesDiary',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: BooleanFloatingFilter,
      floatingFilterComponentParams: {
        values: [{
          id: 'true',
          name: 'Yes'
        },
        {
          id: 'false',
          name: 'No'
        },
        ]
      },
      cellRenderer: CheckboxCellRenderer,
      valueFormatter: CheckboxFormatter,
      minWidth: 100,
      width: 100,
      hide: !delegatedLTE?.managesDiary,
      suppressColumnsToolPanel: !delegatedLTE?.managesDiary,
    },
    {
      headerName: "Is Active",
      field: 'active',
      filter: BooleanFilter,
      filterParams: {
        property: 'active',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: BooleanFloatingFilter,
      floatingFilterComponentParams: {
        values: [{
          id: 'true',
          name: 'Yes'
        },
        {
          id: 'false',
          name: 'No'
        },
        ]
      },
      cellRenderer: CheckboxCellRenderer,
      valueFormatter: CheckboxFormatter,
      minWidth: 100,
      width: 100
    },
    { 
      excludeFromExport: true,
      cellClass: 'lp-actions-cell',
      suppressHeaderMenuButton: true,
      suppressColumnsToolPanel: true,
      cellRenderer: ActionsCellRenderer,
      cellRendererParams: (params: any) => {
        return { buttonsData: [
          {
            type: ActionButtonTypes.View,
            callback: () => viewCallback(lteId, params.data, delegatedLTE)
          },
          ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageLTEDelegateUsers) ? [{
            type: params.data?.active ? ActionButtonTypes.Deactivate : ActionButtonTypes.Activate,
            callback: (id: string) => changeStatusModal(id)
          }] : []),
        ]}
      },
      suppressSizeToFit: true,
      floatingFilter: false,
      sortable: false,
      minWidth: buttonsColumnWidth,
      maxWidth: buttonsColumnWidth,
      width: buttonsColumnWidth,
      suppressMovable: true,
      pinned: 'right',
      resizable: false
    }
  ];

  return columnDefs;
}