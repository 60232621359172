import { ColDef } from "ag-grid-community";
import store from "state/store";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { CalendarEventModel } from "models/CalendarEventModel";
import { DateTimeFormatterForMatterActivityHistory } from "components/Grid/ValueFormatters/DateTimeFormatterForMatterActivityHistory";
import { dateComparator } from "components/Grid/Comparators/DateComparator";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import ViewCalendarEvent from "containers/Calendar/ViewCalendarEvent/ViewCalendarEvent";

export const viewCallback = (matterId: string, graphId: string) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        allowNavigation: true,
        width: "55rem",
        title: "View Appointment",
        children: <ViewCalendarEvent
          matterId={matterId}
          graphId={graphId}
        />
      }
    )
  );
};

export const getColumnDefs = (matterId: string) => {
  const columnDefs: (ColDef<CalendarEventModel> | {excludeFromExport: boolean})[] = [
    { 
      headerName: 'Start Date',
      field: 'startDate',
      filter: 'agDateColumnFilter',
      valueFormatter: DateTimeFormatterForMatterActivityHistory,
      filterParams: {
        comparator: dateComparator
      }
    },
    { 
      headerName: 'End Date',
      field: 'endDate',
      filter: 'agDateColumnFilter',
      valueFormatter: DateTimeFormatterForMatterActivityHistory,
      filterParams: {
        comparator: dateComparator
      }
    },
    {
      headerName: 'Title',
      field: 'title',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Description',
      field: 'description',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Location',
      field: 'location',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Required Attendees',
      field: 'requiredAttendees',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Optional Attendees',
      field: 'optionalAttendees',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Rooms',
      field: 'rooms',
      filter: 'agTextColumnFilter',
    },
    {
      cellClass: 'lp-actions-cell',
      suppressHeaderMenuButton: true,
      suppressColumnsToolPanel: true,
      cellRendererSelector: (params: any) => {
        return {
          component: ActionsCellRenderer,
          params: {
            buttonsData: [{
              type: ActionButtonTypes.View,
              callback: () => viewCallback(matterId, params.data.graphId)
            },
            ]
          },
        }
      },
      excludeFromExport: true,
      suppressSizeToFit: true,
      floatingFilter: false,
      sortable: false,
      minWidth: 65,
      maxWidth: 65,
      width: 65,
      suppressMovable: true,
      pinned: 'right',
      resizable: false
    },
  ];
  return columnDefs;
}
