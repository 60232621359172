import { SelectionColumnDef } from "ag-grid-community";

export const SELECTION_COLUMN_DEFINITIONS: SelectionColumnDef & { excludeFromExport: boolean } = {
  excludeFromExport: true,
  suppressHeaderMenuButton: true,
  resizable: false,
  sortable: false,
  minWidth: 48,
  maxWidth: 48,
  width: 48,
  pinned: "left",
}
