import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { useParams } from "react-router-dom";
import { CreateOrUpdateLteBranchModel } from "models/create/CreateOrUpdateLteBranchModel";
import { Form, Button } from "react-bootstrap";
import { addLteBranch, getLtePhoneNumbers } from "actions/lte";
import useSlidingPanelActions from "actions/slidingPanel";
import Loader from "components/Loader/index";
import {validationSuite} from "./validationSuite";
import { useAppSelector } from "hooks/appSelector";
import CustomSelect from "components/Select/Select";
import { formatAddress } from "utils/address";
import { getLteAddresses } from "actions/lte";
import { LteAddressModel } from "models/view/LteAddressModel";
import { LtePhoneNumberModel } from "models/view/LtePhoneNumberModel";

type Props = {
  submitCallback?: Function
}

export default function CreateLteBranchForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();

  const { id: lteId } = useParams();

  const user = useAppSelector((state) => state.user);

  const {register, reset, control, handleSubmit, formState: {errors}} = useForm<CreateOrUpdateLteBranchModel>({
    resolver: vestResolver(validationSuite)
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateOrUpdateLteBranchModel) {
    setIsLoading(true);
    data.lteId = lteId ?? user.lawPageTradingEntityId!;
    addLteBranch(data).then((response) => {
      if(props.submitCallback) {
        props.submitCallback(response.data);
      }
      slidingPanelActions.clearSlidingPanel()
      reset();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }
      
  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Form.Group className="mb-4" controlId="name">
          <Form.Label className="required">Name</Form.Label>
          <Form.Control
            type="text"
            className={`${errors?.name?.message ? 'invalid' : ''}`}
            {...register("name", {shouldUnregister: true})}
          />
          <Form.Text className="lp-error">
            {errors?.name?.message && (errors.name.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="addressId">
          <Form.Label>Address</Form.Label>
          <Controller
            control={control}
            name="addressId"
            shouldUnregister={true}
            render={({field: { onChange, value, ref }}) => (
              <CustomSelect
                id="addressId"
                getOptionLabel={(x: LteAddressModel) => formatAddress(x.address)}
                getOptionValue={(x: any) => x.addressId}
                endpointCall={() => getLteAddresses(lteId ?? user.lawPageTradingEntityId!)}
                value={value}
                onChange={val => {onChange(val?.addressId ?? null)}}
                isClearable
              />
            )}
          />
        </Form.Group>

        <Form.Group className="mb-4" controlId="phoneNumberId">
          <Form.Label>Phone Number</Form.Label>
          <Controller
            control={control}
            name="phoneNumberId"
            shouldUnregister={true}
            render={({field: { onChange, value, ref }}) => (
              <CustomSelect
                id="phoneNumberId"
                getOptionLabel={(x: LtePhoneNumberModel) => x?.phoneNumber?.number + (x?.phoneNumber?.extension ? ' (Extension: ' + x?.phoneNumber?.extension + ')': '')}
                getOptionValue={(x: any) => x.phoneNumberId}
                endpointCall={() => getLtePhoneNumbers(lteId ?? user.lawPageTradingEntityId!)}
                value={value}
                onChange={val => {onChange(val?.phoneNumberId ?? null)}}
                isClearable
              />
            )}
          />
        </Form.Group>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <Button variant="success" type="submit">Create</Button>
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
