import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import useGridActions from "actions/grid";
import { useAppSelector } from "hooks/appSelector";
import { Form, Button } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import { getValidationSuite } from "./validationSuite";
import Loader from "components/Loader/index";
import { getActivityTypes, getActivityById, updateActivity } from "actions/settings";
import { CreateOrUpdateActivityModel } from "models/create/CreateOrUpdateActivityModel";
import CustomSelect from "components/Select/Select";
import { ActivityModel } from "models/view/ActivityModel";

type Props = {
  activityId: string
}

export default function EditActivityForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const gridActions = useGridActions();
  const grid = useAppSelector((state) => state.grid);
  const slidingPanelActions = useSlidingPanelActions();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    getActivityById(props.activityId).then((response) => {
      reset(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  const {register, reset, control, handleSubmit, formState: {errors}} = useForm<CreateOrUpdateActivityModel>({
    resolver: vestResolver(getValidationSuite(props.activityId!))
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateOrUpdateActivityModel) {
    setIsLoading(true);
    updateActivity(props.activityId, data).then((response) => {
      let index: number = grid.rowData.findIndex((x: any) => x.id === response.data.id);
      let newEntry: ActivityModel = {...response.data};
      let newArray: Array<any> = [...grid.rowData];
      newArray[index] = newEntry;
      gridActions.setGridRowData(newArray);
      
      slidingPanelActions.clearSlidingPanel();
      reset();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }
      
  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Form.Group className="mb-4" controlId="code">
          <Form.Label className="required">Code</Form.Label>
          <Form.Control
            type="text"
            className={`${errors?.code?.message ? 'invalid' : ''}`}
            {...register("code", {shouldUnregister: true})}
          />
          <Form.Text className="lp-error">
            {errors?.code?.message && (errors.code.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="name">
          <Form.Label className="required">Name</Form.Label>
          <Form.Control
            type="text"
            className={`${errors?.name?.message ? 'invalid' : ''}`}
            {...register("name", {shouldUnregister: true})}
          />
          <Form.Text className="lp-error">
            {errors?.name?.message && (errors.name.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="description">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="text"
            {...register("description", {shouldUnregister: true})}
          />
        </Form.Group>

        <Form.Group className="mb-4" controlId="activityTypeId">
          <Form.Label>Type</Form.Label>
          <Controller
            control={control}
            name="activityTypeId"
            shouldUnregister={true}
            render={({field: { onChange, value, ref }}) => (
              <CustomSelect
                id="activityTypeId"
                inputRef={ref}
                className={'lp-select'}
                endpointCall={getActivityTypes}
                value={value}
                onChange={val => onChange(val?.id ?? null)}
              />
            )}
          />
        </Form.Group>

        <Form.Group className="mb-4" controlId="active">
          <Form.Label>Active</Form.Label>
          <Controller
            control={control}
            name="active"
            shouldUnregister={true}
            render={({field: { onChange, value, name, ref }}) => (
              <Form.Check 
                type="switch"
                id="active">
                  <Form.Check.Input
                    className= "form-check-input"
                    ref={ref}
                    checked={value ?? false}
                    onChange={(ev: any) => onChange(ev.target.checked)}
                  />
              </Form.Check>
            )}
          />
        </Form.Group>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <Button variant="success" type="submit">Update</Button>
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
