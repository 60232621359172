import { ColDef } from "ag-grid-community";
import store from "state/store";
import { ModalState, setModalData, setModalErrors, toggleModal, toggleModalLoadingState } from "state/modalSlice";
import { setRowData } from "state/gridSlice";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import CheckboxCellRenderer from "components/Grid/CheckboxCellRenderer";
import { BooleanFilter } from "components/Grid/GridFilters/Filters/BooleanFilter";
import { BooleanFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/BooleanFloatingFilter";
import EditTaxonomyForm from "./EditTaxonomy/EditTaxonomyForm";
import { TaxonomyModel } from "models/view/TaxonomyModel";
import { changeLteTaxonomyStatus, deleteLteTaxonomy } from "actions/taxonomy";
import { DropdownArraySearchFilter } from "components/Grid/GridFilters/Filters/DropdownArraySearchFilter";
import { DropdownFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/DropdownFloatingFilter";
import { getMatterTypesWithoutMatterStages } from "actions/matter";
import DropDownOptionListCellRenderer from "components/Grid/DropDownOptionListCellRenderer";
import { DropDownOptionListFormatter } from "components/Grid/ValueFormatters/DropDownOptionListFormatter";
import { CheckboxFormatter } from "components/Grid/ValueFormatters/CheckboxFormatter";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

const editCallback = (id: string) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        title: "Edit Taxonomy",
        children: <EditTaxonomyForm taxonomyId={id}/>
      }
    )
  );
}

const changeStatusModal = (id: string) => {
  let bodyMessage: React.ReactNode;
  const gridState = store.getState().grid;
  if (!gridState.rowData.find((x: TaxonomyModel) => x.id == id).active) {
    bodyMessage = "Are you sure you want to activate this Taxonomy?";
  } else {
    bodyMessage = "Are you sure you want to deactivate this Taxonomy?";
  }
  let modal: ModalState = {
    title: "Confirmation",
    body: bodyMessage,
    onAction: () => changeStatus(id),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
}

const changeStatus = (id: string) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  changeLteTaxonomyStatus(id).then(() => {
    let index: number = gridState.rowData.findIndex((x: any) => x.id == id);
    let newEntry: TaxonomyModel = { ...gridState.rowData[index], active: !gridState.rowData[index].active };
    let newArray: Array<any> = [...gridState.rowData];
    newArray[index] = newEntry;
    store.dispatch(setRowData(newArray));
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

const deleteModal = (id: string) => {
  let modal: ModalState = {
    title: "Delete confirmation",
    body: "Are you sure you want to delete this Taxonomy?",
    actionText: "Delete",
    actionVariant: "danger",
    onAction: () => deleteCallback(id),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
};

const deleteCallback = (id: string) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  deleteLteTaxonomy(id).then(() => {
    store.dispatch(setRowData(gridState.rowData.filter((x: any) => x.id !== id)));
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

export const getColumnDefs = () => {
  const loggedInUser = store.getState().user;
  
  const columnDefs: (ColDef<TaxonomyModel> | {excludeFromExport: boolean})[] = [
    {
      headerName: 'Display Name',
      field: 'displayName',
      filter: 'agTextColumnFilter',
      flex: 2,
    },
    {
      headerName: 'Description',
      field: 'description',
      filter: 'agTextColumnFilter',
      flex: 2,
    },
    {
      headerName: 'Is Hierarchical',
      field: 'isHierarchical',
      filter: BooleanFilter,
      filterParams: {
        property: 'isHierarchical',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: BooleanFloatingFilter,
      floatingFilterComponentParams: {
        values: [{
          id: 'true',
          name: 'Yes'
        },
        {
          id: 'false',
          name: 'No'
        },
      ]},
      minWidth: 100,
      width: 100,
      cellRenderer: CheckboxCellRenderer,
      valueFormatter: CheckboxFormatter
    },
    {
      headerName: 'Is MultiSelect',
      field: 'isMultiSelect',
      filter: BooleanFilter,
      filterParams: {
        property: 'isMultiSelect',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: BooleanFloatingFilter,
      floatingFilterComponentParams: {
        values: [{
          id: 'true',
          name: 'Yes'
        },
        {
          id: 'false',
          name: 'No'
        },
      ]},
      minWidth: 100,
      width: 100,
      cellRenderer: CheckboxCellRenderer,
      valueFormatter: CheckboxFormatter
    },
    {
      headerName: 'Matter Types',
      field: 'matterTypes',
      filter: DropdownArraySearchFilter,
      filterParams: {
        property: 'matterTypes',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getMatterTypesWithoutMatterStages
      },
      cellRenderer: DropDownOptionListCellRenderer,
      valueFormatter: DropDownOptionListFormatter,
      minWidth: 220,
      flex: 2,
    },
    {
      headerName: 'Is Active',
      field: 'active',
      filter: BooleanFilter,
      filterParams: {
        property: 'active',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: BooleanFloatingFilter,
      floatingFilterComponentParams: {
        values: [{
          id: 'true',
          name: 'Yes'
        },
        {
          id: 'false',
          name: 'No'
        },
      ]},
      minWidth: 100,
      width: 100,
      cellRenderer: CheckboxCellRenderer,
      valueFormatter: CheckboxFormatter
    },
    { 
      excludeFromExport: true,
      cellClass: 'lp-actions-cell',
      suppressHeaderMenuButton: true,
      suppressColumnsToolPanel: true,
      cellRenderer: ActionsCellRenderer,
      cellRendererParams: (params: any) => {
        return { buttonsData: [
          {
            type: ActionButtonTypes.Edit,
            callback: editCallback
          },
          {
            type: params.data.active ? ActionButtonTypes.Deactivate : ActionButtonTypes.Activate,
            callback: changeStatusModal
          },
          {
            type: ActionButtonTypes.Delete,
            callback: deleteModal
          }
        ]};
      },
      hide: !loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageLTESettings),
      suppressSizeToFit: true,
      floatingFilter: false,
      sortable: false,
      minWidth: 105,
      maxWidth: 105,
      width: 105,
      suppressMovable: true,
      pinned: 'right',
      resizable: false
    }
  ];
  return columnDefs;
}