import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Form, Button } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import Loader from "components/Loader";
import { validationSuite } from "./validationSuite";
import { removeEmptyFields } from "utils/form";
import CustomSelect from "components/Select/Select";
import { MatterActivityHistoryModel } from "models/view/MatterActivityHistoryModel";
import { shareActivityHistory } from "actions/matter";
import { ActivityHistoryShareModel } from "models/create/ActivityHistoryShareModel";
import { MatterFileShareRoles } from "enums/MatterFileShareRoles";

type Props = {
  matterId: string,
  activityHistoryRecords: MatterActivityHistoryModel[]
}

export default function ShareActivityHistoryForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();
  const roleOptions = [{
    id: MatterFileShareRoles.View.toString(),
    name: "Can view"
  },{
    id: MatterFileShareRoles.Edit.toString(),
    name: "Can edit"
  }];

  const {reset, register, control, handleSubmit, formState: {errors}} = useForm<ActivityHistoryShareModel>({
    resolver: vestResolver(validationSuite)
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: ActivityHistoryShareModel) {
    setIsLoading(true);
    data.activityHistoryIds = props.activityHistoryRecords.map(x => x.id);
    data.role = Number(data.role);
    removeEmptyFields(data);
    shareActivityHistory(props.matterId, data)
      .then((response) => {
        slidingPanelActions.clearSlidingPanel();
        reset();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Form.Group className="mb-4" controlId="email">
          <Form.Label className="required">Email</Form.Label>
          <Form.Control
            type="text"
            className={`${errors?.email?.message ? 'invalid' : ''}`}
            {...register("email", {shouldUnregister: true})}
          />
          <Form.Text className="lp-error">
            {errors?.email?.message && (errors.email.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="role">
          <Form.Label className="required">Role</Form.Label>
          <Controller
            control={control}
            name="role"
            shouldUnregister={true}
            render={({field: { onChange, value, name, ref }}) => (
              <CustomSelect
                id="role"
                inputRef={ref}
                className={`lp-select${errors?.role?.message ? ' invalid' : ''}`}
                options={roleOptions}
                value={value?.toString()}
                onChange={val => onChange(val?.id ?? null)}
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.role?.message && (errors.role.message)}
          </Form.Text>
        </Form.Group>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <Button variant="success" type="submit">Share</Button>
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
