import CustomTable from "components/CustomTable/CustomTable";
import { AccountIncreaseRepresentedBy } from "enums/AccountIncreaseRepresentedBy";
import { CurrencyFormatter } from "components/Grid/ValueFormatters/CurrencyFormatter";
import { columnDefsLedgerTransactionCustomTable } from "./columnDefsLedgerTransactionCustomTable";
import { AccountLedgerTransactionEntryModel } from "models/view/AccountLedgerTransactionEntryModel";

export default function ViewAccountLedgerTransactionTable(props: { entries: AccountLedgerTransactionEntryModel[] }) {
  // Format the entries with the fields needed for the table
  const formattedEntries: any[] = props.entries.map(e => ({
    accountDisplayName: e.user?.name ? e.account?.displayName + " - " + e.user?.name : e.account?.displayName,
    drAmount: e.entryType === AccountIncreaseRepresentedBy.DR ? e.amount : 0,
    crAmount: e.entryType === AccountIncreaseRepresentedBy.CR ? e.amount : 0,
  }));
  return (
    <CustomTable
      columnDefs={columnDefsLedgerTransactionCustomTable()}
      rowData={formattedEntries}
      footerRow={[
        {
          value: "TOTAL",
          style: {
            "fontWeight": "600",
            "fontSize": "1.6rem",
            "textAlign": "right"
          }
        },
        {
          value: formattedEntries.map(x => x.drAmount ?? 0).reduce((a, b) => a + b, 0),
          formatter: CurrencyFormatter,
          style: {
            "textAlign": "right"
          }
        },
        {
          value: formattedEntries.map(x => x.crAmount ?? 0).reduce((a, b) => a + b, 0),
          formatter: CurrencyFormatter,
          style: {
            "textAlign": "right"
          }
        }
      ]}
    />
  )
}
