import { validateFeeEarnerForMatterFeeEarner } from "actions/matter";
import { MatterFeeEarnerType } from "enums/MatterFeeEarnerType";
import { create, test, enforce, omitWhen } from "vest";

export const getValidationSuite = (matterId: string, isFeeEarner: boolean, matterFeeEarnerType: MatterFeeEarnerType) => {
  return create((data = {}) => {
    test('userId', `Please select a ${matterFeeEarnerType == MatterFeeEarnerType.FeeEarner ? 'Fee Earner' : 'Delegate User'}`, () => {
      enforce(data.userId).isNotEmpty();
      enforce(data.userId).isString();
    });

    test('userId', `${matterFeeEarnerType == MatterFeeEarnerType.FeeEarner ? 'Fee Earner' : 'Delegate User'} already on Matter`, async () => {
      enforce((await validateFeeEarnerForMatterFeeEarner(data.userId, undefined, matterId)).data).isTruthy();
    });

    omitWhen(!isFeeEarner, () => {
      test('feeEarnerLevelId', 'Please select a Fee Earner Level', () => {
        enforce(data.feeEarnerLevelId).isNotEmpty();
        enforce(data.feeEarnerLevelId).isString();
      });
    })
  });
}