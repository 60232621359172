import { deleteTemplate, downloadTemplate, getMatterTemplateTypes, getMatterTemplates } from "actions/settings";
import { DropdownArraySearchFilter } from "components/Grid/GridFilters/Filters/DropdownArraySearchFilter";
import { DropdownFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/DropdownFloatingFilter";
import { ColDef } from "ag-grid-community";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { TemplateModel } from "models/view/TemplateModel";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import store from "state/store";
import { ModalState, setModalData, setModalErrors, toggleModal, toggleModalLoadingState } from "state/modalSlice";
import { addNotification } from "state/notificationSlice";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import { setRowData } from "state/gridSlice";
import EditTemplate from "../TemplateActions/EditTemplate/EditTemplate";
import { downloadFile } from 'utils/misc';
import { NotificationTypes } from "enums/NotificationTypes";
import EditTemplateTaxonomyTermsForm from "../TemplateActions/EditTemplateTaxonomyTerms/EditTemplateTaxonomyTermsForm";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

const editCallback = (id: string) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        width: "55rem",
        title: "Edit Template",
        children: <EditTemplate 
          templateId={id}
          submitCallback={reloadGridData}
          isMatterTemplate
        />
      }
    )
  );
}

const editTaxonomyTermsCallback = (id: string) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        width: "55rem",
        title: "Edit Taxonomy Terms",
        children: <EditTemplateTaxonomyTermsForm 
          templateId={id}
        />
      }
    )
  );
}

const reloadGridData = () => {
  getMatterTemplates().then((response) => 
  {
    store.dispatch(setRowData(response.data));
  });
}

const downloadCallback = (id: string) => {
  downloadTemplate(id).then((response) => {
    downloadFile(response);
  })
  .catch((error) => {
    error.response.data.text().then((text: any) => {
      const responseData = JSON.parse(text);
      addNotification(
        {
          type: NotificationTypes.Error,
          title: "Download Template Error",
          body: `${responseData.Message ?? "There was an error downloading the template."}`,
          isDismissable: true
        }
      );
    });
  });
}

const deleteModal = (id: string) => {
  let modal: ModalState = {
    title: "Delete confirmation",
    body: "Are you sure you want to delete this Template?",
    actionText: "Delete",
    actionVariant: "danger",
    onAction: () => deleteCallback(id),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
};

const deleteCallback = (id: string) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  deleteTemplate(id).then(() => {
    store.dispatch(setRowData(gridState.rowData.filter((x: any) => x.id !== id)));
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

export const getColumnDefs = () => {
  const loggedInUser = store.getState().user;
  const buttonsColumnWidth = loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageLTESettings) ? 105 : 65;

  const columnDefs: (ColDef<TemplateModel> | {excludeFromExport: boolean})[] = [
    {
      headerName: 'Name',
      field: 'name',
      filter: 'agTextColumnFilter',
      suppressSizeToFit: true,
      minWidth: 120,
      flex: 2,
    },
    {
      headerName: 'Description',
      field: 'description',
      filter: 'agTextColumnFilter',
      flex: 3,
    },
    {
      headerName: 'Type',
      field: 'type.name',
      filter: DropdownArraySearchFilter,
      filterParams: {
        property: 'typeId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getMatterTemplateTypes
      },
      minWidth: 220,
      flex: 3,
    },
    { 
      excludeFromExport: true,
      cellClass: 'lp-actions-cell',
      suppressHeaderMenuButton: true,
      suppressColumnsToolPanel: true,
      cellRenderer: ActionsCellRenderer,
      cellRendererParams: (params: any) => {
        return { buttonsData: [
          ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageLTESettings) ? [{
            type: ActionButtonTypes.Edit,
            callback: editCallback
          }] : []),
          {
            type: ActionButtonTypes.Download,
            callback: downloadCallback
          },
          ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageLTESettings) ? [{
            type: ActionButtonTypes.EditTaxonomyTerms,
            callback: editTaxonomyTermsCallback
          }] : []),
          ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageLTESettings) ? [{
            type: ActionButtonTypes.Delete,
            callback: deleteModal
          }] : [])
        ]};
      },
      suppressSizeToFit: true,
      floatingFilter: false,
      sortable: false,
      minWidth: buttonsColumnWidth,
      maxWidth: buttonsColumnWidth,
      width: buttonsColumnWidth,
      suppressMovable: true,
      pinned: 'right',
      resizable: false
    }
  ];

  return columnDefs;
}
