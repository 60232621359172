import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Row, Col, Form, Button } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import CustomSelect from "components/Select/Select";
import Loader from "components/Loader";
import { getValidationSuite } from "./validationSuite";
import { removeEmptyFields } from "utils/form";
import { addDelegatedLTE, getLtesSummary } from "actions/lte";
import { CreateOrUpdateDelegatedLTEModel } from "models/create/CreateOrUpdateDelegatedLTEModel";

type Props = {
  lteId: string,
  submitCallback?: Function
}

export default function CreateDelegatedLTEForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();

  const {reset, control, handleSubmit, formState: {errors}} = useForm<CreateOrUpdateDelegatedLTEModel>({
    resolver: vestResolver(getValidationSuite(props.lteId))
  });

  const onSubmit = handleSubmit((data) => submitData(data));
    
  async function submitData(data: CreateOrUpdateDelegatedLTEModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    data.fromLTEId = props.lteId;
    addDelegatedLTE(data).then((response) => {
      if(props.submitCallback) {
        props.submitCallback();
      }
      slidingPanelActions.clearSlidingPanel();
      reset();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Form.Group className="mb-4" controlId="toLTEId">
          <Form.Label className="required">Trading Entity</Form.Label>
          <Controller
            control={control}
            name="toLTEId"
            shouldUnregister={true}
            render={({field: { onChange, value, ref }}) => (
              <CustomSelect
                id="toLTEId"
                inputRef={ref}
                className={`lp-select${errors?.toLTEId?.message ? ' invalid' : ''}`}
                endpointCall={() => getLtesSummary(props.lteId)}
                value={value}
                onChange={val => onChange(val?.id ?? null)}
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.toLTEId?.message && (errors.toLTEId.message)}
          </Form.Text>
        </Form.Group>

        <Row className="mb-4">
          <Form.Group as={Col} controlId="managesDiary">
            <Form.Label>Manage Diary</Form.Label>
            <Controller
              control={control}
              name="managesDiary"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <Form.Check 
                  type="switch"
                  id="managesDiary">
                    <Form.Check.Input
                      className= "form-check-input"
                      ref={ref}
                      checked={value ?? false}
                      onChange={(ev: any) => onChange(ev.target.checked)}
                    />
                </Form.Check>
              )}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="delegatesAccess">
            <Form.Label>Delegate Access</Form.Label>
            <Controller
              control={control}
              name="delegatesAccess"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <Form.Check 
                  type="switch"
                  id="delegatesAccess">
                    <Form.Check.Input
                      className= "form-check-input"
                      ref={ref}
                      checked={value ?? false}
                      onChange={(ev: any) => onChange(ev.target.checked)}
                    />
                </Form.Check>
              )}
            />
          </Form.Group>
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <Button variant="success" type="submit">Create</Button>
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
