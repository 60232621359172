import { useEffect, useState } from 'react';
import Title from 'components/Title';
import { Link } from 'react-router-dom';
import { MdArrowOutward } from 'react-icons/md';
import { MatterInvoiceModel } from 'models/view/MatterInvoiceModel';
import ListMatterInvoices from 'components/List/ListMatterInvoices';
import { getMatterInvoicesForUser } from 'actions/user';
import { useAppSelector } from 'hooks/appSelector';
import { UserPermissionsNames } from 'enums/UserPermissionsNames';

export default function DashboardMatterInvoices() {
  const [matterInvoices, setMatterInvoices] = useState<MatterInvoiceModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [genericErrors, setGenericErrors] = useState(undefined);
  const loggedInUser = useAppSelector((state) => state.user);

  useEffect(() => {
    loadMatterInvoices();
  }, []);

  const loadMatterInvoices = () => {
    setIsLoading(true);
    getMatterInvoicesForUser(10).then((response) => {
      setMatterInvoices(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <>
      <Title type="section" title="Recent Invoices">
        {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ViewInvoicePages) &&
          <Link to="/invoice/all" rel="noopener noreferrer" className="link">
            View all
            <MdArrowOutward />
          </Link>
        }
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <ListMatterInvoices
        listItems={matterInvoices}
        listButtons={[]}
        loadState={isLoading}
      />
    </>
  );
}