import useSlidingPanelActions from "actions/slidingPanel";
import React, { RefObject, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { MdClose, MdEdit } from 'react-icons/md';
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "state/store";
import './style.scss';
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { AgGridReact } from "ag-grid-react";
import MoreButton from "components/Buttons/MoreButton";
import IconButtonGrid from "components/Buttons/IconButtonGrid";
import { initialState, SlidingPanelState } from "state/slidingPanelSlice";
import _ from "lodash";

type Props = {
  gridRef?: RefObject<AgGridReact>,
  navigationFilter?: (rowData: any) => boolean,
  onNavigation?: (rowData: any) => void,
};

export default function SlidingPanel(props: Props) {
  const slidingPanelState = useSelector((state: RootState) => state.slidingPanel);
  const slidingPanelActions = useSlidingPanelActions();
  const location = useLocation();

  const [navigationData, setNavigationData] = useState<any[]>([]);
  const [currentNavigationIndex, setCurrentNavigationIndex] = useState<number>(0);

  // Store sliding panel state locally to prevent content dissapearing when closing panel
  // Panel transition begins when isShown is set to false - this happens on clearSLidingPanel(), which also clears the other data
  // Keeping a copy of the data locally allows the content to remain visible during the closing transition
  const [localPanelState, setLocalPanelState] = useState<SlidingPanelState>(initialState);

  // Sync sliding panel state with local state
  useEffect(() => {
    // If the panel status is updated and the panel is shown, set the local state to the new state
    if (slidingPanelState.isShown) {
      setLocalPanelState(slidingPanelState);
    }
    else {
      // Else if isShown is set to false, wait for the transition to finish and then clear the local state
      setTimeout(() => {
        setLocalPanelState(initialState);
      }, 400);
    }
  }, [slidingPanelState]);

  const cancel = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    slidingPanelState.onCancel !== undefined && slidingPanelState?.onCancel();
  }

  const edit = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    slidingPanelState.onEdit !== undefined && slidingPanelState?.onEdit();
  }

  useEffect(() => {
    slidingPanelActions.clearSlidingPanel();
  }, [location]);

  // When panel is opened get navigation data & index from the grid ref
  useEffect(() => {
    // Disable navigation if grid has grouping
    if (props.gridRef?.current?.api?.getRowGroupColumns()?.length) {
      setNavigationData([]);
      setCurrentNavigationIndex(0);
      return;
    }

    const rowData: any[] = [];
    let currentIndex = 0;
    let selectedIndex = 0;
    props.gridRef?.current?.api?.forEachNodeAfterFilterAndSort(node => {
      if(!props.navigationFilter || (props.navigationFilter && props.navigationFilter(node.data))) {
        rowData.push({...node.data, rowIndex: node?.rowIndex ?? 0});
        if ((node as any)?.selected) {
          selectedIndex = currentIndex;
        }
        currentIndex++;
      }
    });

    setNavigationData(rowData ?? []);
    setCurrentNavigationIndex(selectedIndex);
  }, [slidingPanelState.isShown]);

  const onPreviousClick = () => {
    if (currentNavigationIndex === 0 || !props.onNavigation) {
      return;
    }

    setCurrentNavigationIndex((currentNavigationIndex ?? 1) - 1);

    // Get previous item and call onPreviousClick (which has to be set from each sliding panel content component)
    const prevIndex = (currentNavigationIndex ?? 1) - 1;
    const prevItem = navigationData?.[prevIndex];
    props.onNavigation(prevItem);

    // Change selected row & scroll to it
    props.gridRef?.current?.api?.forEachNode(node => {
      if (_.isEqual(node.data, _.omit(prevItem, 'rowIndex'))) {
        node.setSelected(true);
        document.querySelector(`[row-index="${node.rowIndex!}"]`)?.scrollIntoView({ block: "nearest" });
      }
      else {
        node.setSelected(false);
      }
    });

    // Change page if needed
    const pageSize = props.gridRef?.current?.api?.paginationGetPageSize();
    const currentPage = props.gridRef?.current?.api?.paginationGetCurrentPage();
    if (pageSize !== undefined && currentPage !== undefined && ((prevItem.rowIndex + 1) <= (pageSize * currentPage))) {
      props.gridRef?.current?.api?.paginationGoToPreviousPage();

      const gridViewportElem = document.querySelectorAll('.grid .ag-body-viewport')?.[0];
      gridViewportElem?.scrollTo({ top: gridViewportElem.scrollHeight, behavior: 'smooth' });
    }
  }

  const onNextClick = () => {
    if ((currentNavigationIndex === (navigationData?.length ?? 1) - 1) || !props.onNavigation) {
      return;
    }

    setCurrentNavigationIndex((currentNavigationIndex ?? 0) + 1);

    // Get next item and call onNextClick (which has to be set from each sliding panel content component)
    const nextIndex = (currentNavigationIndex ?? 0) + 1;
    const nextItem = navigationData?.[nextIndex];
    props.onNavigation(nextItem);

    // Change selected row & scroll to it
    props.gridRef?.current?.api?.forEachNode(node => {
      if (_.isEqual(node.data, _.omit(nextItem, 'rowIndex'))) {
        node.setSelected(true);
        document.querySelector(`[row-index="${node.rowIndex!}"]`)?.scrollIntoView({ block: "nearest" });
      }
      else {
        node.setSelected(false);
      }
    });

    // Change page if needed
    const pageSize = props.gridRef?.current?.api?.paginationGetPageSize();
    const currentPage = props.gridRef?.current?.api?.paginationGetCurrentPage();
    if (pageSize !== undefined && currentPage !== undefined && ((nextItem.rowIndex + 1) > (pageSize * (currentPage + 1)))) {
      props.gridRef?.current?.api?.paginationGoToNextPage();

      const gridViewportElem = document.querySelectorAll('.grid .ag-body-viewport')?.[0];
      gridViewportElem?.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  const panel = (
    <div className={`lp-slide-panel-container${slidingPanelState.isShown ? ' show' : ' hide'}`}>
      <div onClick={cancel} className="lp-slide-panel-overlay" />
      <div className="lp-slide-panel" style={
        // Prioritize width from slidingPanelState, this way the panel has the correct width
        // during the opening transition, and when closing it will take the width from localPanelState
        slidingPanelState.width
          ? {["--lp-panel-width" as any]: slidingPanelState.width }
          : localPanelState.width
            ? {["--lp-panel-width" as any]: localPanelState.width }
            : {}
      }>
        <div className="lp-slide-panel-title">
          <h4>{localPanelState.title}</h4>
          <div className="lp-slide-panel-title-btns">
            {
              localPanelState.moreButtons && localPanelState.moreButtons.length
              ? <div className="lp-slide-panel-more-button">
                  { localPanelState.moreButtons.length === 1
                    ? IconButtonGrid({
                        type: localPanelState.moreButtons[0].type,
                        callback: () => localPanelState.moreButtons![0].callback()
                      })
                    : <MoreButton id="more-button" listButtons={localPanelState.moreButtons} />
                  }
                </div>
              : null
            }
            { localPanelState.onEdit &&
              <div onClick={edit} className="lp-slide-panel-close">
                <MdEdit />
              </div>
            }
            <div onClick={cancel} className="lp-slide-panel-close">
              <MdClose />
            </div>
          </div>
        </div>
        <div className="lp-slide-panel-content">
          {
            // Prioritize taking the children from slidingPanelState and switch
            // to localPanelState after the Sliding Panel data is cleared
            slidingPanelState.children ? slidingPanelState.children : localPanelState.children
          }
        </div>
        { (localPanelState.allowNavigation && navigationData && navigationData.length && currentNavigationIndex !== undefined) &&
          <div className="lp-slide-panel-footer">
            <div className="lp-slide-panel-navigation">
              {props.onNavigation &&
                <>
                  <div className={`lp-slide-panel-navigation-button lp-panel-nav-left${currentNavigationIndex > 0 ? '' : ' disabled'}`} onClick={onPreviousClick}>
                    <HiChevronLeft /> Previous
                  </div>
                  <div className={`lp-slide-panel-navigation-button lp-panel-nav-right${currentNavigationIndex < navigationData.length - 1 ? '' : ' disabled'}`} onClick={onNextClick}>
                    Next <HiChevronRight />
                  </div>
                </>
              }
            </div>
          </div>
        }
      </div>
    </div>
  );

  return ReactDOM.createPortal(panel, document.body);
}
