import useGridActions from "actions/grid";
import { useCallback, useEffect, useRef, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Title from "components/Title";
import DatePicker from "react-datepicker";
import SlidingPanel from "components/SlidingPanel";
import Loader from "components/Loader";
import { DateFormat } from "utils/constants";
import moment from "moment";
import { getDateOnly } from "utils/date";
import Grid from "components/Grid";
import { getFreeBusySchedule } from "actions/lte";
import CustomSelect from "components/Select/Select";
import { getAvailabilityViewIntervalValues } from "utils/misc";
import { FreeBusyIntervalModel, FreeBusyScheduleModel } from "models/view/FreeBusyScheduleModel";
import FreeBusyCellRenderer from "./FreeBusyCellRenderer";
import { AvailabilityViewInterval } from "enums/AvailabilityViewInterval";
import { DropdownFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/DropdownFloatingFilter";
import { DropdownFilter } from "components/Grid/GridFilters/Filters/DropdownFilter";
import { useAppSelector } from "hooks/appSelector";
import { GridState } from "state/gridSlice";

export default function FreeBusyCalendar() {
  const gridActions = useGridActions();
  const [genericErrors, setGenericErrors] = useState<string | undefined>();
  const grid = useAppSelector((state) => state.grid);
  const gridRef = useRef<GridState>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>(
    moment().startOf('week').toDate()
  );
  const [endDate, setEndDate] = useState<Date>(
    moment().endOf('week').toDate()
  );
  const [availabilityViewInterval, setAvailabilityViewInterval] = useState<string>(AvailabilityViewInterval.Hours12);
  const isFirstRender = useRef<boolean>(false);

  const getUsersAsFakePromise = useCallback(() => {
    //the reference is used in order to always have the updated value when this function gets passed to floatingFilterComponentParams:
    if(!gridRef.current?.rowData || gridRef.current.rowData.length == 0) {
      return Promise.resolve({
        data: []
      });
    }

    const dataArray = gridRef.current.rowData.map(x => ({
      id: x.scheduleId,
      name: x.scheduleId
    }));
  
    return Promise.resolve({
      data: dataArray
    });
  }, []);

  const setGridData = () => {
    setIsLoading(true);
    getFreeBusySchedule(startDate.toISOString(), endDate.toISOString(), availabilityViewInterval).then((response) => 
    {
      const columnDefs: any[] = [
        {
          headerName: "User",
          field: "scheduleId",
          filter: DropdownFilter,
          filterParams: {
            property: "scheduleId",
            suppressFilterButtons: false,
          },
          floatingFilterComponent: DropdownFloatingFilter,
          floatingFilterComponentParams: {
            endpointCall: getUsersAsFakePromise,
          },
          minWidth: 250,
          lockPosition: true,
          sortable: false,
          suppressSizeToFit: true
        }
      ];
      let intervalStartDay: any = undefined;
      let childrenColumnDefs: any[] = [];
      if(response.data.length > 0){
        response.data[0].events.forEach((interval: FreeBusyIntervalModel) => {
          const intervalStart = interval.intervalStart;
          if(intervalStartDay != undefined && intervalStartDay != moment(intervalStart).format(DateFormat.Moment)) {
            columnDefs.push({
              headerName: intervalStartDay,
              children: childrenColumnDefs
            })
            childrenColumnDefs = [];
          }
          if (!childrenColumnDefs.some(def => def.headerName === intervalStart)) {
            const fieldName: string = moment(intervalStart).format(DateFormat.MomentWithTimeNoSpaces);
            childrenColumnDefs.push({ 
              headerName: moment(intervalStart).format(DateFormat.MomentTime),
              field: fieldName,
              cellRenderer: FreeBusyCellRenderer,
              cellRendererParams: {
                availabilityViewInterval: availabilityViewInterval,
                columnKey: fieldName
              },
              lockPosition: true,
              valueGetter: (params: any) => {
                if (params.data[fieldName]) {
                    return params.data[fieldName].status;
                }
                return 'Error';
              },
              minWidth: 125,
              sortable: false,
              floatingFilter: false,
            });
          }
          intervalStartDay = moment(intervalStart).format(DateFormat.Moment)
        });
      }

      if(childrenColumnDefs.length != 0) {
        columnDefs.push({
          headerName: intervalStartDay,
          children: childrenColumnDefs
        })
      }

      response.data.forEach((schedule: FreeBusyScheduleModel) => {
        schedule.events.forEach((interval: FreeBusyIntervalModel) => {
          const intervalStart = moment(interval.intervalStart).format(DateFormat.MomentWithTimeNoSpaces);
          (schedule as any)[intervalStart] = interval;
        });
      });

      gridActions.setGridColumnDefs(columnDefs);
      gridActions.setGridRowData(response.data);
    }).catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  useEffect(() => {
    if(startDate >= endDate) {
      setGenericErrors("Start Date cannot be after End Date");
      return;
    }
    const momentDate1 = moment(startDate);
    const momentDate2 = moment(endDate);
    const daysDifference = momentDate2.diff(momentDate1, 'days');

    if(daysDifference > 62) {
      setGenericErrors("The requested time duration specified is too long. The limit is 62 days.");
      return;
    }

    setGenericErrors(undefined);
    if(isFirstRender.current)
    {
      setGridData();
    }
    else
    {
      isFirstRender.current = true;
    }
  }, [startDate, endDate]);

  useEffect(() => {
    setGridData();
  }, [availabilityViewInterval]);

  useEffect(() => {
    gridRef.current = grid;
  }, [grid]);

  return (
    <div className="lp-page-content">
      <>
        <Title
          type="page"
          title="Free/Busy Calendar"
        >
        </Title>

        <SlidingPanel />

        {genericErrors && (
          <div className="lp-errors">
            {genericErrors}
          </div>
        )}

        <Card className="with-grid">
          {isLoading && <Loader inlineLoader />}

          <Card.Body>
            <div className="lp-calendar-date appointments">
              <CustomSelect
                id="availabilityViewInterval"
                menuPlacement="bottom"
                placeholder="Select Interval"
                options={getAvailabilityViewIntervalValues()}
                onChange={(val) => setAvailabilityViewInterval(val?.id ?? null)}
                value={availabilityViewInterval}
              />
              <span>From</span>
              <DatePicker 
                dateFormat={DateFormat.Datepicker}
                value={moment(startDate).format(DateFormat.Moment)}
                selected={startDate ? getDateOnly(startDate) : null}
                onChange={(date: Date) => {setStartDate(moment(date).startOf('day').toDate())}}
                showMonthDropdown
                showYearDropdown
                autoComplete="off"
              />
              <span>to</span>
              <DatePicker 
                dateFormat={DateFormat.Datepicker}
                value={moment(endDate).format(DateFormat.Moment)}
                selected={endDate ? getDateOnly(endDate) : null}
                onChange={(date: Date) => {setEndDate(moment(date).startOf('day').toDate())}}
                showMonthDropdown
                showYearDropdown
                autoComplete="off"
              />
            </div>
            <Grid
            />
          </Card.Body>
        </Card>
      </>
    </div>
  )
}
