import { Dispatch, useEffect, useRef, useState } from "react";
import Title from 'components/Title';
import ViewMatterSummary from "./MatterSummary/ViewMatterSummary/ViewMatterSummary";
import EditMatterSummary from "./MatterSummary/EditMatterSummary/EditMatterSummary";
import { MatterModel } from "models/view/MatterModel";
import { MdEdit } from "react-icons/md";
import { Row, Col, Button } from 'react-bootstrap';
import MatterFeeEarnerList from "./MatterFeeEarners/MatterFeeEarnerList";
import ParticipatingEntityList from "./ParticipatingEntities/ParticipatingEntityList";
import SlidingPanel from "components/SlidingPanel/index";
import SummaryBox from 'components/SummaryBox';
import { SummaryBoxTypes } from "enums/SummaryBoxTypes";
import SummaryBoxesSection from "components/SummaryBoxesSection";
import { MatterSummaryInfoModel } from "models/view/MatterSummaryInfoModel";
import { getMatterSummaryInfo } from "actions/matter";
import Loader from "components/Loader";
import { MatterInvoiceParticipatingEntitySummaryInfoModel } from "models/view/MatterInvoiceParticipatingEntitySummaryInfoModel";
import React from "react";
import { isMobile } from 'react-device-detect';
import { useAppSelector } from 'hooks/appSelector';
import { UserPermissionsNames } from 'enums/UserPermissionsNames';
import { AgGridReact } from "ag-grid-react";
import { viewCallback } from "./ParticipatingEntities/columnDefs";

type Props = {
  matter: MatterModel | undefined,
  setMatter: Dispatch<React.SetStateAction<MatterModel | undefined>>,
  edit: boolean
}

function ViewMatterDetails(props: Props){
  const [edit, setEdit] = useState(props?.edit ?? false);
  const [matterSummaryInfo, setMatterSummaryInfo] = useState<MatterSummaryInfoModel | undefined>(undefined);
  const [isSummaryInfoLoading, setIsSummaryInfoLoading] = useState<boolean>(false);
  const [genericErrorsForSummaryInfo, setGenericErrorsForSummaryInfo] = useState(undefined);
  const loggedInUser = useAppSelector((state) => state.user);
  const gridRef = useRef<AgGridReact>(null);

  useEffect(() => {
    loadMatterSummaryInfo();
  }, [props.matter]);

  const loadMatterSummaryInfo = () => {
    if(props.matter && props.matter.isCurrentUserAllowedToView) {
      setIsSummaryInfoLoading(true);
      getMatterSummaryInfo(props.matter.id).then((response) => {
        setMatterSummaryInfo(response.data);
      })
      .catch((error) => {
        setGenericErrorsForSummaryInfo(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsSummaryInfoLoading(false);
      });
    }
    else{
      setMatterSummaryInfo(undefined);
    }
  }

  return(
    <>
      {props.matter?.isCurrentUserAllowedToView == false && (
        <div className="lp-errors">
          You are not allowed to view this matter because you are not a fee earner on it. Please contact the case manager if you need access to it.
        </div>
      )}

      {isSummaryInfoLoading &&
        <div className="lp-summary-boxes-placeholder">
          <Loader inlineLoader />
        </div>
      }

      {genericErrorsForSummaryInfo && (
        <div className="lp-errors">
          {genericErrorsForSummaryInfo}
        </div>
      )}

      {matterSummaryInfo && props.matter?.isCurrentUserAllowedToView &&
        <Row>
          <Col>
            <SummaryBoxesSection>
              <SummaryBox
                type={SummaryBoxTypes.TimeDoughnut}
                values={[
                  {
                    value: matterSummaryInfo.totalBilledMinutesRecordableItems ?? 0,
                    label: "Billed",
                    className: "lp-primary"
                  },
                  {
                    value: matterSummaryInfo.totalUnbilledMinutesRecordableItems ?? 0,
                    label: "Unbilled",
                    className: "lp-warning"
                  }
                ]}
              />

              <SummaryBox 
                type={SummaryBoxTypes.FeeIncomeDoughnut}
                values={[
                  {
                    value: matterSummaryInfo.totalBilledRecordableItems ?? 0,
                    label: "Billed",
                    className: "lp-primary"
                  },
                  {
                    value: matterSummaryInfo.totalUnbilledRecordableItems ?? 0,
                    label: "Unbilled",
                    className: "lp-warning"
                  }
                ]}
              />

              <SummaryBox
                type={SummaryBoxTypes.DisbursementsDoughnut}
                values={[
                  {
                    value: matterSummaryInfo.totalBilledDisbursements ?? 0,
                    label: "Billed",
                    className: "lp-primary",
                    
                  },
                  {
                    value: matterSummaryInfo.totalUnbilledDisbursements ?? 0,
                    label: "Unbilled",
                    className: "lp-warning"
                  },
                  ...matterSummaryInfo.totalWriteOffDisbursements ? [{
                    value: matterSummaryInfo.totalWriteOffDisbursements ?? 0,
                    label: "Write-Off",
                    className: "lp-danger"
                  }] : []
                ]}
              />

              <SummaryBox
                type={SummaryBoxTypes.IncidentalExpensesDoughnut}
                values={[
                  {
                    value: matterSummaryInfo.totalBilledIncidentalExpenses ?? 0,
                    label: "Billed",
                    className: "lp-primary"
                  },
                  {
                    value: matterSummaryInfo.totalUnbilledIncidentalExpenses ?? 0,
                    label: "Unbilled",
                    className: "lp-warning"
                  },
                  ...matterSummaryInfo.totalWriteOffIncidentalExpenses ? [{
                    value: matterSummaryInfo.totalWriteOffIncidentalExpenses ?? 0,
                    label: "Write-Off",
                    className: "lp-danger"
                  }] : []
                ]}
              />

              <SummaryBox
                type={SummaryBoxTypes.InvoicesDoughnut}
                values={[
                  {
                    value: matterSummaryInfo.totalPaidInvoices ?? 0,
                    label: "Paid",
                    className: "lp-primary"
                  },
                  {
                    value: matterSummaryInfo.totalUnpaidInvoices ?? 0,
                    label: "Unpaid",
                    className: "lp-warning"
                  },
                  ...matterSummaryInfo.totalWriteOffInvoices ? [{
                    value: matterSummaryInfo.totalWriteOffInvoices ?? 0,
                    label: "Write-Off",
                    className: "lp-danger"
                  }] : []
                ]}
              />

              {matterSummaryInfo?.invoiceParticipatingEntitySummaryInfos &&
                matterSummaryInfo.invoiceParticipatingEntitySummaryInfos.map((x: MatterInvoiceParticipatingEntitySummaryInfoModel, index: number) => (
                  <React.Fragment key={index}>
                    <SummaryBox
                      type={SummaryBoxTypes.InvoiceParticipatingEntityDoughnut}
                      title={x.matterInvoiceDisplayName + ' - ' + x.matterParticipatingEntity?.name + ' - ' + x.matterInvoiceStatus?.name}
                      values={[
                        {
                          value: x.totalRecordableItems ?? 0,
                          label: isMobile ? "Fee" : "Professional Fees",
                          className: "lp-primary"
                        },
                        {
                          value: x.totalDisbursements ?? 0,
                          label: isMobile ? "Dis" : "Disbursements",
                          className: "lp-primary-400"
                        },
                        {
                          value: x.totalIncidentalExpenses ?? 0,
                          label: isMobile ? "Exp" : "Incidental Expenses",
                          className: "lp-primary-700"
                        }
                      ]}
                    />
                  </React.Fragment>
                ))
              }
            </SummaryBoxesSection>
          </Col>
        </Row>
      }

      <Row>
        <Col lg={8} xxl={9}>
          <Title type="section" title={"Summary"}>
            {(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatters) && edit !== true && !props.matter?.isClosed) && props.matter?.isCurrentUserAllowedToView &&
              <Button variant="primary" className="btn-icon" onClick={() => setEdit(true)}>
                <MdEdit />
              </Button>
            }
          </Title>
          {props.matter &&
            <>
              {(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatters) && edit === true) && props.matter?.isCurrentUserAllowedToView ?
                (<EditMatterSummary setEdit={setEdit} matter={props.matter} setMatter={props.setMatter} />)
                :
                (<ViewMatterSummary matter={props.matter} />)
              }
            </>
          }
        </Col>
        <Col lg={4} xxl={3} className="mt-4 mt-lg-0">
          {props.matter?.id && 
            <MatterFeeEarnerList 
              matterId={props.matter?.id}
              matterIsClosed={props.matter?.isClosed}
              isCurrentUserAllowedToViewMatter={props.matter?.isCurrentUserAllowedToView}
              lteHasDelegateUsers={props.matter?.lteHasDelegateUsers}
            />
          }
        </Col>
      </Row>
      {props.matter?.isCurrentUserAllowedToView && 
        <Row>
          <Col>
            {props.matter && 
              <ParticipatingEntityList
                ref={gridRef}
                matterId={props.matter?.id}
                matterTypeId={props.matter.matterTypeId}
                matterIsClosed={props.matter?.isClosed}
              />
            }
          </Col>
        </Row>
      }

      <SlidingPanel gridRef={gridRef} onNavigation={(item: any) => viewCallback(item.id, props.matter?.matterTypeId!, props.matter?.isClosed!)} />
    </>
  );
}

export default ViewMatterDetails;
