import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Form, Button } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import Loader from "components/Loader";
import { validationSuite } from "./validationSuite";
import { removeEmptyFields } from "utils/form";
import { ActivityHistoryCopyModel } from "models/create/ActivityHistoryCopyModel";
import CustomSelect from "components/Select/Select";
import { MatterActivityHistoryModel } from "models/view/MatterActivityHistoryModel";
import { copyActivityHistory, getOpenMattersSummary } from "actions/matter";

type Props = {
  matterId: string,
  activityHistoryRecords: MatterActivityHistoryModel[],
  syncMatterFiles?: Function
}

export default function CopyActivityHistoryForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();

  const {reset, control, handleSubmit, formState: {errors}} = useForm<ActivityHistoryCopyModel>({
    resolver: vestResolver(validationSuite)
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: ActivityHistoryCopyModel) {
    setIsLoading(true);
    data.activityHistoryIds = props.activityHistoryRecords.map(x => x.id);
    removeEmptyFields(data);
    copyActivityHistory(props.matterId, data)
      .then((response) => {
        slidingPanelActions.clearSlidingPanel();
        reset();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Form.Group className="mb-4" controlId="destinationMatterId">
          <Form.Label className="required">Destination Matter</Form.Label>
          <Controller
            control={control}
            name="destinationMatterId"
            shouldUnregister={true}
            render={({field: { onChange, value, name, ref }}) => (
              <CustomSelect
                id="destinationMatterId"
                inputRef={ref}
                className={`lp-select${errors?.destinationMatterId?.message ? ' invalid' : ''}`}
                endpointCall={() => getOpenMattersSummary(props.matterId)}
                value={value}
                onChange={val => onChange(val?.id ?? null)}
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.destinationMatterId?.message && (errors.destinationMatterId.message)}
          </Form.Text>
        </Form.Group>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <Button variant="success" type="submit">Copy</Button>
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
