import { vestResolver } from "@hookform/resolvers/vest";
import { updateUserLteInfo, getEmploymentRelationshipType, getUserPermissions, getUserRoles } from "actions/user";
import { LteUserModel } from "models/view/LteUserModel"
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import CustomSelect from "components/Select/Select";
import { useParams } from "react-router-dom";
import { useAppSelector } from "hooks/appSelector";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import { UpdateUserLteInfoModel } from "models/update/UpdateUserLteInfoModel";
import { getLteBranches, getLteBranchesWithDepartmentsAndTeams, getLteSummaryForDiaryManagerDropdown } from "actions/lte";
import { DateFormat } from "utils/constants";
import { LteDepartmentModel } from "models/view/LteDepartmentModel";
import { LteTeamModel } from "models/view/LteTeamModel";
import { LteBranchModel } from "models/view/LteBranchModel";
import { getValidationSuite } from "./validationSuite";
import { EmploymentRelationshipTypeIds } from "enums/EmploymentRelationshipTypeIds";
import { removeEmptyFields } from "utils/form";
import { getChargingSchemesFromLte } from "actions/chargingSchemes";
import { getFeeEarnerLevelsSummaryForLte } from "actions/settings";
import { getDateOnly } from "utils/date";
import Loader from "components/Loader";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

type Props = {
  user: LteUserModel | undefined,
  setEdit: Dispatch<SetStateAction<boolean>>,
  setUserData: Dispatch<SetStateAction<LteUserModel | undefined>>
}

export default function EditUserLteInfo(props: Props) {
  const { id } = useParams();
  const user = useAppSelector((state) => state.user);
  const [genericErrors, setGenericErrors] = useState(null);
  const [loading, setLoading] = useState(false);

  const [lteBranches, setLteBranches] = useState<LteBranchModel[]>([]);
  const [isLoadingBranches, setIsLoadingBranches] = useState<boolean>(false);
  const [lteDepartments, setLteDepartments] = useState<LteDepartmentModel[]>([]);
  const [isLoadingDepartments, setIsLoadingDepartments] = useState<boolean>(false);
  const [lteDepartmentsDisabled, setLteDepartmentsDisabled] = useState<boolean>(true);
  const [lteTeams, setLteTeams] = useState<LteTeamModel[]>([]);
  const [isLoadingTeams, setIsLoadingTeams] = useState<boolean>(false);
  const [lteTeamsDisabled, setLteTeamsDisabled] = useState<boolean>(true);

  const { register, reset, control, watch, setValue, clearErrors, handleSubmit, formState: { errors } } = useForm<UpdateUserLteInfoModel>({
    resolver: vestResolver(getValidationSuite(lteBranches)),
    defaultValues: props.user
  });

  useEffect(() => {
    setIsLoadingBranches(true);
    getLteBranchesWithDepartmentsAndTeams(props.user!.lawPageTradingEntityId)
      .then(response => {
        setLteBranches(response.data);

        var lteDepartments = response.data.find((x: any) => x.id === props.user?.lteBranch?.id).children;
        setLteDepartments(lteDepartments);
        setIsLoadingDepartments(false);

        var lteTeams = lteDepartments.find((x: any) => x.id === props.user?.lteDepartment?.id)?.children;
        setLteTeams(lteTeams ?? []);
        setIsLoadingTeams(false);

        if (props.user?.lteDepartmentId || lteDepartments !== undefined) {
          setLteDepartmentsDisabled(false);
        }
        if (props.user?.lteTeamId || lteTeams !== undefined) {
          setLteTeamsDisabled(false);
        }
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoadingBranches(false);
      });
  }, []);

  const onSubmit = handleSubmit((data: UpdateUserLteInfoModel) => submitData(data));

  async function submitData(data: UpdateUserLteInfoModel) {
    setLoading(true);
    removeEmptyFields(data);

    updateUserLteInfo(id ?? user.userId!, data).then((response) => {
      if (response?.data.userPermissions) {
        response.data.userPermissionsIds = response.data.userPermissions.map((a: any) => a.id);
      }

      if (response?.data.managedDiaries) {
        response.data.managedDiariesIds = response.data.managedDiaries.map((a: any) => a.id);
      }
      
      props.setUserData(response.data);
      props.setEdit(false);
      reset();
    }).catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setLoading(false);
    });
  }

  const onGetLteBranch = (value: any) => {
    try {
      setValue("lteDepartmentId", undefined);
      setValue("lteTeamId", undefined);
      setIsLoadingDepartments(true);

      let departments: LteDepartmentModel[] = [];
      if (value != null) {
        const selectedBranch = lteBranches?.find(x => x.id === value);
        departments = selectedBranch?.children ?? [];
        setLteDepartments(departments);
        setLteTeams([]);
        setLteDepartmentsDisabled(false);
      }
      else {
        setLteDepartments([]);
        setLteTeams([]);
        setLteDepartmentsDisabled(true);
        setLteTeamsDisabled(true);
      }

      setIsLoadingDepartments(false);
    } catch (err: any) {
      setGenericErrors(err);
    };
  }

  const onGetLteDepartment = (value: any) => {
    try {
      setValue("lteTeamId", undefined);
      setIsLoadingTeams(true);

      let teams: LteTeamModel[] = [];
      if (value != null) {
        const selectedDepart = lteDepartments?.find(x => x.id === value);
        teams = selectedDepart?.children ?? [];
        setLteTeams(teams);
        setLteTeamsDisabled(false);
      }
      else {
        setLteTeams([]);
        setLteTeamsDisabled(true);
      }

      setIsLoadingTeams(false);
    } catch (err: any) {
      setGenericErrors(err);
    };
  }

  const onChangeIsFeeEarner = () => {
    clearErrors('defaultFeeEarnerLevelId');
    clearErrors('defaultChargingSchemeId');
  }

  const onChangeEmploymentRelationshipTypeId = () => {
    clearErrors('employmentStartDate');
  }

  return (
    <Card>
      {loading && <Loader inlineLoader />}

      <Card.Body>
        {genericErrors && (
          <div className="lp-errors">
            {genericErrors}
          </div>
        )}

        <Form onSubmit={onSubmit}>
          <Row className="multiple">
            <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="employmentRelationshipTypeId">
              <Form.Label className="required">Relationship with Business</Form.Label>
              <Controller
                control={control}
                name="employmentRelationshipTypeId"
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <CustomSelect
                    id="employmentRelationshipTypeId"
                    inputRef={ref}
                    className={`lp-select${errors?.employmentRelationshipTypeId?.message ? ' invalid' : ''}`}
                    endpointCall={getEmploymentRelationshipType}
                    value={value}
                    onChange={val => {onChange(val?.id ?? null); onChangeEmploymentRelationshipTypeId();}}
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.employmentRelationshipTypeId?.message && (errors.employmentRelationshipTypeId.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="employmentStartDate">
              <Form.Label
                className={`${watch('employmentRelationshipTypeId') == EmploymentRelationshipTypeIds.EmployeeId ? "required" : ""}`}
              >
                Employment Start Date
              </Form.Label>
              <Controller
                control={control}
                name="employmentStartDate"
                shouldUnregister={true}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    className={`${errors?.employmentStartDate?.message ? 'invalid' : ''}`}
                    dateFormat={DateFormat.Datepicker}
                    selected={value ? getDateOnly(value) : null}
                    onChange={(val) => onChange(val != null ? getDateOnly(val) : val)}
                    showMonthDropdown
                    showYearDropdown
                    autoComplete="off"
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.employmentStartDate?.message && (errors.employmentStartDate.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="employmentEndDate">
              <Form.Label>Employment End Date</Form.Label>
              <Controller
                control={control}
                name="employmentEndDate"
                shouldUnregister={true}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    dateFormat={DateFormat.Datepicker}
                    selected={value ? getDateOnly(value) : null}
                    onChange={(val) => onChange(val != null ? getDateOnly(val) : val)}
                    showMonthDropdown
                    showYearDropdown
                    autoComplete="off"
                  />
                )}
              />
            </Form.Group>

            <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="jobTitle">
              <Form.Label className="required">Job Title</Form.Label>
              <Form.Control
                type="text"
                className={`${errors?.jobTitle?.message ? 'invalid' : ''}`}
                {...register("jobTitle", {shouldUnregister: true})}
              />
              <Form.Text className="lp-error">
                {errors?.jobTitle?.message && (errors.jobTitle.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="lteBranchId">
              <Form.Label className="required">Branch</Form.Label>
              <Controller
                control={control}
                name="lteBranchId"
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <CustomSelect
                    id="lteBranchId"
                    inputRef={ref}
                    className={`lp-select${errors?.lteBranchId?.message ? ' invalid' : ''}`}
                    endpointCall={() => getLteBranches(props.user!.lawPageTradingEntityId)}
                    value={value}
                    isLoading={isLoadingBranches}
                    onChange={val => { onChange(val?.id ?? null); onGetLteBranch(val?.id ?? null); }}
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.lteBranchId?.message && (errors.lteBranchId.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="lteDepartmentId">
              <Form.Label className={`${lteDepartments.length > 0 ? "required" : ""}`}>Department</Form.Label>
              <Controller
                control={control}
                name="lteDepartmentId"
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <CustomSelect
                    id="lteDepartmentId"
                    inputRef={ref}
                    className={`lp-select${errors?.lteDepartmentId?.message ? ' invalid' : ''}`}
                    options={lteDepartments.map(x => ({ id: x.id, name: x.name }))}
                    isLoading={isLoadingDepartments}
                    isDisabled={lteDepartmentsDisabled}
                    value={value}
                    onChange={val => { onChange(val?.id ?? null); onGetLteDepartment(val?.id ?? null); }}
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.lteDepartmentId?.message && (errors.lteDepartmentId.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="lteTeamId">
              <Form.Label className={`${lteTeams.length > 0 ? "required" : ""}`}>Team</Form.Label>
              <Controller
                control={control}
                name="lteTeamId"
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <CustomSelect
                    id="lteTeamId"
                    inputRef={ref}
                    className={`lp-select${errors?.lteTeamId?.message ? ' invalid' : ''}`}
                    options={lteTeams.map(x => ({ id: x.id, name: x.name }))}
                    value={value}
                    isDisabled={lteTeamsDisabled}
                    isLoading={isLoadingTeams}
                    onChange={val => onChange(val?.id ?? null)}
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.lteTeamId?.message && (errors.lteTeamId.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="annualHolidayEntitlement">
              <Form.Label
                className={`${watch('employmentRelationshipTypeId') == EmploymentRelationshipTypeIds.EmployeeId ? "required" : ""}`}
              >
                Annual Holiday Entitlement
              </Form.Label>
              <Form.Control
                type="number"
                className={`${errors?.annualHolidayEntitlement?.message ? 'invalid' : ''}`}
                {...register("annualHolidayEntitlement", {shouldUnregister: true})}
                min="0"
                onWheel={e => e.currentTarget.blur()}
              />
              <Form.Text className="lp-error">
                {errors?.annualHolidayEntitlement?.message && (errors.annualHolidayEntitlement.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="regularPayEntitlement">
              <Form.Label>Regular Pay Entitlement</Form.Label>
              <Controller
                control={control}
                name="regularPayEntitlement"
                shouldUnregister={true}
                render={({field: { onChange, value, name, ref }}) => (
                  <Form.Check 
                    type="switch"
                    id="regularPayEntitlement">
                      <Form.Check.Input
                        className= "form-check-input"
                        ref={ref}
                        checked={value}
                        onChange={(ev: any) => onChange(ev.target.checked)}
                      />
                  </Form.Check>
                )}
              />
            </Form.Group>

            <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="feeIncentiveSchemeEntitlement">
              <Form.Label>Fee Incentive Scheme Entitlement</Form.Label>
              <Controller
                control={control}
                name="feeIncentiveSchemeEntitlement"
                shouldUnregister={true}
                render={({field: { onChange, value, name, ref }}) => (
                  <Form.Check 
                    type="switch"
                    id="feeIncentiveSchemeEntitlement">
                      <Form.Check.Input
                        className= "form-check-input"
                        ref={ref}
                        checked={value}
                        onChange={(ev: any) => onChange(ev.target.checked)}
                      />
                  </Form.Check>
                )}
              />
            </Form.Group>

            <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="overtimeEntitlement">
              <Form.Label>Overtime Entitlement</Form.Label>
              <Controller
                control={control}
                name="overtimeEntitlement"
                shouldUnregister={true}
                render={({field: { onChange, value, name, ref }}) => (
                  <Form.Check 
                    type="switch"
                    id="overtimeEntitlement">
                      <Form.Check.Input
                        className= "form-check-input"
                        ref={ref}
                        checked={value}
                        onChange={(ev: any) => onChange(ev.target.checked)}
                      />
                  </Form.Check>
                )}
              />
            </Form.Group>

            <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="nonFeeIncentiveEntitlement">
              <Form.Label>Non Fee Incentive Entitlement</Form.Label>
              <Controller
                control={control}
                name="nonFeeIncentiveEntitlement"
                shouldUnregister={true}
                render={({field: { onChange, value, name, ref }}) => (
                  <Form.Check 
                    type="switch"
                    id="nonFeeIncentiveEntitlement">
                      <Form.Check.Input
                        className= "form-check-input"
                        ref={ref}
                        checked={value}
                        onChange={(ev: any) => onChange(ev.target.checked)}
                      />
                  </Form.Check>
                )}
              />
            </Form.Group>

            <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="userHourlyCost">
              <Form.Label className="required">User Hourly Cost</Form.Label>
              <Form.Control
                type="number"
                className={`${errors?.userHourlyCost?.message ? 'invalid' : ''}`}
                {...register("userHourlyCost", {shouldUnregister: true})}
                min="0"
                step="0.01"
                onWheel={e => e.currentTarget.blur()}
              />
              <Form.Text className="lp-error">
                {errors?.userHourlyCost?.message && (errors.userHourlyCost.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="annualFeeIncomeTarget">
              <Form.Label className="required">Annual Fee Income Target</Form.Label>
              <Form.Control
                type="number"
                className={`${errors?.annualFeeIncomeTarget?.message ? 'invalid' : ''}`}
                {...register("annualFeeIncomeTarget", {shouldUnregister: true})}
                min="0"
                step="0.01"
                onWheel={e => e.currentTarget.blur()}
              />
              <Form.Text className="lp-error">
                {errors?.annualFeeIncomeTarget?.message && (errors.annualFeeIncomeTarget.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="isFeeEarner">
              <Form.Label>Fee Earner</Form.Label>
              <Controller
                control={control}
                name="isFeeEarner"
                shouldUnregister={true}
                render={({field: { onChange, value, name, ref }}) => (
                  <Form.Check 
                    type="switch"
                    id="isFeeEarner">
                      <Form.Check.Input
                        className= "form-check-input"
                        ref={ref}
                        checked={value}
                        onChange={(ev: any) => {onChange(ev.target.checked);onChangeIsFeeEarner();}}
                      />
                  </Form.Check>
                )}
              />
            </Form.Group>

            <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="defaultFeeEarnerLevelId">
              <Form.Label className={`${watch('isFeeEarner') === true ? " required" : ""}`}>
                Default Fee Earner Level
              </Form.Label>
              <Controller
                control={control}
                name="defaultFeeEarnerLevelId"
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <CustomSelect
                    id="defaultFeeEarnerLevelId"
                    inputRef={ref}
                    className={`lp-select${errors?.defaultFeeEarnerLevelId?.message ? ' invalid' : ''}`}
                    endpointCall={() => getFeeEarnerLevelsSummaryForLte(props.user!.lawPageTradingEntityId)}
                    value={value}
                    onChange={val => onChange(val?.id ?? null)}
                    isClearable={watch('isFeeEarner') === false}
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.defaultFeeEarnerLevelId?.message && (errors.defaultFeeEarnerLevelId.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="defaultChargingSchemeId">
              <Form.Label className={`${watch('isFeeEarner') === true ? " required" : ""}`}>
                Default Charging Scheme
              </Form.Label>
              <Controller
                control={control}
                name="defaultChargingSchemeId"
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <CustomSelect
                    id="defaultChargingSchemeId"
                    inputRef={ref}
                    className={`lp-select${errors?.defaultChargingSchemeId?.message ? ' invalid' : ''}`}
                    endpointCall={() => getChargingSchemesFromLte(props.user!.lawPageTradingEntityId)}
                    value={value}
                    onChange={val => onChange(val?.id ?? null)}
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.defaultChargingSchemeId?.message && (errors.defaultChargingSchemeId.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="undertakesLegalAidWork">
              <Form.Label>Undertakes Legal Aid Work</Form.Label>
              <Controller
                control={control}
                name="undertakesLegalAidWork"
                shouldUnregister={true}
                render={({field: { onChange, value, name, ref }}) => (
                  <Form.Check 
                    type="switch"
                    id="undertakesLegalAidWork">
                      <Form.Check.Input
                        className= "form-check-input"
                        ref={ref}
                        checked={value}
                        onChange={(ev: any) => onChange(ev.target.checked)}
                      />
                  </Form.Check>
                )}
              />
            </Form.Group>

            <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="active">
              <Form.Label>Is Active</Form.Label>
              <Controller
                control={control}
                name="active"
                shouldUnregister={true}
                render={({field: { onChange, value, name, ref }}) => (
                  <Form.Check 
                    type="switch"
                    id="active">
                      <Form.Check.Input
                        className= "form-check-input"
                        ref={ref}
                        checked={value}
                        onChange={(ev: any) => onChange(ev.target.checked)}
                      />
                  </Form.Check>
                )}
              />
            </Form.Group>

            <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="userRoleId">
              <Form.Label className="required">User Role</Form.Label>
              <Controller
                control={control}
                name="userRoleId"
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <CustomSelect
                    id="userRoleId"
                    inputRef={ref}
                    className={`lp-select${errors?.userRoleId?.message ? ' invalid' : ''}`}
                    endpointCall={() => getUserRoles(props.user!.lawPageTradingEntityId)}
                    value={value}
                    onChange={val => onChange(val?.id ?? null)}
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.userRoleId?.message && (errors.userRoleId.message)}
              </Form.Text>
            </Form.Group>

            {
              (user.userPermissions?.includes(UserPermissionsNames.ManageAllLTEs) || 
              (user.userPermissions?.includes(UserPermissionsNames.ManageLTE) && props.user?.lawPageTradingEntityId == user.lawPageTradingEntityId)) &&
              <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="managedDiariesIds">
                <Form.Label>Business Diaries Managed by this User</Form.Label>
                <Controller
                  control={control}
                  name="managedDiariesIds"
                  shouldUnregister={true}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <CustomSelect
                      id="managedDiariesIds"
                      inputRef={ref}
                      className={`lp-select${errors?.managedDiariesIds?.message ? ' invalid' : ''}`}
                      endpointCall={() => getLteSummaryForDiaryManagerDropdown(id ?? user.userId!)}
                      value={value}
                      onChange={val => onChange(val?.map((x: { id: any; }) => x.id) ?? null)}
                      isMulti
                      isClearable
                    />
                  )}
                />
              </Form.Group>
            }

            <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="userPermissionsIds">
              <Form.Label>Additional User Permissions</Form.Label>
              <Controller
                control={control}
                name="userPermissionsIds"
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <CustomSelect
                    id="userPermissionsIds"
                    inputRef={ref}
                    endpointCall={() => getUserPermissions(props.user!.lawPageTradingEntityId)}
                    isMulti
                    isClearable
                    value={value}
                    onChange={val => onChange(val?.map((x: { id: any; }) => x.id) ?? null)}
                  />
                )}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group className="d-flex justify-content-between">
              <Button variant="success" type="submit">Update</Button>
              <Button variant="secondary-400" onClick={() => props.setEdit(false)}>Cancel</Button>
            </Form.Group>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
}
