import { MatterRecordableItemModel } from "models/view/MatterRecordableItemModel";
import { ActivityDropdownForRecordTimeModel } from "models/view/ActivityDropdownForRecordTimeModel";
import { ChargingBasisIds } from "enums/ChargingBasisIds";
import { Col, Row } from "react-bootstrap";
import Field from "components/Fields/Field";

type Props = {
  matterRecordableItems: MatterRecordableItemModel[],
  activities: ActivityDropdownForRecordTimeModel[],
}

export default function ViewMatterRecordableEventActivityList(props: Props) {
  return (
    (props.matterRecordableItems?.length && props.matterRecordableItems?.length > 0) ? (
      <div className="lp-box-list auto">
        {props.matterRecordableItems.map((item: MatterRecordableItemModel, index: number) => {
          const activity = props.activities.find(x => x.id === item.activityId) ?? null;

          return (
            <div key={item.id} className="lp-charge-rate-item read-only">
              { item.isBilled && (
                  <span className="billed-icon">Billed</span>
                )
              }

              <Row>
                <Col>
                  <Field
                    label={"Activity"}
                    value={activity?.name}
                  />
                </Col>
              </Row>

              { activity?.chargingBasis === ChargingBasisIds.TimeId && (
                <Row>
                  <Col xs={12} sm={6}>
                    <Field
                      label={"Start Time"}
                      value={item.startTime}
                    />
                  </Col>

                  <Col xs={12} sm={6} className="mt-4 mt-sm-0">
                    <Field
                      label={"End Time"}
                      value={item.endTime}
                    />
                  </Col>
                </Row>
              )}

              <Row>
                <Col xs={12} sm={6}>
                  <Field
                    label={"Number of Minutes"}
                    value={item.numberOfMinutes}
                  />
                </Col>

                <Col xs={12} sm={6} className="mt-4 mt-sm-0">
                  <Field
                    label={"Number of Units"}
                    value={item.numberOfUnits}
                  />
                </Col>
              </Row>
            </div>
          )
        })}
      </div>
    ) : (
      <div className="lp-box-list auto">
        <div className="lp-box-list-item">
          No activity found
        </div>
      </div>
    )
  );
}
