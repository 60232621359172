import { useState } from 'react';
import Loader from 'components/Loader';
import { Form, Button } from 'react-bootstrap';
import CustomSelect from 'components/Select/Select';
import {
  addEmailAttachments,
  getEmailAttachmentsSuggestions,
} from 'actions/matter';
import useSlidingPanelActions from 'actions/slidingPanel';
import { useAppSelector } from 'hooks/appSelector';

type Props = {
  matterId: string;
  emailId: string;
  syncMatterFiles?: Function;
  onSubmitCallback?: Function;
};

function SaveAttachments(props: Props) {
  const [attachmentsIds, setAttachmentsIds] = useState<string[]>();
  const [genericErrors, setGenericErrors] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();

    currentSlidingPanelState.onCancel !== undefined && currentSlidingPanelState.onCancel();
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (attachmentsIds == null || attachmentsIds == undefined || attachmentsIds.length == 0) {
      return setGenericErrors('Attachments cannot be null.');
    }
    setIsLoading(true);
    addEmailAttachments(
      props.matterId,
      props.emailId,
      attachmentsIds
    )
      .then(() => {
        if(props.syncMatterFiles) {
          props.syncMatterFiles();
        }
        slidingPanelActions.clearSlidingPanel();

        props.onSubmitCallback && props.onSubmitCallback();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && <div className="lp-errors">{genericErrors}</div>}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Form.Group className="mb-4" controlId="files">
          <Form.Label className="required">Attachments:</Form.Label>
              <CustomSelect
                id="attachmentsIds"
                endpointCall={() =>
                  getEmailAttachmentsSuggestions(props.matterId, props.emailId)
                }
                onChange={(val) =>
                  setAttachmentsIds(val?.map((x: { id: any }) => x.id) ?? null)
                }
                value={attachmentsIds}
                isMulti
                isClearable
              />
        </Form.Group>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <Button variant="success" type="submit">
              Upload
            </Button>
            <Button variant="secondary-400" onClick={cancelForm}>
              Cancel
            </Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}

export default SaveAttachments;
