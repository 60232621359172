import useSlidingPanelActions from "actions/slidingPanel";
import SlidingPanel from 'components/SlidingPanel';
import Title from "components/Title/index";
import { Button, Card } from "react-bootstrap";
import { MdAddCircle } from "react-icons/md";
import Grid from "components/Grid/index";
import { useCallback, useState } from "react";
import { getCompleteChargingSchemes } from "actions/chargingSchemes";
import useGridActions from "actions/grid";
import { getColumnDefs } from "./columnDefs";
import CreateChargingScheme from "./CreateChargingScheme/CreateChargingScheme";
import { useAppSelector } from 'hooks/appSelector';
import { UserPermissionsNames } from 'enums/UserPermissionsNames';

export default function ChargingSchemeList() {
  const gridActions = useGridActions();
  const slidingPanelActions = useSlidingPanelActions();
  const [genericErrors, setGenericErrors] = useState(null);
  const loggedInUser = useAppSelector((state) => state.user);

  const toggleSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Add Charging Scheme",
        children: <CreateChargingScheme />
      });
  };

  const onGridReady = useCallback(() =>
  {
    const columnDefs = getColumnDefs();
    gridActions.setGridColumnDefs([...columnDefs]);
    getCompleteChargingSchemes().then((response) => 
    {
      gridActions.setGridRowData(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
      gridActions.setGridRowData([]);
    });
  }, []);

  return (
    <div className="lp-page-content">
      <Title
        type="page"
        title="Charging Schemes"
      >
        {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageChargingSchemes) &&
          <Button variant="success" onClick={toggleSlidingPanel}>
            <MdAddCircle />
            Add Charging Scheme
          </Button>
        }
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}
      
      <Card className="with-grid">

        <Card.Body>
          <Grid
            onGridReady={onGridReady}
          />
        </Card.Body>
      </Card>

      <SlidingPanel/>
    </div>
  );
}
