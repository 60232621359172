import { ColDef } from "ag-grid-community";
import store from "state/store";
import { ModalState, setModalData, setModalErrors, toggleModal, toggleModalLoadingState } from "state/modalSlice";
import { setRowData } from "state/gridSlice";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import CheckboxCellRenderer from "components/Grid/CheckboxCellRenderer";
import { BooleanFilter } from "components/Grid/GridFilters/Filters/BooleanFilter";
import { BooleanFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/BooleanFloatingFilter";
import { TaxonomyTermModel } from "models/view/TaxonomyTermModel";
import { DropdownFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/DropdownFloatingFilter";
import { changeMatterTaxonomyTermStatus, deleteMatterTaxonomyTerm, getMatterTaxonomyTermsSummary } from "actions/taxonomy";
import { DropdownFilter } from "components/Grid/GridFilters/Filters/DropdownFilter";
import TreeSelect from "rc-tree-select";
import EditMatterTaxonomyTermForm from "./EditMatterTaxonomyTerm/EditMatterTaxonomyTermForm";
import { compareNumberedSectionStrings } from "utils/misc";
import { CheckboxFormatter } from "components/Grid/ValueFormatters/CheckboxFormatter";

const editCallback = (matterId: string, taxonomyId: string, taxonomyTermId: string, reloadGridData?: Function) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        title: "Edit Taxonomy Term",
        children: <EditMatterTaxonomyTermForm
          matterId={matterId}
          taxonomyId={taxonomyId}
          taxonomyTermId={taxonomyTermId}
          onSubmitCallback={() => reloadGridData ? reloadGridData() : {}}
        />
      }
    )
  );
}

const changeStatusModal = (matterId: string, taxonomyId: string, taxonomyTermId: string) => {
  let bodyMessage: React.ReactNode;
  const gridState = store.getState().grid;
  if (!gridState.rowData.find((x: TaxonomyTermModel) => x.id == taxonomyTermId).active) {
    bodyMessage = "Are you sure you want to activate this Taxonomy Term?";
  } else {
    bodyMessage = "Are you sure you want to deactivate this Taxonomy Term?";
  }
  let modal: ModalState = {
    title: "Confirmation",
    body: bodyMessage,
    onAction: () => changeStatus(matterId, taxonomyId, taxonomyTermId),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
}

const changeStatus = (matterId: string, taxonomyId: string, taxonomyTermId: string) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  changeMatterTaxonomyTermStatus(matterId, taxonomyId, taxonomyTermId).then(() => {
    let index: number = gridState.rowData.findIndex((x: any) => x.id == taxonomyTermId);
    let newEntry: TaxonomyTermModel = { ...gridState.rowData[index], active: !gridState.rowData[index].active };
    let newArray: Array<any> = [...gridState.rowData];
    newArray[index] = newEntry;
    store.dispatch(setRowData(newArray));
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

const deleteModal = (matterId: string, taxonomyId: string, taxonomyTermId: string, reloadGridData?: Function) => {
  let modal: ModalState = {
    title: "Delete confirmation",
    body: "Are you sure you want to delete this Taxonomy Term?",
    actionText: "Delete",
    actionVariant: "danger",
    onAction: () => deleteCallback(matterId, taxonomyId, taxonomyTermId, reloadGridData),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
};

const deleteCallback = (matterId: string, taxonomyId: string, taxonomyTermId: string, reloadGridData?: Function) => {
  store.dispatch(toggleModalLoadingState());
  deleteMatterTaxonomyTerm(matterId, taxonomyId, taxonomyTermId).then(() => {
    reloadGridData && reloadGridData();
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

export const getColumnDefs = (matterId: string, taxonomyId: string, isTaxonomyHierarchical: boolean, taxonomyMatterId?: string, reloadGridData?: Function) => {
  const columnDefs: (ColDef<TaxonomyTermModel> | {excludeFromExport: boolean})[] = [
    {
      headerName: 'Display Name',
      field: 'displayNameWithFullSequence',
      filter: 'agTextColumnFilter',
      comparator: (valueA, valueB, nodeA, nodeB) => {
        if(isTaxonomyHierarchical) {
          return compareNumberedSectionStrings(nodeA.data?.fullSequence, nodeB.data?.fullSequence);
        }
        return (valueA == valueB) ? 0 : (valueA > valueB) ? 1 : -1;
      },
      flex: 2,
    },
    {
      headerName: 'Description',
      field: 'description',
      filter: 'agTextColumnFilter',
      flex: 4,
    },
    {
      headerName: 'Parent Term',
      field: 'parentTaxonomyTerm.displayNameWithFullSequence',
      filter: DropdownFilter,
      filterParams: {
        property: 'parentTaxonomyTermId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: () => getMatterTaxonomyTermsSummary(matterId, taxonomyId, true),
        showCheckedStrategy: TreeSelect.SHOW_ALL
      },
      minWidth: 220,
      hide: !isTaxonomyHierarchical
    },
    {
      headerName: 'Usage Count',
      field: 'usageCount',
      filter: 'agNumberColumnFilter'
    },
    {
      headerName: 'Is Active',
      field: 'active',
      filter: BooleanFilter,
      filterParams: {
        property: 'active',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: BooleanFloatingFilter,
      floatingFilterComponentParams: {
        values: [{
          id: 'true',
          name: 'Yes'
        },
        {
          id: 'false',
          name: 'No'
        },
      ]},
      minWidth: 100,
      width: 100,
      cellRenderer: CheckboxCellRenderer,
      valueFormatter: CheckboxFormatter
    },
    { 
      excludeFromExport: true,
      cellClass: 'lp-actions-cell',
      suppressHeaderMenuButton: true,
      suppressColumnsToolPanel: true,
      cellRenderer: ActionsCellRenderer,
      cellRendererParams: (params: any) => {
        if(taxonomyMatterId != matterId){
          return {
            buttonsData: []
          }
        }
        else {
          return { buttonsData: [
            {
              type: ActionButtonTypes.Edit,
              callback: (id: string) => editCallback(matterId, taxonomyId, id, reloadGridData)
            },
            {
              type: params.data.active ? ActionButtonTypes.Deactivate : ActionButtonTypes.Activate,
              callback: (id: string) => changeStatusModal(matterId, taxonomyId, id)
            },
            {
              type: ActionButtonTypes.Delete,
              callback: (id: string) => deleteModal(matterId, taxonomyId, id, reloadGridData)
            }
          ]};
        }
      },
      suppressSizeToFit: true,
      floatingFilter: false,
      sortable: false,
      minWidth: 105,
      maxWidth: 105,
      width: 105,
      suppressMovable: true,
      pinned: 'right',
      resizable: false,
      hide: taxonomyMatterId != matterId
    }
  ];
  return columnDefs;
}
