import { MatterIncidentalExpenseModel } from "models/view/MatterIncidentalExpenseModel";
import moment from "moment";
import { DateFormat } from "utils/constants";
import BooleanField from "components/Fields/BooleanField";
import { Col, Row } from "react-bootstrap";
import Field from "components/Fields/Field";
import { formatCurrency } from "utils/misc";

type Props = {
  matterIncidentalExpenses: MatterIncidentalExpenseModel[],
}

export default function ViewMatterRecordableEventIncidentalExpenseList(props: Props) {
  return (
    (props.matterIncidentalExpenses?.length && props.matterIncidentalExpenses?.length > 0) ? (
      <>
        <div className="lp-color-title primary full-width">Incidental Expenses</div>
        <div className="lp-box-list auto">
          {props.matterIncidentalExpenses.map((item: MatterIncidentalExpenseModel, index: number) => (
              <div key={item.id} className="lp-charge-rate-item read-only">
                { item.isBilled && (
                    <span className="billed-icon">Billed</span>
                  )
                }

                <Row>
                  <Col xs={12} sm={6}>
                    <Field
                      label={"Date"}
                      value={moment(item.date).format(DateFormat.Moment)}
                    />
                  </Col>

                  <Col xs={12} sm={6} className="mt-4 mt-sm-0">
                    <Field
                      label={"Display Name"}
                      value={item.displayName}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Field
                      label={"Description"}
                      value={item.description}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <BooleanField
                      label={"Paid By User's Own Funds"}
                      value={item.paidByUserOwnFunds ?? false}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} sm={4}>
                    <Field
                      label={"Gross Value"}
                      value={item.grossValue ? formatCurrency(item.grossValue) : "—"}
                    />
                  </Col>

                  <Col xs={12} sm={4} className="mt-4 mt-sm-0">
                    <Field
                      label={"VAT Value"}
                      value={item.vatValue ? formatCurrency(item.vatValue) : "—"}
                    />
                  </Col>

                  <Col xs={12} sm={4} className="mt-4 mt-sm-0">
                    <Field
                      label={"Net Value"}
                      value={item.netValue ? formatCurrency(item.netValue) : "—"}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Field
                      label={"Proofs of Purchase"}
                      value={item.files?.length ? item.files?.map(e => e.name)?.join(", ") : "—"}
                    />
                  </Col>
                </Row>
              </div>
            )
          )}
        </div>
      </>
    ) : (
      <></>
    )
  );
}
