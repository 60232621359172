import useGridActions from "actions/grid";
import Grid from "components/Grid/index";
import { useCallback, useState } from "react";
import { MdAdd } from "react-icons/md";
import { getColumnDefs } from "./columnDefs";
import { Row, Col, Card, Button } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import Title from "components/Title/index";
import SlidingPanel from "components/SlidingPanel/index";
import CreateTemplate from "../TemplateActions/CreateTemplate/CreateTemplate";
import { getMatterTemplates } from "actions/settings";
import { useAppSelector } from "hooks/appSelector";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

export default function MatterTemplates() {
  const gridActions = useGridActions();
  const slidingPanelActions = useSlidingPanelActions();
  const [genericErrors, setGenericErrors] = useState(null);
  const loggedInUser = useAppSelector((state) => state.user);

  const createTemplate = () => {
    slidingPanelActions.setSlidingPanel(
      {
        width: "55rem",
        isShown: true,
        title: "Create Template",
        children: <CreateTemplate 
          refreshTemplatesPage={loadGridData}
          isMatterTemplate
        />
      }
    );
  };

  const loadGridData = () => {
    getMatterTemplates().then((response) => {
      gridActions.setGridRowData(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
      gridActions.setGridRowData([]);
    });
  }

  const onGridReady = useCallback(() =>
  {
    const columnDefs = getColumnDefs();
    gridActions.setGridColumnDefs([...columnDefs]);
    loadGridData();
  }, []);

  return (
    <>
      <Row>
        <Col>
          <Title type="section" title={"Matter Templates"}>
            {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageLTESettings) &&
              <Button onClick={createTemplate} className="btn-icon" variant="success">
                <MdAdd />
              </Button>
            }
          </Title>

          {genericErrors && (
            <div className="lp-errors">
              {genericErrors}
            </div>
          )}

          <Card className="with-grid">
            <Card.Body>
              <Grid 
                onGridReady={onGridReady}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <SlidingPanel />
    </>
  );
}
