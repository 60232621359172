import Loader from 'components/Loader';
import { Button, Modal } from 'react-bootstrap';
import "./style.scss";
import { useEffect, useState } from 'react';
import { getDelegateLtesForUserSummary, useUserActions } from 'actions/user';
import { useNavigate } from 'react-router-dom';
import CustomSelect from 'components/Select/Select';
import { DropDownOptionModel } from 'models/view/DropDownOptionModel';

type Props = {
  showModal: boolean,
  onAction?: Function,
  onCancel?: Function
}

export default function ChangeLTEModal(props: Props) {
  const [selectedChangeLTEId, setSelectedChangeLTEId] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingLTEs, setIsLoadingLTEs] = useState<boolean>(false);
  const [ltes, setLTEs] = useState<DropDownOptionModel[]>([]);
  const [genericErrors, setGenericErrors] = useState(null);
  const userActions = useUserActions();
  const navigate = useNavigate();

  useEffect(() => {
    if(props.showModal) {
      setIsLoadingLTEs(true);
      getDelegateLtesForUserSummary().then((response) => {
        setLTEs(response.data);

        if(response.data.length > 0){
          setSelectedChangeLTEId(response.data[0].id);
        }
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoadingLTEs(false);
      });
    }
  }, [props.showModal]);

  const handleClose = () => {
    props.onCancel && props.onCancel();
  };
  
  const doAction = async () => {
    if(selectedChangeLTEId) {
      setIsLoading(true);
      try {
        await userActions.changeLTE(selectedChangeLTEId);
        
        props.onAction && props.onAction();

        setIsLoading(false);
        navigate('/');
      } catch (err: any) {
        setGenericErrors(err.response?.data?.Message ?? err.message);
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <Modal
        show={props.showModal}
        onHide={handleClose}
        dialogClassName="lp-dialog"
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Trading Entity</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading && <Loader inlineLoader />}
          {genericErrors && (
            <div className="lp-errors">
              {genericErrors}
            </div>
          )}
          <div>
            <div className="lp-label">
              Trading Entity:
            </div>
            <CustomSelect
              id="changeLTEId"
              options={ltes}
              isLoading={isLoadingLTEs}
              onChange={val => setSelectedChangeLTEId(val?.id ?? null)}
              value={selectedChangeLTEId}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='success' onClick={doAction}>
            Change
          </Button>
          <Button variant="secondary-400" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
