interface Props {
  value: boolean
}

export default function BooleanFieldValue(props: Props) {
  return (
    <span className={`lp-chip boolean ${props.value ? 'success' : 'secondary'}`}>
      {props.value ? 'Yes' : 'No'}
    </span>
  );
}
