import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import SlidingPanel from 'components/SlidingPanel/index';
import { TemplateModel } from 'models/view/TemplateModel';
import { getHeaderAndFooterTemplate } from 'actions/settings';
import ViewCardTemplate from 'containers/Settings/TemplateSettings/TemplateActions/ViewCardTemplate/ViewCardTemplate';
import Loader from 'components/Loader';
import { HeaderAndFooterTemplateTypeIds } from 'enums/HeaderAndFooterTemplateTypeIds';

export default function HeaderAndFooterTemplate() {
  const [template, setTemplate] = useState<TemplateModel>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [genericErrors, setGenericErrors] = useState(null);

  const loadHeaderAndFooterTemplate = () => {
    setIsLoading(true);
    getHeaderAndFooterTemplate()
      .then((response) => {
        if(response.data == undefined || response.data.length == 0) {
          setTemplate({
            typeId: HeaderAndFooterTemplateTypeIds.HeaderAndFooterTemplateId
          } as any);
        }
        else {
          setTemplate(response.data);
        }
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadHeaderAndFooterTemplate();
  }, []);

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && <div className="lp-errors">{genericErrors}</div>}

      <Row className="templates">
        {template && (
          <ViewCardTemplate
            template={template}
            refreshTemplatesPage={loadHeaderAndFooterTemplate}
            hideEditTemplateTaxonomyTerms
          />
        )}
      </Row>

      <SlidingPanel />
    </>
  );
}
